// 入金情報
import React from 'react';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import TitleHeader from '../../../components/common/TitleHeader';
import { State as ReduxState } from '../../../modules';
import { track } from '../../../modules/logging';
import styled from 'styled-components';
import SelectBox from '../../../components/common/atoms/SelectBox';
import { StoresData } from '../../../modules/user';
import {
  changePeriod,
  initialFetch,
  actions as paymentUiActions,
  selectStore,
} from '../../../modules/payment/ui';
import {
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  API_STATE_INITIAL,
  API_STATE_STARTED,
  ApiState,
} from '../../../typedef/api/Utility';
import { BatchProcessedDate } from '../../../typedef/BatchProcessedDate';
import { PaymentMaster } from '../../../typedef/api/Payment/PaymentMaster';
import Templates from '../../../components/common/templates';
import { ActivityIndicator } from '../../../components/common';
import { assertNever } from '../../../helpers/util';
import ApiError from '../../../components/common/templates/ApiError';
import { black, textLinkColor, verylightgray, verylightgrayBorder } from '../../../constants/colors';
import CalendarIcon from '../../../icons/calendarIconBlack.svg';
import {
  PaymentSummary,
  TransferInfoSummary,
  GopTransferInfoSummary,
} from '../../../typedef/api/Payment/PaymentSummary';
import {
  actions as paymentApiActions,
  startFetchPaymentSummary,
  startFetchPayDetail,
  startFetchPayQrDetail,
  startFetchGopDetail,
} from '../../../modules/payment/api';
import Summary from './components/Summary';
import SummaryTable from './components/SummaryTable';
import PaymentCalendarModal from './components/PaymentCalendarModal';
import CsvDownloadModal from './components/CsvDownloadModal';
import PayDetailModal from './components/PayDetailModal';
import { storesWithTransferInfoSummary, transferYearMonthList } from '../../../selectors/paymentSelectors';
import OpenLinkIcon from '../../../icons/openLinkBlue.svg';
import DownloadIcon from '../../../icons/download.svg';
import { airPayEntryUrl, airPayUrl, airPayqrEntryUrl, airPayqrUrl } from '../../../constants/externalLink';
import {
  TRANSFER_APY_QR_USE_ACT_BTN_LID_PARAMETER,
  TRANSFER_BOTTOM_APY_TEXTLINK_ACT_BTN_LID_PARAMETER,
  TRANSFER_MPA_APY_USE_ACT_BTN_LID_PARAMETER,
} from '../../../constants/externalLinkParameter';
import { transferFaq } from '../../../constants/faqUrls';
import AirPayAppeal from './components/AirPayAppeal';
import { Waypoint } from 'react-waypoint';
import { genGaLog } from '../../../gaLogger';
import { PRODUCT_CODE } from './transferConstants';
import { assignedStoresSelector } from '../../../selectors/userDataSelector';
import { getCookie } from '../../../helpers/cookieHelper';
import { LocalYearMonthObj, formatter, mclDayjs, parser } from '../../../helpers/mclDate';

type DispatchProps = {
  readonly logging: typeof track;
  readonly selectStore: typeof selectStore;
  readonly initialFetch: typeof initialFetch;
  readonly changePeriod: typeof changePeriod;
  readonly startFetchPaymentSummary: typeof startFetchPaymentSummary;
  readonly fetchPayDetail: typeof startFetchPayDetail;
  readonly fetchPayQrDetail: typeof startFetchPayQrDetail;
  readonly fetchGopDetail: typeof startFetchGopDetail;
};
type StateProps = {
  readonly stores: ReadonlyArray<StoresData>;
  readonly selectedStores: Set<string>;
  readonly paymentMasterState: ApiState<PaymentMaster>;
  readonly yearMonthList?: ReadonlyArray<string> | undefined;
  readonly period: {
    year: string;
    month: string;
  };
  readonly yearMonth: LocalYearMonthObj;
  readonly paymentSummaryState: ApiState<PaymentSummary>;
  readonly storesWithTransferInfoSummary: ReadonlyArray<
    (TransferInfoSummary | GopTransferInfoSummary) & StoresData
  >;
  readonly batchProcessedDate: BatchProcessedDate;
  readonly isSpUse?: boolean;
};

type State = {
  isOpenPaymentCalendarModal: boolean;
  isOpenDownloadModal: boolean;
  isOpenPayDetaildModal: boolean;
  isChangeStore: boolean;
  selectedProductCode?: string;
  selectedTransferDate: string;
  selectedAkrCode: string;
};

type Props = RouteComponentProps<{}> & StateProps & DispatchProps;

class DepositInfomation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpenPaymentCalendarModal: false,
      isOpenDownloadModal: false,
      isOpenPayDetaildModal: false,
      isChangeStore: false,
      selectedProductCode: '',
      selectedTransferDate: '',
      selectedAkrCode: '',
    };
  }

  componentDidMount(): void {
    this.props.initialFetch();
  }

  selectDate = (value: string) => {
    const { changePeriod, startFetchPaymentSummary, selectedStores } = this.props;
    changePeriod(value);
    startFetchPaymentSummary(
      mclDayjs(value, formatter.mapiDefaultYearMonthNotFixed).format(formatter.mapiYearMonth),
      [...selectedStores]
    );
  };

  selectStores = (store: Set<string>) => {
    this.props.selectStore(store);
    this.setState({ isChangeStore: true });
  };

  onClose = () => {
    const { startFetchPaymentSummary, yearMonth, selectedStores } = this.props;
    const stores = [...selectedStores];
    if (stores.length !== 0 && this.state.isChangeStore) {
      startFetchPaymentSummary(parser.fromYearMonthObject(yearMonth).format(formatter.mapiYearMonth), [
        ...selectedStores,
      ]);
    }
    this.setState({ isChangeStore: false });
  };

  _onClickPayDetailLink = (
    transferDate: string,
    termStartDate: string,
    termEndDate: string,
    akrCode: string,
    deadlineDate: string,
    productManagementId: string,
    productCode?: string
  ) => {
    const { fetchPayDetail, fetchPayQrDetail, fetchGopDetail } = this.props;
    this.setState({
      isOpenPayDetaildModal: true,
      selectedProductCode: productCode,
      selectedTransferDate: transferDate,
      selectedAkrCode: akrCode,
    });
    if (productCode === 'apy') {
      fetchPayDetail(transferDate, termStartDate, termEndDate, akrCode, deadlineDate, productManagementId);
    } else if (productCode === 'mpa') {
      fetchPayQrDetail(transferDate, termStartDate, termEndDate, akrCode, deadlineDate, productManagementId);
    } else {
      fetchGopDetail(transferDate, termStartDate, termEndDate, productManagementId, akrCode);
    }
  };

  _closePayDetailModal = () => {
    this.setState({ isOpenPayDetaildModal: false });
    this.props.logging(_genCloseDetailModalLog());
  };

  _onClickDownloadLink = () => {
    this.setState({ isOpenDownloadModal: true });
    this.props.logging(_genClickCsvButtonLog());
  };

  _closeDownloadModal = () => {
    this.setState({ isOpenDownloadModal: false });
  };

  render() {
    const {
      logging,
      stores,
      selectedStores,
      paymentMasterState,
      yearMonthList,
      period,
      yearMonth,
      paymentSummaryState,
      storesWithTransferInfoSummary,
      batchProcessedDate,
      isSpUse,
    } = this.props;
    const isAirPayUse = stores.some(store => store.isAirPayUse);
    const isPayqrUse = stores.some(store => store.isPayqrUse);
    const isGopUse = stores.some(store => store.isGopUse);
    const isSingleStore = stores.length === 1;
    switch (paymentMasterState.type) {
      case API_STATE_INITIAL:
      case API_STATE_STARTED:
        return (
          <Templates.Center>
            <ActivityIndicator />
          </Templates.Center>
        );
      case API_STATE_COMPLETED:
        return (
          <Wrapper>
            <Waypoint
              onEnter={() => {
                logging(_genLoadedLog());
              }}
            />
            <TitleHeader
              track={logging}
              title="入金情報"
              faqTitle="入金情報の使い方"
              faqLink={transferFaq}
              pageName="transfer"
            />
            <Lead>
              Airペイ、Airペイ QR、オンライン決済（ホットペッパーグルメのスマート支払い、Airレジ
              オーダーのオンライン決済）の前日分までの入金情報をまとめて確認できます。
              {(isAirPayUse || isPayqrUse || isGopUse) &&
                '最新のデータはそれぞれの管理画面でご確認ください。'}
            </Lead>
            {/* AirペイとAirペイQRとGOPのどれも未利用の場合、Airペイ訴求画面を表示 */}
            {!isAirPayUse && !isPayqrUse && !isGopUse ? (
              <Waypoint
                onEnter={() => {
                  logging(_genCrossuseImpressionLog('apy_nompa'));
                }}
              >
                <AppealWrapper>
                  <AirPayAppeal
                    onClick={() => {
                      logging(_genCrossuseClickLog('apy_nompa'));
                    }}
                  />
                </AppealWrapper>
              </Waypoint>
            ) : (
              <React.Fragment>
                {/* AirペイとAirペイQRのクロスセル */}
                {(!isAirPayUse || !isPayqrUse) && (
                  <LinkWrapper>
                    {!isAirPayUse && (
                      <Waypoint
                        onEnter={() => {
                          logging(_genCrossuseImpressionLog('apy'));
                        }}
                      >
                        <Link
                          href={`${airPayEntryUrl}?lid=${TRANSFER_APY_QR_USE_ACT_BTN_LID_PARAMETER}`}
                          target="_blank"
                          onClick={() => {
                            logging(_genCrossuseClickLog('apy'));
                          }}
                        >
                          Airペイでクレジットカード・電子マネー決済を導入する
                          <StyledOpenLinkIcon />
                        </Link>
                      </Waypoint>
                    )}
                    {!isPayqrUse && (
                      <Waypoint
                        onEnter={() => {
                          logging(_genCrossuseImpressionLog('mpa'));
                        }}
                      >
                        <Link
                          href={`${airPayqrEntryUrl}?lid=${TRANSFER_MPA_APY_USE_ACT_BTN_LID_PARAMETER}`}
                          target="_blank"
                          onClick={() => {
                            logging(_genCrossuseClickLog('mpa'));
                          }}
                        >
                          Airペイ QRでQRコード決済を導入する
                          <StyledOpenLinkIcon />
                        </Link>
                      </Waypoint>
                    )}
                  </LinkWrapper>
                )}
                <SelectBoxWrapper>
                  {yearMonthList != null && (
                    <StyledDateSelectBox
                      options={yearMonthList.map(month => ({ key: month, value: month }))}
                      onChange={e => this.selectDate(e.value)}
                      onClick={() => {
                        logging(
                          _genSelectMonthLog(
                            parser.fromYearMonthObject(yearMonth).format(formatter.mapiYearMonth)
                          )
                        );
                      }}
                      size="auto"
                      balloonSize="small"
                      placeholder={
                        period.year === ''
                          ? // 初期表示は今月、yearMonthListは最も過去月から来月までの降順なので２番目
                            { key: yearMonthList[1], value: yearMonthList[1] }
                          : {
                              key: parser
                                .fromYearMonthObject(yearMonth)
                                .format(formatter.mapiDefaultYearMonthNotFixed),
                              value: parser
                                .fromYearMonthObject(yearMonth)
                                .format(formatter.mapiDefaultYearMonthNotFixed),
                            }
                      }
                    />
                  )}
                  {!isSingleStore && (
                    <StyledStoreSelectBox
                      required={true}
                      options={
                        new Map(
                          stores.map(store => {
                            return [store.akrCode, store.storeName];
                          })
                        )
                      }
                      allSelectMessage={'全店舗'}
                      onChange={stores => {
                        this.selectStores(stores);
                      }}
                      onClose={this.onClose}
                      onClick={() => {
                        logging(_genSelectStoreLog(selectedStores));
                      }}
                      selectedItems={selectedStores}
                    />
                  )}
                  <CalendarButton
                    onClick={() => {
                      this.setState({
                        isOpenPaymentCalendarModal: true,
                      });
                      logging(_genOpenCalendarLog());
                    }}
                  >
                    <StyledCalendarIcon />
                    入金カレンダー
                  </CalendarButton>
                  {this.state.isOpenPaymentCalendarModal && (
                    <PaymentCalendarModal
                      onClose={() => {
                        this.setState({
                          isOpenPaymentCalendarModal: false,
                        });
                        logging(_genCloseCalendarModalLog());
                      }}
                    />
                  )}
                </SelectBoxWrapper>
                {paymentSummaryState.type === API_STATE_COMPLETED ? (
                  <React.Fragment>
                    <Summary
                      paymentSummary={paymentSummaryState.payload}
                      isAirPayUse={isAirPayUse}
                      isPayqrUse={isPayqrUse}
                      isGopUse={isGopUse}
                      logging={logging}
                      isSpUse={isSpUse != null && isSpUse}
                    />
                    <DownloadLinkWrapper>
                      <DownloadLink onClick={this._onClickDownloadLink}>
                        <StyledCSVDownloadIcon />
                        <DownloadLinkText>各種データのダウンロード</DownloadLinkText>
                      </DownloadLink>
                    </DownloadLinkWrapper>
                    <SummaryTable
                      storesWithTransferInfoSummary={storesWithTransferInfoSummary}
                      paymentSummary={paymentSummaryState.payload}
                      logging={logging}
                      onClickPayDetai={this._onClickPayDetailLink}
                      isSingleStore={isSingleStore}
                    />
                    {this.state.isOpenPayDetaildModal && (
                      <PayDetailModal
                        productCode={this.state.selectedProductCode}
                        transferDate={this.state.selectedTransferDate}
                        akrCode={this.state.selectedAkrCode}
                        onClose={this._closePayDetailModal}
                      />
                    )}
                    {this.state.isOpenDownloadModal && (
                      <CsvDownloadModal
                        closeModal={this._closeDownloadModal}
                        tracker={logging}
                        batchProcessedDate={batchProcessedDate}
                        stores={stores}
                        defaultDate={yearMonth}
                        defaultStore={Array.from(selectedStores)}
                        yearMonthList={yearMonthList}
                        isAirPayUse={isAirPayUse}
                        isPayqrUse={isPayqrUse}
                      />
                    )}
                  </React.Fragment>
                ) : (
                  <Templates.Center>
                    <ActivityIndicator />
                  </Templates.Center>
                )}
                {(isAirPayUse || isPayqrUse) && (
                  <LinkWrapper>
                    {isAirPayUse && (
                      <Link
                        href={`${airPayUrl}?lid=${TRANSFER_BOTTOM_APY_TEXTLINK_ACT_BTN_LID_PARAMETER}`}
                        target="_blank"
                        onClick={() => {
                          logging(_genOpenAirPayAndQrLog('apy'));
                        }}
                      >
                        Airペイ管理画面
                        <StyledOpenLinkIcon />
                      </Link>
                    )}
                    {isPayqrUse && (
                      <Link
                        href={`${airPayqrUrl}?lid=${TRANSFER_BOTTOM_APY_TEXTLINK_ACT_BTN_LID_PARAMETER}`}
                        target="_blank"
                        onClick={() => {
                          logging(_genOpenAirPayAndQrLog('mpa'));
                        }}
                      >
                        Airペイ QR管理画面
                        <StyledOpenLinkIcon />
                      </Link>
                    )}
                  </LinkWrapper>
                )}
              </React.Fragment>
            )}
          </Wrapper>
        );
      case API_STATE_FAILED:
        return (
          <Templates.Center>
            <ApiError />
          </Templates.Center>
        );
      default:
        return assertNever(paymentMasterState);
    }
  }
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  stores: assignedStoresSelector(state),
  selectedStores: state.payment.ui.selectedStores,
  paymentMasterState: state.payment.payment.paymentMasterState,
  yearMonthList: transferYearMonthList(state),
  period: state.payment.ui.period,
  yearMonth: state.payment.ui.yearMonth,
  paymentSummaryState: state.payment.payment.paymentSummaryState,
  storesWithTransferInfoSummary: storesWithTransferInfoSummary(state),
  batchProcessedDate: state.uiConfig.batchProcessedDate,
  isSpUse: state.user.data?.isSpUse,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    ...bindActionCreators(
      {
        logging: track,
        selectStore: paymentUiActions.selectStore,
        initialFetch: paymentUiActions.initialFetch,
        changePeriod: paymentUiActions.changePeriod,
        startFetchPaymentSummary: paymentApiActions.startFetchPaymentSummary,
        fetchPayDetail: paymentApiActions.startFetchPayDetail,
        fetchPayQrDetail: paymentApiActions.startFetchPayQrDetail,
        fetchGopDetail: paymentApiActions.startFetchGopDetail,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DepositInfomation);

const _genLoadedLog = () => {
  const cookieData = getCookie('influxData');
  let vos: string | undefined;
  let lid: string | undefined;
  let viaPromoFlg: string | undefined;
  if (cookieData != null) {
    const cookieDataJson = JSON.parse(cookieData);
    vos = cookieDataJson.vos;
    lid = cookieDataJson.lid;
    viaPromoFlg = cookieDataJson.via_promo_flg;
  }
  return genGaLog('transfer', 'transfer', 'on_load', {}, {}, 'load', undefined, vos, lid, viaPromoFlg);
};

const _genSelectMonthLog = date => {
  return genGaLog('transfer', 'top_menu', 'select_month', date, {}, 'click');
};

const _genSelectStoreLog = akrCode => {
  return genGaLog('transfer', 'top_menu', 'select_store', akrCode, {}, 'click');
};

const _genOpenCalendarLog = () => {
  return genGaLog('transfer', 'top_menu', 'open_calendar', {}, {}, 'click');
};

const _genCrossuseImpressionLog = (productCode: keyof typeof PRODUCT_CODE | 'apy_nompa') => {
  return genGaLog(
    'transfer',
    `${productCode}_crossuse_panel`,
    'impression',
    {},
    { type: [productCode === 'apy_nompa' ? 'apy' : productCode] },
    'impression'
  );
};

const _genCrossuseClickLog = (productCode: keyof typeof PRODUCT_CODE | 'apy_nompa') => {
  return genGaLog(
    'transfer',
    `${productCode}_crossuse_panel`,
    'open',
    {},
    { type: [productCode === 'apy_nompa' ? 'apy' : productCode] },
    'click'
  );
};

const _genOpenAirPayAndQrLog = (feature: keyof typeof PRODUCT_CODE) => {
  return genGaLog('transfer', `open_${feature}`, 'open', {}, {}, 'click');
};

const _genClickCsvButtonLog = () => {
  return genGaLog('transfer', 'top_menu', 'open_csv', {}, {}, 'click');
};

const _genCloseCalendarModalLog = () => {
  return genGaLog('transfer', 'top_menu', 'close_calendar', {}, {}, 'click');
};

const _genCloseDetailModalLog = () => {
  return genGaLog('transfer', 'close_transfer_detail', 'close', {}, {}, 'click');
};

const Wrapper = styled.div`
  padding: 16px 24px 24px;
  height: 100%;
`;

const Lead = styled.div`
  font-size: 14px;
  margin-top: 16px;
`;

const SelectBoxWrapper = styled.div`
  display: flex;
  margin-top: 24px;
`;

const StyledStoreSelectBox = styled(SelectBox.multiple)`
  width: 320px;
`;
const StyledDateSelectBox = styled(SelectBox.normal)`
  width: 140px;
  margin-right: 16px;
`;

const CalendarButton = styled.button`
  border-radius: 4px;
  border: 1px solid ${verylightgrayBorder};
  background: var(--base-very-light-gray, ${verylightgray});
  box-shadow: 0px 1px 0px 0px ${verylightgrayBorder};
  font-size: 14px;
  font-weight: 300;
  color: ${black};
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
`;

const StyledCalendarIcon = styled(CalendarIcon)`
  margin-right: 6px;
`;

const Link = styled.a`
  color: ${textLinkColor};
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  margin-right: 26px;
`;

const StyledOpenLinkIcon = styled(OpenLinkIcon)`
  margin-left: 6px;
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  padding-bottom: 16px;
`;

const DownloadLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 12px;
`;

const DownloadLink = styled.div`
  display: flex;
  cursor: pointer;
`;

const DownloadLinkText = styled.span`
  font-size: 14px;
  color: ${textLinkColor};
`;

const StyledCSVDownloadIcon = styled(DownloadIcon)`
  margin-right: 6px;
`;

const AppealWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 43px);
`;
