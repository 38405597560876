import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ToggleStateless } from '@air-kit/air-kit';
import { Formik, Form, FieldArray } from 'formik';
import { genGaLog } from '../../../gaLogger';
import { Row, Col } from '../../../components/common/Grid';
import { uploadBorderColor, hoverAndSelectedColor } from '../../../constants/colors';
import Text from '../../../components/common/atoms/Text';
import Dialog from '../../../components/common/molecules/Airkit/AirDialog';
import Templates from '../../../components/common/templates';
import { ActivityIndicator } from '../../../components/common';
import Modal from '../../../components/common/molecules/Airkit/AirModal';
import { startPostConfig, toggleDialog, edit, toggleModal } from '../../../modules/allIndex';
import DraggableIcon from '../../../icons/DraggableIcon.svg';
import { allIndexCustomizeModalName, isColumnForGourmet, itemDescriptions } from './AllIndexConstants';
import { State as ReduxState } from '../../../modules';
import { MydConfig } from '../../../modules/allIndex';
import { ApiState } from '../../../typedef/api/Utility';
import BorderedLabel from '../../../components/common/atoms/BorderedLabel';
import { track } from '../../../modules/logging';
type DispatchProps = {
  startPostConfig: typeof startPostConfig;
  toggleDialog: typeof toggleDialog;
  edit: typeof edit;
  toggleModal: typeof toggleModal;
  onClose: () => void;
};
type StateProps = {
  readonly mydConfig: ApiState<MydConfig>;
  readonly isOpenDialog: boolean;
  readonly isEditing: boolean;
  readonly isPosting: boolean;
};
type Props = Readonly<
  {
    config: MydConfig;
    descriptions: {
      main: typeof itemDescriptions.main;
      sub: { [key in keyof typeof itemDescriptions.sub]: string | React.ReactElement | undefined };
    };
    track: typeof track;
    // 月末売上予測のデータがあるか
    hasMonthlySalesPredicted: boolean;
  } & DispatchProps &
    StateProps
>;

class ConfigModal extends React.Component<Props> {
  // func: arrayHelperのmoveが渡される.
  onDragEnd(result, func) {
    const { edit } = this.props;
    edit();

    if (!result.destination) {
      return;
    }
    func(result.source.index, result.destination.index);
  }

  _handleCloseDialog() {
    const { toggleDialog } = this.props;
    toggleDialog();
  }

  _handleConfirm() {
    const { toggleDialog, toggleModal } = this.props;
    toggleDialog();
    toggleModal();
  }

  render() {
    const {
      mydConfig,
      isPosting,
      isEditing,
      isOpenDialog,
      config,
      descriptions,
      edit,
      startPostConfig,
      onClose,
      track,
      hasMonthlySalesPredicted,
    } = this.props;

    // 時間あるときにutility化したい
    // @ts-ignore
    const data = config.map(conf => {
      return {
        item: conf.item, // 項目Key(= Column Name)(e.g. sales, lastYearMonthlySales)
        main: descriptions.main[conf.item],
        sub: descriptions.sub[conf.item],
        visible: conf.visible,
      };
    });

    if (mydConfig.type === 'API_STATE_COMPLETED' && data) {
      const monthlySalesPredictedIndex = data.findIndex(c => c.item === 'monthlySalesPredicted');

      return (
        <React.Fragment>
          <Formik
            initialValues={{
              config: data,
            }}
            onSubmit={() => {}}
          >
            {props => (
              <Modal
                buttonText={isPosting ? '保存中' : '保存する'}
                disabled={!isEditing || isPosting}
                onSubmit={() => {
                  const log = _startPostConfig();

                  startPostConfig(
                    {
                      summariesItem: props.values.config,
                    },
                    log
                  );
                }}
                onClose={onClose}
                title={'項目の編集（全店舗一覧）'}
                modalId="all_index_edit_modal"
                modalInnerId="all_index_edit_modal_item_list"
                buttonId="all_index_edit_modal_save_button"
              >
                <ModalContentWrapper>
                  <DescriptionWrapper>
                    項目の表示／非表示切り替え、ドラッグで順番の入れ替えができます。
                  </DescriptionWrapper>
                  <Form>
                    <FieldArray
                      name="config"
                      render={arrayHelpers => (
                        <DragDropContext onDragEnd={result => this.onDragEnd(result, arrayHelpers.move)}>
                          <Droppable droppableId="all_index_config" type="CONFIG">
                            {provided => (
                              <div ref={provided.innerRef} {...provided.droppableProps}>
                                {props.values.config.map((config, idx) => {
                                  return (
                                    <Draggable
                                      key={`draggable_${idx}`}
                                      draggableId={`draggable_${idx}`}
                                      index={idx}
                                    >
                                      {(provided, { isDragging }) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.dragHandleProps}
                                          {...provided.draggableProps}
                                          style={{
                                            background: isDragging && hoverAndSelectedColor,
                                            opacity: isDragging && 0.8,
                                            ...provided.draggableProps.style,
                                            left: 0,
                                          }}
                                        >
                                          <ConfigRow>
                                            <ConfigItem num={4} left flow="row">
                                              <StyledDraggable />
                                              {allIndexCustomizeModalName[config.item]}
                                            </ConfigItem>
                                            <ConfigItem num={7} left flow="column">
                                              {isColumnForGourmet(config.item) && (
                                                <Row style={{ marginBottom: '4px' }}>
                                                  <BorderedLabel leadingSpace={0}>飲食店向け</BorderedLabel>
                                                </Row>
                                              )}
                                              <Row>
                                                <Text.Small>{config.main}</Text.Small>
                                              </Row>
                                              <Row>
                                                <Text.SubDescription>{config.sub}</Text.SubDescription>
                                              </Row>
                                            </ConfigItem>
                                            <ConfigItem num={1}>
                                              <ToggleStateless
                                                name={`config.${idx}.visible`}
                                                onChange={() => {
                                                  // 月末売上予測のトグル変更時のみログ出力
                                                  if (monthlySalesPredictedIndex === idx) {
                                                    track(
                                                      _genMonthlySalesPredictedChangeLog(
                                                        !props.values.config[idx].visible,
                                                        hasMonthlySalesPredicted
                                                      )
                                                    );
                                                  }
                                                  props.setFieldValue(
                                                    `config.${idx}.visible`,
                                                    !props.values.config[idx].visible
                                                  );
                                                  edit();
                                                }}
                                                isChecked={config.visible}
                                              />
                                            </ConfigItem>
                                          </ConfigRow>
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                })}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      )}
                    />
                  </Form>
                </ModalContentWrapper>
              </Modal>
            )}
          </Formik>

          {isOpenDialog && isEditing && (
            <Dialog
              actions={[
                {
                  text: '設定を続ける',
                  onClick: () => this._handleCloseDialog(),
                },
                {
                  text: '変更を破棄',
                  onClick: () => this._handleConfirm(),
                  primary: true,
                },
              ]}
              title="保存されていない変更があります"
              isConfirm
            >
              画面を離れると変更内容は保存されません。
            </Dialog>
          )}
        </React.Fragment>
      );
    } else {
      return (
        <ModalContentWrapper>
          <Templates.Center>
            <ActivityIndicator />
          </Templates.Center>
        </ModalContentWrapper>
      );
    }
  }
}

const _startPostConfig = () => {
  return genGaLog('all_index_customize_this_month', 'form', 'save_value', {}, {}, 'click');
};

const _genMonthlySalesPredictedChangeLog = (isActive: boolean, hasMonthlySalesPredicted: boolean) => {
  return genGaLog(
    'all_index',
    'all_index',
    `change_monthlySalesPredicted_${isActive ? 'on' : 'off'}`,
    {},
    isActive ? { dataState: hasMonthlySalesPredicted ? 'notNULL' : 'NULL' } : {},
    'click'
  );
};

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    mydConfig: state.allIndex.mydConfig,
    isOpenDialog: state.allIndex.isOpenDialog,
    isEditing: state.allIndex.isEditing,
    isPosting: state.allIndex.isPosting,
  };
};

export default connect(mapStateToProps, {
  startPostConfig,
  toggleDialog,
  edit,
  toggleModal,
})(ConfigModal);
const ModalContentWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;
const DescriptionWrapper = styled.div`
  background: #f3f3f3;
  font-size: 14px;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 16px;
`;
const ConfigItem = styled(Col)<{ flow?: string; left?: boolean }>`
  display: flex;
  flex-flow: ${props => props.flow} nowrap;
  align-items: center;
  justify-content: ${props => !props.left && 'center'};
`;
const StyledDraggable = styled(DraggableIcon)`
  margin-left: 12px;
  margin-right: 12px;
`;
const ConfigRow = styled(Row)`
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: solid 1px ${uploadBorderColor};
  user-select: none;
`;
