import axios from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { AxiosResponse } from 'axios';
import { MapiResponse, ApiPromise, ErrorType } from '../typedef/api/Utility';
import { YearlyLookbackResponse } from '../typedef/api/YearlyReport/Yearlyreport';

const YearlyReportAPI = {
  // リクエスト（targetYear）なしの場合は最新のレスポンスが返ってくる
  fetchYearlyReport(targetYear?: number): ApiPromise<YearlyLookbackResponse> {
    return axios
      .get(getAPIURL('core/v1/yearly_lookback/report'), {
        params: {
          targetYear,
        },
      })
      .then((res: AxiosResponse<MapiResponse<YearlyLookbackResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },
};
export default YearlyReportAPI;
