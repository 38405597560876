import * as React from 'react';
import styled from 'styled-components';
import {
  FormatTenThousand,
  baseFontSize,
  prefixUnit,
  postfixUnit,
  LocaleInteger,
  Decimal,
  signWithZero,
} from '../../../../components/common/atoms/Number';
import * as Styles from './salesAnalysis/Styles';
import { uploadBorderColor, airGray, disabledTextColor } from '../../../../constants/colors';
import { Course, NoCourse, ProductAnalysis } from '../../../../typedef/api/MonthlyLookback';
import DetailedFlowFirst from '../../../../icons/detailedFlowFirst.png';
import DetailedFlowSecond from '../../../../icons/detailedFlowSecond.png';

type Props = {
  readonly course?: Course | null;
  readonly noCourse?: NoCourse | null;
  readonly analysisType: 'ランチ' | 'ディナー' | '店内' | '';
  readonly productAnalysis?: ProductAnalysis;
};

const Format万 = baseFontSize(24)(prefixUnit('¥')(postfixUnit('万')(FormatTenThousand)));
const FormatSmall万 = baseFontSize(14)(prefixUnit('¥')(postfixUnit('万')(FormatTenThousand)));
const Format比較万 = baseFontSize(12)(prefixUnit('¥')(postfixUnit('万')(signWithZero(FormatTenThousand))));

const Format円 = baseFontSize(20)(prefixUnit('¥')(LocaleInteger));
const FormatMedium円 = baseFontSize(14)(prefixUnit('¥')(LocaleInteger));
const FormatSmall円 = baseFontSize(10)(prefixUnit('¥')(LocaleInteger));
const Format比較円 = baseFontSize(12)(prefixUnit('¥')(signWithZero(LocaleInteger)));

const Format人 = baseFontSize(20)(postfixUnit('人')(LocaleInteger));
const FormatSmall人 = baseFontSize(14)(postfixUnit('人')(LocaleInteger));
const Format比較人 = baseFontSize(12)(postfixUnit('人')(signWithZero(LocaleInteger)));

const Format品 = baseFontSize(10)(postfixUnit('品')(Decimal));

class SalesLogicTree extends React.Component<Props> {
  _renderSmallBox = (
    title: string,
    sales: number,
    orderNum: string,
    unitPrice: number,
    lastYearDiff?: number | null,
    lastMonthDiff?: number | null,
    isSmall?: boolean
  ) => {
    return (
      <SecondDisassemblyBox height={120}>
        <BoxHeader smallBox isSmall={isSmall}>
          <SubTitle isSmall={isSmall}>{title}</SubTitle>
        </BoxHeader>
        <ContentSmall>
          <TableContent width={100}>
            <FormatMedium円 value={sales} />
          </TableContent>
          <TableContent width={100} fontSize={10}>
            前年&nbsp;
            {lastYearDiff != null ? (
              <React.Fragment>
                <Format比較円 value={lastYearDiff} />
                <Styles.ArrowCheck value={lastYearDiff} />
              </React.Fragment>
            ) : (
              'ー'
            )}
          </TableContent>
          <TableContent width={100} fontSize={10}>
            前月&nbsp;
            {lastMonthDiff != null ? (
              <React.Fragment>
                <Format比較円 value={lastMonthDiff} />
                <Styles.ArrowCheck value={lastMonthDiff} />
              </React.Fragment>
            ) : (
              'ー'
            )}
          </TableContent>
          <TableContent width={100} fontSize={10}>
            (<Format品 value={orderNum} />
            &nbsp;/&nbsp;
            <FormatSmall円 value={unitPrice} />)
          </TableContent>
        </ContentSmall>
      </SecondDisassemblyBox>
    );
  };

  _renderHeader = (title: string) => {
    return (
      <BoxHeader>
        <TableTitle>{title}</TableTitle>
        <TableTitleText>前年</TableTitleText>
        <TableTitleText>前月</TableTitleText>
        <TableTitleText>目標</TableTitleText>
      </BoxHeader>
    );
  };

  _renderSalesLine = (
    sales: number,
    lastYearSalesDiff?: number | null,
    lastMonthSalesDiff?: number | null,
    lastMonthGoalSalesDiff?: number | null
  ) => {
    return (
      <React.Fragment>
        <TableContent width={34}>
          <Format万 value={sales} />
        </TableContent>
        <TableContent width={22} fontSize={12} isCenter>
          {lastYearSalesDiff != null ? (
            <React.Fragment>
              <Format比較万 value={lastYearSalesDiff} />
              <Styles.ArrowCheckMan value={lastYearSalesDiff} />
            </React.Fragment>
          ) : (
            'ー'
          )}
        </TableContent>
        <TableContent width={22} fontSize={12} isCenter>
          {lastMonthSalesDiff != null ? (
            <React.Fragment>
              <Format比較万 value={lastMonthSalesDiff} />
              <Styles.ArrowCheckMan value={lastMonthSalesDiff} />
            </React.Fragment>
          ) : (
            'ー'
          )}
        </TableContent>
        <TableContent width={22} fontSize={12} isCenter>
          {lastMonthGoalSalesDiff != null ? (
            <React.Fragment>
              <Format比較万 value={lastMonthGoalSalesDiff} />
              <Styles.ArrowCheckMan value={lastMonthGoalSalesDiff} />
            </React.Fragment>
          ) : (
            'ー'
          )}
        </TableContent>
      </React.Fragment>
    );
  };

  _renderVisitorNumLine = (
    visitorNum: number,
    lastYearVisitorNumDiff?: number | null,
    lastMonthVisitorNumDiff?: number | null,
    goalVisitorNumDiff?: number | null
  ) => {
    return (
      <React.Fragment>
        <TableContent width={34}>
          <Format人 value={visitorNum} />
        </TableContent>
        <TableContent width={22} fontSize={12} isCenter>
          {lastYearVisitorNumDiff != null ? (
            <React.Fragment>
              <Format比較人 value={lastYearVisitorNumDiff} />
              <Styles.ArrowCheck value={lastYearVisitorNumDiff} />
            </React.Fragment>
          ) : (
            'ー'
          )}
        </TableContent>
        <TableContent width={22} fontSize={12} isCenter>
          {lastMonthVisitorNumDiff != null ? (
            <React.Fragment>
              <Format比較人 value={lastMonthVisitorNumDiff} />
              <Styles.ArrowCheck value={lastMonthVisitorNumDiff} />
            </React.Fragment>
          ) : (
            'ー'
          )}
        </TableContent>
        <TableContent width={22} fontSize={12} isCenter>
          {goalVisitorNumDiff != null ? (
            <React.Fragment>
              <Format比較人 value={goalVisitorNumDiff} />
              <Styles.ArrowCheck value={goalVisitorNumDiff} />
            </React.Fragment>
          ) : (
            'ー'
          )}
        </TableContent>
      </React.Fragment>
    );
  };

  _renderPaymentLine = (
    customerPayment: number,
    lastYearCustomerPaymentDiff?: number | null,
    lastMonthCustomerPaymentDiff?: number | null,
    goalCustomerPaymentDiff?: number | null
  ) => {
    return (
      <React.Fragment>
        <TableContent width={34}>
          <Format円 value={customerPayment} />
        </TableContent>
        <TableContent width={22} fontSize={12} isCenter>
          {lastYearCustomerPaymentDiff != null ? (
            <React.Fragment>
              <Format比較円 value={lastYearCustomerPaymentDiff} />
              <Styles.ArrowCheck value={lastYearCustomerPaymentDiff} />
            </React.Fragment>
          ) : (
            'ー'
          )}
        </TableContent>
        <TableContent width={22} fontSize={12} isCenter>
          {lastMonthCustomerPaymentDiff != null ? (
            <React.Fragment>
              <Format比較円 value={lastMonthCustomerPaymentDiff} />
              <Styles.ArrowCheck value={lastMonthCustomerPaymentDiff} />
            </React.Fragment>
          ) : (
            'ー'
          )}
        </TableContent>
        <TableContent width={22} fontSize={12} isCenter>
          {goalCustomerPaymentDiff != null ? (
            <React.Fragment>
              <Format比較円 value={goalCustomerPaymentDiff} />
              <Styles.ArrowCheck value={goalCustomerPaymentDiff} />
            </React.Fragment>
          ) : (
            'ー'
          )}
        </TableContent>
      </React.Fragment>
    );
  };

  _renderCourse = (course: Course) => {
    const { analysisType, productAnalysis } = this.props;
    const _renderSecondFlow = (course: Course) => {
      return (
        <React.Fragment>
          <FlowTop src={DetailedFlowSecond} alt="フローチャート" />
          <SecondDisassembly>
            <Title>アラカルト客単価の分解</Title>
            {this._renderSmallBox(
              'フード',
              course.alacarteFoodCustomerPayment,
              course.perVisitorAlacarteFoodOrderNum,
              course.perItemAlacarteFoodUnitPrice,
              course.lastYearAlacarteFoodCustomerPaymentDiff,
              course.lastMonthAlacarteFoodCustomerPaymentDiff
            )}
            {this._renderSmallBox(
              'ドリンク',
              course.alacarteDrinkCustomerPayment,
              course.perVisitorAlacarteDrinkOrderNum,
              course.perItemAlacarteDrinkUnitPrice,
              course.lastYearAlacarteDrinkCustomerPaymentDiff,
              course.lastMonthAlacarteDrinkCustomerPaymentDiff
            )}
            {this._renderSmallBox(
              'フードドリンク以外',
              course.alacarteOtherCustomerPayment,
              course.perVisitorAlacarteOtherOrderNum,
              course.perItemAlacarteOtherUnitPrice,
              course.lastYearAlacarteOtherCustomerPaymentDiff,
              course.lastMonthAlacarteOtherCustomerPaymentDiff,
              true
            )}
            <Description>(1人あたり商品点数/1品あたり単価)</Description>
          </SecondDisassembly>
        </React.Fragment>
      );
    };

    return (
      <Wrapper>
        <Box width={100} height={130}>
          {this._renderHeader(analysisType + '売上')}
          <Content>
            {this._renderSalesLine(
              course.sales,
              course.lastYearSalesDiff,
              course.lastMonthSalesDiff,
              course.lastMonthGoalSalesDiff
            )}
            <Hr />
            <TableContent width={16}>
              <SubTitle>コース</SubTitle>
            </TableContent>
            <TableContent width={18}>
              <FormatSmall万 value={course.courseSales} />
            </TableContent>
            <TableContent width={22} fontSize={12} isCenter>
              {course.lastYearCourseSalesDiff != null ? (
                <React.Fragment>
                  <Format比較万 value={course.lastYearCourseSalesDiff} />
                  <Styles.ArrowCheckMan value={course.lastYearCourseSalesDiff} />
                </React.Fragment>
              ) : (
                'ー'
              )}
            </TableContent>
            <TableContent width={22} fontSize={12} isCenter>
              {course.lastMonthCourseSalesDiff != null ? (
                <React.Fragment>
                  <Format比較万 value={course.lastMonthCourseSalesDiff} />
                  <Styles.ArrowCheckMan value={course.lastMonthCourseSalesDiff} />
                </React.Fragment>
              ) : (
                'ー'
              )}
            </TableContent>
            <TableContent width={22} />
            <TableContent width={16}>
              <SubTitle>アラカルト</SubTitle>
            </TableContent>
            <TableContent width={18}>
              <FormatSmall万 value={course.alacarteSales} />
            </TableContent>
            <TableContent width={22} fontSize={12} isCenter>
              {course.lastYearAlacarteSalesDiff != null ? (
                <React.Fragment>
                  <Format比較万 value={course.lastYearAlacarteSalesDiff} />
                  <Styles.ArrowCheckMan value={course.lastYearAlacarteSalesDiff} />
                </React.Fragment>
              ) : (
                'ー'
              )}
            </TableContent>
            <TableContent width={22} fontSize={12} isCenter>
              {course.lastMonthAlacarteSalesDiff != null ? (
                <React.Fragment>
                  <Format比較万 value={course.lastMonthAlacarteSalesDiff} />
                  <Styles.ArrowCheckMan value={course.lastMonthAlacarteSalesDiff} />
                </React.Fragment>
              ) : (
                'ー'
              )}
            </TableContent>
          </Content>
        </Box>
        <FlowTop src={DetailedFlowFirst} alt="フローチャート" isFirst />
        <FirstDisassembly>
          <FirstDisassemblyBox width={100} height={126}>
            {this._renderHeader(analysisType + '客数')}
            <Content>
              {this._renderVisitorNumLine(
                course.visitorNum,
                course.lastYearVisitorNumDiff,
                course.lastMonthVisitorNumDiff,
                course.goalVisitorNumDiff
              )}
              <Hr />
              <TableContent width={16}>
                <SubTitle>コース</SubTitle>
              </TableContent>
              <TableContent width={18}>
                <FormatSmall人 value={course.courseVisitorNum} />
              </TableContent>
              <TableContent width={22} fontSize={12} isCenter>
                {course.lastYearCourseVisitorNumDiff != null ? (
                  <React.Fragment>
                    <Format比較人 value={course.lastYearCourseVisitorNumDiff} />
                    <Styles.ArrowCheck value={course.lastYearCourseVisitorNumDiff} />
                  </React.Fragment>
                ) : (
                  'ー'
                )}
              </TableContent>
              <TableContent width={22} fontSize={12} isCenter>
                {course.lastMonthCourseVisitorNumDiff != null ? (
                  <React.Fragment>
                    <Format比較人 value={course.lastMonthCourseVisitorNumDiff} />
                    <Styles.ArrowCheck value={course.lastMonthCourseVisitorNumDiff} />
                  </React.Fragment>
                ) : (
                  'ー'
                )}
              </TableContent>
              <TableContent width={22} />
              <TableContent width={16}>
                <SubTitle>アラカルト</SubTitle>
              </TableContent>
              <TableContent width={18}>
                <FormatSmall人 value={course.alacarteVisitorNum} />
              </TableContent>
              <TableContent width={22} fontSize={12} isCenter>
                {course.lastYearAlacarteVisitorNumDiff != null ? (
                  <React.Fragment>
                    <Format比較人 value={course.lastYearAlacarteVisitorNumDiff} />
                    <Styles.ArrowCheck value={course.lastYearAlacarteVisitorNumDiff} />
                  </React.Fragment>
                ) : (
                  'ー'
                )}
              </TableContent>
              <TableContent width={22} fontSize={12} isCenter>
                {course.lastMonthAlacarteVisitorNumDiff != null ? (
                  <React.Fragment>
                    <Format比較人 value={course.lastMonthAlacarteVisitorNumDiff} />
                    <Styles.ArrowCheck value={course.lastMonthAlacarteVisitorNumDiff} />
                  </React.Fragment>
                ) : (
                  'ー'
                )}
              </TableContent>
            </Content>
          </FirstDisassemblyBox>

          <FirstDisassemblyBox width={100} height={126}>
            {this._renderHeader(analysisType + '客単価')}
            <Content>
              {this._renderPaymentLine(
                course.customerPayment,
                course.lastYearCustomerPaymentDiff,
                course.lastMonthCustomerPaymentDiff,
                course.goalCustomerPaymentDiff
              )}
              <Hr />
              <TableContent width={16}>
                <SubTitle>コース</SubTitle>
              </TableContent>
              <TableContent width={18}>
                <FormatMedium円 value={course.courseCustomerPayment} />
              </TableContent>
              <TableContent width={22} fontSize={12} isCenter>
                {course.lastYearCourseCustomerPaymentDiff != null ? (
                  <React.Fragment>
                    <Format比較円 value={course.lastYearCourseCustomerPaymentDiff} />
                    <Styles.ArrowCheck value={course.lastYearCourseCustomerPaymentDiff} />
                  </React.Fragment>
                ) : (
                  'ー'
                )}
              </TableContent>
              <TableContent width={22} fontSize={12} isCenter>
                {course.lastMonthCourseCustomerPaymentDiff != null ? (
                  <React.Fragment>
                    <Format比較円 value={course.lastMonthCourseCustomerPaymentDiff} />
                    <Styles.ArrowCheck value={course.lastMonthCourseCustomerPaymentDiff} />
                  </React.Fragment>
                ) : (
                  'ー'
                )}
              </TableContent>
              <TableContent width={22} />
              <TableContent width={16}>
                <SubTitle>アラカルト</SubTitle>
              </TableContent>
              <TableContent width={18}>
                <FormatMedium円 value={course.alacarteCustomerPayment} />
              </TableContent>
              <TableContent width={22} fontSize={12} isCenter>
                {course.lastYearAlacarteCustomerPaymentDiff != null ? (
                  <React.Fragment>
                    <Format比較円 value={course.lastYearAlacarteCustomerPaymentDiff} />
                    <Styles.ArrowCheck value={course.lastYearAlacarteCustomerPaymentDiff} />
                  </React.Fragment>
                ) : (
                  'ー'
                )}
              </TableContent>
              <TableContent width={22} fontSize={12} isCenter>
                {course.lastMonthAlacarteCustomerPaymentDiff != null ? (
                  <React.Fragment>
                    <Format比較円 value={course.lastMonthAlacarteCustomerPaymentDiff} />
                    <Styles.ArrowCheck value={course.lastMonthAlacarteCustomerPaymentDiff} />
                  </React.Fragment>
                ) : (
                  'ー'
                )}
              </TableContent>
            </Content>
          </FirstDisassemblyBox>
        </FirstDisassembly>
        {productAnalysis != null
          ? !productAnalysis?.isInputLunchSales && !productAnalysis?.isRestaurant
            ? null
            : _renderSecondFlow(course)
          : _renderSecondFlow(course)}
      </Wrapper>
    );
  };

  _renderNoCourse = (noCourse: NoCourse) => {
    const { analysisType, productAnalysis } = this.props;
    const _renderSecondFlow = (noCourse: NoCourse) => {
      return (
        <>
          <FlowTop src={DetailedFlowSecond} alt="フローチャート" />
          <SecondDisassembly>
            <Title>客単価の分解</Title>
            {this._renderSmallBox(
              'フード',
              noCourse.foodCustomerPayment,
              noCourse.perVisitorFoodOrderNum,
              noCourse.perItemFoodUnitPrice,
              noCourse.lastYearFoodCustomerPaymentDiff,
              noCourse.lastMonthFoodCustomerPaymentDiff
            )}
            {this._renderSmallBox(
              'ドリンク',
              noCourse.drinkCustomerPayment,
              noCourse.perVisitorDrinkOrderNum,
              noCourse.perItemDrinkUnitPrice,
              noCourse.lastYearDrinkCustomerPaymentDiff,
              noCourse.lastMonthDrinkCustomerPaymentDiff
            )}
            {this._renderSmallBox(
              'フードドリンク以外',
              noCourse.otherCustomerPayment,
              noCourse.perVisitorOtherOrderNum,
              noCourse.perItemOtherUnitPrice,
              noCourse.lastYearOtherCustomerPaymentDiff,
              noCourse.lastMonthOtherCustomerPaymentDiff,
              true
            )}
            <Description>(1人あたり商品点数/1品あたり単価)</Description>
          </SecondDisassembly>
        </>
      );
    };
    return (
      <Wrapper>
        <Box width={100} height={94}>
          {this._renderHeader(analysisType + '売上')}
          <Content>
            {this._renderSalesLine(
              noCourse.sales,
              noCourse.lastYearSalesDiff,
              noCourse.lastMonthSalesDiff,
              noCourse.lastMonthGoalSalesDiff
            )}
          </Content>
        </Box>
        <FlowTop src={DetailedFlowFirst} alt="フローチャート" isFirst isSmall />
        <FirstDisassembly>
          <FirstDisassemblyBox width={100} height={88}>
            {this._renderHeader(analysisType + '客数')}
            <Content>
              {this._renderVisitorNumLine(
                noCourse.visitorNum,
                noCourse.lastYearVisitorNumDiff,
                noCourse.lastMonthVisitorNumDiff,
                noCourse.goalVisitorNumDiff
              )}
            </Content>
          </FirstDisassemblyBox>

          <FirstDisassemblyBox width={100} height={88}>
            {this._renderHeader(analysisType + '客単価')}
            <Content>
              {this._renderPaymentLine(
                noCourse.customerPayment,
                noCourse.lastYearCustomerPaymentDiff,
                noCourse.lastMonthCustomerPaymentDiff,
                noCourse.goalCustomerPaymentDiff
              )}
            </Content>
          </FirstDisassemblyBox>
        </FirstDisassembly>
        {productAnalysis != null
          ? !productAnalysis?.isInputLunchSales && !productAnalysis?.isRestaurant
            ? null
            : _renderSecondFlow(noCourse)
          : _renderSecondFlow(noCourse)}
      </Wrapper>
    );
  };

  render() {
    const { course, noCourse } = this.props;
    return course != null
      ? this._renderCourse(course)
      : noCourse != null
      ? this._renderNoCourse(noCourse)
      : null;
  }
}

export default SalesLogicTree;

const Wrapper = styled.div`
  grid-column: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  white-space: nowrap;
  justify-content: flex-end;
`;

const FirstDisassembly = styled.div`
  width: 344px;
`;

const SecondDisassembly = styled.div`
  width: 316px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Box = styled.div<{ width?: number; height: number }>`
  border: 1px solid ${uploadBorderColor};
  border-radius: 8px;
  ${props => props.width && `width: ${props.width}%;`}
  height: ${props => props.height}px;
`;

const FirstDisassemblyBox = styled(Box)`
  margin-top: 12px;
`;

const SecondDisassemblyBox = styled(Box)`
  width: 100px;
`;

const BoxHeader = styled.div<{ smallBox?: boolean; isSmall?: boolean }>`
  width: 100%;
  height: ${props => (props.smallBox ? '24' : '28')}px;
  background-color: ${airGray};
  border-radius: 7px 7px 0 0;
  display: flex;
  padding: 6px ${props => (props.isSmall ? '2' : props.smallBox ? '6' : '12')}px;
`;

const Content = styled.div`
  padding: 10px 12px;
  display: flex;
  flex-wrap: wrap;
  height: calc(100% - 28px);
`;

const ContentSmall = styled(Content)`
  padding: 6px 0 0 8px;
`;

const Hr = styled.hr`
  border: none;
  border-top: dashed 1px ${disabledTextColor};
  width: 100%;
  margin: 5px 0;
`;

const Title = styled.p`
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  margin: 8px 0 4px 0;
`;

const TableTitle = styled(Title)`
  margin: 0;
  width: 34%;
`;

const SubTitle = styled.span<{ isSmall?: boolean }>`
  font-size: 10px;
  font-weight: 600;
  ${props => props.isSmall && 'transform: scale(0.9);'}
`;

const TableTitleText = styled.span`
  width: 22%;
  font-size: 10px;
  text-align: center;
`;

const TableContent = styled.p<{ width: number; isCenter?: boolean; fontSize?: number }>`
  width: ${props => props.width}%;
  ${props => props.isCenter && 'text-align: center;justify-content:center;'}
  ${props => props.fontSize != null && `font-size: ${props.fontSize}px;`}
  display: flex;
  align-items: center;
`;

const Description = styled.span`
  font-size: 8px;
  margin: 6px 0;
`;

const FlowTop = styled.img<{ isFirst?: boolean; isSmall?: boolean }>`
  ${props =>
    props.isFirst && props.isSmall
      ? `
    margin-left: calc(100% - 364px);
    height: 169px;
  `
      : props.isFirst
      ? `
  margin-left: calc(100% - 364px);
    height: 223px;
  `
      : `
    margin-left: calc(100% - 332px);
    height: 91px;
  `}
  width: 16px;
`;
