/* Airペイから連携中モーダル */

import React from 'react';
import Modal from './molecules/Airkit/AirModal';
import styled from 'styled-components';
import { textLinkColor } from '../../constants/colors';
import AirPayLinkageImage from '../../icons/AirPayLinkageImage.svg';
import ArrowRightBlue from '../../icons/arrow_blue_right.svg';
import { Link } from 'react-router-dom';
import { airRegiPromoUrl } from '../../constants/externalLink';
import {
  ALL_INDEX_ARG_LINKINGTOAIRPAY_MODAL_SUBMIT_PARAMETER,
  STORE_INDICES_ARG_LINKINGTOAIRPAY_MODAL_SUBMIT_PARAMETER,
} from '../../constants/externalLinkParameter';
import * as AkrCode from '../../typedef/AkrCode';
import { track } from '../../modules/logging';
import { genGaLog } from '../../gaLogger';
import { INDICES_TYPE } from '../../ui/pages/Store/storesConstants';

type Props = {
  onClick?: () => void;
  akrCode?: AkrCode.T;
  from: string;
  tracker: typeof track;
  indiceType?: keyof typeof INDICES_TYPE;
};

export const AirPayLinkageModal = (props: Props) => {
  const { onClick, akrCode, from, tracker } = props;
  const lid = () => {
    switch (from) {
      case 'store_indices':
        return STORE_INDICES_ARG_LINKINGTOAIRPAY_MODAL_SUBMIT_PARAMETER;
      case 'all_index':
        return ALL_INDEX_ARG_LINKINGTOAIRPAY_MODAL_SUBMIT_PARAMETER;
      default:
        return null;
    }
  };
  const airRegiPromoLink = `${airRegiPromoUrl}?lid=${lid()}`;
  return (
    <Modal title="Airペイから連携中" onClose={onClick} noFooter={true}>
      <AirPayLinkageModalWrapper>
        <StyledAirPayLinkageImage />
        <Lead>
          「Airペイから連携中」の表示がある店舗は、Airペイ・Airペイ QRの決済金額を売上として表示しています。
        </Lead>
        <List>
          <li>Airペイ・Airペイ QRを利用しない現金決済などは含まれていません。</li>
          <li>Airレジで会計を行うことで、すべての売上データを表示することができます。</li>
          <li>
            <a
              href={airRegiPromoLink}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                tracker(_genClickAirPayLinkageModalAirRegiLinkLog(akrCode, from));
              }}
            >
              Airレジについて詳しく見る <Arrow />
            </a>
          </li>
        </List>
        <AnnotationList>
          <li>
            ※ Airペイ・Airペイ
            QRから「成績」「全店舗一覧」に連携されるのは、データ連携時点から17ヶ月前以降のデータになります。
          </li>
          <li>
            ※ 売上の表示を「Airレジからの連携」または「日報からの入力」に切り替える場合は、「
            <StyledLink
              to={'/data_import_setting/'}
              onClick={() => {
                tracker(_genClickAirPayLinkageModalDataImportSettingLinkLog(akrCode, from));
              }}
            >
              売上取込設定
            </StyledLink>
            」から変更してください。
          </li>
        </AnnotationList>
      </AirPayLinkageModalWrapper>
    </Modal>
  );
};

const AirPayLinkageModalWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 0 48px;
`;

const Lead = styled.div`
  width: 84%;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  line-height: 1.7;
  margin-top: 30px;
  font-family: 'Hiragino Kaku Gothic Pro', sans-serif;
`;

const List = styled.ul`
  align-self: flex-start;
  width: 82%;
  margin-top: 12px;
  margin-left: 90px;
  > li {
    margin-top: 10px;
    font-family: 'Hiragino Kaku Gothic Pro', sans-serif;
    &:last-of-type {
      list-style: none;
    }
    > a {
      color: ${textLinkColor};
    }
  }
`;

const AnnotationList = styled.ul`
  align-self: flex-start;
  width: 82%;
  margin-top: 32px;
  margin-left: 60px;
  list-style: none;
  padding-left: 1.5em;
  > li {
    font-size: 14px;
    text-indent: -1.2em;
    margin-top: 10px;
    font-family: 'Hiragino Kaku Gothic Pro', sans-serif;
  }
`;

const StyledAirPayLinkageImage = styled(AirPayLinkageImage)`
  width: 380px;
  height: 80px;
`;

const StyledLink = styled(Link)`
  color: ${textLinkColor};
`;

const Arrow = styled(ArrowRightBlue)``;

const _genClickAirPayLinkageModalAirRegiLinkLog = (
  akrCode: AkrCode.T | undefined,
  from: string,
  indiceType?: keyof typeof INDICES_TYPE
) => {
  return genGaLog(
    from,
    `${from}_linkingtoairpay_modal_detaillink`,
    'submit',
    {},
    indiceType != null
      ? { type: '[apy]', akr_code: akrCode, selected_indices_type: indiceType }
      : { type: '[apy]' },
    'click'
  );
};

const _genClickAirPayLinkageModalDataImportSettingLinkLog = (
  akrCode: AkrCode.T | undefined,
  from: string,
  indiceType?: keyof typeof INDICES_TYPE
) => {
  return genGaLog(
    from,
    `${from}_linkingtoairpay_modal`,
    'submit',
    {},
    indiceType != null
      ? { type: '[apy]', akr_code: akrCode, selected_indices_type: from }
      : { type: '[apy]' },
    'click'
  );
};
