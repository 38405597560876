/**
 * 日別詳細 サマリー部分のWrapper
 */
import React from 'react';
import styled from 'styled-components';
import {
  DAILY_DETAILT_TYPE,
  DAILY_DETAIL_TYPE_SALES,
  DAILY_DETAIL_TYPE_SHIFTS,
  DAILY_DETAIL_TYPE_SEAT_OCCUPANCY,
  DAILY_DETAIL_TYPE_LATE_SERVE,
  DAILY_DETAIL_TYPE_GOAL_SALES,
  DAILY_DETAIL_TYPE_FUTURE_SHIFTS,
  DAILY_DETAIL_TYPE_RESEVE,
  DAILY_DETAIL_TYPE_RESEVE_COURSE,
} from '../../../storesConstants';
import SittingVisitorTotalCapacityRate from '../../../../../../icons/sittingVisitorTotalCapacityRate.svg';
import ExternalLink from '../../../../../../icons/openLink.svg';
import SittingTableTotalRate from '../../../../../../icons/sittingTableTotalRate.svg';
import {
  disabledTextColor,
  airblue,
  attentionRed,
  white,
  tooltipBg,
} from '../../../../../../constants/colors';
import { assertNever } from '../../../../../../helpers/util';
import {
  baseFontSize,
  prefixUnit,
  postfixUnit,
  LocaleInteger,
  Decimal,
} from '../../../../../../components/common/atoms/Number';
import {
  DailySalesDetail,
  DailyLateServeDetail,
  DailySeatOccupancyDetail,
} from '../../../../../../typedef/api/StoreIndices';
import { PreparationDetailResponse } from '../../../../../../typedef/api/Preparation';
import { DailyLaborCostResponse } from '../../../../../../typedef/api/LaborCost';
import { ReserveDetail } from '../../../../../../typedef/api/Realtime';
import Tooltip from '../../../../../../components/common/molecules/Tooltip';
import { dailyDetailFutureFaq } from '../../../../../../constants/faqUrls';

const FormatYen = baseFontSize(17)(prefixUnit('¥')(LocaleInteger));
const FormatYenSmall = baseFontSize(11)(prefixUnit('¥')(LocaleInteger));
const FormatPersonNum = baseFontSize(19)(postfixUnit('人')(LocaleInteger));
const FormatPersonNumSmall = baseFontSize(11)(postfixUnit('人')(LocaleInteger));
const FormatRate = baseFontSize(19)(postfixUnit('%')(Decimal));
const FormatRateSmall = baseFontSize(11)(postfixUnit('%')(Decimal));

const FormatHour = baseFontSize(15)(postfixUnit('時間')(LocaleInteger));
const FormatMinute = baseFontSize(15)(postfixUnit('分')(LocaleInteger));
const FormatTurnover = baseFontSize(19)(postfixUnit('回')(Decimal));
const FormatSeat = baseFontSize(11)(postfixUnit('席')(LocaleInteger));
const Format組 = baseFontSize(19)(postfixUnit('組')(LocaleInteger));
const Format名 = baseFontSize(19)(postfixUnit('名')(LocaleInteger));

type Props = {
  readonly contentType: DAILY_DETAILT_TYPE;
  readonly dailySalesDetail?: DailySalesDetail;
  readonly dailyShiftDetail?: DailyLaborCostResponse;
  readonly dailyLateServeDetail?: DailyLateServeDetail;
  readonly dailySeatOccupancyDetail?: DailySeatOccupancyDetail;
  readonly dailyReserveDetail?: ReserveDetail | null;
  readonly dailyPreparationDetail?: PreparationDetailResponse;
};

const SummarySales = ({
  isOutsideSales,
  sales,
  visitorNum,
  customerPayment,
  goalSales,
}: {
  isOutsideSales: boolean;
  sales: number;
  visitorNum: number;
  customerPayment: number;
  goalSales?: number | null;
}) => {
  return (
    <MainWrapper>
      <ItemWrapper>
        <ItemRow>
          <FormatYen value={sales} />
          <SmallText>
            (目標　
            {goalSales != null ? <FormatYenSmall value={goalSales} /> : <SmallText>-</SmallText>})
          </SmallText>
        </ItemRow>
        <ItemRow>
          <ItemTitle>客数</ItemTitle>
          <FormatPersonNum value={visitorNum} />
        </ItemRow>
        <ItemRow>
          <ItemTitle>客単価</ItemTitle>
          <FormatYen value={customerPayment} />
        </ItemRow>
      </ItemWrapper>
      {isOutsideSales && (
        <LegendWrapper>
          <InSideLegend />
          <LegendText>店内</LegendText>
          <React.Fragment>
            <OutSideLegend />
            <LegendText>店外</LegendText>
          </React.Fragment>
        </LegendWrapper>
      )}
    </MainWrapper>
  );
};

const SummaryShift = ({
  laborCostRate,
  workingTime,
  plannedWorkingTime,
  goalLaborCostRate,
}: {
  laborCostRate: number;
  workingTime: number;
  plannedWorkingTime: number;
  goalLaborCostRate?: number | null;
}) => {
  return (
    <MainWrapper>
      <ItemWrapper>
        <ItemRow>
          <ItemTitle>人件費率</ItemTitle>
          <FormatRate value={laborCostRate} />
          <SmallText>
            (月間目標　
            {goalLaborCostRate != null ? (
              <FormatRateSmall value={goalLaborCostRate} />
            ) : (
              <SmallText>-%</SmallText>
            )}
            )
          </SmallText>
        </ItemRow>
        <ItemRow>
          <ItemTitle>労働時間</ItemTitle>
          <FormatHour value={Math.floor(workingTime / 60)} />
          <FormatMinute value={workingTime % 60} />
          <SmallText>
            　(シフト上　{Math.floor(plannedWorkingTime / 60)}時間
            {plannedWorkingTime % 60}分)
          </SmallText>
        </ItemRow>
      </ItemWrapper>
      <LegendWrapper>
        <ShiftLegend />
        <LegendText>シフト</LegendText>
        <AttendanceLegend />
        <LegendText>勤怠実績</LegendText>
      </LegendWrapper>
    </MainWrapper>
  );
};
const SummarySeatOccupancy = ({
  customerTurnover,
  visitorNum,
  seatNum,
}: {
  customerTurnover?: string | null;
  visitorNum: number;
  seatNum: number;
}) => {
  return (
    <MainWrapper>
      <ItemWrapper>
        <ItemRow>
          <ItemTitle>回転数</ItemTitle>
          {customerTurnover != null ? <FormatTurnover value={customerTurnover} /> : <SmallText>-</SmallText>}
          <SmallText>
            (
            <FormatPersonNumSmall value={visitorNum} />
            /
            <FormatSeat value={seatNum} />)
          </SmallText>
        </ItemRow>
      </ItemWrapper>
      <LegendWrapper>
        <SittingVisitorTotalCapacityRate />
        <LegendText>満席率</LegendText>
        <SittingTableTotalRate />
        <LegendText>満卓率</LegendText>
      </LegendWrapper>
    </MainWrapper>
  );
};
const SummaryRateServe = ({
  lateServeRate,
  lateServeVisitorNum,
  serveVisitorNum,
}: {
  lateServeRate: string;
  lateServeVisitorNum: number;
  serveVisitorNum: number;
}) => {
  return (
    <MainWrapper>
      <ItemWrapper>
        <ItemRow>
          <ItemTitle>提供遅れ発生率</ItemTitle>
          <FormatRate value={lateServeRate} />
        </ItemRow>
        <ItemRow>
          <ServeVisitorLegend />
          <LegendText>提供遅れなし</LegendText>
          <FormatPersonNum value={serveVisitorNum} />
        </ItemRow>
        <ItemRow>
          <LateServeVisitorLegend />
          <LegendText>提供遅れあり</LegendText>
          <FormatPersonNum value={lateServeVisitorNum} />
        </ItemRow>
      </ItemWrapper>
    </MainWrapper>
  );
};

const SummaryReserve = ({
  totalReserveSetNum,
  totalReservePersonNum,
}: {
  totalReserveSetNum?: number;
  totalReservePersonNum?: number;
}) => {
  return (
    <MainWrapper>
      <ItemWrapper>
        <ItemRow>
          <ItemTitle>予約数</ItemTitle>
          {totalReserveSetNum != null ? <Format組 value={totalReserveSetNum} /> : '-組'}
          {totalReservePersonNum != null ? <Format名 value={totalReservePersonNum} /> : '-名'}
        </ItemRow>
      </ItemWrapper>
    </MainWrapper>
  );
};

const SummaryFutureShift = ({
  laborCostRate,
  totalWorkingMin,
  goalLaborCostRate,
  displayTooltip,
}: {
  laborCostRate?: number | null;
  totalWorkingMin?: number | null;
  goalLaborCostRate?: number | null;
  displayTooltip: boolean;
}) => {
  return (
    <MainWrapper>
      <ItemWrapper>
        <ItemRow>
          <ItemTitle noMargin={true}>概算人件費率</ItemTitle>
          {displayTooltip ? (
            <StyledTooltip>
              Airシフト上のシフト計画から算出した概算人件費と、Airメイトで入力した人件費を足したものです。
              <br />
              ※詳しい説明は
              <LinkText href={dailyDetailFutureFaq} target="_blank">
                こちら
                <StyledExternalLink />
              </LinkText>
            </StyledTooltip>
          ) : (
            <TooltipIcon>?</TooltipIcon>
          )}
          {laborCostRate != null ? <FormatRate value={laborCostRate} /> : '-%'}
          <SmallText>
            (目標{goalLaborCostRate != null ? <FormatRateSmall value={goalLaborCostRate} /> : '-%'})
          </SmallText>
        </ItemRow>
        <ItemRow>
          <ItemTitle>労働時間</ItemTitle>
          {totalWorkingMin != null ? (
            <React.Fragment>
              <FormatHour value={Math.floor(totalWorkingMin / 60)} />
              <FormatMinute value={totalWorkingMin % 60} />
            </React.Fragment>
          ) : (
            '-時間-分'
          )}
        </ItemRow>
      </ItemWrapper>
    </MainWrapper>
  );
};

const SummaryWrapper = (props: Props) => {
  const {
    contentType,
    dailySalesDetail,
    dailyShiftDetail,
    dailyLateServeDetail,
    dailySeatOccupancyDetail,
    dailyReserveDetail,
    dailyPreparationDetail,
  } = props;

  switch (contentType) {
    case DAILY_DETAIL_TYPE_SALES:
      return (
        <React.Fragment>
          {dailySalesDetail != null ? (
            <SummarySales
              isOutsideSales={dailySalesDetail.salesDetail.isOutsideSales}
              sales={dailySalesDetail.salesDetail.dailySummary.sales}
              visitorNum={dailySalesDetail.salesDetail.dailySummary.visitorNum}
              customerPayment={dailySalesDetail.salesDetail.dailySummary.customerPayment}
              goalSales={dailySalesDetail.salesDetail.dailySummary.goalSales}
            />
          ) : (
            // 未連携時に表示するサンプル値を設定
            <SummarySales
              isOutsideSales={true}
              sales={472400}
              visitorNum={168}
              customerPayment={2812}
              goalSales={450000}
            />
          )}
        </React.Fragment>
      );
    case DAILY_DETAIL_TYPE_SHIFTS:
      return (
        <React.Fragment>
          {dailyShiftDetail != null && dailyShiftDetail.shiftAttendance != null ? (
            <SummaryShift
              laborCostRate={dailyShiftDetail.summary.laborCostRate.value}
              workingTime={dailyShiftDetail.shiftAttendance.workingTime.actualValue}
              plannedWorkingTime={dailyShiftDetail.shiftAttendance.workingTime.plannedValue}
              goalLaborCostRate={dailyShiftDetail.summary.laborCostRate.target}
            />
          ) : (
            // 未連携時に表示するサンプル値を設定
            <SummaryShift
              laborCostRate={43.3}
              workingTime={1230}
              plannedWorkingTime={1330}
              goalLaborCostRate={35.0}
            />
          )}
        </React.Fragment>
      );
    case DAILY_DETAIL_TYPE_SEAT_OCCUPANCY:
      return (
        <React.Fragment>
          {dailySeatOccupancyDetail != null ? (
            <SummarySeatOccupancy
              customerTurnover={dailySeatOccupancyDetail.seatOccupancyDetail.dailySummary.customerTurnover}
              visitorNum={dailySeatOccupancyDetail.seatOccupancyDetail.dailySummary.visitorNum}
              seatNum={dailySeatOccupancyDetail.seatOccupancyDetail.dailySummary.seatNum}
            />
          ) : (
            // 未連携時に表示するサンプル値を設定
            <SummarySeatOccupancy customerTurnover={'4.1'} visitorNum={168} seatNum={35} />
          )}
        </React.Fragment>
      );

    case DAILY_DETAIL_TYPE_LATE_SERVE:
      return (
        <React.Fragment>
          {dailyLateServeDetail != null ? (
            <SummaryRateServe
              lateServeRate={dailyLateServeDetail.lateServeDetail.dailySummary.lateServeRate}
              lateServeVisitorNum={dailyLateServeDetail.lateServeDetail.dailySummary.lateServeVisitorNum}
              serveVisitorNum={dailyLateServeDetail.lateServeDetail.dailySummary.serveVisitorNum}
            />
          ) : (
            // 未連携時に表示するサンプル値を設定
            <SummaryRateServe lateServeRate={'9.5'} lateServeVisitorNum={16} serveVisitorNum={152} />
          )}
        </React.Fragment>
      );
    case DAILY_DETAIL_TYPE_FUTURE_SHIFTS:
      return (
        <React.Fragment>
          {dailyPreparationDetail != null ? (
            <SummaryFutureShift
              laborCostRate={dailyPreparationDetail.preparation.laborCostRate}
              totalWorkingMin={dailyPreparationDetail.preparation.totalWorkingMin}
              goalLaborCostRate={dailyPreparationDetail.preparation.goalLaborRate}
              displayTooltip={true}
            />
          ) : (
            // 未連携時に表示するサンプル値を設定
            <SummaryFutureShift
              laborCostRate={30.0}
              totalWorkingMin={1330}
              goalLaborCostRate={32.0}
              displayTooltip={false}
            />
          )}
        </React.Fragment>
      );
    case DAILY_DETAIL_TYPE_RESEVE:
      return (
        <React.Fragment>
          {dailyReserveDetail == null ? (
            // 未連携時に表示するサンプル値を設定
            <SummaryReserve totalReserveSetNum={3} totalReservePersonNum={35} />
          ) : (
            <SummaryReserve
              totalReserveSetNum={dailyReserveDetail?.totalReserveSetNum}
              totalReservePersonNum={dailyReserveDetail?.totalReservePersonNum}
            />
          )}
        </React.Fragment>
      );
    case DAILY_DETAIL_TYPE_GOAL_SALES:
    case DAILY_DETAIL_TYPE_RESEVE_COURSE:
      return <React.Fragment></React.Fragment>;
    default:
      return assertNever(contentType);
  }
};

const MainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const ItemWrapper = styled.div`
  display: flex;
`;

const ItemRow = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
`;

const ItemTitle = styled.p<{ noMargin?: boolean }>`
  font-size: 12px;
  ${props => !props.noMargin && 'margin-right: 10px;'}
`;

const SmallText = styled.span`
  font-size: 11px;
  margin-left: 10px;
`;

const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
`;

const Legend = styled.div`
  width: 24px;
  height: 12px;
`;

const LegendText = styled(SmallText)`
  margin-left: 4px;
  :not(:last-child) {
    margin-right: 14px;
  }
`;

const InSideLegend = styled(Legend)`
  background-color: ${disabledTextColor};
`;

const OutSideLegend = styled(Legend)`
  border: solid 1px ${disabledTextColor};
`;

const ShiftLegend = styled(Legend)`
  border: dashed 1px ${disabledTextColor};
`;

const AttendanceLegend = styled(Legend)`
  border: solid 1px ${airblue};
  background-color: #e1f0f8;
`;

const LateServeVisitorLegend = styled(Legend)`
  background-color: ${attentionRed};
`;

const ServeVisitorLegend = styled(Legend)`
  background-color: ${disabledTextColor};
`;

const StyledTooltip = styled(Tooltip.UpperLeftPortal)`
  margin: 10px 6px;
`;

const TooltipIcon = styled.div`
  width: 16px;
  height: 16px;
  color: white;
  border-radius: 16px;
  background-color: ${tooltipBg};
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  margin: 10px 6px;
`;

const LinkText = styled.a`
  cursor: pointer;
  color: ${white};
  text-decoration: underline;
`;

const StyledExternalLink = styled(ExternalLink)`
  margin-left: 4px;
`;

export default SummaryWrapper;
