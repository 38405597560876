import React from 'react';
import { ResponsiveContainer, AreaChart, Area, ReferenceDot, YAxis, XAxis } from 'recharts';
import Big from 'big.js';
import { airblue, white } from '../../../../../constants/colors';
import { SalesData } from '../../../../../typedef/api/StoreIndices';
import { BatchProcessedDate } from '../../../../../typedef/BatchProcessedDate';
import { STORE_USAGE_TYPES } from '../../../../../typedef/StoreUsageType';
import { formatter, mclDayjs, parser } from '../../../../../helpers/mclDate';

type Props = {
  readonly data: ReadonlyArray<SalesData>;
  readonly selectedDate?: string;
  readonly batchDate: BatchProcessedDate;
  readonly storeUsageType: string;
};

const big = Big();

const SalesGraph = (props: Props) => {
  const { data, selectedDate, batchDate, storeUsageType } = props;

  const formattedForUiData = data.map(d => {
    return d.sales != null ? d : { ...d, totalSales: 0 };
  });

  return (
    <ResponsiveContainer height={150} width="110%">
      <AreaChart data={formattedForUiData} margin={{ top: 8, right: 6 }}>
        <XAxis
          tickLine={false}
          xAxisId={0}
          dataKey="businessDate"
          minTickGap={0}
          interval={0}
          tick={props => {
            return (
              <g>
                <text textAnchor="middle" x={props.x} y={props.y + 8} fontSize={10}>
                  {mclDayjs(props.payload.value).format(formatter.day)}
                </text>
                <text textAnchor="middle" x={props.x} y={props.y + 18} fontSize={8}>
                  {mclDayjs(props.payload.value).format(formatter.shortWeek)}
                </text>
              </g>
            );
          }}
        />
        <YAxis
          yAxisId={0}
          tickCount={3}
          tick={props => {
            return props.payload.value !== 0 ? (
              <g>
                <text textAnchor="end" x={props.x} y={props.y} fontSize={11}>
                  {Number(big(props.payload.value).div(10000))}万
                </text>
              </g>
            ) : (
              <text textAnchor="end" x={props.x} y={props.y} fontSize={11}>
                {props.payload.value}
              </text>
            );
          }}
        />
        <Area
          type="monotone"
          dataKey={
            storeUsageType === STORE_USAGE_TYPES.LAST_MONTH ? 'lastMonthTotalSales' : 'lastYearTotalSales'
          }
          fill="#e7e7e7"
          fillOpacity={1}
          stroke="none"
        />
        <Area type="monotone" dataKey="totalSales" fill={airblue} fillOpacity={0.3} stroke={airblue} />
        <Area
          type="monotone"
          dataKey="totalGoalSales"
          fillOpacity={0}
          stroke={'gray'}
          strokeDasharray={'2 4'}
          strokeWidth={2}
        />
        {batchDate != null &&
          batchDate.year != null &&
          batchDate.month != null &&
          batchDate.day != null &&
          formattedForUiData[batchDate.day - 1] != null &&
          parser.fromDateObject(batchDate).format(formatter.yearMonthFixedSix) === selectedDate && (
            <ReferenceDot
              x={parser.fromDateObject(batchDate).format(formatter.mapiDate)}
              y={formattedForUiData[batchDate.day - 1]['totalSales']}
              r={5}
              fill={white}
              stroke={airblue}
              strokeWidth={3}
              alwaysShow
              isFront
              xAxisId={0}
              yAxisId={0}
            />
          )}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default SalesGraph;
