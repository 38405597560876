// 年始サマリーレポート
import React from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { State as ReduxState } from '../../../modules';
import { ApiState } from '../../../typedef/api/Utility';
import { UserData } from '../../../modules/user';
import { track } from '../../../modules/logging';
import { YEAR } from '../../../constants/requestParameter';
import { startFetchYearlyReport } from '../../../modules/yearlyReport/api';
import { YearlyLookbackResponse } from '../../../typedef/api/YearlyReport/Yearlyreport';
import YearlyReportModal from './components/YearlyReportModal';

type DispatchProps = {
  readonly track: typeof track;
  readonly fetchYearlyReport: typeof startFetchYearlyReport;
};
type StateProps = {
  readonly userData: UserData | null;
  readonly yearlyReportState: ApiState<YearlyLookbackResponse>;
};

type State = {
  isOpenModal: boolean;
};

type Props = RouteComponentProps<{}> & StateProps & DispatchProps;

class YearlyReportContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpenModal: false,
    };
  }

  componentDidMount(): void {
    const url = new URL(window.location.href);
    const year = Number(url.searchParams.get(YEAR));
    this.props.fetchYearlyReport(year);
  }

  render() {
    const { userData, track, yearlyReportState } = this.props;
    const url = new URL(window.location.href);
    const year = Number(url.searchParams.get(YEAR));
    return (
      <YearlyReportModal
        yearlyReportState={yearlyReportState}
        userData={userData}
        track={track}
        year={year}
      />
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  userData: state.user.data,
  yearlyReportState: state.yearlyReport.yearlyReport.yearlyReportState,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    track: bindActionCreators(track, dispatch),
    fetchYearlyReport: bindActionCreators(startFetchYearlyReport, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(YearlyReportContainer));
