import React, { PureComponent } from 'react';
import styled from 'styled-components';
import {
  baseFontSize,
  prefixUnit,
  postfixUnit,
  LocaleInteger,
  LocaleIntegerWithDecimal,
  redGreen100,
  redGreen0,
  sign,
  Decimal,
} from '../../../../../components/common/atoms/Number';
import Tooltip from '../../../../../components/common/molecules/Tooltip';
import {
  lightgray,
  gray,
  black,
  verylightgray,
  dangerColor,
  textLinkColor,
  verylightgrayBorder,
  verylightgrayFont,
  bgGray,
} from '../../../../../constants/colors';
import { CROSSSELL_PRODUCT_TYPE } from '../../../../../constants/crossSellProduct';
import Sunny from '../../../../../icons/sunGray.svg';
import Cloudy from '../../../../../icons/cloudGray.svg';
import Rainy from '../../../../../icons/rainGray.svg';
import Snow from '../../../../../icons/snowGray.svg';
import Sleet from '../../../../../icons/sleetGray.svg';
import { SalesData, IndexAnalysis } from '../../../../../typedef/api/StoreIndices';
import { BatchProcessedDate } from '../../../../../typedef/BatchProcessedDate';
import BorderedLabel from '../../../../../components/common/atoms/BorderedLabel';
import { StoresData, UserData } from '../../../../../modules/user';
import { track } from '../../../../../modules/logging';
import { genGaLog } from '../../../../../gaLogger';
import { Link } from 'react-router-dom';
import { selectStoreData } from '../../../../../modules/realtime/ui';
import { connect } from 'react-redux';
import { bindActionCreators, Action, Dispatch } from 'redux';
import { formatter, mclDayjs, parser } from '../../../../../helpers/mclDate';
import { greaterThanOrEqualTo } from '../../../../../helpers/numberHelper';
import { DataImportType } from '../../../../../typedef/api/DailySales';

type ThroughProps = {
  readonly userData: UserData | null;
  readonly selectedStore?: StoresData;
  readonly indexAnalysisData: IndexAnalysis;
  readonly onScrollTable: () => void;
  readonly headerColumns: ReadonlyArray<{
    key: string;
    headerTitle: string;
    toolTip?: string;
    isForGourmet: boolean;
  }>;
  readonly onClickAutoShift: () => void;
  readonly onClickAutoOrder: () => void;
  readonly tracking: typeof track;
  readonly onClickDetail: (date: string) => void;
  readonly onClickWeatherSetting: () => void;
  readonly onClickGoalSetting: () => void;
  readonly isThisMonth: boolean;
  readonly batchProcessedDate: BatchProcessedDate;
  isBatchFinish: boolean;
  readonly isLaborCostNoAuthority: boolean;
  readonly dataImportType: DataImportType | null;
};

type State = {
  innerHeight: number | null;
  isScroll?: boolean;
};

type DispatchProps = {
  readonly selectStore: typeof selectStoreData;
};

type Props = Readonly<ThroughProps & DispatchProps>;

const FormatYen = baseFontSize(16)(prefixUnit('¥')(LocaleInteger));
const FormatSmallYen = baseFontSize(12)(prefixUnit('¥')(LocaleInteger));
const FormatPercent = baseFontSize(16)(postfixUnit('%')(Decimal));
const FormatPercentDiff = baseFontSize(16)(redGreen100(postfixUnit('%')(Decimal)));
const FormatYenDiffSign = baseFontSize(16)(redGreen0(prefixUnit('¥')(sign(LocaleInteger))));
const FormatVisitorNum = baseFontSize(16)(postfixUnit('人')(LocaleInteger));
const FormatSmallVisitorNum = baseFontSize(12)(postfixUnit('人')(LocaleInteger));
const FormatGroupNum = baseFontSize(16)(postfixUnit('組')(LocaleInteger));
const FormatHours = baseFontSize(16)(postfixUnit('時間')(LocaleInteger));
const FormatMinutes = baseFontSize(16)(postfixUnit('分')(LocaleInteger));
const FormatSeconds = baseFontSize(16)(postfixUnit('秒')(LocaleInteger));
const FormatGoods = baseFontSize(16)(postfixUnit('品')(LocaleInteger));
const FormatCount = baseFontSize(16)(postfixUnit('件')(LocaleInteger));
const FormatCustomItem = baseFontSize(16)(LocaleIntegerWithDecimal);
// テーブルの行生成
const TableRow = ({
  row,
  monthRow,
  flag,
  totalRow,
  headerColumns,
  batchProcessedDate,
  onClickDetail,
  selectStore,
  selectedStore,
  isSingleStore,
  tracking,
  dataImportType,
}: {
  row: SalesData;
  monthRow?: SalesData;
  flag: IndexAnalysis;
  totalRow?: boolean;
  headerColumns: ReadonlyArray<{ key: string; headerTitle: string; toolTip?: string }>;
  batchProcessedDate: BatchProcessedDate;
  onClickDetail: (date: string) => void;
  selectStore: typeof selectStoreData;
  selectedStore?: StoresData;
  isSingleStore: boolean;
  tracking: typeof track;
  dataImportType: DataImportType | null;
}) => {
  const isFuture =
    row.businessDate != null
      ? mclDayjs(row.businessDate, formatter.mapiDate).isAfter(parser.fromDateObject(batchProcessedDate))
      : false;
  const { goalLaborCostRate, goalFoodCostRate } = flag;

  const getTableRowData = () => {
    return headerColumns.map((header, idx) => {
      switch (header.key) {
        case 'weather':
          return (
            <React.Fragment key={idx}>
              <WeatherTd row={row} />
            </React.Fragment>
          );

        case 'sales':
          return (
            <React.Fragment key={idx}>
              <SalesTd
                row={row}
                selectStore={selectStore}
                selectedStore={selectedStore}
                isSingleStore={isSingleStore}
                tracking={tracking}
              />
            </React.Fragment>
          );

        case 'goalSales':
          return (
            <React.Fragment key={idx}>
              <GoalSalesTd row={row} isFuture={isFuture} monthRow={monthRow} />
            </React.Fragment>
          );

        case 'goalSalesDiff':
          return (
            <React.Fragment key={idx}>
              <GoalSalesDiffTd row={row} isFuture={isFuture} />
            </React.Fragment>
          );

        case 'goalSalesRate':
          return (
            <React.Fragment key={idx}>
              <GoalSalesRateTd row={row} isFuture={isFuture} />
            </React.Fragment>
          );

        case 'salesLastYearRate':
          return (
            <React.Fragment key={idx}>
              <SalesLastYearRateRateTd row={row} />
            </React.Fragment>
          );

        case 'salesLastMonthRate':
          return (
            <React.Fragment key={idx}>
              <SalesLastMonthRateTd row={row} />
            </React.Fragment>
          );

        case 'visitorNum':
          return (
            <React.Fragment key={idx}>
              <VisitorNumTd row={row} />
            </React.Fragment>
          );

        case 'goalVisitorNum':
          return (
            <React.Fragment key={idx}>
              <GoalVisitorNumTd row={row} isFuture={isFuture} monthRow={monthRow} />
            </React.Fragment>
          );

        case 'customerPayment':
          return (
            <React.Fragment key={idx}>
              <CustomerPaymentTd row={row} />
            </React.Fragment>
          );

        case 'lunchSales':
          return (
            <React.Fragment key={idx}>
              <LunchSalesTd row={row} dataImportType={dataImportType} />
            </React.Fragment>
          );

        case 'goalLunchSales':
          return (
            <React.Fragment key={idx}>
              <GoalLunchSalesTd row={row} isFuture={isFuture} monthRow={monthRow} />
            </React.Fragment>
          );

        case 'goalLunchSalesDiff':
          return (
            <React.Fragment key={idx}>
              <GoalLunchSalesDiffTd row={row} isFuture={isFuture} />
            </React.Fragment>
          );

        case 'lunchVisitorNum':
          return (
            <React.Fragment key={idx}>
              <LunchVisitorNumTd row={row} />
            </React.Fragment>
          );

        case 'goalLunchVisitorNum':
          return (
            <React.Fragment key={idx}>
              <GoalLunchVisitorNumTd row={row} isFuture={isFuture} monthRow={monthRow} />
            </React.Fragment>
          );

        case 'lunchCustomerPayment':
          return (
            <React.Fragment key={idx}>
              <LunchCustomerPaymentTd row={row} />
            </React.Fragment>
          );

        case 'dinnerSales':
          return (
            <React.Fragment key={idx}>
              <DinnerSalesTd row={row} />
            </React.Fragment>
          );

        case 'goalDinnerSales':
          return (
            <React.Fragment key={idx}>
              <GoalDinnerSalesTd row={row} isFuture={isFuture} monthRow={monthRow} />
            </React.Fragment>
          );

        case 'goalDinnerSalesDiff':
          return (
            <React.Fragment key={idx}>
              <GoalDinnerSalesDiffTd row={row} isFuture={isFuture} />
            </React.Fragment>
          );

        case 'dinnerVisitorNum':
          return (
            <React.Fragment key={idx}>
              <DinnerVisitorNumTd row={row} />
            </React.Fragment>
          );

        case 'goalDinnerVisitorNum':
          return (
            <React.Fragment key={idx}>
              <GoalDinnerVisitorNumTd row={row} isFuture={isFuture} monthRow={monthRow} />
            </React.Fragment>
          );

        case 'dinnerCustomerPayment':
          return (
            <React.Fragment key={idx}>
              <DinnerCustomerPaymentTd row={row} />
            </React.Fragment>
          );

        case 'insideSales':
          return (
            <React.Fragment key={idx}>
              <InsideSalesTd row={row} />
            </React.Fragment>
          );

        case 'insideVisitorNum':
          return (
            <React.Fragment key={idx}>
              <InsideVisitorNumTd row={row} />
            </React.Fragment>
          );

        case 'insideCustomerPayment':
          return (
            <React.Fragment key={idx}>
              <InsideCustomerPaymentTd row={row} />
            </React.Fragment>
          );

        case 'outsideSales':
          return (
            <React.Fragment key={idx}>
              <OutsideSalesTd row={row} />
            </React.Fragment>
          );

        case 'outsideVisitorNum':
          return (
            <React.Fragment key={idx}>
              <OutsideVisitorNumTd row={row} />
            </React.Fragment>
          );

        case 'outsideCustomerPayment':
          return (
            <React.Fragment key={idx}>
              <OutsideCustomerPaymentTd row={row} />
            </React.Fragment>
          );

        case 'reserveGroupNum':
          return (
            <React.Fragment key={idx}>
              <ReserveGroupNumTd row={row} isFuture={isFuture} />
            </React.Fragment>
          );

        case 'reserveVisitorNum':
          return (
            <React.Fragment key={idx}>
              <ReserveVisitorNumTd row={row} isFuture={isFuture} />
            </React.Fragment>
          );

        case 'reserveSales':
          return (
            <React.Fragment key={idx}>
              <ReserveSalesTd row={row} isFuture={isFuture} />
            </React.Fragment>
          );

        case 'personTimeSales':
          return (
            <React.Fragment key={idx}>
              <PersonTimeSalesTd row={row} />
            </React.Fragment>
          );

        case 'shiftEstimateLaborCost':
          return (
            <React.Fragment key={idx}>
              <ShiftEstimateLaborCostTd row={row} />
            </React.Fragment>
          );

        case 'laborCost':
          return (
            <React.Fragment key={idx}>
              <LaborCostTd row={row} />
            </React.Fragment>
          );

        case 'laborCostRate':
          return (
            <React.Fragment key={idx}>
              <LaborCostRateTd row={row} goalLaborCostRate={goalLaborCostRate} />
            </React.Fragment>
          );

        case 'foodCost':
          return (
            <React.Fragment key={idx}>
              <FoodCostTd row={row} />
            </React.Fragment>
          );

        case 'foodCostRate':
          return (
            <React.Fragment key={idx}>
              <FoodCostRateTd row={row} goalFoodCostRate={goalFoodCostRate} totalRow={totalRow} />
            </React.Fragment>
          );

        case 'lateServeRate':
          return (
            <React.Fragment key={idx}>
              <LateServeRateTd row={row} />
            </React.Fragment>
          );
        case 'lateServeVisitorNum':
          return (
            <React.Fragment key={idx}>
              <LateServeVisitorNumTd row={row} />
            </React.Fragment>
          );
        case 'firstFoodAverageServingTime':
          return (
            <React.Fragment key={idx}>
              <FirstFoodAverageServingTimeTd row={row} />
            </React.Fragment>
          );
        case 'firstDrinkAverageServingTime':
          return (
            <React.Fragment key={idx}>
              <FirstDrinkAverageServingTimeTd row={row} />
            </React.Fragment>
          );
        case 'alertTimeDelayItemCount':
          return (
            <React.Fragment key={idx}>
              <AlertTimeDelayItemCountTd row={row} />
            </React.Fragment>
          );
        case 'lastYearSales':
          return (
            <React.Fragment key={idx}>
              <LastYearSalesTd row={row} />
            </React.Fragment>
          );
        case 'salesLastYearWeekAverageRate':
          return (
            <React.Fragment key={idx}>
              <SalesLastYearWeekAverageRateTd row={row} />
            </React.Fragment>
          );
        case 'salesLastMonthWeekAverageRate':
          return (
            <React.Fragment key={idx}>
              <SalesLastMonthWeekAverageRateTd row={row} />
            </React.Fragment>
          );
        case 'groupNum':
          return (
            <React.Fragment key={idx}>
              <GroupNumTd row={row} />
            </React.Fragment>
          );
        case 'repeaterRate':
          return (
            <React.Fragment key={idx}>
              <RepeaterRateTd row={row} />
            </React.Fragment>
          );
        case 'repeaterGroupRate':
          return (
            <React.Fragment key={idx}>
              <RepeaterGroupRateTd row={row} />
            </React.Fragment>
          );
        case 'foodSales':
          return (
            <React.Fragment key={idx}>
              <FoodSalesTd row={row} />
            </React.Fragment>
          );
        case 'drinkSales':
          return (
            <React.Fragment key={idx}>
              <DrinkSalesTd row={row} />
            </React.Fragment>
          );
        case 'alcoholDrinkSales':
          return (
            <React.Fragment key={idx}>
              <AlcoholDrinkSalesTd row={row} />
            </React.Fragment>
          );
        case 'softDrinkSales':
          return (
            <React.Fragment key={idx}>
              <SoftDrinkSalesTd row={row} />
            </React.Fragment>
          );
        case 'courseSales':
          return (
            <React.Fragment key={idx}>
              <CourseSalesTd row={row} />
            </React.Fragment>
          );
        case 'takeoutSales':
          return (
            <React.Fragment key={idx}>
              <TakeoutSalesTd row={row} />
            </React.Fragment>
          );
        case 'deliverySales':
          return (
            <React.Fragment key={idx}>
              <DeliverySalesTd row={row} />
            </React.Fragment>
          );
        case 'personTimeGrossIncome':
          return (
            <React.Fragment key={idx}>
              <PersonTimeGrossIncomeTd row={row} />
            </React.Fragment>
          );
        case 'theoryCost':
          return (
            <React.Fragment key={idx}>
              <TheoryCostTd row={row} />
            </React.Fragment>
          );
        case 'theoryCostRate':
          return (
            <React.Fragment key={idx}>
              <TheoryCostRateTd row={row} />
            </React.Fragment>
          );
        case 'flCost':
          return (
            <React.Fragment key={idx}>
              <FlCostTd row={row} />
            </React.Fragment>
          );
        case 'flCostRate':
          return (
            <React.Fragment key={idx}>
              <FlCostRateTd row={row} />
            </React.Fragment>
          );
        case 'currentProfit':
          return (
            <React.Fragment key={idx}>
              <CurrentProfitTd row={row} />
            </React.Fragment>
          );
        case 'currentProfitRate':
          return (
            <React.Fragment key={idx}>
              <CurrentProfitRateTd row={row} />
            </React.Fragment>
          );
        case 'foodLateServeCountAverage':
          return (
            <React.Fragment key={idx}>
              <FoodLateServeCountAverageTd row={row} />
            </React.Fragment>
          );
        case 'drinkLateServeCountAverage':
          return (
            <React.Fragment key={idx}>
              <DrinkLateServeCountAverageTd row={row} />
            </React.Fragment>
          );
        case 'recommendSuccessCountAverage':
          return (
            <React.Fragment key={idx}>
              <RecommendSuccessCountAverageTd row={row} />
            </React.Fragment>
          );
        case 'customItem':
          return (
            <React.Fragment key={idx}>
              <CustomItemsTd row={row} headerTitle={header.headerTitle} isFuture={isFuture} />
            </React.Fragment>
          );
        default:
          return <React.Fragment key={idx} />;
      }
    });
  };

  return (
    <Tr
      // 合計行の場合のみnullになる
      key={row.businessDate != null ? row.businessDate : 'total'}
      isTotalRowStyle={row.businessDate == null}
    >
      <Td color={isFuture ? black : textLinkColor} isTotalStyle={row.businessDate == null} sticky>
        {row.businessDate == null ? (
          <Total>
            合計<MonthTotal>(月合計)</MonthTotal>
          </Total>
        ) : (
          <React.Fragment>
            <DateRowWrapper>
              <DateWrapper>
                {mclDayjs(row.businessDate).format(formatter.monthDay)}
                <WeekDay>
                  ({mclDayjs(row.businessDate).format(formatter.week)}
                  {row.isNationalHoliday ? '・祝)' : ')'}
                </WeekDay>
              </DateWrapper>
              <DetailLink onClick={() => onClickDetail(row.businessDate != null ? row.businessDate : '')}>
                {isFuture ? '予定' : '詳細'}
              </DetailLink>
            </DateRowWrapper>
          </React.Fragment>
        )}
      </Td>
      {getTableRowData()}
    </Tr>
  );
};

const WeatherTd = ({ row }: { row: SalesData }) => {
  let weather;
  switch (row.weather) {
    case '晴':
      weather = <Sunny />;
      break;
    case '曇':
      weather = <Cloudy />;
      break;
    case '雨':
      weather = <Rainy />;
      break;
    case '雪':
      weather = <Snow />;
      break;
    case 'みぞれ':
      weather = <Sleet />;
      break;
    default:
      break;
  }
  return (
    <Td align="center">
      <IconWrapper>{weather}</IconWrapper>
    </Td>
  );
};

const SalesTd = ({
  row,
  selectStore,
  selectedStore,
  isSingleStore,
  tracking,
}: {
  row: SalesData;
  selectStore: typeof selectStoreData;
  selectedStore?: StoresData;
  isSingleStore: boolean;
  tracking: typeof track;
}) => {
  const isCurrentDay = mclDayjs().format(formatter.mapiDate) === row.businessDate;
  return (
    <Td>
      {isCurrentDay ? (
        <RealTimeLink
          to={'/realtime/'}
          onClick={() => {
            // 選択している店舗をリアルタイム画面のデフォルトに設定する処理(単店舗除く)
            if (!isSingleStore && selectedStore != null) {
              selectStore(
                selectedStore?.akrCode,
                selectedStore.isRbActive,
                selectedStore.isShiftActive,
                selectedStore.isHandyActive
              );
            }
            tracking(_genClickRealTimeLinkLog());
          }}
        >
          リアルタイムで確認
        </RealTimeLink>
      ) : row.sales != null ? (
        <FormatYen value={row.sales} />
      ) : (
        '-'
      )}
    </Td>
  );
};

const GoalSalesTd = ({
  row,
  isFuture,
  monthRow,
}: {
  row: SalesData;
  isFuture: boolean;
  monthRow?: SalesData;
}) => {
  return (
    <Td>
      {isFuture ? (
        <SmallParentheses>
          ({row.goalSales != null ? <FormatSmallYen value={row.goalSales} /> : '-'})
        </SmallParentheses>
      ) : row.goalSales != null ? (
        <FormatYen value={row.goalSales} />
      ) : (
        '-'
      )}
      {monthRow && (
        <MonthTotal>
          ({monthRow.goalSales != null ? <FormatSmallYen value={monthRow.goalSales} /> : '-'})
        </MonthTotal>
      )}
    </Td>
  );
};

const GoalSalesDiffTd = ({ row, isFuture }: { row: SalesData; isFuture: boolean }) => {
  return (
    <Td>{isFuture || row.goalSalesDiff == null ? '-' : <FormatYenDiffSign value={row.goalSalesDiff} />}</Td>
  );
};

const GoalSalesRateTd = ({ row, isFuture }: { row: SalesData; isFuture: boolean }) => {
  return (
    <Td>{isFuture || row.goalSalesRate == null ? '-' : <FormatPercentDiff value={row.goalSalesRate} />}</Td>
  );
};

const SalesLastYearRateRateTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.salesLastYearRate == null ? '-' : <FormatPercentDiff value={row.salesLastYearRate} />}</Td>;
};

const SalesLastMonthRateTd = ({ row }: { row: SalesData }) => {
  return (
    <Td>{row.salesLastMonthRate == null ? '-' : <FormatPercentDiff value={row.salesLastMonthRate} />}</Td>
  );
};

const LunchSalesTd = ({ row }: { row: SalesData; dataImportType: DataImportType | null }) => {
  return <Td>{row.lunchSales != null ? <FormatYen value={row.lunchSales} /> : '-'}</Td>;
};

const GoalLunchSalesTd = ({
  row,
  isFuture,
  monthRow,
}: {
  row: SalesData;
  isFuture: boolean;
  monthRow?: SalesData;
}) => {
  return (
    <Td>
      {isFuture ? (
        <SmallParentheses>
          ({row.goalLunchSales != null ? <FormatSmallYen value={row.goalLunchSales} /> : '-'})
        </SmallParentheses>
      ) : row.goalLunchSales != null ? (
        <FormatYen value={row.goalLunchSales} />
      ) : (
        '-'
      )}
      {monthRow && (
        <MonthTotal>
          ({monthRow.goalLunchSales != null ? <FormatSmallYen value={monthRow.goalLunchSales} /> : '-'})
        </MonthTotal>
      )}
    </Td>
  );
};

const GoalLunchSalesDiffTd = ({ row, isFuture }: { row: SalesData; isFuture: boolean }) => {
  return (
    <Td>
      {isFuture || row.goalLunchSalesDiff == null ? (
        '-'
      ) : (
        <FormatYenDiffSign value={row.goalLunchSalesDiff} />
      )}
    </Td>
  );
};

const LunchVisitorNumTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.lunchVisitorNum != null ? <FormatVisitorNum value={row.lunchVisitorNum} /> : '-'}</Td>;
};

const GoalLunchVisitorNumTd = ({
  row,
  isFuture,
  monthRow,
}: {
  row: SalesData;
  isFuture: boolean;
  monthRow?: SalesData;
}) => {
  return (
    <Td>
      {isFuture ? (
        <SmallParentheses>
          ({row.goalLunchVisitorNum != null ? <FormatSmallVisitorNum value={row.goalLunchVisitorNum} /> : '-'}
          )
        </SmallParentheses>
      ) : row.goalLunchVisitorNum != null ? (
        <FormatVisitorNum value={row.goalLunchVisitorNum} />
      ) : (
        '-'
      )}
      {monthRow && (
        <MonthTotal>
          (
          {monthRow.goalLunchVisitorNum != null ? (
            <FormatSmallVisitorNum value={monthRow.goalLunchVisitorNum} />
          ) : (
            '-'
          )}
          )
        </MonthTotal>
      )}
    </Td>
  );
};

const LunchCustomerPaymentTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.lunchCustomerPayment != null ? <FormatYen value={row.lunchCustomerPayment} /> : '-'}</Td>;
};

const DinnerSalesTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.dinnerSales != null ? <FormatYen value={row.dinnerSales} /> : '-'}</Td>;
};

const GoalDinnerSalesTd = ({
  row,
  isFuture,
  monthRow,
}: {
  row: SalesData;
  isFuture: boolean;
  monthRow?: SalesData;
}) => {
  return (
    <Td>
      {isFuture ? (
        <SmallParentheses>
          ({row.goalDinnerSales != null ? <FormatSmallYen value={row.goalDinnerSales} /> : '-'})
        </SmallParentheses>
      ) : row.goalDinnerSales != null ? (
        <FormatYen value={row.goalDinnerSales} />
      ) : (
        '-'
      )}
      {monthRow && (
        <MonthTotal>
          ({monthRow.goalDinnerSales != null ? <FormatSmallYen value={monthRow.goalDinnerSales} /> : '-'})
        </MonthTotal>
      )}
    </Td>
  );
};

const GoalDinnerSalesDiffTd = ({ row, isFuture }: { row: SalesData; isFuture: boolean }) => {
  return (
    <Td>
      {isFuture || row.goalDinnerSalesDiff == null ? (
        '-'
      ) : (
        <FormatYenDiffSign value={row.goalDinnerSalesDiff} />
      )}
    </Td>
  );
};

const DinnerVisitorNumTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.dinnerVisitorNum != null ? <FormatVisitorNum value={row.dinnerVisitorNum} /> : '-'}</Td>;
};

const GoalDinnerVisitorNumTd = ({
  row,
  isFuture,
  monthRow,
}: {
  row: SalesData;
  isFuture: boolean;
  monthRow?: SalesData;
}) => {
  return (
    <Td>
      {isFuture ? (
        <SmallParentheses>
          (
          {row.goalDinnerVisitorNum != null ? (
            <FormatSmallVisitorNum value={row.goalDinnerVisitorNum} />
          ) : (
            '-'
          )}
          )
        </SmallParentheses>
      ) : row.goalDinnerVisitorNum != null ? (
        <FormatVisitorNum value={row.goalDinnerVisitorNum} />
      ) : (
        '-'
      )}
      {monthRow && (
        <MonthTotal>
          (
          {monthRow.goalDinnerVisitorNum != null ? (
            <FormatSmallVisitorNum value={monthRow.goalDinnerVisitorNum} />
          ) : (
            '-'
          )}
          )
        </MonthTotal>
      )}
    </Td>
  );
};

const DinnerCustomerPaymentTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.dinnerCustomerPayment != null ? <FormatYen value={row.dinnerCustomerPayment} /> : '-'}</Td>;
};

const OutsideSalesTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.outsideSales != null ? <FormatYen value={row.outsideSales} /> : '-'}</Td>;
};

const OutsideVisitorNumTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.outsideVisitorNum != null ? <FormatVisitorNum value={row.outsideVisitorNum} /> : '-'}</Td>;
};

const OutsideCustomerPaymentTd = ({ row }: { row: SalesData }) => {
  return (
    <Td>{row.outsideCustomerPayment != null ? <FormatYen value={row.outsideCustomerPayment} /> : '-'}</Td>
  );
};

const InsideSalesTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.insideSales != null ? <FormatYen value={row.insideSales} /> : '-'}</Td>;
};

const InsideVisitorNumTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.insideVisitorNum != null ? <FormatVisitorNum value={row.insideVisitorNum} /> : '-'}</Td>;
};

const InsideCustomerPaymentTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.insideCustomerPayment != null ? <FormatYen value={row.insideCustomerPayment} /> : '-'}</Td>;
};

const VisitorNumTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.visitorNum != null ? <FormatVisitorNum value={row.visitorNum} /> : '-'}</Td>;
};

const GoalVisitorNumTd = ({
  row,
  isFuture,
  monthRow,
}: {
  row: SalesData;
  isFuture: boolean;
  monthRow?: SalesData;
}) => {
  return (
    <Td>
      {isFuture ? (
        <SmallParentheses>
          ({row.goalVisitorNum != null ? <FormatSmallVisitorNum value={row.goalVisitorNum} /> : '-'})
        </SmallParentheses>
      ) : row.goalVisitorNum != null ? (
        <FormatVisitorNum value={row.goalVisitorNum} />
      ) : (
        '-'
      )}
      {monthRow && (
        <MonthTotal>
          ({monthRow.goalVisitorNum != null ? <FormatSmallVisitorNum value={monthRow.goalVisitorNum} /> : '-'}
          )
        </MonthTotal>
      )}
    </Td>
  );
};

const CustomerPaymentTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.customerPayment != null ? <FormatYen value={row.customerPayment} /> : '-'}</Td>;
};

const ReserveGroupNumTd = ({ row, isFuture }: { row: SalesData; isFuture: boolean }) => {
  return <Td>{isFuture ? '-' : <FormatGroupNum value={row.reserveGroupNum} />}</Td>;
};

const ReserveVisitorNumTd = ({ row, isFuture }: { row: SalesData; isFuture: boolean }) => {
  return <Td>{isFuture ? '-' : <FormatVisitorNum value={row.reserveVisitorNum} />}</Td>;
};

const ReserveSalesTd = ({ row, isFuture }: { row: SalesData; isFuture: boolean }) => {
  return <Td>{isFuture ? '-' : <FormatYen value={row.reserveSales} />}</Td>;
};

const PersonTimeSalesTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.personTimeSales != null ? <FormatYen value={row.personTimeSales} /> : '-'}</Td>;
};

const ShiftEstimateLaborCostTd = ({ row }: { row: SalesData }) => {
  return (
    <Td>{row.shiftEstimateLaborCost != null ? <FormatYen value={row.shiftEstimateLaborCost} /> : '-'}</Td>
  );
};

const LaborCostTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.laborCost != null ? <FormatYen value={row.laborCost} /> : '-'}</Td>;
};

const LaborCostRateTd = ({
  row,
  goalLaborCostRate,
}: {
  row: SalesData;
  goalLaborCostRate?: string | null;
}) => {
  return (
    <Td color={greaterThanOrEqualTo(goalLaborCostRate, row.laborCostRate) ? dangerColor : black}>
      {row.laborCostRate != null ? <FormatPercent value={row.laborCostRate} /> : '-'}
    </Td>
  );
};

const FoodCostTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.foodCost != null ? <FormatYen value={row.foodCost} /> : '-'}</Td>;
};

const FoodCostRateTd = ({
  row,
  goalFoodCostRate,
  totalRow,
}: {
  row: SalesData;
  goalFoodCostRate?: string | null;
  totalRow?: boolean;
}) => {
  return (
    <Td
      color={
        totalRow != null && greaterThanOrEqualTo(goalFoodCostRate, row.foodCostRate) ? dangerColor : black
      }
    >
      {row.foodCostRate != null ? <FormatPercent value={row.foodCostRate} /> : '-'}
    </Td>
  );
};

const LateServeRateTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.lateServeRate == null ? '-' : <FormatPercent value={row.lateServeRate} />}</Td>;
};

const LateServeVisitorNumTd = ({ row }: { row: SalesData }) => {
  return (
    <Td>{row.lateServeVisitorNum != null ? <FormatVisitorNum value={row.lateServeVisitorNum} /> : '-'}</Td>
  );
};

const FirstFoodAverageServingTimeTd = ({ row }: { row: SalesData }) => {
  if (row.firstFoodAverageServingTime == null) {
    return <Td>{'-'}</Td>;
  }

  const localTime = mclDayjs.duration(row.firstFoodAverageServingTime, 'seconds');
  return (
    <Td>
      {localTime.hours() > 0 && <FormatHours value={localTime.hours()} />}
      <FormatMinutes value={localTime.minutes()} />
      <FormatSeconds value={localTime.seconds()} />
    </Td>
  );
};

const FirstDrinkAverageServingTimeTd = ({ row }: { row: SalesData }) => {
  if (row.firstDrinkAverageServingTime == null) {
    return <Td>{'-'}</Td>;
  }

  const localTime = mclDayjs.duration(row.firstDrinkAverageServingTime, 'seconds');
  return (
    <Td>
      {localTime.hours() > 0 && <FormatHours value={localTime.hours()} />}
      <FormatMinutes value={localTime.minutes()} />
      <FormatSeconds value={localTime.seconds()} />
    </Td>
  );
};

const AlertTimeDelayItemCountTd = ({ row }: { row: SalesData }) => {
  return (
    <Td>{row.alertTimeDelayItemCount != null ? <FormatGoods value={row.alertTimeDelayItemCount} /> : '-'}</Td>
  );
};

const LastYearSalesTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.lastYearSales != null ? <FormatYen value={row.lastYearSales} /> : '-'}</Td>;
};

const SalesLastYearWeekAverageRateTd = ({ row }: { row: SalesData }) => {
  return (
    <Td>
      {row.salesLastYearWeekAverageRate != null ? (
        <FormatPercentDiff value={row.salesLastYearWeekAverageRate} />
      ) : (
        '-'
      )}
    </Td>
  );
};

const SalesLastMonthWeekAverageRateTd = ({ row }: { row: SalesData }) => {
  return (
    <Td>
      {row.salesLastMonthWeekAverageRate != null ? (
        <FormatPercentDiff value={row.salesLastMonthWeekAverageRate} />
      ) : (
        '-'
      )}
    </Td>
  );
};

const GroupNumTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.groupNum != null ? <FormatGroupNum value={row.groupNum} /> : '-'}</Td>;
};

const RepeaterRateTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.repeaterRate != null ? <FormatPercent value={row.repeaterRate} /> : '-'}</Td>;
};

const RepeaterGroupRateTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.repeaterGroupRate != null ? <FormatPercent value={row.repeaterGroupRate} /> : '-'}</Td>;
};

const FoodSalesTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.foodSales != null ? <FormatYen value={row.foodSales} /> : '-'}</Td>;
};

const DrinkSalesTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.drinkSales != null ? <FormatYen value={row.drinkSales} /> : '-'}</Td>;
};

const AlcoholDrinkSalesTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.alcoholDrinkSales != null ? <FormatYen value={row.alcoholDrinkSales} /> : '-'}</Td>;
};
const SoftDrinkSalesTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.softDrinkSales != null ? <FormatYen value={row.softDrinkSales} /> : '-'}</Td>;
};
const CourseSalesTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.courseSales != null ? <FormatYen value={row.courseSales} /> : '-'}</Td>;
};
const TakeoutSalesTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.takeoutSales != null ? <FormatYen value={row.takeoutSales} /> : '-'}</Td>;
};
const DeliverySalesTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.deliverySales != null ? <FormatYen value={row.deliverySales} /> : '-'}</Td>;
};
const PersonTimeGrossIncomeTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.personTimeGrossIncome != null ? <FormatYen value={row.personTimeGrossIncome} /> : '-'}</Td>;
};

const TheoryCostTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.theoryCost != null ? <FormatYen value={row.theoryCost} /> : '-'}</Td>;
};
const TheoryCostRateTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.theoryCostRate != null ? <FormatPercent value={row.theoryCostRate} /> : '-'}</Td>;
};

const FlCostTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.flCost != null ? <FormatYen value={row.flCost} /> : '-'}</Td>;
};

const FlCostRateTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.flCostRate != null ? <FormatPercent value={row.flCostRate} /> : '-'}</Td>;
};

const CurrentProfitTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.currentProfit != null ? <FormatYen value={row.currentProfit} /> : '-'}</Td>;
};

const CurrentProfitRateTd = ({ row }: { row: SalesData }) => {
  return <Td>{row.currentProfitRate != null ? <FormatPercent value={row.currentProfitRate} /> : '-'}</Td>;
};

const FoodLateServeCountAverageTd = ({ row }: { row: SalesData }) => {
  return (
    <Td>
      {row.foodLateServeCountAverage != null ? <FormatCount value={row.foodLateServeCountAverage} /> : '-'}
    </Td>
  );
};

const DrinkLateServeCountAverageTd = ({ row }: { row: SalesData }) => {
  return (
    <Td>
      {row.drinkLateServeCountAverage != null ? <FormatCount value={row.drinkLateServeCountAverage} /> : '-'}
    </Td>
  );
};

const RecommendSuccessCountAverageTd = ({ row }: { row: SalesData }) => {
  return (
    <Td>
      {row.recommendSuccessCountAverage != null ? (
        <FormatCount value={row.recommendSuccessCountAverage} />
      ) : (
        '-'
      )}
    </Td>
  );
};

const CustomItemsTd = ({
  row,
  headerTitle,
  isFuture,
}: {
  row: SalesData;
  headerTitle: string;
  isFuture: boolean;
}) => {
  let value = 0;
  if (row.customItems) {
    row.customItems.map(item => {
      if (item.customItemName === headerTitle) {
        value = Number(item.value);
      }
    });
  }
  return (
    <Td>
      {row.customItems != null && row.customItems.length !== 0 && !isFuture ? (
        <FormatCustomItem value={value} />
      ) : (
        '-'
      )}
    </Td>
  );
};

const halfWrapHeaderTitle = (str: string) => {
  const halfIndex = Math.ceil(str.length / 2);
  return (
    <React.Fragment>
      {str.slice(0, halfIndex)}
      <br />
      {str.slice(halfIndex)}
    </React.Fragment>
  );
};

// スクロール時のログが送信済かどうかのフラグ
let sendScrollLog = false;

/**
 * 当月の売上状況を表示する表
 */
class RecordTable extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      innerHeight: null,
      isScroll: true,
    };
  }
  componentDidMount() {
    this.setState({ innerHeight: window.innerHeight });
    const { selectedStore, headerColumns, tracking } = this.props;
    const isShiftDisplay: boolean =
      headerColumns.find(
        column =>
          column.key === 'personTimeSales' ||
          column.key === 'laborCost' ||
          column.key === 'laborCostRate' ||
          column.key === 'shiftEstimateLaborCost'
      ) != null &&
      // TODO: selectedStoreはnullにならないので考慮していない
      !selectedStore?.isShiftUse;
    const isOrderDisplay: boolean =
      headerColumns.find(
        column =>
          column.key === 'lateServeRate' ||
          column.key === 'lateServeVisitorNum' ||
          column.key === 'firstFoodAverageServingTime' ||
          column.key === 'firstDrinkAverageServingTime' ||
          column.key === 'alertTimeDelayItemCount'
      ) != null && !selectedStore?.isHandyUse;

    if (isShiftDisplay || isOrderDisplay) {
      const akrCode = selectedStore != null ? selectedStore.akrCode : '';

      isShiftDisplay && tracking(_genAutoShiftLog(akrCode));
      isOrderDisplay && tracking(_genAutoOrderLog(akrCode));
    }
  }

  render() {
    const {
      selectedStore,
      indexAnalysisData,
      headerColumns,
      batchProcessedDate,
      onClickAutoShift,
      onClickDetail,
      onClickAutoOrder,
      onScrollTable,
      onClickWeatherSetting,
      onClickGoalSetting,
      isThisMonth,
      isBatchFinish,
      selectStore,
      userData,
      tracking,
      isLaborCostNoAuthority,
      dataImportType,
    } = this.props;
    const { salesData, totalSalesData, monthlyTotalSalesData } = indexAnalysisData;
    const { innerHeight, isScroll } = this.state;
    // 追加集計項目の項目名が8文字より多い場合項目名を中間で改行する
    const wrapCount: number = 8;

    // 表のスクロールバー制御
    const outerScrollElm = document.querySelector('#store_info');
    outerScrollElm?.addEventListener('scroll', () => {
      // 成績画面のスクロールバーを一番下付近までスクロールした場合(成績画面の高さ - (見えている部分の高さ + スクロール量) <= 10のとき)表のスクロールバーを操作可能にする
      if (outerScrollElm.scrollHeight - (outerScrollElm.clientHeight + outerScrollElm.scrollTop) <= 10) {
        this.setState({ isScroll: false });
      } else {
        // 上記に当てはまらない場合表のスクロールバーを操作不能にする
        this.setState({ isScroll: true });
      }
    });

    // 単店舗かどうか判定するフラグ
    const isSingleStore: boolean = userData?.stores.length === 1;

    // 閲覧権限が無い場合、シフト概算人件費(shiftEstimateLaborCost)を除外する
    const filteredHeaderColumns = isLaborCostNoAuthority
      ? headerColumns.filter(item => item.key !== 'shiftEstimateLaborCost')
      : headerColumns;

    return (
      <TableWrapper
        innerHeight={innerHeight != null ? innerHeight : 0}
        onScroll={e => {
          if (e.currentTarget.scrollLeft > 0 && !sendScrollLog) {
            onScrollTable();
            sendScrollLog = true;
          }
        }}
        isBatchFinish={isBatchFinish}
        isScroll={isScroll}
      >
        <Table id="store_table">
          <thead>
            <tr>
              {filteredHeaderColumns.map((header, idx) => {
                const tooltipForItem = (toolTipText?: string, isForGourmet: boolean = false) => {
                  if (toolTipText == null) {
                    return <React.Fragment key={idx}></React.Fragment>;
                  }

                  const tooltipContent = (
                    <React.Fragment key={idx}>
                      {isForGourmet && (
                        <GourmetRow>
                          <BorderedLabel leadingSpace={0}>飲食店向け</BorderedLabel>
                        </GourmetRow>
                      )}
                      <div>{toolTipText}</div>
                    </React.Fragment>
                  );

                  // ヘッダーの右端2つの項目はツールチップの矢印を右側に変える。（はみ出して見切れる状態を避けるため）
                  return filteredHeaderColumns.length - 3 < idx ? (
                    <Tooltip.UpperRightPortal key={idx}>{tooltipContent}</Tooltip.UpperRightPortal>
                  ) : (
                    <StyledTooltip key={idx}>{tooltipContent}</StyledTooltip>
                  );
                };

                return (
                  <Th isZIndex={idx === 0} key={idx}>
                    <HeaderBox>
                      <HeaderTitle>
                        {header.headerTitle.length > wrapCount
                          ? halfWrapHeaderTitle(header.headerTitle)
                          : header.headerTitle}
                        {tooltipForItem(header.toolTip, header.isForGourmet)}
                      </HeaderTitle>
                      {/** シフト */}
                      {(header.key === 'personTimeSales' ||
                        header.key === 'laborCost' ||
                        header.key === 'laborCostRate' ||
                        header.key === 'shiftEstimateLaborCost') &&
                        // TODO: selectedStoreはnullにならないので考慮していない
                        !selectedStore?.isShiftUse && (
                          <React.Fragment>
                            <div style={{ width: '100%' }} />
                            <CustomButton onClick={onClickAutoShift}>自動連携</CustomButton>
                          </React.Fragment>
                        )}
                      {/** オーダー */}
                      {(header.key === 'lateServeRate' ||
                        header.key === 'lateServeVisitorNum' ||
                        header.key === 'firstFoodAverageServingTime' ||
                        header.key === 'firstDrinkAverageServingTime' ||
                        header.key === 'alertTimeDelayItemCount') &&
                        !selectedStore?.isHandyUse && (
                          <React.Fragment>
                            <div style={{ width: '100%' }} />
                            <CustomButton onClick={onClickAutoOrder}>自動連携</CustomButton>
                          </React.Fragment>
                        )}
                      {/** 天気 天気未設定の場合のみ */}
                      {header.key === 'weather' &&
                        (salesData[0].weather == null || salesData[0].weather === '') && (
                          <React.Fragment>
                            <div style={{ width: '100%' }} />
                            <CustomButton onClick={onClickWeatherSetting}>地図設定</CustomButton>
                          </React.Fragment>
                        )}
                      {/** 目標設定 当月で目標未設定の場合のみ */}
                      {header.key === 'goalSalesRate' &&
                        isThisMonth &&
                        monthlyTotalSalesData?.goalSales == null && (
                          <React.Fragment>
                            <div style={{ width: '100%' }} />
                            <CustomButton onClick={onClickGoalSetting}>目標設定</CustomButton>
                          </React.Fragment>
                        )}
                    </HeaderBox>
                  </Th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <TableRow
              row={totalSalesData}
              monthRow={monthlyTotalSalesData}
              flag={indexAnalysisData}
              totalRow
              headerColumns={filteredHeaderColumns}
              batchProcessedDate={batchProcessedDate}
              onClickDetail={onClickDetail}
              selectStore={selectStore}
              selectedStore={selectedStore}
              isSingleStore={isSingleStore}
              tracking={tracking}
              dataImportType={dataImportType}
            />
            {salesData.map((row, idx) => (
              <React.Fragment key={idx}>
                <TableRow
                  row={row}
                  flag={indexAnalysisData}
                  headerColumns={filteredHeaderColumns}
                  batchProcessedDate={batchProcessedDate}
                  onClickDetail={onClickDetail}
                  selectStore={selectStore}
                  selectedStore={selectedStore}
                  isSingleStore={isSingleStore}
                  tracking={tracking}
                  dataImportType={dataImportType}
                />
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    selectStore: bindActionCreators(selectStoreData, dispatch),
  };
};

const IconWrapper = styled.div`
  width: 16px;
  margin: 0 auto;
`;

const TableWrapper = styled.div<{ innerHeight: number; isBatchFinish: boolean; isScroll?: boolean }>`
  overflow: auto;
  ${props => props.isScroll && 'overflow-y: hidden;'}
  max-height: calc(
    ${props => props.innerHeight && props.innerHeight}px - ${props => (props.isBatchFinish ? 130 : 170)}px
  );
  margin-bottom: 24px;
`;
const Table = styled.table`
  border: solid 1px ${lightgray};
  border-bottom: 0;
  width: 100%;
  border-spacing: 0;
`;
const WeekDay = styled.span`
  font-size: 12px;
`;
const Total = styled.div`
  color: ${black};
  font-size: 14px;
  font-weight: 600;
  text-align: center;
`;
const MonthTotal = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

const SmallParentheses = styled.span`
  font-size: 12px;
`;

const Th = styled.th<{ isZIndex: boolean }>`
  text-align: center;
  position: sticky;
  top: 0;
  left: 0;
  background-color: ${gray};
  display: table-cell;
  padding: 10px;
  white-space: nowrap;
  height: 39px;
  font-size: 14px;
  ${({ isZIndex }) => isZIndex && 'z-index: 2; border-right: 1px solid #ddd;'}
`;
const Tr = styled.tr<{ isTotalRowStyle?: boolean }>`
  padding: 12px;
  ${({ isTotalRowStyle }) => isTotalRowStyle && `font-weight: 600; background: ${verylightgray}`}
`;
const Td = styled.td<{ sticky?: boolean; isTotalStyle?: boolean; align?: 'center' | 'left' | 'right' }>`
  :not(:last-child) {
    border-right: 1px solid #ddd;
  }
  text-align: ${props => (props.align ? props.align : 'right')};
  padding: 12px;
  border-bottom: 0.1rem solid #e1e1e1;
  color: ${props => props.color};
  white-space: nowrap;
  ${props =>
    props.sticky &&
    `position: sticky; left: 0; background: ${
      props.isTotalStyle ? verylightgray : 'white'
    }; text-align: left;`}
`;

const StyledTooltip = styled(Tooltip.UpperLeftPortal)`
  margin-left: 3px;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
`;

const GourmetRow = styled.div`
  margin-bottom: 5px;
`;

const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const CustomButton = styled.button`
  background-color: ${verylightgray};
  border: solid 1px ${verylightgrayBorder};
  border-bottom-width: 2px;
  border-radius: 4px;
  padding: 2px 10px;
  color: ${verylightgrayFont};
  margin-top: 4px;
  cursor: pointer;
  &:hover {
    background-color: white;
  }
  &:active {
    background-color: ${bgGray};
  }
`;

const DateRowWrapper = styled.div`
  display: flex;
`;
const DateWrapper = styled.div`
  flex: 1;
`;

const DetailLink = styled.a`
  font-size: 14px;
  color: ${textLinkColor};
  cursor: pointer;
  width: 20px;
  margin: 0 5px;
`;

const RealTimeLink = styled(Link)`
  cursor: pointer;
  color: ${textLinkColor};
  font-size: 14px;
`;

export default connect(null, mapDispatchToProps)(RecordTable);

const _genAutoShiftLog = (akrCode: string) => {
  return genGaLog(
    'store_indices',
    'store_indices_crossuse_banner',
    'impression',
    {},
    {
      type: [CROSSSELL_PRODUCT_TYPE.sft],
      akr_code: akrCode,
      selected_indices_type: 'daily',
    },
    'impression'
  );
};

const _genAutoOrderLog = (akrCode: string) => {
  return genGaLog(
    'store_indices',
    'store_indices_crossuse_banner',
    'impression',
    {},
    {
      type: [CROSSSELL_PRODUCT_TYPE.ord],
      akr_code: akrCode,
      selected_indices_type: 'daily',
    },
    'impression'
  );
};

const _genClickRealTimeLinkLog = () => {
  return genGaLog('store_indices', 'daily', 'realtime', {}, {}, 'click');
};
