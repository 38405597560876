import { ApiState, apiState, ErrorType } from '../../typedef/api/Utility';
import { YearlyLookbackResponse } from '../../typedef/api/YearlyReport/Yearlyreport';

export type State = {
  readonly yearlyReportState: ApiState<YearlyLookbackResponse>;
};

export const START_FETCH_YEARLY_REPORT = 'yearly_report/START_FETCH_YEARLY_REPORT';
export const SUCCESS_FETCH_YEARLY_REPORT = 'yearly_report/SUCCESS_FETCH_YEARLY_REPORT';
export const FAIL_FETCH_YEARLY_REPORT = 'yearly_report/FAIL_FETCH_YEARLY_REPORT';

export const types = {
  START_FETCH_YEARLY_REPORT,
  SUCCESS_FETCH_YEARLY_REPORT,
  FAIL_FETCH_YEARLY_REPORT,
};

export type StartFetchYearlyReportAction = {
  readonly type: typeof START_FETCH_YEARLY_REPORT;
  readonly payload?: number;
};

export type SuccessFetchYearlyReportAction = {
  readonly type: typeof SUCCESS_FETCH_YEARLY_REPORT;
  readonly payload: YearlyLookbackResponse;
};

export type FailFetchYearlyReportAction = {
  readonly type: typeof FAIL_FETCH_YEARLY_REPORT;
  readonly payload: ErrorType;
};

export type Action =
  | StartFetchYearlyReportAction
  | SuccessFetchYearlyReportAction
  | FailFetchYearlyReportAction;

// Action Creators
export const startFetchYearlyReport = (year?: number): StartFetchYearlyReportAction => {
  return {
    type: START_FETCH_YEARLY_REPORT,
    payload: year,
  };
};

export const successFetchYearlyReport = (data: YearlyLookbackResponse): SuccessFetchYearlyReportAction => {
  return {
    type: SUCCESS_FETCH_YEARLY_REPORT,
    payload: data,
  };
};

export const failFetchYearlyReport = (error: ErrorType): FailFetchYearlyReportAction => {
  return {
    type: FAIL_FETCH_YEARLY_REPORT,
    payload: error,
  };
};

export const actions = {
  startFetchYearlyReport,
  successFetchYearlyReport,
  failFetchYearlyReport,
};

export const initialState: State = {
  yearlyReportState: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case START_FETCH_YEARLY_REPORT:
      return { ...state, yearlyReportState: apiState.started() };

    case SUCCESS_FETCH_YEARLY_REPORT:
      return { ...state, yearlyReportState: apiState.completed(action.payload) };

    case FAIL_FETCH_YEARLY_REPORT:
      return { ...state, yearlyReportState: apiState.failed(action.payload) };

    default:
      return state;
  }
};

export default reducer;
