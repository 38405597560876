//100万以上超える桁数
export const OVER_ONE_MILLION_NUMBER_OF_DIGITS = 7;

//pc画面で表示時のコンテンツの横幅限度
export const WIDTH_LIMIT = 1024;

//店名の1行の文字数限度
export const STORE_NAME_LENGTH_LIMIT = 16;

//1行ごとに店名を区切る文字数のカウント
export const STORE_NAME_LENGTH_LIMIT_NUM = [
  { start: 0, end: 16 },
  { start: 17, end: 33 },
  { start: 34, end: 50 },
];
