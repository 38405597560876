// 他プロダクトからメイトに遷移して来た場合の店舗情報を受け取る為のKEY
export const AC = 'ac';

// 年始サマリの年度を受け取る為のKEY
export const YEAR = 'year';

// メイトにどこから遷移してきたかを受け取るKEY
export const FROM = 'from';
export const FROM_VALUE = {
  ADJ_MONTHLY_SALES_PREDICTED: 'adj_monthlySalesPredicted',
};
