import * as React from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';
import ZIndex from '../../../../constants/z-index';
import { airblueHover } from '../../../../constants/colors';
import {
  ApiState,
  API_STATE_STARTED,
  API_STATE_INITIAL,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
} from '../../../../typedef/api/Utility';
import { assertNotNull } from '../../../../helpers/util';
import { UserData } from '../../../../modules/user';
import Templates from '../../../../components/common/templates';
import ActivityIndicator from '../../../../components/common/ActivityIndicator';
import { track } from '../../../../modules/logging';
import { genGaLog } from '../../../../gaLogger';
import { YearlyLookbackResponse } from '../../../../typedef/api/YearlyReport/Yearlyreport';
import YearlyReport from './YearlyReport';
import YearlyReportLayout from './YearlyReportLayout';
import YearlyReportError from './YearlyReportError';

const modalRoot: HTMLElement = assertNotNull(document.getElementById('dialog-modal-root'));

type Props = {
  readonly userData: UserData | null;
  readonly track: typeof track;
  readonly year: number;
  readonly yearlyReportState: ApiState<YearlyLookbackResponse>;
};

type State = {
  isScroll: boolean;
};

export class YearlyReportModal extends React.Component<Props, State> {
  el: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.el = document.createElement('div');
    this.state = {
      isScroll: false,
    };
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    if (modalRoot != null && modalRoot.hasChildNodes()) {
      modalRoot.removeChild(this.el);
    }
  }

  _renderContents = (yearReportState: ApiState<YearlyLookbackResponse>) => {
    const { track, year } = this.props;
    switch (yearReportState.type) {
      case API_STATE_COMPLETED:
        return (
          <YearlyReportLayout yearlyReport={yearReportState.payload} track={track}>
            <Wrapper
              onScroll={() => {
                !this.state.isScroll && track(_genScrollLog());
                this.setState({ isScroll: true });
              }}
            >
              <YearlyReport yearlyReport={yearReportState.payload} track={track} year={year} />
            </Wrapper>
          </YearlyReportLayout>
        );
      case API_STATE_FAILED:
        return <YearlyReportError />;
      case API_STATE_INITIAL:
      case API_STATE_STARTED:
        return (
          <Templates.Center>
            <ActivityIndicator />
          </Templates.Center>
        );
      default:
        return <React.Fragment></React.Fragment>;
    }
  };

  _renderModal = () => {
    const { yearlyReportState } = this.props;
    return (
      <BackGround>
        <div>
          <Overlay />
        </div>
        <ModalWrapper innerHeight={window.innerHeight}>
          {this._renderContents(yearlyReportState)}
        </ModalWrapper>
      </BackGround>
    );
  };

  render() {
    return ReactDOM.createPortal(this._renderModal(), this.el);
  }
}

export default YearlyReportModal;

const _genScrollLog = () => {
  return genGaLog('year_lookback', 'year_lookback', 'scroll', {}, {}, 'scroll');
};

const fadeIn = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
`;

const Overlay = styled.div`
  animation: ${fadeIn} 0.25s forwards ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`;

const BackGround = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex: 1;
  justify-content: center;
  left: 0;
  min-height: 200px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${ZIndex.modalPortal};
`;

const ModalWrapper = styled.div<{ innerHeight: number }>`
  background: ${airblueHover};
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  // 100vhだとipadで見た時に崩れる
  height: ${props => props.innerHeight}px;
  max-height: none;
`;

const Wrapper = styled.div`
  overflow-y: auto;
  height: 100%;
  position: relative;
`;
