/* レジ利用検知モーダル */

import React, { useEffect } from 'react';
import Modal from './molecules/Airkit/AirModal';
import styled from 'styled-components';
import { disabledTextColor, lightOrange, textLinkColor } from '../../constants/colors';
import ArrowDownGray from '../../icons/arrow_down_gray.svg';
import { Link } from 'react-router-dom';
import { StoresData } from '../../modules/user';
import { startPostReadDailySalesAutoSettingChange } from '../../modules/stores';
import { Waypoint } from 'react-waypoint';
import { track } from '../../modules/logging';
import { genGaLog } from '../../gaLogger';
import * as AkrCode from '../../typedef/AkrCode';

type Props = {
  onClick?: () => void;
  selectedStore?: StoresData;
  stores?: (AkrCode.T | undefined)[] | string[];
  readonly startPostReadDailySalesAutoSettingChange?: typeof startPostReadDailySalesAutoSettingChange;
  tracker: typeof track;
  readonly from: string;
};

export const AirRegiUseDetectionModal = (props: Props) => {
  const { onClick, startPostReadDailySalesAutoSettingChange, tracker, stores, from } = props;
  useEffect(() => {
    if (startPostReadDailySalesAutoSettingChange != null && stores != null) {
      const akrCodes = stores.map(store => {
        return { akrCode: store };
      });
      startPostReadDailySalesAutoSettingChange({ storeList: akrCodes });
    }
  }, []);
  return (
    <Modal title="お知らせ" onClose={onClick} noFooter={true}>
      <Waypoint
        onEnter={() => {
          tracker(_genShowDailySalesAutoSettingChangeModal(from));
        }}
      >
        <AirRegiUseDetectionModalWrapper>
          <Lead>
            Airレジの売上データが連携されたため、
            <br />
            該当店舗の売上取込方法を<UnderLine>「Airレジから連携」に変更</UnderLine>しました。
          </Lead>
          <ChangePoint>
            <p>
              <strong>変更前</strong>Airペイの決済金額を売上として表示
            </p>
            <Arrow />
            <p>
              <strong>変更後</strong>
              Airレジから連携
            </p>
          </ChangePoint>
          <Annotation>
            <p>
              ※ 今後もAirペイ決済金額のみを表示したい場合は、
              <StyledLink
                to={'/data_import_setting/'}
                onClick={() => {
                  tracker(_genClickDailySalesAutoSettingChangeModal(from));
                }}
              >
                売上取込設定
              </StyledLink>
              で<br />
              「Airペイの決済金額を売上として表示」に変更してください。
            </p>
          </Annotation>
        </AirRegiUseDetectionModalWrapper>
      </Waypoint>
    </Modal>
  );
};

const AirRegiUseDetectionModalWrapper = styled.div`
  padding: 54px 0 58px;
`;

const Lead = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  line-height: 1.7;
`;

const UnderLine = styled.span`
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-color: ${lightOrange};
  text-decoration-thickness: 8px;
`;

const ChangePoint = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: 45px;
  > p {
    width: 45%;
    text-align: left;
    > strong {
      display: inline-block;
      border: 1px solid ${disabledTextColor};
      border-radius: 20px;
      padding: 4px 12px 3px 15px;
      margin-right: 12px;
    }
  }
`;

const Arrow = styled(ArrowDownGray)`
  width: 14px;
  height: 15px;
`;

const Annotation = styled.div`
  width: 68%;
  margin: 42px auto 0;
  text-align: center;
  > p {
    font-size: 16px;
    line-height: 1.5;
  }
`;

const StyledLink = styled(Link)`
  color: ${textLinkColor};
`;

const _genShowDailySalesAutoSettingChangeModal = from => {
  return genGaLog(from, `${from}_argusedetected_modal`, 'impression', {}, {}, 'impression');
};

const _genClickDailySalesAutoSettingChangeModal = from => {
  return genGaLog(from, `${from}_argusedetected_modal`, 'submit', {}, {}, 'click');
};
