import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch, Action, bindActionCreators } from 'redux';
import styled from 'styled-components';

import SelectBox from '../../../components/common/atoms/SelectBox';
import RangeCalendar from './components/RangeCalendar';
import Graph from './components/Graph';
import Table from './components/Table';
import ActivityIndicator from '../../../components/common/ActivityIndicator';
import Template from '../../../components/common/templates';
import TitleHeader from '../../../components/common/TitleHeader';
import AirRadioButton from '../../../components/common/molecules/Airkit/AirRadioButton';

import { State as ReduxState } from '../../../modules';
import { StoresData } from '../../../modules/user';
import { actions as uiActions } from '../../../modules/dayofweekHourly/ui';
import { actions as apiActions } from '../../../modules/dayofweekHourly/api';
import { track } from '../../../modules/logging';

import { StoreList } from '../../../typedef/api/AllIndex';
import { BatchProcessedDate } from '../../../typedef/BatchProcessedDate';
import { DayOfWeekHourlyDetail, CustomTableData } from '../../../typedef/api/DayofweekHourly';
import { API_STATE_COMPLETED, ApiState } from '../../../typedef/api/Utility';

import { assertUnreachable, isBatchFailed } from '../../../helpers/util';

import { hourlyAndWeeklyTableDataSelector } from '../../../selectors/dayofweekHourlySelector';

import { ANALYSIS_TYPE, DAYOFWEEK_HOURLY_DATA_TYPE } from './DayofweekHourlyConstants';
import { dayofweekHourlyFaq } from '../../../constants/faqUrls';
import { genGaLog } from '../../../gaLogger';
import { getCookie } from '../../../helpers/cookieHelper';
import { Waypoint } from 'react-waypoint';
import Tooltip from '../../../components/common/molecules/Tooltip';
import { assignedStoresSelector } from '../../../selectors/userDataSelector';
import AirRegiAppeal from './components/AirRegiAppeal';
import { AppealModal } from '../../../components/common/appealModal/AppealModal';
import { LocalDateObj, MclDayjs, formatter, mclDayjs, parser } from '../../../helpers/mclDate';
import { AC } from '../../../constants/requestParameter';

type DispatchProps = {
  readonly changeAnalysisType: typeof uiActions.changeAnalysisType;
  readonly changeSelectDate: typeof uiActions.changeSelectDate;
  readonly changeViewDataType: typeof uiActions.changeViewDataType;
  readonly changeSelecteStore: typeof uiActions.changeSelecteStore;
  readonly fetchDayOfWeekHourlyDetail: typeof apiActions.fetchDayOfWeekHourlyDetail;
  readonly tracker: typeof track;
  readonly changeSalesFlag: typeof uiActions.changeSalesFlag;
};

type StateProps = {
  readonly batchProcessedDate: BatchProcessedDate;
  readonly stores: ReadonlyArray<StoresData>;
  readonly selectedAkrCode?: string | null;
  readonly selectedAnalysisType: keyof typeof ANALYSIS_TYPE;
  readonly selectedDate: { dateFrom: LocalDateObj; dateTo: LocalDateObj } | null;
  readonly viewDataType: keyof typeof DAYOFWEEK_HOURLY_DATA_TYPE;
  readonly tableData?: CustomTableData;
  readonly dayOfWeekHourlyDetailState: ApiState<DayOfWeekHourlyDetail>;
  readonly batchFinishTime?: string | null;
  readonly hasSalesFlag: boolean;
};

type PassedProps = {
  readonly handleClickStoreListItem: (store: StoresData & StoreList) => void;
};
type Props = Readonly<DispatchProps & StateProps & PassedProps>;

type State = {
  innerHeight: number | null;
  now: MclDayjs;
  isShowModal: boolean;
};

// デフォルトの日付選択期間
const DEFAULT_DATE_RANGE = -29;

class DayofweekHourlyContainer extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      innerHeight: null,
      now: mclDayjs(),
      isShowModal: false,
    };
  }
  componentDidMount() {
    const {
      batchProcessedDate,
      selectedDate,
      selectedAkrCode,
      changeSelectDate,
      fetchDayOfWeekHourlyDetail,
      changeSelecteStore,
      stores,
      tracker,
    } = this.props;

    if (selectedAkrCode == null) {
      const url = new URL(window.location.href);
      const ac = url.searchParams.get(AC);
      // クエリパラメータacに付与されている & 付与されているAKRコードの店舗が表示店舗設定でONになっている店舗の場合、AKRコードの店舗を初期表示に設定する
      if (ac != null && stores.some(store => store.akrCode === ac)) {
        changeSelecteStore(ac);
        tracker(_genComponentDidMountLog(ac));
      } else {
        changeSelecteStore(stores[0].akrCode);
        tracker(_genComponentDidMountLog(stores[0].akrCode));
      }
    } else {
      if (!stores.some(store => store.akrCode === selectedAkrCode)) {
        changeSelecteStore(stores[0].akrCode);
      }
      tracker(_genComponentDidMountLog(selectedAkrCode));
    }

    const date =
      selectedDate == null
        ? {
            dateFrom: parser
              .fromDateObject(batchProcessedDate)
              .add(DEFAULT_DATE_RANGE, 'day')
              .toLocalDateObj(),
            dateTo: batchProcessedDate,
          }
        : selectedDate;
    changeSelectDate(date);

    fetchDayOfWeekHourlyDetail();
    this.setState({ innerHeight: window.innerHeight });
  }

  componentDidUpdate(prevState: Pick<StateProps, 'dayOfWeekHourlyDetailState'>): void {
    const { dayOfWeekHourlyDetailState, changeSalesFlag } = this.props;
    if (
      dayOfWeekHourlyDetailState.type !== prevState.dayOfWeekHourlyDetailState.type &&
      dayOfWeekHourlyDetailState.type === API_STATE_COMPLETED
    ) {
      if (dayOfWeekHourlyDetailState.payload.averageSales === 0) {
        changeSalesFlag(false);
      } else {
        changeSalesFlag(true);
      }
    }
  }

  // 店舗セレクトボックスで選択変更時の処理
  _handleChangeSelectStore = (storeName: { key: string; value: string }) => {
    const {
      stores,
      changeSelecteStore,
      fetchDayOfWeekHourlyDetail,
      selectedAnalysisType,
      tracker,
      viewDataType,
      changeViewDataType,
    } = this.props;
    const store = stores.find(s => s.akrCode === storeName.key);
    if (store != null) {
      changeSelecteStore(store.akrCode);
      fetchDayOfWeekHourlyDetail();
      tracker(_genChangeStoreLog(store.akrCode, storeName.value, selectedAnalysisType));
      // 平均人時売上が選択状態かつAirシフトを使っていない店舗が選択された場合、平均売上を選択状態にする
      if (viewDataType === 'personTimeSales' && !store.isShiftUse) {
        changeViewDataType('sales');
      }
    }
  };

  onClickShowModalButton = () => {
    this.setState({ isShowModal: true });
  };
  render() {
    const {
      batchProcessedDate,
      selectedAnalysisType,
      selectedDate,
      viewDataType,
      selectedAkrCode,
      tableData,
      dayOfWeekHourlyDetailState,
      batchFinishTime,
      stores,
      hasSalesFlag,
    } = this.props;
    const { changeAnalysisType, changeSelectDate, changeViewDataType, fetchDayOfWeekHourlyDetail, tracker } =
      this.props;
    const { innerHeight } = this.state;
    const url = new URL(window.location.href);
    const ac = url.searchParams.get(AC);
    // 店舗プルダウンのプレースホルダー用、didmountより先にrenderの処理が始まってnull状態でプレースホルダーに反映されない為、didmountではなくrenderでacで受け取った店舗情報を取得してる
    const acStoreKey = stores.find(store => store.akrCode === ac);
    const selectedStoreData = stores.find(store => store.akrCode === selectedAkrCode);
    const isRegiUse = selectedStoreData != null && selectedStoreData.isRegiUse;
    return (
      <React.Fragment>
        <Container>
          <StyledTitleHeader
            track={tracker}
            title={'曜日・時間帯別分析'}
            faqTitle="曜日・時間帯別分析の使い方"
            faqLink={dayofweekHourlyFaq}
            pageName="dayofweek_hourly_analysis"
            lastUpdateDate={batchFinishTime != null ? batchFinishTime : undefined}
            lastUpdateDateLog={_genTooltipFaqLog()}
            feature="dayofweek_hourly_analysis"
            functionName="lastUpdatedDateLabel"
          />
          <HeaderContents>
            <SearchWrapper>
              <SelectBoxWrapper>
                <StyledSelectBox
                  isOneLine
                  placeholder={{
                    key: selectedAnalysisType,
                    value: ANALYSIS_TYPE[selectedAnalysisType].itemName,
                  }}
                  isSelected
                  options={Object.keys(ANALYSIS_TYPE).map(type => ({
                    key: type,
                    value: ANALYSIS_TYPE[type].itemName,
                  }))}
                  onChange={(t: { key: string; value: string }) =>
                    Object.keys(ANALYSIS_TYPE).forEach(type => {
                      if (t.key === type && type !== selectedAnalysisType) {
                        // forEachの都合上stringになってしまうので変換
                        changeAnalysisType(type as keyof typeof ANALYSIS_TYPE);
                        tracker(_genChangeDimensionTypeLog(type as keyof typeof ANALYSIS_TYPE));
                      }
                    })
                  }
                />
                <StyledStoreSelectBox
                  size="large"
                  isOneLine
                  options={stores.map(s => ({ key: s.akrCode, value: s.storeName }))}
                  isSelected
                  placeholder={
                    // クエリパラメータacが付与されておりAKRコードが店舗一覧のAKRコードに含まれている場合、初期表示時に付与されたAKRコードの店舗名を表示する
                    acStoreKey != null
                      ? {
                          key: acStoreKey.akrCode,
                          value: acStoreKey.storeName,
                        }
                      : selectedStoreData != null
                      ? {
                          key: selectedStoreData.akrCode,
                          value: selectedStoreData.storeName,
                        }
                      : undefined
                  }
                  onChange={storeName => {
                    this._handleChangeSelectStore(storeName);
                  }}
                />
                <RangeCalendar
                  startDate={
                    selectedDate != null
                      ? parser.fromDateObject(selectedDate.dateFrom)
                      : parser.fromDateObject(batchProcessedDate).add(DEFAULT_DATE_RANGE, 'day')
                  }
                  endDate={
                    selectedDate != null
                      ? parser.fromDateObject(selectedDate.dateTo)
                      : parser.fromDateObject(batchProcessedDate)
                  }
                  batchDate={batchProcessedDate}
                  selectDate={(from, to) => {
                    const selectedDate = {
                      dateFrom: from.toLocalDateObj(),
                      dateTo: to.toLocalDateObj(),
                    };
                    changeSelectDate(selectedDate);
                    if (selectedStoreData == null) {
                      assertUnreachable();
                      return;
                    }
                    fetchDayOfWeekHourlyDetail();
                    tracker(_genSelectPeriodLog(selectedAnalysisType, selectedDate));
                  }}
                  balloonPosition={'center'}
                />
              </SelectBoxWrapper>
              {/* レジ利用あり＆売上が０以外の場合 */}
              {isRegiUse && hasSalesFlag && (
                <RadioWrapper>
                  <StyledAirRadioButton
                    name="dayofweekHourlyDataType"
                    label={DAYOFWEEK_HOURLY_DATA_TYPE['sales']}
                    value={DAYOFWEEK_HOURLY_DATA_TYPE.sales}
                    checked={viewDataType === 'sales'}
                    onChange={() => {
                      changeViewDataType('sales');
                      tracker(_genSelectDataObjectLog(selectedAnalysisType, 'sales'));
                    }}
                  />
                  <StyledAirRadioButton
                    name="dayofweekHourlyDataType"
                    label={DAYOFWEEK_HOURLY_DATA_TYPE['visitorNum']}
                    value={DAYOFWEEK_HOURLY_DATA_TYPE.visitorNum}
                    checked={viewDataType === 'visitorNum'}
                    onChange={() => {
                      changeViewDataType('visitorNum');
                      tracker(_genSelectDataObjectLog(selectedAnalysisType, 'visitorNum'));
                    }}
                  />
                  <StyledAirRadioButton
                    name="dayofweekHourlyDataType"
                    label={DAYOFWEEK_HOURLY_DATA_TYPE['customerPayment']}
                    value={DAYOFWEEK_HOURLY_DATA_TYPE.customerPayment}
                    checked={viewDataType === 'customerPayment'}
                    onChange={() => {
                      changeViewDataType('customerPayment');
                      tracker(_genSelectDataObjectLog(selectedAnalysisType, 'customerPayment'));
                    }}
                  />
                  {/* Airシフトを使っている店舗の場合、平均人時売上ラジオボタン表示 */}
                  {selectedStoreData?.isShiftUse && (
                    <React.Fragment>
                      <AveragePersonTimeSalesButtonWrapper>
                        <StyledAirRadioButton
                          name="dayofweekHourlyDataType"
                          label={DAYOFWEEK_HOURLY_DATA_TYPE['personTimeSales']}
                          value={DAYOFWEEK_HOURLY_DATA_TYPE.personTimeSales}
                          checked={viewDataType === 'personTimeSales'}
                          onChange={() => {
                            changeViewDataType('personTimeSales');
                            tracker(_genSelectDataObjectLog(selectedAnalysisType, 'personTimeSales'));
                          }}
                        />
                        <StyledTooltip>
                          売上実績を、Airシフトから連携した累計労働時間で割った金額です。スタッフの労働時間当たりの売上を確認できます。
                        </StyledTooltip>
                      </AveragePersonTimeSalesButtonWrapper>
                    </React.Fragment>
                  )}
                </RadioWrapper>
              )}
            </SearchWrapper>
          </HeaderContents>
          {dayOfWeekHourlyDetailState.type === API_STATE_COMPLETED ? (
            isRegiUse && hasSalesFlag && tableData != null ? (
              <React.Fragment>
                <Graph
                  analysisType={selectedAnalysisType}
                  viewDataType={viewDataType}
                  apiData={dayOfWeekHourlyDetailState.payload}
                  tracker={tracker}
                />
                <Table
                  viewDataType={viewDataType}
                  tableData={tableData}
                  innerHeight={innerHeight != null ? innerHeight : 0}
                  isBatchFinish={batchFinishTime != null && !isBatchFailed(batchFinishTime, this.state.now)}
                  tracker={tracker}
                  analysisType={selectedAnalysisType}
                />
                {selectedAnalysisType === 'dailyOfWeek' && (
                  <Waypoint
                    onEnter={() => {
                      tracker(_genScrollLog(selectedAnalysisType));
                    }}
                  />
                )}
                <Footer />
              </React.Fragment>
            ) : (
              // レジ未利用 or レジ利用かつ売上０の場合、訴求画面表示
              <AirRegiAppeal
                isDailyOfWeekType={selectedAnalysisType === 'dailyOfWeek'}
                isRegiUse={isRegiUse}
                onClick={this.onClickShowModalButton}
                tracker={tracker}
              />
            )
          ) : (
            <Template.Center>
              <ActivityIndicator />
            </Template.Center>
          )}
        </Container>
        {this.state.isShowModal && (
          <AppealModal
            productType={isRegiUse ? 'DAYOFWEEKHOURLY_REGI_USE' : 'DAYOFWEEKHOURLY_REGI_NOT_USE'}
            onClick={() => {
              this.setState({ isShowModal: false });
              tracker(_genCloseModalLog(isRegiUse));
            }}
            isNoFooter={true}
            tracker={tracker}
            isRegiUse={isRegiUse}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    batchProcessedDate: state.uiConfig.batchProcessedDate,
    stores: assignedStoresSelector(state),
    selectedAkrCode: state.dayofweekHourly.ui.selectedAkrCode,
    selectedAnalysisType: state.dayofweekHourly.ui.selectedAnalysisType,
    selectedDate: state.dayofweekHourly.ui.selectedDate,
    viewDataType: state.dayofweekHourly.ui.viewDataType,
    tableData: hourlyAndWeeklyTableDataSelector(state),
    dayOfWeekHourlyDetailState: state.dayofweekHourly.api.dayOfWeekHourlyDetailState,
    batchFinishTime: state.uiConfig.batchProcessLastFinishDatetime,
    hasSalesFlag: state.dayofweekHourly.ui.hasSalesFlag,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    ...bindActionCreators(
      {
        changeAnalysisType: uiActions.changeAnalysisType,
        changeSelectDate: uiActions.changeSelectDate,
        changeViewDataType: uiActions.changeViewDataType,
        changeSelecteStore: uiActions.changeSelecteStore,
        fetchDayOfWeekHourlyDetail: apiActions.fetchDayOfWeekHourlyDetail,
        tracker: track,
        changeSalesFlag: uiActions.changeSalesFlag,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DayofweekHourlyContainer);

const _genComponentDidMountLog = (akrCode: string) => {
  const cookieData = getCookie('influxData');
  let vos: string | undefined;
  let lid: string | undefined;
  let viaPromoFlg: string | undefined;
  if (cookieData != null) {
    const cookieDataJson = JSON.parse(cookieData);
    vos = cookieDataJson.vos;
    lid = cookieDataJson.lid;
    viaPromoFlg = cookieDataJson.via_promo_flg;
  }

  return genGaLog(
    'dayofweek_hourly',
    'dayofweek_hourly',
    'on_load',
    {},
    {},
    'load',
    akrCode,
    vos,
    lid,
    viaPromoFlg
  );
};

const _genChangeDimensionTypeLog = (analysisType: keyof typeof ANALYSIS_TYPE) => {
  return genGaLog(
    'dayofweek_hourly',
    'select_box',
    'select_dimension_type',
    {},
    { selected_type: ANALYSIS_TYPE[analysisType].logName },
    'change'
  );
};

const _genChangeStoreLog = (akrCode, storeName, indicesType: keyof typeof ANALYSIS_TYPE) => {
  return genGaLog(
    'dayofweek_hourly',
    'select_box',
    'select_store',
    { store_name: storeName },
    { selected_akr_code: akrCode, selected_type: ANALYSIS_TYPE[indicesType].logName },
    'change'
  );
};

const _genSelectPeriodLog = (
  selectedType: keyof typeof ANALYSIS_TYPE,
  date: { dateFrom: LocalDateObj; dateTo: LocalDateObj }
) => {
  return genGaLog(
    'dayofweek_hourly',
    'period_selection_box',
    'select_period',
    {},
    {
      start_date: parser.fromDateObject(date.dateFrom).format(formatter.mapiDefaultDate),
      end_date: parser.fromDateObject(date.dateTo).format(formatter.mapiDefaultDate),
      selected_type: ANALYSIS_TYPE[selectedType].logName,
    },
    'change'
  );
};

const _genSelectDataObjectLog = (
  selectedType: keyof typeof ANALYSIS_TYPE,
  viewDataType: keyof typeof DAYOFWEEK_HOURLY_DATA_TYPE
) => {
  const viewDataLog = {
    sales: 'sales',
    visitorNum: 'visitor_num',
    customerPayment: 'customer_unit_price',
    personTimeSales: 'man_hour_sales',
  };
  return genGaLog(
    'dayofweek_hourly',
    'radio_button',
    'select_data_object',
    {},
    { selected_data: viewDataLog[viewDataType], selected_type: ANALYSIS_TYPE[selectedType].logName },
    'change'
  );
};

const _genTooltipFaqLog = () => {
  return genGaLog(
    'dayofweek_hourly_analysis',
    'data_update_tooltip',
    'open_tooltip_lastUpdatedDateLabel_faq',
    {},
    {},
    'click'
  );
};

const _genScrollLog = (selectedType: keyof typeof ANALYSIS_TYPE) => {
  return genGaLog(
    'dayofweek_hourly',
    'table',
    'scroll',
    {},
    { selected_type: ANALYSIS_TYPE[selectedType].logName },
    'scroll'
  );
};

const _genCloseModalLog = (isRegiUse: boolean) => {
  return genGaLog(
    'dayofweek_hourly',
    'dayofweek_hourly_panel_arg_modal',
    'close',
    {},
    { type: ['arg'], cu_status: isRegiUse ? 'use' : 'not_use' },
    'click'
  );
};

const Container = styled.div`
  height: 100%;
  padding: 24px;
`;

const StyledTitleHeader = styled(TitleHeader)`
  padding-bottom: 24px;
`;

const HeaderContents = styled.div`
  width: 100%;
  display: flex;
`;

const StyledSelectBox = styled(SelectBox.normal)`
  margin-right: 12px;
  width: 120px;
`;

const StyledStoreSelectBox = styled(SelectBox.normal)`
  margin-right: 12px;
  width: 368px;
`;

const StyledAirRadioButton = styled(AirRadioButton)`
  margin-right: 24px;
`;

const RadioWrapper = styled.div`
  display: flex;
  margin-top: 24px;
`;
const SelectBoxWrapper = styled.div`
  display: flex;
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

const Footer = styled.div`
  height: 24px;
`;

const AveragePersonTimeSalesButtonWrapper = styled.div`
  position: relative;
`;

const StyledTooltip = styled(Tooltip.UpperLeftPortal)`
  position: absolute;
  top: 1px;
  right: 0;
`;
