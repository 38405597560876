import Big from 'big.js';
import { eqn } from './util';
const big = Big();

// 万円表記する時の数値計算（小数点のルールを適用する）
// 表記ルール: https://wiki.misosiru.io/pages/viewpage.action?pageId=556013730
export const convertForMan = (num: number) => {
  return Number(roundDown(num / 10000));
};

/**
 * 万円の変換用(conertForManとの違いはbigで変換していて切り捨て設定にしてある)
 * @param num 値
 */
export const conertForManBig = (num: number) => {
  const big2 = Big();
  big2.RM = 0;
  const bigValue = big2(num);
  return Number(bigValue.div(10000).toFixed(1));
};

/**
 * 受け取ったvalueをdigit桁で切り上げて返す
 * @param {number} digit 切り上げたい桁
 * @param {number} value 切り上げを行いたい値
 */
export const roundUp = (digit: number, value: number): Big => {
  return big(value).round(digit, 3);
};

/**
 * 受け取ったvalueをdigit桁で切り捨てで返す
 * @param {number} value 切り捨てを行いたい値
 * @param {number} digit 切り捨てたい桁
 */
export const roundDown = (value: number, digit: number = 1): Big => {
  return big(value).round(digit, 0);
};

/**
 * numeratorをdenominatorで割り、digit桁以下を切り捨てて返す
 * @param {*} numerator 分子
 * @param {*} denominator 分母
 * @param {*} digit 切り捨て桁、デフォルトは1
 */
export const division = (
  numerator: number,
  denominator: number,
  digit: number = 1
): Big | undefined | null => {
  const divBig = Big();
  divBig.RM = 0;
  divBig.DP = digit;

  if (eqn(denominator, 0)) {
    return null;
  }

  return divBig(numerator).div(denominator);
};

/**
 * 第1引数で指定した要素が、第2引数の値より小さいことをチェックします
 * @param source 比較元
 * @param target 比較先
 * @returns チェック結果を返す
 */
export const lessThen = (source?: number | string | null, target?: number | string | null): boolean => {
  if (source != null && !isNaN(Number(source)) && target != null && !isNaN(Number(target))) {
    return Number(source) > Number(target);
  }
  return false;
};

/**
 * 第1引数で指定した要素が、第2引数の値と等しいか小さいことをチェックします
 * @param source 比較元
 * @param target 比較先
 * @returns チェック結果を返す
 */
export const lessThanOrEqualTo = (
  source?: number | string | null,
  target?: number | string | null
): boolean => {
  if (source != null && !isNaN(Number(source)) && target != null && !isNaN(Number(target))) {
    return Number(source) >= Number(target);
  }
  return false;
};

/**
 * 第1引数で指定した要素が、第2引数の値より大きいことをチェックします
 * @param source 比較元
 * @param target 比較先
 * @returns チェック結果を返す
 */
export const greaterThan = (source?: number | string | null, target?: number | string | null): boolean => {
  if (source != null && !isNaN(Number(source)) && target != null && !isNaN(Number(target))) {
    return Number(source) < Number(target);
  }
  return false;
};

/**
 * 第1引数で指定した要素が、第2引数の値と等しいか大きいことをチェックします
 * @param source 比較元
 * @param target 比較先
 * @returns チェック結果を返す
 */
export const greaterThanOrEqualTo = (
  source?: number | string | null,
  target?: number | string | null
): boolean => {
  if (source != null && !isNaN(Number(source)) && target != null && !isNaN(Number(target))) {
    return Number(source) <= Number(target);
  }
  return false;
};
