import React from 'react';
import styled from 'styled-components';
import {
  airblue,
  airBoxShadow,
  buttonActiveColor,
  buttonHoverColor,
  white,
  yearReportBoxShadowColor,
} from '../../../../constants/colors';
import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';
import { startFetchYearlyReport } from '../../../../modules/yearlyReport/api';
import Logo from '../../../../icons/logo.svg';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { genGaLog } from '../../../../gaLogger';
import { track } from '../../../../modules/logging';

type DispatchProps = {
  readonly track: typeof track;
  readonly fetchYearlyReport: typeof startFetchYearlyReport;
};

type Props = RouteComponentProps<{}> & DispatchProps;

/**
 * 年始サマリのデータ取得失敗した時
 */
const YearlyReportError = (props: Props) => {
  const { fetchYearlyReport, history, track } = props;

  return (
    <React.Fragment>
      <Header>
        <StyledLogo
          onClick={() => {
            history.push('/store/indices');
          }}
        />
      </Header>
      <Wrapper>
        <Text>指定されたURLがありません</Text>
        <AnnotationText>URLをお確かめの上、正しいURLでアクセスしてください。</AnnotationText>
        <CloseButtonWrapper>
          <StyledButton
            onClick={() => {
              fetchYearlyReport();
              track(_genClickLog());
            }}
          >
            最新の年間レポートを見る
          </StyledButton>
        </CloseButtonWrapper>
      </Wrapper>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    track: bindActionCreators(track, dispatch),
    fetchYearlyReport: bindActionCreators(startFetchYearlyReport, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(withRouter(YearlyReportError));

const Header = styled.div`
  background-color: ${airblue};
  color: ${white};
  display: flex;
  align-items: center;
  padding: 10px 24px 10px 16px;
  justify-content: space-between;
`;

const StyledLogo = styled(Logo)`
  width: 128px;
  cursor: pointer;
`;

const _genClickLog = () => {
  return genGaLog('year_lookback', 'not_found', 'button', {}, {}, 'click');
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Text = styled.b`
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  font-weight: 600;
`;

const AnnotationText = styled.p`
  margin-top: 16px;
  font-size: 14px;
  letter-spacing: 0px;
  text-align: center;
`;

const CloseButtonWrapper = styled.div`
  margin-top: 40px;
`;

const StyledButton = styled.button`
  border: 1px solid ${airBoxShadow};
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  height: 48px;
  width: 320px;
  padding: 12px 16px 12px 16px;
  white-space: nowrap;
  background: ${airblue};
  box-shadow: 0px 1px 0px 0px ${yearReportBoxShadowColor};
  color: ${white};
  cursor: pointer;
  &:hover {
    background: ${buttonHoverColor};
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
  &:active {
    background: ${buttonActiveColor};
  }
`;
