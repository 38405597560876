import { fork, call, take, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { types as allIndexTypes, actions as allIndexActions } from '../modules/allIndex';
import { types as uiConfigTypes, UiConfigBatchDate } from '../modules/uiConfig';
import API from '../services/allIndexAPI';
import {
  CustomRangeResponse,
  AllIndexSummaryItemResponse,
  ThisMonthPostConfigRequest,
} from '../typedef/api/AllIndex';
import { ApiCallEffectResult, PostResponse, ErrorType } from '../typedef/api/Utility';
import { StartPostConfigAction } from '../modules/allIndex';
import { State as RootState } from '../modules';
import * as AkrCode from '../typedef/AkrCode';
import { FETCH_USER_INFO_SUCCESS, UserData } from '../modules/user';
import { changeSelectStore } from '../modules/stores';
import { LocalDateObj, formatter, parser } from '../helpers/mclDate';

export function* fetchConfigItem() {
  yield takeEvery(allIndexTypes.START_FETCH_CUSTOMIZE_ITEM, function* () {
    const { payload, error }: ApiCallEffectResult<AllIndexSummaryItemResponse> = yield call(
      API.fetchMonthlyItem
    );

    if (error) {
      yield put(allIndexActions.failFetchCustomizeItem(error));
    } else if (payload && payload.summariesItem) {
      const data = payload.summariesItem;
      yield put(allIndexActions.successFetchMYDCustomizeItem(data));
    }
  });
}

export function* fetchConfigItemInitialize() {
  yield takeEvery(allIndexTypes.START_FETCH_CUSTOMIZE_ITEM_INITIALIZE, function* () {
    const { payload, error }: ApiCallEffectResult<AllIndexSummaryItemResponse> = yield call(
      API.fetchMonthlyItem
    );

    if (error) {
      yield put(allIndexActions.failFetchCustomizeItemInitialize(error));
    } else if (payload && payload.summariesItem) {
      const data = payload.summariesItem;
      yield put(allIndexActions.successFetchMYDCustomizeItemInitialize(data));
    }
  });
}

export function* postConfig() {
  // ボタン動線でallIndexTypes.START_POST_CONFIG発火(`startPostConfig()`を検索すれば見つける)
  // 本来Everyで良いですが、連続クリック対応の確証を取れないのでwhileのまま
  while (true) {
    const action: StartPostConfigAction = yield take(allIndexTypes.START_POST_CONFIG);
    const values = action.payload;
    let data: PostResponse | undefined | null;
    let err: ErrorType | undefined | null;
    //local環境でのみJSON.stringify cannot serialize cyclic structuresが発生するので、実際にpostする値だけ抽出
    const requestValues: ThisMonthPostConfigRequest = {
      summariesItem: values.summariesItem.map(conf => {
        return {
          item: conf.item,
          visible: conf.visible,
        };
      }),
    };

    const { payload, error }: ApiCallEffectResult<PostResponse> = yield call(
      API.postMTDConfig,
      requestValues
    );
    data = payload;
    err = error;

    // 起動時の初期化処理を含める場合
    if (values.isIncludeInitialized) {
      if (err) {
        yield put(allIndexActions.failPostConfigInitialize(err));
      } else if (data) {
        yield put(allIndexActions.successPostConfigInitialize(data));
      }
    } else {
      if (err) {
        yield put(allIndexActions.failPostConfig(err));
      } else if (data) {
        yield put(allIndexActions.successPostConfig(data));
      }
    }
  }
}

export function* reloadHeaderConfig() {
  while (true) {
    yield take(allIndexTypes.SUCCESS_POST_CONFIG);
    yield put(allIndexActions.startFetchCustomizeItem());
  }
}

export function* initializedHeaderConfig() {
  while (true) {
    yield take(allIndexTypes.SUCCESS_POST_CONFIG_INITIALIZE);
    yield put(allIndexActions.startFetchCustomizeItemInitialize());
  }
}

export function* initializeSelectDate() {
  const { payload }: { payload: UiConfigBatchDate } = yield take(
    uiConfigTypes.SUCCESS_FETCH_BATCH_PROCCESSED_DATE
  );

  yield put(
    allIndexActions.changeSelectDate({
      dateFrom: parser.fromDateObject(payload.latestDate).startOf('month').toLocalDateObj(),
      dateTo: payload.latestDate,
    })
  );
}

export function* changeSelectDate() {
  while (true) {
    yield take(allIndexTypes.CHANGE_SELECT_DATE);
    const akrCodes: ReadonlyArray<AkrCode.T> | undefined = yield select((state: RootState) => [
      ...state.stores.selectedStores,
    ]);
    if (akrCodes?.length === 0) {
      break;
    }
    yield put(allIndexActions.startFetchCustomRangeStoreData());
  }
}

export function* setSelectStore() {
  while (true) {
    const { payload }: { payload: UserData } = yield take(FETCH_USER_INFO_SUCCESS);
    yield put(
      changeSelectStore(
        new Set(payload.stores.filter(store => store.isAssignedStore).map(store => store.akrCode))
      )
    );
  }
}

export function* fetchCustomRangeStoreData() {
  yield takeLatest(
    allIndexTypes.START_FETCH_CUSTOM_RANGE_STORE_DATA,
    _takeLatestStartFetchCustomRangeStoreData
  );
}

// export for test only
export function* _takeLatestStartFetchCustomRangeStoreData() {
  const akrCodes: ReadonlyArray<AkrCode.T> | undefined = yield select((state: RootState) => [
    ...state.stores.selectedStores,
  ]);
  const selectedDate: { dateFrom: LocalDateObj; dateTo: LocalDateObj } | null = yield select(
    (state: RootState) => state.allIndex.selectedDate
  );

  const { payload, error }: ApiCallEffectResult<CustomRangeResponse> = yield call(
    API.fetchCustomRangeStoreData,
    {
      akrCodes: akrCodes,
      dateFrom: selectedDate ? parser.fromDateObject(selectedDate.dateFrom).format(formatter.mapiDate) : null,
      dateTo: selectedDate ? parser.fromDateObject(selectedDate.dateTo).format(formatter.mapiDate) : null,
    }
  );

  if (error != null) {
    yield put(allIndexActions.failureFetchCustomRangeStoreData(error));
  } else if (payload != null) {
    yield put(allIndexActions.successFetchCustomRangeStoreData(payload));
  }
}

export default function* allIndexSaga() {
  yield fork(fetchConfigItem);
  yield fork(fetchConfigItemInitialize);
  yield fork(postConfig);
  yield fork(reloadHeaderConfig);
  yield fork(initializedHeaderConfig);
  yield fork(initializeSelectDate);
  yield fork(changeSelectDate);
  yield fork(fetchCustomRangeStoreData);
  yield fork(setSelectStore);
}
