import React, { PureComponent } from 'react';
import styled from 'styled-components';
import {
  baseFontSize,
  prefixUnit,
  postfixUnit,
  LocaleInteger,
  LocaleIntegerWithDecimal,
  redGreen100,
  redGreen0,
  sign,
  Decimal,
} from '../../../../../components/common/atoms/Number';
import Tooltip from '../../../../../components/common/molecules/Tooltip';
import BorderedLabel from '../../../../../components/common/atoms/BorderedLabel';
import {
  lightgray,
  gray,
  textLinkColor,
  black,
  verylightgray,
  dangerColor,
  verylightgrayBorder,
  verylightgrayFont,
  bgGray,
} from '../../../../../constants/colors';
import { CROSSSELL_PRODUCT_TYPE } from '../../../../../constants/crossSellProduct';
import {
  MonthlyDetailList,
  Total as TotalType,
  FiscalYearTotal,
} from '../../../../../typedef/api/StoreMonthlyIndices';
import { BatchProcessedDate } from '../../../../../typedef/BatchProcessedDate';
import { StoresData, UserData } from '../../../../../modules/user';
import { track } from '../../../../../modules/logging';
import { genGaLog } from '../../../../../gaLogger';
import { formatter, mclDayjs, parser } from '../../../../../helpers/mclDate';
import { greaterThanOrEqualTo } from '../../../../../helpers/numberHelper';

type Props = {
  readonly userData: UserData | null;
  readonly selectedStore?: StoresData;
  readonly tableData: ReadonlyArray<MonthlyDetailList>;
  readonly total: TotalType;
  readonly goalTotal?: FiscalYearTotal | null;
  readonly onClickItem: (row: MonthlyDetailList) => void;
  readonly headerColumns: ReadonlyArray<{
    key: string;
    headerTitle: string;
    toolTip?: string;
    isForGourmet: boolean;
  }>;
  readonly onClickAutoShift: () => void;
  readonly onClickAutoOrder: () => void;
  readonly tracking: typeof track;
  readonly onClickGoalSetting: () => void;
  readonly isThisFiscalYear: boolean;
  readonly batchProcessedDate: BatchProcessedDate;
  isBatchFinish: boolean;
  isLaborCostNoAuthority: boolean;
};

type State = {
  innerHeight: number | null;
};
const FormatYen = baseFontSize(16)(prefixUnit('¥')(LocaleInteger));
const FormatSmallYen = baseFontSize(12)(prefixUnit('¥')(LocaleInteger));
const FormatPercent = baseFontSize(16)(postfixUnit('%')(Decimal));
const FormatPercentDiff = baseFontSize(16)(redGreen100(postfixUnit('%')(Decimal)));
const FormatYenDiffSign = baseFontSize(16)(redGreen0(prefixUnit('¥')(sign(LocaleInteger))));
const FormatVisitorNum = baseFontSize(16)(postfixUnit('人')(LocaleInteger));
const FormatSmallVisitorNum = baseFontSize(12)(postfixUnit('人')(LocaleInteger));
const FormatGroupNum = baseFontSize(16)(postfixUnit('組')(LocaleInteger));
const FormatDay = baseFontSize(16)(postfixUnit('日')(LocaleInteger));
const FormatHours = baseFontSize(16)(postfixUnit('時間')(LocaleInteger));
const FormatMinutes = baseFontSize(16)(postfixUnit('分')(LocaleInteger));
const FormatSeconds = baseFontSize(16)(postfixUnit('秒')(LocaleInteger));
const FormatGoods = baseFontSize(16)(postfixUnit('品')(LocaleInteger));
const FormatCount = baseFontSize(16)(postfixUnit('件')(LocaleInteger));
const FormatCustomItem = baseFontSize(16)(LocaleIntegerWithDecimal);

// テーブルの行生成
const TableRow = ({
  row,
  onClickItem,
  monthRow,
  totalRow,
  headerColumns,
  batchProcessedDate,
}: {
  row: TotalType | MonthlyDetailList;
  onClickItem?: (row: MonthlyDetailList) => void;
  monthRow?: FiscalYearTotal | null;
  totalRow?: boolean;
  headerColumns: ReadonlyArray<{ key: string; headerTitle: string; toolTip?: string }>;
  batchProcessedDate: BatchProcessedDate;
}) => {
  const {
    goalLaborCostRate,
    goalFoodCostRate,
    sales,
    goalSales,
    goalSalesDiff,
    goalSalesRate,
    salesLastYearRate,
    salesLastMonthRate,
    businessDayCount,
    avgSales,
    visitorNum,
    goalVisitorNum,
    customerPayment,
    reserveGroupNum,
    reserveVisitorNum,
    reserveSales,
    personTimeSales,
    shiftEstimateLaborCost,
    laborCost,
    laborCostRate,
    foodCost,
    foodCostRate,
    lunchSales,
    goalLunchSales,
    goalLunchSalesDiff,
    lunchVisitorNum,
    goalLunchVisitorNum,
    lunchCustomerPayment,
    dinnerSales,
    goalDinnerSales,
    goalDinnerSalesDiff,
    dinnerVisitorNum,
    goalDinnerVisitorNum,
    dinnerCustomerPayment,
    insideSales,
    insideVisitorNum,
    insideCustomerPayment,
    outsideSales,
    outsideVisitorNum,
    outsideCustomerPayment,
    lateServeRate,
    lateServeVisitorNum,
    firstFoodAverageServingTime,
    firstDrinkAverageServingTime,
    alertTimeDelayItemCount,
  } = row;
  const yearMonth =
    'businessMonth' in row && row.businessMonth != null
      ? mclDayjs(row.businessMonth, formatter.mapiYearMonth).toLocalYearMonthObj()
      : null;

  const isFuture =
    yearMonth != null
      ? parser.fromDateObject(batchProcessedDate).isBefore(parser.fromYearMonthObject(yearMonth), 'month')
      : false;
  const isDailyAnalysisEnableFlag =
    'isDailyAnalysisEnableFlag' in row ? row.isDailyAnalysisEnableFlag : false;
  // 人件費率・原価率の年度目標はgoalTotalの値（目標設定したもの）を利用する
  const goalLaborCostRateValue = monthRow ? monthRow.goalLaborCostRate : goalLaborCostRate;
  const goalFoodCostRateValue = monthRow ? monthRow.goalFoodCostRate : goalFoodCostRate;

  const getTableRowData = () => {
    return headerColumns.map((header, idx) => {
      switch (header.key) {
        case 'sales':
          return (
            <React.Fragment key={idx}>
              <SalesTd sales={sales} />
            </React.Fragment>
          );

        case 'goalSales':
          return (
            <React.Fragment key={idx}>
              <GoalSalesTd isFuture={isFuture} goalSales={goalSales} monthRow={monthRow} />
            </React.Fragment>
          );

        case 'goalSalesDiff':
          return (
            <React.Fragment key={idx}>
              <GoalSalesDiffTd goalSalesDiff={goalSalesDiff} />
            </React.Fragment>
          );

        case 'goalSalesRate':
          return (
            <React.Fragment key={idx}>
              <GoalSalesRateTd goalSalesRate={goalSalesRate} />
            </React.Fragment>
          );

        case 'salesLastYearRate':
          return (
            <React.Fragment key={idx}>
              <AvgSalesLastYearRateTd salesLastYearRate={salesLastYearRate} />
            </React.Fragment>
          );

        case 'salesLastMonthRate':
          return (
            <React.Fragment key={idx}>
              <AvgSalesLastMonthRateTd salesLastMonthRate={salesLastMonthRate} />
            </React.Fragment>
          );

        case 'businessDayCount':
          return (
            <React.Fragment key={idx}>
              <BusinessDayCountTd businessDayCount={businessDayCount} />
            </React.Fragment>
          );

        case 'avgSales':
          return (
            <React.Fragment key={idx}>
              <AvgSalesTd avgSales={avgSales} businessDayCount={businessDayCount} />
            </React.Fragment>
          );

        case 'visitorNum':
          return (
            <React.Fragment key={idx}>
              <VisitorNumTd visitorNum={visitorNum} />
            </React.Fragment>
          );

        case 'goalVisitorNum':
          return (
            <React.Fragment key={idx}>
              <GoalVisitorNumTd isFuture={isFuture} goalVisitorNum={goalVisitorNum} monthRow={monthRow} />
            </React.Fragment>
          );

        case 'customerPayment':
          return (
            <React.Fragment key={idx}>
              <CustomerPaymentTd customerPayment={customerPayment} />
            </React.Fragment>
          );

        case 'lunchSales':
          return (
            <React.Fragment key={idx}>
              <LunchSalesTd lunchSales={lunchSales} />
            </React.Fragment>
          );

        case 'goalLunchSales':
          return (
            <React.Fragment key={idx}>
              <GoalLunchSalesTd isFuture={isFuture} goalLunchSales={goalLunchSales} monthRow={monthRow} />
            </React.Fragment>
          );

        case 'goalLunchSalesDiff':
          return (
            <React.Fragment key={idx}>
              <GoalLunchSalesDiffTd goalLunchSalesDiff={goalLunchSalesDiff} />
            </React.Fragment>
          );

        case 'lunchVisitorNum':
          return (
            <React.Fragment key={idx}>
              <LunchVisitorNumTd lunchVisitorNum={lunchVisitorNum} />
            </React.Fragment>
          );

        case 'goalLunchVisitorNum':
          return (
            <React.Fragment key={idx}>
              <GoalLunchVisitorNumTd
                isFuture={isFuture}
                goalLunchVisitorNum={goalLunchVisitorNum}
                monthRow={monthRow}
              />
            </React.Fragment>
          );

        case 'lunchCustomerPayment':
          return (
            <React.Fragment key={idx}>
              <LunchCustomerPaymentTd lunchCustomerPayment={lunchCustomerPayment} />
            </React.Fragment>
          );

        case 'dinnerSales':
          return (
            <React.Fragment key={idx}>
              <DinnerSalesTd dinnerSales={dinnerSales} />
            </React.Fragment>
          );

        case 'goalDinnerSales':
          return (
            <React.Fragment key={idx}>
              <GoalDinnerSalesTd isFuture={isFuture} goalDinnerSales={goalDinnerSales} monthRow={monthRow} />
            </React.Fragment>
          );

        case 'goalDinnerSalesDiff':
          return (
            <React.Fragment key={idx}>
              <GoalDinnerSalesDiffTd goalDinnerSalesDiff={goalDinnerSalesDiff} />
            </React.Fragment>
          );

        case 'dinnerVisitorNum':
          return (
            <React.Fragment key={idx}>
              <DinnerVisitorNumTd dinnerVisitorNum={dinnerVisitorNum} />
            </React.Fragment>
          );

        case 'goalDinnerVisitorNum':
          return (
            <React.Fragment key={idx}>
              <GoalDinnerVisitorNumTd
                isFuture={isFuture}
                goalDinnerVisitorNum={goalDinnerVisitorNum}
                monthRow={monthRow}
              />
            </React.Fragment>
          );

        case 'dinnerCustomerPayment':
          return (
            <React.Fragment key={idx}>
              <DinnerCustomerPaymentTd dinnerCustomerPayment={dinnerCustomerPayment} />
            </React.Fragment>
          );

        case 'insideSales':
          return (
            <React.Fragment key={idx}>
              <InsideSalesTd insideSales={insideSales} />
            </React.Fragment>
          );

        case 'insideVisitorNum':
          return (
            <React.Fragment key={idx}>
              <InsideVisitorNumTd insideVisitorNum={insideVisitorNum} />
            </React.Fragment>
          );

        case 'insideCustomerPayment':
          return (
            <React.Fragment key={idx}>
              <InsideCustomerPaymentTd insideCustomerPayment={insideCustomerPayment} />
            </React.Fragment>
          );

        case 'outsideSales':
          return (
            <React.Fragment key={idx}>
              <OutsideSalesTd outsideSales={outsideSales} />
            </React.Fragment>
          );

        case 'outsideVisitorNum':
          return (
            <React.Fragment key={idx}>
              <OutsideVisitorNumTd outsideVisitorNum={outsideVisitorNum} />
            </React.Fragment>
          );

        case 'outsideCustomerPayment':
          return (
            <React.Fragment key={idx}>
              <OutsideCustomerPaymentTd outsideCustomerPayment={outsideCustomerPayment} />
            </React.Fragment>
          );

        case 'reserveGroupNum':
          return (
            <React.Fragment key={idx}>
              <ReserveGroupNumTd reserveGroupNum={reserveGroupNum} />
            </React.Fragment>
          );

        case 'reserveVisitorNum':
          return (
            <React.Fragment key={idx}>
              <ReserveVisitorNumTd reserveVisitorNum={reserveVisitorNum} />
            </React.Fragment>
          );

        case 'reserveSales':
          return (
            <React.Fragment key={idx}>
              <ReserveSalesTd reserveSales={reserveSales} />
            </React.Fragment>
          );

        case 'personTimeSales':
          return (
            <React.Fragment key={idx}>
              <PersonTimeSalesTd personTimeSales={personTimeSales} />
            </React.Fragment>
          );

        case 'shiftEstimateLaborCost':
          return (
            <React.Fragment key={idx}>
              <ApproximateLaborCostTd shiftEstimateLaborCost={shiftEstimateLaborCost} />
            </React.Fragment>
          );

        case 'laborCost':
          return (
            <React.Fragment key={idx}>
              <LaborCostTd laborCost={laborCost} />
            </React.Fragment>
          );

        case 'laborCostRate':
          return (
            <React.Fragment key={idx}>
              <LaborCostRateTd
                laborCostRate={laborCostRate}
                goalLaborCostRateValue={goalLaborCostRateValue}
              />
            </React.Fragment>
          );

        case 'foodCost':
          return (
            <React.Fragment key={idx}>
              <FoodCostTd foodCost={foodCost} />
            </React.Fragment>
          );

        case 'foodCostRate':
          return (
            <React.Fragment key={idx}>
              <FoodCostRateTd foodCostRate={foodCostRate} goalFoodCostRateValue={goalFoodCostRateValue} />
            </React.Fragment>
          );

        case 'lateServeRate':
          return (
            <React.Fragment key={idx}>
              <LateServeRateTd lateServeRate={lateServeRate} />
            </React.Fragment>
          );

        case 'lateServeVisitorNum':
          return (
            <React.Fragment key={idx}>
              <LateServeVisitorNumTd lateServeVisitorNum={lateServeVisitorNum} />
            </React.Fragment>
          );

        case 'firstFoodAverageServingTime':
          return (
            <React.Fragment key={idx}>
              <FirstFoodAverageServingTimeTd firstFoodAverageServingTime={firstFoodAverageServingTime} />
            </React.Fragment>
          );

        case 'firstDrinkAverageServingTime':
          return (
            <React.Fragment key={idx}>
              <FirstDrinkAverageServingTimeTd firstDrinkAverageServingTime={firstDrinkAverageServingTime} />
            </React.Fragment>
          );

        case 'alertTimeDelayItemCount':
          return (
            <React.Fragment key={idx}>
              <AlertTimeDelayItemCountTd alertTimeDelayItemCount={alertTimeDelayItemCount} />
            </React.Fragment>
          );

        case 'lastYearSales':
          return (
            <React.Fragment key={idx}>
              <LastYearSalesTd row={row} />
            </React.Fragment>
          );
        case 'groupNum':
          return (
            <React.Fragment key={idx}>
              <GroupNumTd row={row} />
            </React.Fragment>
          );
        case 'repeaterRate':
          return (
            <React.Fragment key={idx}>
              <RepeaterRateTd row={row} />
            </React.Fragment>
          );
        case 'repeaterGroupRate':
          return (
            <React.Fragment key={idx}>
              <RepeaterGroupRateTd row={row} />
            </React.Fragment>
          );
        case 'foodSales':
          return (
            <React.Fragment key={idx}>
              <FoodSalesTd row={row} />
            </React.Fragment>
          );
        case 'drinkSales':
          return (
            <React.Fragment key={idx}>
              <DrinkSalesTd row={row} />
            </React.Fragment>
          );
        case 'alcoholDrinkSales':
          return (
            <React.Fragment key={idx}>
              <AlcoholDrinkSalesTd row={row} />
            </React.Fragment>
          );
        case 'softDrinkSales':
          return (
            <React.Fragment key={idx}>
              <SoftDrinkSalesTd row={row} />
            </React.Fragment>
          );
        case 'courseSales':
          return (
            <React.Fragment key={idx}>
              <CourseSalesTd row={row} />
            </React.Fragment>
          );
        case 'takeoutSales':
          return (
            <React.Fragment key={idx}>
              <TakeoutSalesTd row={row} />
            </React.Fragment>
          );
        case 'deliverySales':
          return (
            <React.Fragment key={idx}>
              <DeliverySalesTd row={row} />
            </React.Fragment>
          );
        case 'personTimeGrossIncome':
          return (
            <React.Fragment key={idx}>
              <PersonTimeGrossIncomeTd row={row} />
            </React.Fragment>
          );
        case 'theoryCost':
          return (
            <React.Fragment key={idx}>
              <TheoryCostTd row={row} />
            </React.Fragment>
          );
        case 'theoryCostRate':
          return (
            <React.Fragment key={idx}>
              <TheoryCostRateTd row={row} />
            </React.Fragment>
          );
        case 'flCost':
          return (
            <React.Fragment key={idx}>
              <FlCostTd row={row} />
            </React.Fragment>
          );
        case 'flCostRate':
          return (
            <React.Fragment key={idx}>
              <FlCostRateTd row={row} />
            </React.Fragment>
          );
        case 'currentProfit':
          return (
            <React.Fragment key={idx}>
              <CurrentProfitTd row={row} />
            </React.Fragment>
          );
        case 'currentProfitRate':
          return (
            <React.Fragment key={idx}>
              <CurrentProfitRateTd row={row} />
            </React.Fragment>
          );
        case 'foodLateServeCountAverage':
          return (
            <React.Fragment key={idx}>
              <FoodLateServeCountAverageTd row={row} />
            </React.Fragment>
          );
        case 'drinkLateServeCountAverage':
          return (
            <React.Fragment key={idx}>
              <DrinkLateServeCountAverageTd row={row} />
            </React.Fragment>
          );
        case 'recommendSuccessCountAverage':
          return (
            <React.Fragment key={idx}>
              <RecommendSuccessCountAverageTd row={row} />
            </React.Fragment>
          );
        case 'customItem':
          return (
            <React.Fragment key={idx}>
              <CustomItemsTd row={row} headerTitle={header.headerTitle} isFuture={isFuture} />
            </React.Fragment>
          );
        default:
          return (
            <React.Fragment key={idx}>
              <React.Fragment />
            </React.Fragment>
          );
      }
    });
  };

  return (
    <Tr
      key={'businessMonth' in row && row.businessMonth != null ? row.businessMonth : 'total'}
      isTotalRowStyle={totalRow}
    >
      <Td
        color={!isDailyAnalysisEnableFlag ? black : textLinkColor}
        isTotalStyle={totalRow}
        isClickable={isDailyAnalysisEnableFlag != null ? isDailyAnalysisEnableFlag : false}
        onClick={() => {
          if (
            onClickItem &&
            isDailyAnalysisEnableFlag != null &&
            isDailyAnalysisEnableFlag &&
            'businessMonth' in row
          ) {
            onClickItem(row as MonthlyDetailList);
          }
        }}
        sticky
      >
        {totalRow ? (
          <Total>
            合計<MonthTotal>(年度合計)</MonthTotal>
          </Total>
        ) : (
          <React.Fragment>
            {'businessMonth' in row && mclDayjs(row.businessMonth).format(formatter.month)}
          </React.Fragment>
        )}
      </Td>
      {getTableRowData()}
    </Tr>
  );
};

const SalesTd = ({ sales }: { sales?: number | null }) => {
  return <Td>{sales != null ? <FormatYen value={sales} /> : '-'}</Td>;
};

const GoalSalesTd = ({
  isFuture,
  goalSales,
  monthRow,
}: {
  isFuture: boolean;
  goalSales?: number | null;
  monthRow?: FiscalYearTotal | null;
}) => {
  return (
    <Td>
      {isFuture ? (
        <SmallParentheses>
          ({goalSales != null ? <FormatSmallYen value={goalSales} /> : '-'})
        </SmallParentheses>
      ) : goalSales != null ? (
        <FormatYen value={goalSales} />
      ) : (
        '-'
      )}
      {monthRow && (
        <MonthTotal>
          ({monthRow.goalSales != null ? <FormatSmallYen value={monthRow.goalSales} /> : '-'})
        </MonthTotal>
      )}
    </Td>
  );
};

const GoalSalesDiffTd = ({ goalSalesDiff }: { goalSalesDiff?: number | null }) => {
  return <Td>{goalSalesDiff == null ? '-' : <FormatYenDiffSign value={goalSalesDiff} />}</Td>;
};

const GoalSalesRateTd = ({ goalSalesRate }: { goalSalesRate?: string | null }) => {
  return <Td>{goalSalesRate == null ? '-' : <FormatPercentDiff value={goalSalesRate} />}</Td>;
};

const AvgSalesLastYearRateTd = ({ salesLastYearRate }: { salesLastYearRate?: string | null }) => {
  return <Td>{salesLastYearRate == null ? '-' : <FormatPercentDiff value={salesLastYearRate} />}</Td>;
};

const AvgSalesLastMonthRateTd = ({ salesLastMonthRate }: { salesLastMonthRate?: string | null }) => {
  return <Td>{salesLastMonthRate == null ? '-' : <FormatPercentDiff value={salesLastMonthRate} />}</Td>;
};

const BusinessDayCountTd = ({ businessDayCount }: { businessDayCount?: number | null }) => {
  return (
    <Td>
      {businessDayCount != null && businessDayCount !== 0 ? <FormatDay value={businessDayCount} /> : '-'}
    </Td>
  );
};

const AvgSalesTd = ({
  avgSales,
  businessDayCount,
}: {
  avgSales?: number | null;
  businessDayCount?: number | null;
}) => {
  return <Td>{avgSales != null && businessDayCount !== 0 ? <FormatYen value={avgSales} /> : '-'}</Td>;
};

const LunchSalesTd = ({ lunchSales }: { lunchSales?: number | null }) => {
  return <Td>{lunchSales != null ? <FormatYen value={lunchSales} /> : '-'}</Td>;
};

const GoalLunchSalesTd = ({
  isFuture,
  goalLunchSales,
  monthRow,
}: {
  isFuture: boolean;
  goalLunchSales?: number | null;
  monthRow?: FiscalYearTotal | null;
}) => {
  return (
    <Td>
      {isFuture ? (
        <SmallParentheses>
          ({goalLunchSales != null ? <FormatSmallYen value={goalLunchSales} /> : '-'})
        </SmallParentheses>
      ) : goalLunchSales != null ? (
        <FormatYen value={goalLunchSales} />
      ) : (
        '-'
      )}
      {monthRow && (
        <MonthTotal>
          ({monthRow.goalLunchSales != null ? <FormatSmallYen value={monthRow.goalLunchSales} /> : '-'})
        </MonthTotal>
      )}
    </Td>
  );
};

const GoalLunchSalesDiffTd = ({ goalLunchSalesDiff }: { goalLunchSalesDiff?: number | null }) => {
  return <Td>{goalLunchSalesDiff == null ? '-' : <FormatYenDiffSign value={goalLunchSalesDiff} />}</Td>;
};

const LunchVisitorNumTd = ({ lunchVisitorNum }: { lunchVisitorNum?: number | null }) => {
  return <Td>{lunchVisitorNum != null ? <FormatVisitorNum value={lunchVisitorNum} /> : '-'}</Td>;
};

const GoalLunchVisitorNumTd = ({
  isFuture,
  goalLunchVisitorNum,
  monthRow,
}: {
  isFuture: boolean;
  goalLunchVisitorNum?: number | null;
  monthRow?: FiscalYearTotal | null;
}) => {
  return (
    <Td>
      {isFuture ? (
        <SmallParentheses>
          ({goalLunchVisitorNum != null ? <FormatSmallVisitorNum value={goalLunchVisitorNum} /> : '-'})
        </SmallParentheses>
      ) : goalLunchVisitorNum != null ? (
        <FormatVisitorNum value={goalLunchVisitorNum} />
      ) : (
        '-'
      )}
      {monthRow && (
        <MonthTotal>
          (
          {monthRow.goalLunchVisitorNum != null ? (
            <FormatSmallVisitorNum value={monthRow.goalLunchVisitorNum} />
          ) : (
            '-'
          )}
          )
        </MonthTotal>
      )}
    </Td>
  );
};

const LunchCustomerPaymentTd = ({ lunchCustomerPayment }: { lunchCustomerPayment?: number | null }) => {
  return <Td>{lunchCustomerPayment != null ? <FormatYen value={lunchCustomerPayment} /> : '-'}</Td>;
};

const DinnerSalesTd = ({ dinnerSales }: { dinnerSales?: number | null }) => {
  return <Td>{dinnerSales != null ? <FormatYen value={dinnerSales} /> : '-'}</Td>;
};

const GoalDinnerSalesTd = ({
  isFuture,
  goalDinnerSales,
  monthRow,
}: {
  isFuture: boolean;
  goalDinnerSales?: number | null;
  monthRow?: FiscalYearTotal | null;
}) => {
  return (
    <Td>
      {isFuture ? (
        <SmallParentheses>
          ({goalDinnerSales != null ? <FormatSmallYen value={goalDinnerSales} /> : '-'})
        </SmallParentheses>
      ) : goalDinnerSales != null ? (
        <FormatYen value={goalDinnerSales} />
      ) : (
        '-'
      )}
      {monthRow && (
        <MonthTotal>
          ({monthRow.goalDinnerSales != null ? <FormatSmallYen value={monthRow.goalDinnerSales} /> : '-'})
        </MonthTotal>
      )}
    </Td>
  );
};

const GoalDinnerSalesDiffTd = ({ goalDinnerSalesDiff }: { goalDinnerSalesDiff?: number | null }) => {
  return <Td>{goalDinnerSalesDiff == null ? '-' : <FormatYenDiffSign value={goalDinnerSalesDiff} />}</Td>;
};

const DinnerVisitorNumTd = ({ dinnerVisitorNum }: { dinnerVisitorNum?: number | null }) => {
  return <Td>{dinnerVisitorNum != null ? <FormatVisitorNum value={dinnerVisitorNum} /> : '-'}</Td>;
};

const GoalDinnerVisitorNumTd = ({
  isFuture,
  goalDinnerVisitorNum,
  monthRow,
}: {
  isFuture: boolean;
  goalDinnerVisitorNum?: number | null;
  monthRow?: FiscalYearTotal | null;
}) => {
  return (
    <Td>
      {isFuture ? (
        <SmallParentheses>
          ({goalDinnerVisitorNum != null ? <FormatSmallVisitorNum value={goalDinnerVisitorNum} /> : '-'})
        </SmallParentheses>
      ) : goalDinnerVisitorNum != null ? (
        <FormatVisitorNum value={goalDinnerVisitorNum} />
      ) : (
        '-'
      )}
      {monthRow && (
        <MonthTotal>
          (
          {monthRow.goalDinnerVisitorNum != null ? (
            <FormatSmallVisitorNum value={monthRow.goalDinnerVisitorNum} />
          ) : (
            '-'
          )}
          )
        </MonthTotal>
      )}
    </Td>
  );
};

const DinnerCustomerPaymentTd = ({ dinnerCustomerPayment }: { dinnerCustomerPayment?: number | null }) => {
  return <Td>{dinnerCustomerPayment != null ? <FormatYen value={dinnerCustomerPayment} /> : '-'}</Td>;
};

const OutsideSalesTd = ({ outsideSales }: { outsideSales?: number | null }) => {
  return <Td>{outsideSales != null ? <FormatYen value={outsideSales} /> : '-'}</Td>;
};

const OutsideVisitorNumTd = ({ outsideVisitorNum }: { outsideVisitorNum?: number | null }) => {
  return <Td>{outsideVisitorNum != null ? <FormatVisitorNum value={outsideVisitorNum} /> : '-'}</Td>;
};

const OutsideCustomerPaymentTd = ({ outsideCustomerPayment }: { outsideCustomerPayment?: number | null }) => {
  return <Td>{outsideCustomerPayment != null ? <FormatYen value={outsideCustomerPayment} /> : '-'}</Td>;
};

const InsideSalesTd = ({ insideSales }: { insideSales?: number | null }) => {
  return <Td>{insideSales != null ? <FormatYen value={insideSales} /> : '-'}</Td>;
};

const InsideVisitorNumTd = ({ insideVisitorNum }: { insideVisitorNum?: number | null }) => {
  return <Td>{insideVisitorNum != null ? <FormatVisitorNum value={insideVisitorNum} /> : '-'}</Td>;
};

const InsideCustomerPaymentTd = ({ insideCustomerPayment }: { insideCustomerPayment?: number | null }) => {
  return <Td>{insideCustomerPayment != null ? <FormatYen value={insideCustomerPayment} /> : '-'}</Td>;
};

const VisitorNumTd = ({ visitorNum }: { visitorNum?: number | null }) => {
  return <Td>{visitorNum != null ? <FormatVisitorNum value={visitorNum} /> : '-'}</Td>;
};

const GoalVisitorNumTd = ({
  isFuture,
  goalVisitorNum,
  monthRow,
}: {
  isFuture: boolean;
  goalVisitorNum?: number | null;
  monthRow?: FiscalYearTotal | null;
}) => {
  return (
    <Td>
      {isFuture ? (
        <SmallParentheses>
          ({goalVisitorNum != null ? <FormatSmallVisitorNum value={goalVisitorNum} /> : '-'})
        </SmallParentheses>
      ) : goalVisitorNum != null ? (
        <FormatVisitorNum value={goalVisitorNum} />
      ) : (
        '-'
      )}
      {monthRow && (
        <MonthTotal>
          ({monthRow.goalVisitorNum != null ? <FormatSmallVisitorNum value={monthRow.goalVisitorNum} /> : '-'}
          )
        </MonthTotal>
      )}
    </Td>
  );
};

const CustomerPaymentTd = ({ customerPayment }: { customerPayment?: number | null }) => {
  return <Td>{customerPayment != null ? <FormatYen value={customerPayment} /> : '-'}</Td>;
};

const ReserveGroupNumTd = ({ reserveGroupNum }: { reserveGroupNum?: number | null }) => {
  return <Td>{reserveGroupNum != null ? <FormatGroupNum value={reserveGroupNum} /> : '-'}</Td>;
};

const ReserveVisitorNumTd = ({ reserveVisitorNum }: { reserveVisitorNum?: number | null }) => {
  return <Td>{reserveVisitorNum != null ? <FormatVisitorNum value={reserveVisitorNum} /> : '-'}</Td>;
};

const ReserveSalesTd = ({ reserveSales }: { reserveSales?: number | null }) => {
  return <Td>{reserveSales != null ? <FormatYen value={reserveSales} /> : '-'}</Td>;
};

const PersonTimeSalesTd = ({ personTimeSales }: { personTimeSales?: number | null }) => {
  return <Td>{personTimeSales != null ? <FormatYen value={personTimeSales} /> : '-'}</Td>;
};

const ApproximateLaborCostTd = ({ shiftEstimateLaborCost }: { shiftEstimateLaborCost?: number | null }) => {
  return <Td>{shiftEstimateLaborCost != null ? <FormatYen value={shiftEstimateLaborCost} /> : '-'}</Td>;
};

const LaborCostTd = ({ laborCost }: { laborCost?: number | null }) => {
  return <Td>{laborCost != null ? <FormatYen value={laborCost} /> : '-'}</Td>;
};

const LaborCostRateTd = ({
  laborCostRate,
  goalLaborCostRateValue,
}: {
  laborCostRate?: string | null;
  goalLaborCostRateValue?: string | null;
}) => {
  return (
    <Td color={greaterThanOrEqualTo(goalLaborCostRateValue, laborCostRate) ? dangerColor : black}>
      {laborCostRate != null ? <FormatPercent value={laborCostRate} /> : '-'}
    </Td>
  );
};

const FoodCostTd = ({ foodCost }: { foodCost?: number | null }) => {
  return <Td>{foodCost != null ? <FormatYen value={foodCost} /> : '-'}</Td>;
};

const FoodCostRateTd = ({
  foodCostRate,
  goalFoodCostRateValue,
}: {
  foodCostRate?: string | null;
  goalFoodCostRateValue?: string | null;
}) => {
  return (
    <Td color={greaterThanOrEqualTo(goalFoodCostRateValue, foodCostRate) ? dangerColor : black}>
      {foodCostRate != null ? <FormatPercent value={foodCostRate} /> : '-'}
    </Td>
  );
};

const LateServeRateTd = ({ lateServeRate }: { lateServeRate?: string | null }) => {
  return <Td>{lateServeRate == null ? '-' : <FormatPercent value={lateServeRate} />}</Td>;
};

const LateServeVisitorNumTd = ({ lateServeVisitorNum }: { lateServeVisitorNum?: number | null }) => {
  return <Td>{lateServeVisitorNum != null ? <FormatVisitorNum value={lateServeVisitorNum} /> : '-'}</Td>;
};

const FirstFoodAverageServingTimeTd = ({
  firstFoodAverageServingTime,
}: {
  firstFoodAverageServingTime?: number | null;
}) => {
  if (firstFoodAverageServingTime == null) {
    return <Td>{'-'}</Td>;
  }

  const localTime = mclDayjs.duration(firstFoodAverageServingTime, 'seconds');
  return (
    <Td>
      {localTime.hours() > 0 && <FormatHours value={localTime.hours()} />}
      <FormatMinutes value={localTime.minutes()} />
      <FormatSeconds value={localTime.seconds()} />
    </Td>
  );
};

const FirstDrinkAverageServingTimeTd = ({
  firstDrinkAverageServingTime,
}: {
  firstDrinkAverageServingTime?: number | null;
}) => {
  if (firstDrinkAverageServingTime == null) {
    return <Td>{'-'}</Td>;
  }

  const localTime = mclDayjs.duration(firstDrinkAverageServingTime, 'seconds');
  return (
    <Td>
      {localTime.hours() > 0 && <FormatHours value={localTime.hours()} />}
      <FormatMinutes value={localTime.minutes()} />
      <FormatSeconds value={localTime.seconds()} />
    </Td>
  );
};

const AlertTimeDelayItemCountTd = ({
  alertTimeDelayItemCount,
}: {
  alertTimeDelayItemCount?: number | null;
}) => {
  return <Td>{alertTimeDelayItemCount != null ? <FormatGoods value={alertTimeDelayItemCount} /> : '-'}</Td>;
};

const LastYearSalesTd = ({ row }: { row: MonthlyDetailList | TotalType }) => {
  return <Td>{row.lastYearSales != null ? <FormatYen value={row.lastYearSales} /> : '-'}</Td>;
};

const GroupNumTd = ({ row }: { row: MonthlyDetailList | TotalType }) => {
  return <Td>{row.groupNum != null ? <FormatGroupNum value={row.groupNum} /> : '-'}</Td>;
};

const RepeaterRateTd = ({ row }: { row: MonthlyDetailList | TotalType }) => {
  return <Td>{row.repeaterRate != null ? <FormatPercent value={row.repeaterRate} /> : '-'}</Td>;
};

const RepeaterGroupRateTd = ({ row }: { row: MonthlyDetailList | TotalType }) => {
  return <Td>{row.repeaterGroupRate != null ? <FormatPercent value={row.repeaterGroupRate} /> : '-'}</Td>;
};

const FoodSalesTd = ({ row }: { row: MonthlyDetailList | TotalType }) => {
  return <Td>{row.foodSales != null ? <FormatYen value={row.foodSales} /> : '-'}</Td>;
};

const DrinkSalesTd = ({ row }: { row: MonthlyDetailList | TotalType }) => {
  return <Td>{row.drinkSales != null ? <FormatYen value={row.drinkSales} /> : '-'}</Td>;
};

const AlcoholDrinkSalesTd = ({ row }: { row: MonthlyDetailList | TotalType }) => {
  return <Td>{row.alcoholDrinkSales != null ? <FormatYen value={row.alcoholDrinkSales} /> : '-'}</Td>;
};
const SoftDrinkSalesTd = ({ row }: { row: MonthlyDetailList | TotalType }) => {
  return <Td>{row.softDrinkSales != null ? <FormatYen value={row.softDrinkSales} /> : '-'}</Td>;
};
const CourseSalesTd = ({ row }: { row: MonthlyDetailList | TotalType }) => {
  return <Td>{row.courseSales != null ? <FormatYen value={row.courseSales} /> : '-'}</Td>;
};
const TakeoutSalesTd = ({ row }: { row: MonthlyDetailList | TotalType }) => {
  return <Td>{row.takeoutSales != null ? <FormatYen value={row.takeoutSales} /> : '-'}</Td>;
};
const DeliverySalesTd = ({ row }: { row: MonthlyDetailList | TotalType }) => {
  return <Td>{row.deliverySales != null ? <FormatYen value={row.deliverySales} /> : '-'}</Td>;
};
const PersonTimeGrossIncomeTd = ({ row }: { row: MonthlyDetailList | TotalType }) => {
  return <Td>{row.personTimeGrossIncome != null ? <FormatYen value={row.personTimeGrossIncome} /> : '-'}</Td>;
};
const TheoryCostTd = ({ row }: { row: MonthlyDetailList | TotalType }) => {
  return <Td>{row.theoryCost != null ? <FormatYen value={row.theoryCost} /> : '-'}</Td>;
};
const TheoryCostRateTd = ({ row }: { row: MonthlyDetailList | TotalType }) => {
  return <Td>{row.theoryCostRate != null ? <FormatPercent value={row.theoryCostRate} /> : '-'}</Td>;
};

const FlCostTd = ({ row }: { row: MonthlyDetailList | TotalType }) => {
  return <Td>{row.flCost != null ? <FormatYen value={row.flCost} /> : '-'}</Td>;
};

const FlCostRateTd = ({ row }: { row: MonthlyDetailList | TotalType }) => {
  return <Td>{row.flCostRate != null ? <FormatPercent value={row.flCostRate} /> : '-'}</Td>;
};

const CurrentProfitTd = ({ row }: { row: MonthlyDetailList | TotalType }) => {
  return <Td>{row.currentProfit != null ? <FormatYen value={row.currentProfit} /> : '-'}</Td>;
};

const CurrentProfitRateTd = ({ row }: { row: MonthlyDetailList | TotalType }) => {
  return <Td>{row.currentProfitRate != null ? <FormatPercent value={row.currentProfitRate} /> : '-'}</Td>;
};

const FoodLateServeCountAverageTd = ({ row }: { row: MonthlyDetailList | TotalType }) => {
  return (
    <Td>
      {row.foodLateServeCountAverage != null ? <FormatCount value={row.foodLateServeCountAverage} /> : '-'}
    </Td>
  );
};

const DrinkLateServeCountAverageTd = ({ row }: { row: MonthlyDetailList | TotalType }) => {
  return (
    <Td>
      {row.drinkLateServeCountAverage != null ? <FormatCount value={row.drinkLateServeCountAverage} /> : '-'}
    </Td>
  );
};

const RecommendSuccessCountAverageTd = ({ row }: { row: MonthlyDetailList | TotalType }) => {
  return (
    <Td>
      {row.recommendSuccessCountAverage != null ? (
        <FormatCount value={row.recommendSuccessCountAverage} />
      ) : (
        '-'
      )}
    </Td>
  );
};

const CustomItemsTd = ({
  row,
  headerTitle,
  isFuture,
}: {
  row: MonthlyDetailList | TotalType;
  headerTitle: string;
  isFuture: boolean;
}) => {
  let value = 0;
  if (row.customItems) {
    row.customItems.map(item => {
      if (item.customItemName === headerTitle) {
        value = Number(item.value);
      }
    });
  }
  return (
    <Td>
      {row.customItems != null && row.customItems.length !== 0 && !isFuture ? (
        <FormatCustomItem value={value} />
      ) : (
        '-'
      )}
    </Td>
  );
};

const halfWrapHeaderTitle = (str: string) => {
  const halfIndex = Math.ceil(str.length / 2);
  return (
    <React.Fragment>
      {str.slice(0, halfIndex)}
      <br />
      {str.slice(halfIndex)}
    </React.Fragment>
  );
};

/**
 * 当月の売上状況を表示する表
 */
class RecordTableMonthly extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      innerHeight: null,
    };
  }
  componentDidMount() {
    const { selectedStore, headerColumns, tracking } = this.props;
    const isShiftDisplay: boolean =
      headerColumns.find(
        column =>
          column.key === 'personTimeSales' ||
          column.key === 'laborCost' ||
          column.key === 'laborCostRate' ||
          column.key === 'shiftEstimateLaborCost'
      ) != null &&
      // TODO: selectedStoreはnullにならないので考慮していない
      !selectedStore?.isShiftUse;
    const isOrderDisplay: boolean =
      headerColumns.find(
        column =>
          column.key === 'lateServeRate' ||
          column.key === 'lateServeVisitorNum' ||
          column.key === 'firstFoodAverageServingTime' ||
          column.key === 'firstDrinkAverageServingTime' ||
          column.key === 'alertTimeDelayItemCount'
      ) != null && !selectedStore?.isHandyUse;

    if (isShiftDisplay || isOrderDisplay) {
      const akrCode = selectedStore != null ? selectedStore.akrCode : '';
      isShiftDisplay && tracking(_genAutoShiftLog(akrCode));
      isOrderDisplay && tracking(_genAutoOrderLog(akrCode));
    }
    this.setState({ innerHeight: window.innerHeight });
  }

  render() {
    const {
      selectedStore,
      tableData,
      total,
      goalTotal,
      headerColumns,
      onClickItem,
      onClickAutoShift,
      onClickAutoOrder,
      onClickGoalSetting,
      isThisFiscalYear,
      batchProcessedDate,
      isBatchFinish,
      isLaborCostNoAuthority,
    } = this.props;
    const filteredHeaderColumns = isLaborCostNoAuthority
      ? headerColumns.filter(item => item.key !== 'shiftEstimateLaborCost')
      : headerColumns;
    const { innerHeight } = this.state;
    // 追加集計項目の項目名が8文字より多い場合項目名を中間で改行する
    const wrapCount: number = 8;
    return (
      <TableWrapper innerHeight={innerHeight != null ? innerHeight : 0} isBatchFinish={isBatchFinish}>
        <Table>
          <thead>
            <tr>
              {filteredHeaderColumns.map((header, idx) => {
                const tooltipForItem = (toolTipText?: string, isForGourmet: boolean = false) => {
                  if (toolTipText == null) {
                    return <React.Fragment key={idx}></React.Fragment>;
                  }

                  const tooltipContent = (
                    <React.Fragment key={idx}>
                      {isForGourmet && (
                        <GourmetRow>
                          <BorderedLabel leadingSpace={0}>飲食店向け</BorderedLabel>
                        </GourmetRow>
                      )}
                      <div>{toolTipText}</div>
                    </React.Fragment>
                  );

                  // ヘッダーの右端2つの項目はツールチップの矢印を右側に変える。（はみ出して見切れる状態を避けるため）
                  return filteredHeaderColumns.length - 3 < idx ? (
                    <Tooltip.UpperRightPortal key={idx}>{tooltipContent}</Tooltip.UpperRightPortal>
                  ) : (
                    <StyledTooltip key={idx}>{tooltipContent}</StyledTooltip>
                  );
                };

                return (
                  <Th isZIndex={header.key === 'businessMonth'} key={idx}>
                    <HeaderBox>
                      <HeaderTitle>
                        {header.headerTitle.length > wrapCount
                          ? halfWrapHeaderTitle(header.headerTitle)
                          : header.headerTitle}
                        {tooltipForItem(header.toolTip, header.isForGourmet)}
                      </HeaderTitle>
                      {/** シフト */}
                      {(header.key === 'personTimeSales' ||
                        header.key === 'laborCost' ||
                        header.key === 'laborCostRate' ||
                        header.key === 'shiftEstimateLaborCost') &&
                        // TODO: selectedStoreはnullにならないので考慮していない
                        !selectedStore?.isShiftUse && (
                          <React.Fragment>
                            <div style={{ width: '100%' }} />
                            <CustomButton onClick={onClickAutoShift}>自動連携</CustomButton>
                          </React.Fragment>
                        )}
                      {/* オーダー */}
                      {(header.key === 'lateServeRate' ||
                        header.key === 'lateServeVisitorNum' ||
                        header.key === 'firstFoodAverageServingTime' ||
                        header.key === 'firstDrinkAverageServingTime' ||
                        header.key === 'alertTimeDelayItemCount') &&
                        !selectedStore?.isHandyUse && (
                          <React.Fragment>
                            <div style={{ width: '100%' }} />
                            <CustomButton onClick={onClickAutoOrder}>自動連携</CustomButton>
                          </React.Fragment>
                        )}
                      {/** 目標設定 当年度かつどの月も目標設定されていない場合 */}
                      {header.key === 'goalSalesRate' &&
                        isThisFiscalYear &&
                        tableData.find(row => row.goalSales != null) == null && (
                          <React.Fragment>
                            <div style={{ width: '100%' }} />
                            <CustomButton onClick={onClickGoalSetting}>目標設定</CustomButton>
                          </React.Fragment>
                        )}
                    </HeaderBox>
                  </Th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <TableRow
              row={total}
              monthRow={goalTotal}
              totalRow
              headerColumns={filteredHeaderColumns}
              batchProcessedDate={batchProcessedDate}
            />
            {tableData.map((row, idx) => (
              <React.Fragment key={idx}>
                <TableRow
                  row={row}
                  onClickItem={onClickItem}
                  headerColumns={filteredHeaderColumns}
                  batchProcessedDate={batchProcessedDate}
                />
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    );
  }
}

const TableWrapper = styled.div<{ innerHeight: number; isBatchFinish: boolean }>`
  overflow: auto;
  max-height: calc(
    ${props => props.innerHeight && props.innerHeight}px - ${props => (props.isBatchFinish ? 130 : 170)}px
  );
  margin-bottom: 24px;
`;
const Table = styled.table`
  border: solid 1px ${lightgray};
  border-bottom: 0;
  width: 100%;
  border-spacing: 0;
`;
const Total = styled.div`
  color: ${black};
  font-size: 14px;
  font-weight: 600;
  text-align: center;
`;
const MonthTotal = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

const Th = styled.th<{ isZIndex?: boolean }>`
  text-align: center;
  position: sticky;
  top: 0;
  left: 0;
  background-color: ${gray};
  display: table-cell;
  padding: 10px;
  white-space: nowrap;
  height: 39px;
  font-size: 14px;
  ${({ isZIndex }) => isZIndex && 'z-index: 2; border-right: 1px solid #ddd;'}
`;
const Tr = styled.tr<{ isTotalRowStyle?: boolean }>`
  padding: 12px;
  ${({ isTotalRowStyle }) => isTotalRowStyle && `font-weight: 600; background: ${verylightgray}`}
`;
const Td = styled.td<{
  sticky?: boolean;
  isTotalStyle?: boolean;
  align?: 'center' | 'left' | 'right';
  isClickable?: boolean;
}>`
  :not(:last-child) {
    border-right: 1px solid #ddd;
  }
  text-align: ${props => (props.align ? props.align : 'right')};
  padding: 12px;
  border-bottom: 0.1rem solid #e1e1e1;
  color: ${props => props.color};
  white-space: nowrap;
  ${props =>
    props.sticky &&
    `position: sticky; left: 0; background: ${
      props.isTotalStyle ? verylightgray : 'white'
    }; text-align: left;`}

  ${props =>
    props.isClickable &&
    `&:hover {
    cursor: pointer;
  }`}
`;

const StyledTooltip = styled(Tooltip.UpperLeftPortal)`
  margin-left: 3px;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const SmallParentheses = styled.span`
  font-size: 12px;
`;

const GourmetRow = styled.div`
  margin-bottom: 5px;
`;

const CustomButton = styled.button`
  background-color: ${verylightgray};
  border: solid 1px ${verylightgrayBorder};
  border-bottom-width: 2px;
  border-radius: 4px;
  padding: 2px 10px;
  color: ${verylightgrayFont};
  margin-top: 4px;
  cursor: pointer;
  &:hover {
    background-color: white;
  }
  &:active {
    background-color: ${bgGray};
  }
`;

export default RecordTableMonthly;

const _genAutoShiftLog = (akrCode: string) => {
  return genGaLog(
    'store_indices',
    'store_indices_crossuse_banner',
    'impression',
    {},
    {
      type: [CROSSSELL_PRODUCT_TYPE.sft],
      akr_code: akrCode,
      selected_indices_type: 'monthly',
    },
    'impression'
  );
};

const _genAutoOrderLog = (akrCode: string) => {
  return genGaLog(
    'store_indices',
    'store_indices_crossuse_banner',
    'impression',
    {},
    {
      type: [CROSSSELL_PRODUCT_TYPE.ord],
      akr_code: akrCode,
      selected_indices_type: 'monthly',
    },
    'impression'
  );
};
