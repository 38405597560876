import React from 'react';
import styled from 'styled-components';
import { Bar, XAxis, ResponsiveContainer, YAxis, Tooltip, ComposedChart } from 'recharts';
import {
  disabledTextColor,
  white,
  uploadBorderColor,
  navy,
  lightgray,
  attentionRed,
  darkGray,
} from '../../../constants/colors';
import { baseFontSize, postfixUnit, LocaleInteger } from '../../../components/common/atoms/Number';
import { DailyLateServeDetail } from '../../../typedef/api/StoreIndices';

type Props = {
  readonly dailyLateServeDetail: DailyLateServeDetail;
};

const FormatPersonNum = baseFontSize(16)(postfixUnit('人')(LocaleInteger));

const CustomTooltip = props => {
  if (
    props.payload == null ||
    props.payload[0] == null ||
    (props.payload[0].payload.serveVisitorNum === 0 && props.payload[0].payload.lateServeVisitorNum === 0)
  ) {
    return null;
  }
  const value = props.payload[0].payload;

  return (
    <React.Fragment>
      <Balloon>
        <LeftWrapper>
          <ContentWrapper>
            <TitleText>提供遅れなし</TitleText>
            <ContentText>
              <FormatPersonNum value={value.serveVisitorNum} />
            </ContentText>
          </ContentWrapper>
        </LeftWrapper>
        <RightWrapper>
          <ContentWrapper>
            <TitleText>提供遅あり</TitleText>
            <ContentText>
              <FormatPersonNum value={value.lateServeVisitorNum} />
            </ContentText>
          </ContentWrapper>
        </RightWrapper>
      </Balloon>
    </React.Fragment>
  );
};

const DailyLateServeGraph = (props: Props) => {
  const detailList = props.dailyLateServeDetail.lateServeDetail.hourlyDetails;

  return (
    <GraphArea>
      <ResponsiveContainer width="100%">
        <ComposedChart data={detailList}>
          <XAxis
            tickLine={false}
            interval={0}
            dataKey="visitTime"
            minTickGap={0}
            stroke={uploadBorderColor}
            tick={props => {
              return (
                <text text-anchor="middle" x={props.x} y={props.y + 10} fontSize={12} fill={darkGray}>
                  {props.payload.value}
                </text>
              );
            }}
          />
          <YAxis
            tickLine={false}
            yAxisId={0}
            tickCount={3}
            stroke={uploadBorderColor}
            tick={props => {
              return props.payload.value !== 0 ? (
                <g>
                  <text text-anchor="end" x={props.x} y={props.y} fontSize={8} fill={darkGray}>
                    {props.payload.value}
                  </text>
                </g>
              ) : (
                <text text-anchor="end" x={props.x} y={props.y} fontSize={8} fill={darkGray}>
                  {props.payload.value}
                </text>
              );
            }}
          />
          <Tooltip cursor={{ stroke: '#e1f0f8', strokeWidth: 30 }} content={<CustomTooltip />} offset={25} />
          <Bar
            yAxisId={0}
            dataKey="serveVisitorNum"
            stackId="visitorNum"
            barSize={12}
            fill={disabledTextColor}
            stroke={disabledTextColor}
          />
          <Bar
            yAxisId={0}
            dataKey="lateServeVisitorNum"
            stackId="visitorNum"
            barSize={12}
            fill={attentionRed}
            stroke={attentionRed}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </GraphArea>
  );
};

const Balloon = styled.div`
  color: white;
  white-space: initial;
  background-color: ${navy};
  width: 162px;
  height: 54px;
  padding: 12px;
  border-radius: 4px;
  text-align: left;
  line-height: 1.4;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  display: flex;
`;

const GraphArea = styled.div`
  width: 100%;
  height: 150px;
`;

const ContentText = styled.span`
  color: ${white};
  line-height: 16px;
`;

const ContentWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

const TitleText = styled.span`
  font-size: 10px;
  color: ${lightgray};
  margin-bottom: 5px;
  text-align: left;
`;

const LeftWrapper = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;
const RightWrapper = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export default DailyLateServeGraph;
