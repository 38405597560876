/**
 * 画面名：お役立ちアイデア
 */
import * as React from 'react';
import { bindActionCreators, Dispatch, Action } from 'redux';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { track } from '../../../modules/logging';
import { State as ReduxState } from '../../../modules';
import { RouteComponentProps } from 'react-router-dom';
import NotLinkedPanel from './components/NotLinkedPanel';
import { PRODUCTLIST_MODAL_TYPE, PRODUCT_ITEM, getLogParam } from './ProductListConstants';
import LinkedPanel from './components/LinkedPanel';
import { productListApiState } from '../../../selectors/productListSelectors';
import { StoresData } from '../../../modules/user';
import { startFetchCardStatus } from '../../../modules/cardSetting/cardSetting';

import { ApiState } from '../../../typedef/api/Utility';
import { CardStatusResponse } from '../../../typedef/api/CardSetting/CardStatus';

import Templates from '../../../components/common/templates';
import { ActivityIndicator } from '../../../components/common';
import ApiError from '../../../components/common/templates/ApiError';
import { API_STATE_INITIAL } from '../../../typedef/api/Utility';
import { API_STATE_STARTED } from '../../../typedef/api/Utility';
import { API_STATE_COMPLETED } from '../../../typedef/api/Utility';
import { API_STATE_FAILED } from '../../../typedef/api/Utility';

import { black } from '../../../constants/colors';
import { AppealModal } from '../../../components/common/appealModal/AppealModal';
import { PRODUCT_TYPE } from '../../../constants/appealModal';
import { genGaLog } from '../../../gaLogger';
import { Waypoint } from 'react-waypoint';
import { assignedStoresSelector } from '../../../selectors/userDataSelector';

type DispatchProps = {
  readonly track: typeof track;
  readonly fetchCardStatus: typeof startFetchCardStatus;
};

type StateProps = {
  readonly stores: ReadonlyArray<StoresData>;
  readonly productListState: ApiState<{
    cardStatusResponse?: CardStatusResponse;
  }>;
};

type Props = Readonly<RouteComponentProps<{}> & StateProps & DispatchProps>;

type State = {
  linkedItem: Array<keyof typeof PRODUCT_ITEM>;
  noLinkedItem: Array<keyof typeof PRODUCT_ITEM>;
  isShowModal: boolean;
  modalType: keyof typeof PRODUCT_TYPE;
  buttonText?: string;
  url?: string;
  productName: keyof typeof PRODUCT_ITEM;
};

class ProductListContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      linkedItem: [],
      noLinkedItem: [],
      isShowModal: false,
      modalType: 'PRODUCTLIST_CARD',
      buttonText: undefined,
      url: undefined,
      productName: 'card',
    };
  }

  componentDidMount() {
    const { fetchCardStatus, track } = this.props;
    fetchCardStatus();
    track(_genComponentDidMountLog());
  }

  componentDidUpdate(prevState: Pick<StateProps, 'productListState'>): void {
    const { productListState, stores } = this.props;
    if (productListState.type !== prevState.productListState.type) {
      // 連携済パネル 表示優先度：レジ＞ペイ＞シフト＞OES＞RB＞カード＞インボイス
      let linkedItem: Array<keyof typeof PRODUCT_ITEM> = [
        'regi',
        'pay',
        'mpa',
        'shift',
        'oes',
        'rb',
        'card',
        'invoice',
      ];
      // 未連携パネル 表示優先度：カード＞レジ＞ペイ or ペイQR＞キャッシュ＞シフト＞インボイス＞OES＞RB
      let noLinkedItem: Array<keyof typeof PRODUCT_ITEM> = [];

      // 未連携の場合はnoLinkedItemに追加させる
      const linkedCheck = (isLinked: boolean, productName: keyof typeof PRODUCT_ITEM) => {
        if (!isLinked) {
          switch (productName) {
            // cashはisLinkedがtrueの時に処理
            case 'cash':
              break;
            default:
              noLinkedItem.push(productName);
          }
          if (linkedItem.includes(productName)) {
            linkedItem.splice(
              linkedItem.findIndex(item => item === productName),
              1
            );
          }
        } else {
          // cashはisAirPayUse（isLinked）がtrueの場合未連携パネルを表示
          if (productName === 'cash') {
            noLinkedItem.push(productName);
          }
        }
      };

      if (productListState.type === API_STATE_COMPLETED) {
        const isAnyAllowedCard =
          productListState.payload.cardStatusResponse != null &&
          productListState.payload.cardStatusResponse.cardStatus.isAnyAllowedCard;

        // 表示優先度順にチェック
        linkedCheck(isAnyAllowedCard, 'card');

        linkedCheck(
          stores.some(store => store.isRegiUse),
          'regi'
        );
        // AirPay未使用ならAirペイ
        // AirPay使用 かつ MPA未使用ならMPAを表示
        if (!stores.some(store => store.isAirPayUse)) {
          linkedCheck(
            stores.some(store => store.isAirPayUse),
            'pay'
          );
        } else if (!stores.some(store => store.isPayqrUse)) {
          linkedCheck(
            stores.some(store => store.isPayqrUse),
            'mpa'
          );
        }
        linkedCheck(
          stores.some(store => store.isAirPayUse),
          'cash'
        );
        linkedCheck(
          stores.some(store => store.isShiftUse),
          'shift'
        );
        linkedCheck(
          stores.some(store => store.isAirInvoiceUse),
          'invoice'
        );
        linkedCheck(
          stores.some(store => store.isHandyUse),
          'oes'
        );
        linkedCheck(
          stores.some(store => store.isRbUse),
          'rb'
        );
        this.setState({ linkedItem: linkedItem, noLinkedItem: noLinkedItem });
      }
    }
  }
  render() {
    const { linkedItem, noLinkedItem, isShowModal, modalType, buttonText, url, productName } = this.state;
    const { productListState, stores, history, track } = this.props;

    switch (productListState.type) {
      case API_STATE_INITIAL:
      case API_STATE_STARTED:
        return (
          <Templates.Center>
            <ActivityIndicator />
          </Templates.Center>
        );
      case API_STATE_COMPLETED:
        return (
          <React.Fragment>
            <Wrapper>
              {/* スクロール検知用 */}
              <Waypoint
                onLeave={() => {
                  track(_genScrollLog());
                }}
              />
              <TitleWrapper>
                <Title>お役立ちアイデア</Title>
                <Lead>
                  リクルートの業務・経営支援サービスを活用すると、よりカンタンに経営改善に取り組むことができます。
                </Lead>
              </TitleWrapper>
              {noLinkedItem.length !== 0 && (
                <NotLinkedPanelWrapper>
                  {noLinkedItem.map(productName => (
                    <NotLinkedPanel
                      productName={productName}
                      openModal={() => {
                        this.setState({
                          isShowModal: true,
                          modalType: PRODUCTLIST_MODAL_TYPE[productName].type,
                          buttonText: PRODUCTLIST_MODAL_TYPE[productName].buttonText,
                          url: PRODUCTLIST_MODAL_TYPE[productName].url,
                          productName: productName,
                        });
                      }}
                      history={history}
                      stores={stores}
                      track={track}
                    />
                  ))}
                </NotLinkedPanelWrapper>
              )}
              {/* ペイQRの連携パネルは表出しない */}
              {linkedItem.filter(productName => productName !== 'mpa').length !== 0 && (
                <React.Fragment>
                  <LinkedTitle>Airメイトとデータ連携中のサービス</LinkedTitle>
                  <LinkedPanelWrapper>
                    {linkedItem
                      .filter(productName => productName !== 'mpa')
                      .map(productName => {
                        return <LinkedPanel productName={productName} track={track} />;
                      })}
                  </LinkedPanelWrapper>
                </React.Fragment>
              )}
            </Wrapper>
            {/* モーダル */}
            {isShowModal && (
              <AppealModal
                onClick={() => {
                  this.setState({ isShowModal: false });
                  track(_genModalCloseLog(getLogParam(productName)));
                }}
                productType={modalType}
                buttonText={buttonText}
                onSubmit={() => {
                  window.open(url);
                  track(_genModalButtonLog(getLogParam(productName)));
                }}
              />
            )}
          </React.Fragment>
        );
      case API_STATE_FAILED:
        return (
          <Templates.Center>
            <ApiError />
          </Templates.Center>
        );
    }
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    stores: assignedStoresSelector(state),
    productListState: productListApiState(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    track: bindActionCreators(track, dispatch),
    fetchCardStatus: bindActionCreators(startFetchCardStatus, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductListContainer);

const _genComponentDidMountLog = () => {
  return genGaLog('product_idea_list', 'product_idea_list', 'product_idea_list', {}, {}, 'load');
};

const _genScrollLog = () => {
  return genGaLog('product_idea_list', 'product_idea_list', 'scroll', {}, {}, 'scroll');
};

const _genModalCloseLog = (productName: string) => {
  return genGaLog(
    'product_idea_list',
    `product_idea_list_${productName}_modal`,
    'close',
    {},
    { type: [productName] },
    'click'
  );
};

const _genModalButtonLog = (productName: string) => {
  return genGaLog(
    'product_idea_list',
    `product_idea_list_${productName}_modal`,
    'submit',
    {},
    { type: [productName] },
    'click'
  );
};

const Wrapper = styled.div`
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  margin-bottom: 32px;
`;

const Title = styled.div`
  font-weight: 300;
  font-size: 20px;
  color: ${black};
  margin-bottom: 20px;
`;

const Lead = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: ${black};
`;

const NotLinkedPanelWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
  min-width: 930px;
`;

const LinkedTitle = styled.div`
  font-weight: 600;
  color: ${black};
`;

const LinkedPanelWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
