import { createSelector } from 'reselect';
import { _InputSelector, _OutputSelector } from '../typedef/selector';
import { Notice, YearlyReportNotice } from '../typedef/api/NoticeAndTodo';

const announceNotice: _InputSelector<ReadonlyArray<Notice>> = state => state.noticeAndTodo.noticeList;

const yearlyReportNotice: _InputSelector<ReadonlyArray<YearlyReportNotice>> = state =>
  state.noticeAndTodo.yearlyReportNoticeList;

/**
 * お知らせ一覧を返す
 */
export const noticeSelector: _OutputSelector<ReadonlyArray<Notice | YearlyReportNotice> | undefined> =
  createSelector([announceNotice, yearlyReportNotice], (announce, yearlyReport) => {
    // announceとyearlyReportが存在する場合、結合し新しい順で返す
    if (announce != null && yearlyReport != null) {
      const noticeArr = [...announce, ...yearlyReport];
      return noticeArr.sort(function (a, b) {
        if (a.timestamp < b.timestamp) {
          return 1;
        } else {
          return -1;
        }
      });
    } else if (announce != null) {
      return announce;
    } else if (yearlyReport != null) {
      return yearlyReport;
    } else {
      return undefined;
    }
  });
