import React from 'react';
import styled from 'styled-components';
import {
  airblue,
  airBoxShadow,
  buttonActiveColor,
  buttonHoverColor,
  white,
  yearReportBoxShadowColor,
} from '../../../../constants/colors';
import MateSorry from '../../../../icons/NewYearReport/MateSorry.svg';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';

/**
 * 年始サマリのレジ利用なし・売上0円
 */
const YearlyReportEmptyData = (props: RouteComponentProps<{}>) => {
  const { history } = props;

  return (
    <React.Fragment>
      <Wrapper>
        <StyledMateSorry />
        <Text>表示できる店舗がありません</Text>
        <AnnotationText>集計期間内にAirレジでの会計が¥1以上ある店舗を表示します。</AnnotationText>
        <CloseButtonWrapper>
          <StyledButton
            onClick={() => {
              history.push('/store');
            }}
          >
            レポートを閉じてAirメイトに戻る
          </StyledButton>
        </CloseButtonWrapper>
      </Wrapper>
    </React.Fragment>
  );
};

export default withRouter(YearlyReportEmptyData);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 24px 28px;
  height: calc(100% - 210px);
`;

const Text = styled.b`
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  font-weight: 600;
`;

const AnnotationText = styled.p`
  margin-top: 16px;
  font-size: 14px;
  letter-spacing: 0px;
  text-align: center;
`;

const CloseButtonWrapper = styled.div`
  margin-top: 40px;
`;

const StyledButton = styled.a`
  border: 1px solid ${airBoxShadow};
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  height: 48px;
  width: 320px;
  padding: 12px 16px 12px 16px;
  white-space: nowrap;
  background: ${airblue};
  box-shadow: 0px 1px 0px 0px ${yearReportBoxShadowColor};
  color: ${white};
  cursor: pointer;
  &:hover {
    background: ${buttonHoverColor};
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
  &:active {
    background: ${buttonActiveColor};
  }
`;

const StyledMateSorry = styled(MateSorry)`
  width: 82px;
  height: 109px;
  margin-bottom: 24px;
`;
