/**
 * 成績表画面の月別に対応するコンテナ
 */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Big from 'big.js';
import { Link } from 'react-router-dom';
import { bindActionCreators, Action, Dispatch } from 'redux';
import { ActivityIndicator } from '../../../components/common';
import Template from '../../../components/common/templates';
import { ResponsiveContainer, ComposedChart, Bar, Line, YAxis, XAxis, Tooltip } from 'recharts';
import Grid from '../../../components/common/Grid';
import Templates from '../../../components/common/templates';
import { ErrorCommon as Error, ERROR_TYPE_FAILED } from '../../../components/common/templates/ErrorCommon';
import ApiError from '../../../components/common/templates/ApiError';
import TopPane from './components/Indices/TopPane';
import Tab from './components/common/Toggle';
import FaqLink from '../../../components/common/FaqLink';
import { AppealModal } from '../../../components/common/appealModal/AppealModal';
import Table from './components/Indices/RecordTableMonthly';
import { actions as apiActions } from '../../../modules/storeMonthlyIndices/api';
import { actions as uiActions } from '../../../modules/storeMonthlyIndices/ui';
import { selectedStoreSelector, judgeThisYearSelector } from '../../../selectors/storeSelector';
import { track } from '../../../modules/logging';
import IndexGraphLine from '../../../icons/indexGraphLine.svg';
import SampleImage from '../../../icons/indicesMonthlySample.svg';
import { returnCodes } from '../../../constants/mapi';
import { MonthlyTimeMachineDatePicker } from './components/Indices/TimeMachineDatePicker';
import { State as ReduxState } from '../../../modules';
import { genGaLog } from '../../../gaLogger';
import * as AkrCode from '../../../typedef/AkrCode';
import {
  API_STATE_INITIAL,
  API_STATE_STARTED,
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  ApiState,
} from '../../../typedef/api/Utility';
import { StoresData, UserData } from '../../../modules/user';
import { handyFaq, storeIndexFaq } from '../../../constants/faqUrls';
import {
  lightgray,
  uploadBorderColor,
  disabledTextColor,
  navy,
  limeGreen,
  crimsonRed,
  airblue,
  textLinkColor,
} from '../../../constants/colors';
import { SELECTED_INDICES_TYPE_KEY_NAME } from '../../../constants/LocalStorage';
import { PRODUCT_TYPE } from '../../../constants/appealModal';
import { CROSSSELL_PRODUCT_TYPE } from '../../../constants/crossSellProduct';
import {
  ExistanceOfFiscalYearResultListResponse,
  FiscalYearAnalysisResponse,
  MonthlyDetailList,
} from '../../../typedef/api/StoreMonthlyIndices';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { actions as existMonthResultListActions } from '../../../modules/storeIndices/existMonthResultList';
import SorryMate from '../../../icons/MateSorry.svg';
import EditConfigIcon from '../../../icons/editConfig.svg';
import {
  assertUnreachable,
  getErrorData,
  getReturnCode,
  isBatchFailed,
  isLaborCostNoAuthority,
} from '../../../helpers/util';
import {
  baseFontSize,
  FormatTenThousand,
  Decimal,
  prefixUnit,
  postfixUnit,
} from '../../../components/common/atoms/Number';
import { Logger } from '../../../typedef/logger';
import { StoreList } from '../../../typedef/api/StoreMonthlyIndices';
import { monthDataWithStoreDataSelector } from '../../../selectors/storeSelector';
import * as GroupFiscalYearInfo from '../../../modules/targetSetting/model/groupFiscalYearInfo';
import * as Optional from '../../../helpers/optional';
import { changeSelectIndicesType } from '../../../modules/stores';
import {
  MONTHLY_INDICES_HEADER_NAME,
  SALES_CUSTOMIZE_TOOLTIP,
  INDICES_TYPE,
  DESCRIPTION_WITH_BREAK,
  isColumnForGourmet,
  HAS_NEWLINE_TOOLTIP,
  MONTHLY_APPEAL_MODAL_TYPE,
} from './storesConstants';
import { SalesCustomizeItemList } from '../../../typedef/api/StoreIndices';
import { BatchProcessedDate } from '../../../typedef/BatchProcessedDate';
import { actions as targetSettingActions } from '../../../modules/targetSetting/ui/index';
import * as TargetSettingPeriod from '../../../modules/targetSetting/ui/settingAllStoreTarget/targetSettingPeriod';
import SelectBox from '../../../components/common/atoms/SelectBox';
import { MclDayjs, formatter, mclDayjs } from '../../../helpers/mclDate';

type DispatchProps = {
  readonly fetchTargetYearList: typeof apiActions.fetchTargetYearList;
  readonly fetchYearAnalysis: typeof apiActions.fetchYearAnalysis;
  readonly selectYear: typeof uiActions.selectYear;
  readonly selectedMonth: typeof existMonthResultListActions.selectedMonth;
  readonly track: typeof track;
  readonly changeSelectIndicesType: typeof changeSelectIndicesType;
  readonly changePeriod: typeof targetSettingActions.changePeriod;
  readonly selectStore: typeof targetSettingActions.selectStore;
  readonly changeViewMode: typeof targetSettingActions.changeViewMode;
  readonly resetStoreMonthlyApiState: typeof apiActions.resetStoreMonthlyApiState;
};
type StateProps = {
  readonly userData: UserData | null;
  readonly selectedStore?: StoresData;
  readonly selectedYear?: number;
  readonly fetchTargetYearListState: ApiState<ExistanceOfFiscalYearResultListResponse>;
  readonly fetchYearAnalysisState: ApiState<FiscalYearAnalysisResponse>;
  readonly stores: ReadonlyArray<StoresData & StoreList>;
  readonly isDisplayedStoreList: boolean;
  readonly groupFiscalYearInfo: Optional.T<GroupFiscalYearInfo.T>;
  readonly isSelectedThisYear: boolean;
  readonly selectedIndicesType: keyof typeof INDICES_TYPE;
  readonly storeSalesCustomizeItemApiState: ApiState<SalesCustomizeItemList>;
  readonly batchProcessedDate: BatchProcessedDate;
  readonly batchFinishTime?: string | null;
  readonly laborCostViewScopeType: 'all' | 'manager';
};
type PassedProps = {
  readonly handleClickStoreListItem: (store: StoresData & StoreList) => void;
  readonly handleClickConfigModalOpener: () => void;
};
type Props = Readonly<RouteComponentProps<{}> & DispatchProps & StateProps & PassedProps>;

type State = {
  now: MclDayjs;
  isShowModal: boolean;
  modalType: keyof typeof PRODUCT_TYPE;
  buttonText?: string;
  url: string;
  logText: string;
};

const big = Big();

const FormatSales = baseFontSize(12)(prefixUnit('¥')(postfixUnit('万')(FormatTenThousand)));
const FormatPercent = baseFontSize(12)(postfixUnit('%')(Decimal));

type headerColumns = Array<{
  key: string;
  headerTitle: string;
  toolTip?: string;
  isForGourmet: boolean;
}>;

const CustomTooltip = props => {
  if (props.payload == null || props.payload[0] == null) {
    return null;
  }
  const value = props.payload[0].payload;

  const yearMonth = mclDayjs(value.businessMonth, formatter.mapiYearMonth);
  if (yearMonth != null && yearMonth.isValid() && yearMonth.isAfter(mclDayjs(), 'month')) {
    return null;
  }
  return (
    <React.Fragment>
      <BalloonPointer />
      <Balloon>
        <ContentText>
          <span>売上実績</span> {value.sales != null ? <FormatSales value={value.sales} /> : '¥-万'}
        </ContentText>
        <ContentText>
          <span>目標達成率</span>
          {value.goalSalesRate != null ? <FormatPercent value={value.goalSalesRate} /> : '-%'}
        </ContentText>
      </Balloon>
    </React.Fragment>
  );
};

const Content = ({
  userData,
  selectedStore,
  fetchTargetYearListState,
  fetchYearAnalysisState,
  selectedYear,
  batchProcessedDate,
  onClickItem,
  onMouseEnter,
  generateHeaderData,
  tracking,
  onClickAutoShift,
  onClickAutoOrder,
  onClickGoalSetting,
  onClickConfigModalOpener,
  isBatchFinish,
  isLaborCostNoAuthority,
}: {
  userData: UserData | null;
  selectedStore?: StoresData;
  fetchTargetYearListState: ApiState<ExistanceOfFiscalYearResultListResponse>;
  fetchYearAnalysisState: ApiState<FiscalYearAnalysisResponse>;
  selectedYear?: number;
  batchProcessedDate: BatchProcessedDate;
  onClickItem: (dataRow: MonthlyDetailList) => void;
  onMouseEnter: () => void;
  generateHeaderData: () => ReadonlyArray<{
    key: string;
    headerTitle: string;
    toolTip?: string;
    isForGourmet: boolean;
  }>;
  tracking: typeof track;
  onClickAutoShift: () => void;
  onClickAutoOrder: () => void;
  onClickGoalSetting: () => void;
  onClickConfigModalOpener: () => void;
  isBatchFinish: boolean;
  isLaborCostNoAuthority: boolean;
}): React.ReactElement => {
  switch (fetchTargetYearListState.type) {
    case 'API_STATE_INITIAL':
    case 'API_STATE_STARTED':
      return (
        <Template.Center>
          <ActivityIndicator />
        </Template.Center>
      );
    case 'API_STATE_COMPLETED':
      return !fetchTargetYearListState.payload.fiscalYearList.find(fy => fy.fiscalYear === selectedYear)
        ?.exist ? (
        <Template.Center>
          <NoDataWrapper id="store_nodata">
            <p>選択された年度のデータがありません。</p>
            <p>
              Airレジで会計を行うか、
              <StyledLink
                to={'/daily_report_list'}
                onClick={() => {
                  const log = _genNoDataToDailyReportLog();
                  tracking(log);
                }}
              >
                日報
              </StyledLink>
              で売上・人件費・原価を入力すると、翌日から反映されます。
            </p>
            <StyledSampleImage />
          </NoDataWrapper>
        </Template.Center>
      ) : (
        <IndexWrapper>
          <GraphTable
            userData={userData}
            selectedStore={selectedStore}
            fetchYearAnalysisState={fetchYearAnalysisState}
            batchProcessedDate={batchProcessedDate}
            onClickItem={onClickItem}
            onMouseEnter={onMouseEnter}
            generateHeaderData={generateHeaderData}
            onClickAutoShift={onClickAutoShift}
            tracking={tracking}
            onClickAutoOrder={onClickAutoOrder}
            onClickGoalSetting={onClickGoalSetting}
            // 取得した年度リストの最大値(最新年度)を取得して選んだ年度が最新年度かチェックする
            isThisFiscalYear={
              fetchTargetYearListState.payload.fiscalYearList.reduce((a, b) =>
                a.fiscalYear > b.fiscalYear ? a : b
              ).fiscalYear === selectedYear
            }
            onClickConfigModalOpener={onClickConfigModalOpener}
            isBatchFinish={isBatchFinish}
            isLaborCostNoAuthority={isLaborCostNoAuthority}
          />
        </IndexWrapper>
      );

    case 'API_STATE_FAILED':
      return getErrorData(fetchTargetYearListState.error) != null &&
        getReturnCode(fetchTargetYearListState.error) === returnCodes.replaceGroupId ? (
        <Templates.Center>
          <Error
            type={ERROR_TYPE_FAILED}
            msg={
              '店舗グループ統廃合によるデータ移行処理中のため、\nデータを表示できません。\n　\nお手数ですが、時間をおいて再度お試しください。'
            }
          />
        </Templates.Center>
      ) : (
        <Template.Center>
          <ApiError />
        </Template.Center>
      );
    default:
      assertUnreachable();
      return <React.Fragment />;
  }
};

const GraphTable = ({
  userData,
  selectedStore,
  fetchYearAnalysisState,
  batchProcessedDate,
  onClickItem,
  onMouseEnter,
  generateHeaderData,
  onClickAutoShift,
  onClickAutoOrder,
  tracking,
  onClickGoalSetting,
  isThisFiscalYear,
  onClickConfigModalOpener,
  isBatchFinish,
  isLaborCostNoAuthority,
}: {
  userData: UserData | null;
  selectedStore?: StoresData;
  fetchYearAnalysisState: ApiState<FiscalYearAnalysisResponse>;
  batchProcessedDate: BatchProcessedDate;
  onClickItem: (dataRow: MonthlyDetailList) => void;
  onMouseEnter: () => void;
  generateHeaderData: () => ReadonlyArray<{
    key: string;
    headerTitle: string;
    toolTip?: string;
    isForGourmet: boolean;
  }>;
  onClickAutoShift: () => void;
  onClickAutoOrder: () => void;
  tracking: typeof track;
  onClickGoalSetting: () => void;
  isThisFiscalYear: boolean;
  onClickConfigModalOpener: () => void;
  isBatchFinish: boolean;
  isLaborCostNoAuthority: boolean;
}): React.ReactElement => {
  switch (fetchYearAnalysisState.type) {
    case 'API_STATE_INITIAL':
    case 'API_STATE_STARTED':
      return (
        <Template.Center>
          <ActivityIndicator />
        </Template.Center>
      );
    case 'API_STATE_FAILED':
      return getErrorData(fetchYearAnalysisState.error) != null &&
        getReturnCode(fetchYearAnalysisState.error) === returnCodes.replaceGroupId ? (
        <Templates.Center>
          <Error
            type={ERROR_TYPE_FAILED}
            msg={
              '店舗グループ統廃合によるデータ移行処理中のため、\nデータを表示できません。\n　\nお手数ですが、時間をおいて再度お試しください。'
            }
          />
        </Templates.Center>
      ) : (
        <Template.Center>
          <NoDataWrapper id="store_nodata">
            <p>選択された年度のデータがありません。</p>
            <p>
              Airレジで会計を行うか、<StyledLink to={'/daily_report_list'}>日報</StyledLink>
              で売上・人件費・原価を入力すると、翌日から反映されます。
            </p>
            <StyledSampleImage />
          </NoDataWrapper>
        </Template.Center>
      );
    case 'API_STATE_COMPLETED':
      const { total, goalTotal, monthlyDetailList } = fetchYearAnalysisState.payload;
      const detailList = monthlyDetailList.map(detail => {
        return { ...detail, goalSalesLine: detail.goalSales != null ? detail.goalSales : 0 };
      });
      const headerItem = generateHeaderData();
      if (headerItem.length > 0) {
        return (
          <React.Fragment>
            <GraphWrapper>
              <Grid.Col num={9}>
                <ResponsiveContainer height={150} width="120%">
                  <ComposedChart data={detailList}>
                    <Tooltip
                      content={<CustomTooltip />}
                      allowEscapeViewBox={{ x: true }}
                      offset={0}
                      wrapperStyle={{ left: '-70px' }}
                      position={{ y: 0 }}
                      cursor={false}
                    />
                    <XAxis
                      tickLine={false}
                      dataKey="businessMonth"
                      interval={0}
                      minTickGap={0}
                      stroke={uploadBorderColor}
                      tick={props => {
                        return (
                          <text textAnchor="middle" x={props.x} y={props.y + 10} fontSize={10}>
                            {mclDayjs(props.payload.value, formatter.mapiYearMonth).pureMonth()}
                          </text>
                        );
                      }}
                    />
                    <YAxis
                      yAxisId={0}
                      tickCount={3}
                      tickLine={false}
                      stroke={uploadBorderColor}
                      tick={props => {
                        return props.payload.value !== 0 ? (
                          <g>
                            <text textAnchor="end" x={props.x} y={props.y} fontSize={11}>
                              {Number(big(props.payload.value).div(10000))}万
                            </text>
                          </g>
                        ) : (
                          <text textAnchor="end" x={props.x} y={props.y} fontSize={11}>
                            {props.payload.value}
                          </text>
                        );
                      }}
                    />
                    <Bar
                      dataKey="lastYearSalesReference"
                      fill={lightgray}
                      barSize={24}
                      minPointSize={5}
                      onMouseEnter={onMouseEnter}
                      shape={({ x, y, width, height, fill, payload }) => {
                        if (payload.lastYearSalesReference === 0 || payload.lastYearSalesReference == null)
                          return null;
                        return (
                          <path
                            fill={fill}
                            width={width}
                            height={height}
                            x={x}
                            y={y}
                            radius="0"
                            d={`M ${x + 3},${y} h ${width + 8} v ${height} h ${-width - 8} Z`}
                          ></path>
                        );
                      }}
                    />
                    <Bar
                      dataKey="sales"
                      fill={'#6fc02c'}
                      barSize={12}
                      minPointSize={5}
                      onMouseEnter={onMouseEnter}
                      shape={({ x, y, width, height, payload }) => {
                        const { sales, goalSales } = payload;
                        if (sales === 0 || sales == null) return null;
                        return (
                          <path
                            fill={
                              goalSales == null
                                ? disabledTextColor
                                : goalSales > sales
                                ? '#f55852'
                                : '#6fc02c'
                            }
                            width={width}
                            height={height}
                            x={x}
                            y={y}
                            radius="0"
                            d={`M ${width === 12 ? x - 2 : x + 7},${y} h ${-width} v ${height} h ${width} Z`}
                          ></path>
                        );
                      }}
                    />
                    <Line
                      type="monotone"
                      dataKey="goalSalesLine"
                      activeDot={false}
                      dot={false}
                      stroke={'gray'}
                      strokeDasharray={'2 4'}
                      strokeWidth={2}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </Grid.Col>
            </GraphWrapper>
            <LegendWrapper>
              <TotalSalesTitle margin={'6px'}>売上実績（</TotalSalesTitle>
              <GraphColor color={limeGreen} />
              <TotalSalesTitle margin={'20px'}>目標達成</TotalSalesTitle>
              <GraphColor color={crimsonRed} />
              <TotalSalesTitle margin={'20px'}>目標未達成</TotalSalesTitle>
              <GraphColor color={disabledTextColor} />
              <TotalSalesTitle margin={'12px'}>目標設定なし ）</TotalSalesTitle>
              <StyledIndexGraphLine />
              <TotalSalesTitle margin={'20px'}>売上目標</TotalSalesTitle>
              <GraphColor color={lightgray} />
              <TotalSalesTitle margin={'0px'}>前年実績</TotalSalesTitle>
            </LegendWrapper>
            <LinkWrapper>
              <EditConfigLink onClick={onClickConfigModalOpener}>
                <StyledEditConfigIcon />
                <EditConfigLinkText>表の項目を編集</EditConfigLinkText>
              </EditConfigLink>
            </LinkWrapper>
            <Table
              userData={userData}
              selectedStore={selectedStore}
              tableData={monthlyDetailList}
              total={total}
              goalTotal={goalTotal}
              onClickItem={onClickItem}
              headerColumns={headerItem}
              onClickAutoShift={onClickAutoShift}
              onClickAutoOrder={onClickAutoOrder}
              tracking={tracking}
              onClickGoalSetting={onClickGoalSetting}
              isThisFiscalYear={isThisFiscalYear}
              batchProcessedDate={batchProcessedDate}
              isBatchFinish={isBatchFinish}
              isLaborCostNoAuthority={isLaborCostNoAuthority}
            />
          </React.Fragment>
        );
      } else {
        return (
          <Template.Center>
            <SorryMate />
            <p>通信に失敗しました。ネットワーク状態を確認して再度お試しください。</p>
          </Template.Center>
        );
      }
    default:
      assertUnreachable();
      return <React.Fragment />;
  }
};

class StoreMonthlyIndicesContainer extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      now: mclDayjs(),
      isShowModal: false,
      modalType: 'STORE_MONTHLY_INDICES_SHIFT',
      buttonText: undefined,
      url: '',
      logText: '',
    };
  }
  componentDidMount() {
    const { selectedStore, fetchTargetYearList, fetchYearAnalysis, selectedYear, fetchYearAnalysisState } =
      this.props;
    if (selectedStore != null) {
      fetchTargetYearList(AkrCode.of(selectedStore.akrCode));

      if (selectedYear != null && fetchYearAnalysisState.type !== 'API_STATE_STARTED') {
        fetchYearAnalysis({ akrCode: AkrCode.of(selectedStore.akrCode), fiscalYear: selectedYear });
      }
    }
  }

  componentDidUpdate() {
    const { selectedYear, fetchTargetYearListState, selectYear } = this.props;
    if (selectedYear == null && fetchTargetYearListState.type === API_STATE_COMPLETED) {
      selectYear(fetchTargetYearListState.payload.fiscalYearList[0].fiscalYear);
    }
  }

  generateHasNewlineTooltipText = (paramName: string): React.ReactElement => {
    return (
      <div>
        {HAS_NEWLINE_TOOLTIP[paramName].description}
        <br />
        <br />
        {HAS_NEWLINE_TOOLTIP[paramName].annotation != null ? (
          HAS_NEWLINE_TOOLTIP[paramName].annotation.map(annotation => (
            <React.Fragment key={annotation}>
              {annotation}
              <br />
            </React.Fragment>
          ))
        ) : (
          <React.Fragment />
        )}
      </div>
    );
  };
  generateTooltipText = (paramName: string): React.ReactElement => {
    return (
      <div>
        {DESCRIPTION_WITH_BREAK[paramName].description}
        <br />
        <br />
        {DESCRIPTION_WITH_BREAK[paramName].annotation.map(annotation => (
          <React.Fragment key={annotation}>
            {annotation}
            <br />
          </React.Fragment>
        ))}
        {DESCRIPTION_WITH_BREAK[paramName].needDetail && (
          <div>
            詳細は
            <LinkText target="_blank" href={handyFaq}>
              こちら
            </LinkText>
            からご確認ください。
          </div>
        )}
      </div>
    );
  };
  genarateLaborCostRateTooltip = () => (
    <div>
      コスト項目設定で、分類を「人件費」で登録したコスト項目の合計を売上実績で割ったものです。
      <br />
      <br />
      人件費は
      <StyledLink to={'/daily_report_list'}>日報</StyledLink>、
      <StyledLink to={'/cost_prediction_setting'}>月次コスト管理</StyledLink>
      から入力いただくか、Airシフトからも連携できます。
    </div>
  );

  genarateFoodCostRateTooltip = () => (
    <div>
      コスト項目設定で、分類を「原価」で登録したコスト項目の合計を売上実績で割ったものです。
      <br />
      <br />
      原価は
      <StyledLink to={'/daily_report_list'}>日報</StyledLink>、
      <StyledLink to={'/cost_prediction_setting'}>月次コスト管理</StyledLink>
      から入力いただけます。
    </div>
  );

  genarateGoalSalesTooltip = () => {
    const { selectedStore, changePeriod, selectStore, changeViewMode } = this.props;
    return (
      <div>
        売上目標です。
        <StyledLink
          onClick={() => {
            this.props.track(_genClickGoalSalesTooltipLink());
            if (selectedStore != null) {
              changeViewMode('eachStore');
              changePeriod(TargetSettingPeriod.currentYear);
              selectStore(selectedStore.akrCode);
            }
          }}
          to={'/set_target'}
        >
          目標設定
        </StyledLink>
        から設定できます。
      </div>
    );
  };

  genarateSalesGoalRateTooltip = () => {
    const { selectedStore, changePeriod, selectStore, changeViewMode } = this.props;
    return (
      <div>
        売上実績を、売上目標で割ったものです。売上目標は
        <StyledLink
          onClick={() => {
            this.props.track(_genClickSalesGoalRateTooltipLink());
            if (selectedStore != null) {
              changeViewMode('eachStore');
              changePeriod(TargetSettingPeriod.currentYear);
              selectStore(selectedStore.akrCode);
            }
          }}
          to={'/set_target'}
        >
          目標設定
        </StyledLink>
        から設定できます。
      </div>
    );
  };

  generateTooltips = () => {
    return {
      ...SALES_CUSTOMIZE_TOOLTIP,
      businessDayCount: this.generateHasNewlineTooltipText('businessDayCount'),
      lateServeRate: this.generateTooltipText('lateServeRate'),
      lateServeVisitorNum: this.generateTooltipText('lateServeVisitorNum'),
      firstFoodAverageServingTime: this.generateTooltipText('firstFoodAverageServingTime'),
      firstDrinkAverageServingTime: this.generateTooltipText('firstDrinkAverageServingTime'),
      foodLateServeCountAverage: this.generateTooltipText('foodLateServeCountAverage'),
      drinkLateServeCountAverage: this.generateTooltipText('drinkLateServeCountAverage'),
      alertTimeDelayItemCount: this.generateTooltipText('alertTimeDelayItemCount'),
      // 全てリンクがついていて文言の内容も異なるため、別々に実装
      laborCostRate: this.genarateLaborCostRateTooltip(),
      foodCostRate: this.genarateFoodCostRateTooltip(),
      goalSales: this.genarateGoalSalesTooltip(),
      goalSalesRate: this.genarateSalesGoalRateTooltip(),
    };
  };

  _onClickItem = (dataRow: MonthlyDetailList) => {
    const { changeSelectIndicesType } = this.props;
    this.props.selectedMonth(dataRow.businessMonth);
    this.props.track(onClickMonthLog(dataRow.businessMonth));
    changeSelectIndicesType('daily');
  };

  // date: YYYY-MM
  _onChangeYear = (date: number): void => {
    const { selectYear, selectedStore, track } = this.props;
    selectYear(date);
    if (selectedStore != null) {
      track(changeYearLog(date, selectedStore.akrCode));
    }
  };

  _onMouseEnter = (): void => {
    this.props.track(onEnterBarLog());
  };

  // 店舗セレクトボックスで選択変更時の処理
  _handleChangeSelectStore = (storeName: string) => {
    const { handleClickStoreListItem, stores, track } = this.props;
    const store = stores.find(s => s.storeName === storeName);

    if (store != null) {
      const log = _genChangeSelectBoxStoreLog(storeName, store.akrCode);
      track(log);
      handleClickStoreListItem(store);
    }
  };

  // タブ変更時の処理
  _handleChangeTab = (indicesType: keyof typeof INDICES_TYPE) => {
    const { changeSelectIndicesType, track } = this.props;
    changeSelectIndicesType(indicesType);
    localStorage.setItem(SELECTED_INDICES_TYPE_KEY_NAME, indicesType);
    track(_genChangeTabLog(indicesType === 'daily' ? 'indices' : indicesType));
  };

  // 日別、月別セレクトボックスで選択変更時の処理
  _handleChangeSelectType = (indicesType: keyof typeof INDICES_TYPE) => {
    const { changeSelectIndicesType, selectedIndicesType, track } = this.props;
    if (indicesType !== selectedIndicesType) {
      changeSelectIndicesType(indicesType);
      track(_genChangeIndicesTypeLog(indicesType));
    }
  };

  generateHeaderData = (): ReadonlyArray<{
    key: string;
    headerTitle: string;
    toolTip?: string;
    isForGourmet: boolean;
  }> => {
    const { storeSalesCustomizeItemApiState, fetchYearAnalysisState } = this.props;
    const headerColumns: headerColumns = [];
    switch (storeSalesCustomizeItemApiState.type) {
      case API_STATE_INITIAL:
      case API_STATE_STARTED:
      case API_STATE_FAILED:
        break;
      case API_STATE_COMPLETED:
        const { salesCustomizeItem } = storeSalesCustomizeItemApiState.payload;
        headerColumns.push({
          key: 'businessMonth',
          headerTitle: MONTHLY_INDICES_HEADER_NAME.businessMonth,
          toolTip: undefined,
          isForGourmet: false,
        });
        const tooltipItem = this.generateTooltips();
        salesCustomizeItem.map(col => {
          if (Object.keys(MONTHLY_INDICES_HEADER_NAME).includes(col.item) && col.visible) {
            headerColumns.push({
              key: col.item,
              headerTitle: MONTHLY_INDICES_HEADER_NAME[col.item],
              toolTip: tooltipItem[col.item],
              isForGourmet: isColumnForGourmet(col.item),
            });
          }
          return null;
        });

        // 追加集計項目表示がvisibleか判定
        const target = {
          item: 'customItem',
          visible: true,
        };
        const isVisible = salesCustomizeItem.some(
          item => item.item === target.item && item.visible === target.visible
        );

        if (isVisible) {
          // 追加集計項目の項目名を集めた配列を作成
          const customItemHeaderTitles = new Array<string>();
          if (fetchYearAnalysisState.type === API_STATE_COMPLETED) {
            const { monthlyDetailList } = fetchYearAnalysisState.payload;
            monthlyDetailList[0].customItems?.forEach(item => {
              if (item.customItemName != null) {
                customItemHeaderTitles.push(item.customItemName);
              }
            });
          }

          // headerColumnsに追加する分の追加集計項目の配列を作成
          const customItems: headerColumns = customItemHeaderTitles.map(headerTitle => {
            return {
              key: 'customItem',
              headerTitle: headerTitle,
              toolTip: undefined,
              isForGourmet: false,
            };
          });

          // 既存のcustomItemをspliceで置き換える
          let start: number = 0;
          const deleteCount: number = 1;
          headerColumns.forEach((item, i) => {
            if (item.key === 'customItem') {
              start = i;
            }
          });
          headerColumns.splice(start, deleteCount, ...customItems);
        }
    }
    return headerColumns;
  };

  _onClickAutoShift = () => {
    const { selectedStore, track } = this.props;
    const akrCode = selectedStore != null ? selectedStore.akrCode : '';
    this.setState({
      isShowModal: true,
      modalType: MONTHLY_APPEAL_MODAL_TYPE['shift'].type,
      buttonText: MONTHLY_APPEAL_MODAL_TYPE['shift'].buttonText,
      url: MONTHLY_APPEAL_MODAL_TYPE['shift'].url,
      logText: MONTHLY_APPEAL_MODAL_TYPE['shift'].logText,
    });
    track(_genAutoShiftLog(akrCode));
  };

  _onClickAutoOrder = () => {
    const { selectedStore, track } = this.props;
    const akrCode = selectedStore != null ? selectedStore.akrCode : '';
    this.setState({
      isShowModal: true,
      modalType: MONTHLY_APPEAL_MODAL_TYPE['oes'].type,
      buttonText: MONTHLY_APPEAL_MODAL_TYPE['oes'].buttonText,
      url: MONTHLY_APPEAL_MODAL_TYPE['oes'].url,
      logText: MONTHLY_APPEAL_MODAL_TYPE['oes'].logText,
    });
    track(_genAutoOrderLog(akrCode));
  };

  _onClickGoalSetting = () => {
    const { selectedStore, changePeriod, selectStore, changeViewMode } = this.props;
    this.props.track(_genClickGoalSettingButton());
    if (selectedStore != null) {
      changeViewMode('eachStore');
      changePeriod(TargetSettingPeriod.currentYear);
      selectStore(selectedStore.akrCode);
    }
    this.props.history.push('/set_target/');
  };

  render() {
    const {
      userData,
      selectedStore,
      selectedYear,
      fetchTargetYearListState,
      fetchYearAnalysisState,
      track,
      groupFiscalYearInfo,
      selectedIndicesType,
      isDisplayedStoreList,
      batchProcessedDate,
      handleClickConfigModalOpener,
      batchFinishTime,
      laborCostViewScopeType,
    } = this.props;
    const { isShowModal, modalType, buttonText, url, logText } = this.state;

    const headerItem = this.generateHeaderData();

    return (
      <Container>
        <HeaderContents>
          <SelectStoreDate>
            <StyledTab selectedIndicesType={selectedIndicesType} onClickItem={this._handleChangeTab} />
            <StyledSelectBox
              id="store_indices_type_select"
              isOneLine
              placeholder={{
                key: selectedIndicesType,
                value: INDICES_TYPE[selectedIndicesType].itemName,
              }}
              isSelected
              options={Object.entries(INDICES_TYPE)
                .map(([key, value]) => ({
                  key: key,
                  value: value.itemName,
                }))
                .filter(item => item.key !== 'calendar')}
              onChange={t =>
                Object.keys(INDICES_TYPE).forEach(type => {
                  if (t.key === type) {
                    this._handleChangeSelectType(type as keyof typeof INDICES_TYPE);
                  }
                })
              }
              size="auto"
            />
            {selectedYear != null &&
              fetchTargetYearListState.type === 'API_STATE_COMPLETED' &&
              groupFiscalYearInfo != null && (
                <MonthlyTimeMachineDatePicker
                  onChangeDate={this._onChangeYear}
                  selectedDate={selectedYear}
                  targetList={fetchTargetYearListState}
                  startMonth={GroupFiscalYearInfo.startMonthOrDefault(groupFiscalYearInfo)}
                />
              )}
            {!isDisplayedStoreList &&
              fetchYearAnalysisState.type === 'API_STATE_COMPLETED' &&
              headerItem.length > 0 &&
              fetchTargetYearListState.type === 'API_STATE_COMPLETED' &&
              fetchTargetYearListState.payload.fiscalYearList.find(
                yearList => yearList.fiscalYear === selectedYear
              )?.exist && (
                <IndexSummaryWrapper>
                  <TopPane
                    totalSales={fetchYearAnalysisState.payload.total.sales}
                    targetAchievementDiff={fetchYearAnalysisState.payload.total.goalSalesDiff}
                    personnelExpenseRate={fetchYearAnalysisState.payload.total.laborCostRate}
                    costRate={fetchYearAnalysisState.payload.total.foodCostRate}
                    goalLaborCostRate={fetchYearAnalysisState.payload.goalTotal?.goalLaborCostRate}
                    goalFoodCostRate={fetchYearAnalysisState.payload.goalTotal?.goalFoodCostRate}
                    page={'monthly'}
                    isDisplayedStoreList={isDisplayedStoreList}
                  />
                </IndexSummaryWrapper>
              )}
          </SelectStoreDate>
          <FaqLink track={track} faqTitle="使い方" faqLink={storeIndexFaq} pageName="store_indices" />
        </HeaderContents>

        {isDisplayedStoreList &&
          fetchYearAnalysisState.type === 'API_STATE_COMPLETED' &&
          headerItem.length > 0 &&
          fetchTargetYearListState.type === 'API_STATE_COMPLETED' &&
          fetchTargetYearListState.payload.fiscalYearList.find(
            yearList => yearList.fiscalYear === selectedYear
          )?.exist && (
            <TopPane
              totalSales={fetchYearAnalysisState.payload.total.sales}
              targetAchievementDiff={fetchYearAnalysisState.payload.total.goalSalesDiff}
              personnelExpenseRate={fetchYearAnalysisState.payload.total.laborCostRate}
              costRate={fetchYearAnalysisState.payload.total.foodCostRate}
              goalLaborCostRate={fetchYearAnalysisState.payload.goalTotal?.goalLaborCostRate}
              goalFoodCostRate={fetchYearAnalysisState.payload.goalTotal?.goalFoodCostRate}
              page={'monthly'}
              isDisplayedStoreList={isDisplayedStoreList}
            />
          )}

        <Content
          userData={userData}
          selectedStore={selectedStore}
          selectedYear={selectedYear}
          fetchTargetYearListState={fetchTargetYearListState}
          fetchYearAnalysisState={fetchYearAnalysisState}
          batchProcessedDate={batchProcessedDate}
          onClickItem={this._onClickItem}
          onMouseEnter={this._onMouseEnter}
          generateHeaderData={this.generateHeaderData}
          tracking={this.props.track}
          onClickAutoShift={this._onClickAutoShift}
          onClickAutoOrder={this._onClickAutoOrder}
          onClickGoalSetting={this._onClickGoalSetting}
          onClickConfigModalOpener={handleClickConfigModalOpener}
          isBatchFinish={batchFinishTime != null && !isBatchFailed(batchFinishTime, this.state.now)}
          isLaborCostNoAuthority={isLaborCostNoAuthority(laborCostViewScopeType, userData?.plfGrant)}
        />
        {isShowModal && (
          <AppealModal
            onClick={() => {
              this.setState({ isShowModal: false });
              track(_genClickClose(logText, selectedStore?.akrCode));
            }}
            productType={modalType}
            buttonText={buttonText}
            onSubmit={() => {
              window.open(url);
              track(_genClickSubmit(logText, selectedStore?.akrCode));
            }}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    userData: state.user.data,
    selectedStore: selectedStoreSelector(state),
    selectedYear: state.storeMonthlyIndices.ui.selectYear,
    fetchTargetYearListState: state.storeMonthlyIndices.api.fetchTargetYearListState,
    fetchYearAnalysisState: state.storeMonthlyIndices.api.fetchYearAnalysisState,
    stores: monthDataWithStoreDataSelector(state),
    isDisplayedStoreList: state.stores.isDisplayedStoreList,
    groupFiscalYearInfo: state.targetSetting.model.groupFiscalYearInfo,
    isSelectedThisYear: judgeThisYearSelector(state),
    selectedIndicesType: state.stores.selectedIndicesType,
    storeSalesCustomizeItemApiState: state.stores.storeSalesCustomizeItem,
    batchProcessedDate: state.uiConfig.batchProcessedDate,
    batchFinishTime: state.uiConfig.batchProcessLastFinishDatetime,
    laborCostViewScopeType: state.uiConfig.laborCostViewScopeType,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    fetchTargetYearList: bindActionCreators(apiActions.fetchTargetYearList, dispatch),
    fetchYearAnalysis: bindActionCreators(apiActions.fetchYearAnalysis, dispatch),
    selectYear: bindActionCreators(uiActions.selectYear, dispatch),
    selectedMonth: (month?: string | null) => dispatch(existMonthResultListActions.selectedMonth(month)),
    track: bindActionCreators(track, dispatch),
    changeSelectIndicesType: bindActionCreators(changeSelectIndicesType, dispatch),
    changePeriod: (period: TargetSettingPeriod.T) => dispatch(targetSettingActions.changePeriod(period)),
    selectStore: (akrCode: AkrCode.T) => dispatch(targetSettingActions.selectStore(akrCode)),
    changeViewMode: (mode: 'eachStore' | 'allStores') => dispatch(targetSettingActions.changeViewMode(mode)),
    resetStoreMonthlyApiState: bindActionCreators(apiActions.resetStoreMonthlyApiState, dispatch),
  };
};

const Container = styled.div`
  height: 100%;
  position: relative;
`;

const HeaderContents = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const IndexWrapper = styled.div`
  height: calc(100% - 22px);
  width: 100%;
  top: 135px;
`;

const GraphWrapper = styled.div`
  margin: 24px 0 0 0;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
  flex-direction: row;
`;

const LegendWrapper = styled.div`
  display: flex;
  margin: 5px 0 25px 0;
  align-items: center;
  justify-content: center;
`;

const GraphColor = styled.div<{ color: string }>`
  display: inline-block;
  margin-right: 8px;
  width: 16px;
  height: 10px;
  ${({ color }) => `background: ${color}`}
`;

const StyledIndexGraphLine = styled(IndexGraphLine)`
  margin: auto 7px auto 0px;
`;

const TotalSalesTitle = styled.span<{ margin: string }>`
  font-size: 10px;
  ${({ margin }) => `margin-right: ${margin}`}
`;

const IndexSummaryWrapper = styled.div`
  width: 350px;
  margin-left: 20px;
`;
const BalloonPointer = styled.span`
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom: 10px solid ${navy};
  top: 55px;
  left: 60px;
  position: absolute;
  transform: rotate(180deg);
`;
const Balloon = styled.div`
  color: white;
  white-space: initial;
  background-color: ${navy};
  width: 140px;
  padding: 12px;
  border-radius: 4px;
  text-align: left;
  line-height: 1.4;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  top: 20px;
`;
const ContentText = styled.span`
  font-size: 12px;
  display: flex;
  justify-content: space-between;
`;

const SelectStoreDate = styled.div`
  display: flex;
  top: 0;
`;

const StyledTab = styled(Tab)`
  margin-right: 16px;
`;

const StyledSelectBox = styled(SelectBox.normal)`
  margin-right: 12px;
  width: 80px;
`;

const StyledLink = styled(Link)`
  color: ${airblue};
`;

const NoDataWrapper = styled.div`
  text-align: center;
`;

const StyledSampleImage = styled(SampleImage)`
  margin-top: 32px;
`;

const LinkText = styled.a`
  color: ${airblue};
`;

const LinkWrapper = styled.div`
  margin: 10px 0 20px;
  display: flex;
  justify-content: flex-end;
`;

const EditConfigLink = styled.div`
  display: flex;
  cursor: pointer;
`;

const StyledEditConfigIcon = styled(EditConfigIcon)`
  margin-right: 6px;
`;

const EditConfigLinkText = styled.span`
  font-size: 14px;
  color: ${textLinkColor};
`;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StoreMonthlyIndicesContainer));

const changeYearLog = (selectYear: number, selected_akr_code: AkrCode.T): Logger => {
  return genGaLog(
    'store_indices',
    'select_box',
    'select_fiscal _year',
    {},
    {
      selected_date: selectYear,
      akr_code: selected_akr_code,
      selected_indices_type: INDICES_TYPE['monthly'].logName,
    },
    'change'
  );
};

const onEnterBarLog = (): Logger => {
  return genGaLog('store_indices', 'monthly_graph', 'hover_bar', {}, {}, 'hover');
};

const onClickMonthLog = (selectMonth?: string | null): Logger => {
  return genGaLog('store_indices', 'table_month', 'click_link', {}, { selectMonth }, 'click');
};

const _genChangeSelectBoxStoreLog = (store_name: string, selected_akr_code: AkrCode.T) => {
  return genGaLog(
    'store_indices',
    'select_box',
    'select_store',
    {},
    { store_name, selected_akr_code },
    'click'
  );
};

const _genChangeTabLog = (indicesType: string) => {
  return genGaLog(
    'store_indices',
    'select_toggle',
    'select_Indices_type',
    {},
    { selected_indices_type: indicesType },
    'click'
  );
};

const _genChangeIndicesTypeLog = (indicesType: keyof typeof INDICES_TYPE) => {
  return genGaLog(
    'store_indices',
    'select_box',
    'select_Indices_type',
    {},
    { selected_indices_type: INDICES_TYPE[indicesType].logName },
    'change'
  );
};

const _genNoDataToDailyReportLog = () => {
  return genGaLog('store_indices_monthly', 'no_data', 'click_daily_report', {}, {}, 'click');
};

const _genAutoShiftLog = (akrCode: string) => {
  return genGaLog(
    'store_indices',
    'store_indices_crossuse_banner',
    'open',
    {},
    {
      type: [CROSSSELL_PRODUCT_TYPE.sft],
      akr_code: akrCode,
      selected_indices_type: 'monthly',
    },
    'click'
  );
};

const _genAutoOrderLog = (akrCode: string) => {
  return genGaLog(
    'store_indices',
    'store_indices_crossuse_banner',
    'open',
    {},
    {
      type: [CROSSSELL_PRODUCT_TYPE.ord],
      akr_code: akrCode,
      selected_indices_type: 'monthly',
    },
    'click'
  );
};

const _genClickGoalSettingButton = () => {
  return genGaLog(
    'store_indices_monthly',
    'store_indices_monthly',
    'click_goal_setting_button',
    {},
    {},
    'click'
  );
};

const _genClickGoalSalesTooltipLink = () => {
  return genGaLog(
    'store_indices_monthly',
    'goal_sales_tooltip',
    'click_goal_sales_tooltip_link',
    {},
    {},
    'click'
  );
};

const _genClickSalesGoalRateTooltipLink = () => {
  return genGaLog(
    'store_indices_monthly',
    'sales_goal_rate_tooltip',
    'click_sales_goal_rate_tooltip_link',
    {},
    {},
    'click'
  );
};

const _genClickSubmit = (type: string, akrCode?: string) => {
  return genGaLog(
    'store_indices',
    'store_indices_' + type + '_modal',
    'submit',
    {},
    { type: [type], selected_indices_type: 'monthly' },
    'click',
    akrCode
  );
};

const _genClickClose = (type: string, akrCode?: string) => {
  return genGaLog(
    'store_indices',
    'store_indices_' + type + '_modal',
    'close',
    {},
    { type: [type], selected_indices_type: 'monthly' },
    'click',
    akrCode
  );
};
