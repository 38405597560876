import React from 'react';
import styled from 'styled-components';
import { ipadVerticalSizeY, sideNavFontSmallSize, sideNavWidth } from '../../constants/size';
import { commonTermForService, privacyPolicy } from '../../constants/externalLink';
import SideNavItem from './SideNavItem';
import SideNavToggleItem from './SideNavToggleItem';
import DataTable from '../../icons/DataTable.svg';
import Realtime from '../../icons/Realtime.svg';
import Assistant from '../../icons/Assistant.svg';
import Setting from '../../icons/Setting.svg';
import ArrowDouble from '../../icons/ArrowDouble.svg';
import Report from '../../icons/Report.svg';
import Budget from '../../icons/Budget.svg';
import Data from '../../icons/Data.svg';
import Analysis from '../../icons/Analysis.svg';
import Dailyreport from '../../icons/Dailyreport.svg';
import Target from '../../icons/Target.svg';
import ExternalLink from '../../icons/openLink.svg';
import Phone from '../../icons/phone.svg';
import ProductIdeaList from '../../icons/productIdeaList.svg';
import CashFlow from '../../icons/cashFlow.svg';
import { Logger } from '../../typedef/logger';
import * as Ui from '../../modules/targetSetting/ui';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { genSideBarLogger } from '../../gaLogger';
import { white, disabledTextColor } from '../../constants/colors';
import { GLOBAL_NAVIGATION_KEY_NAME } from '../../constants/LocalStorage';
import SpLeadModal from './SpLeadModal';
import { Waypoint } from 'react-waypoint';
import { changeSideNavFold } from '../../modules/uiConfig';

type DispatchProps = {
  readonly isChangeOpenBaloon: typeof Ui.actions.isChangeOpenBaloon;
};

type ThroughProps = {
  readonly path: string;
  readonly logging: (a: Logger) => any;
  readonly folded: boolean;
  readonly changeSideNavFold: typeof changeSideNavFold;
  readonly dailyReportUnreadCount: number;
};

type Props = DispatchProps & ThroughProps;

type State = {
  isShow: boolean;
};

class SideNav extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isShow: false,
    };
  }
  componentDidMount() {
    const { changeSideNavFold } = this.props;
    const isSmallerIpad =
      window.screen.height > window.screen.width && window.screen.height <= ipadVerticalSizeY;
    // ローカルストレージのグロナビKEYがある場合 or 解像度がiPad以下の判定の場合グロナビ最小化、KEYがない場合グロナビ最大化
    if (localStorage.getItem(GLOBAL_NAVIGATION_KEY_NAME) === 'folded' || isSmallerIpad) {
      changeSideNavFold(true);
    } else {
      changeSideNavFold(false);
    }
  }
  handleFold = () => {
    if (localStorage.getItem(GLOBAL_NAVIGATION_KEY_NAME) === 'folded') {
      this.props.changeSideNavFold(true);
    }
  };
  handleBar = (isFolded: boolean) => {
    const { logging } = this.props;
    if (isFolded) {
      logging(genSideBarLogger('', 'click_sidebar_maximize'));
      localStorage.removeItem(GLOBAL_NAVIGATION_KEY_NAME);
    } else {
      logging(genSideBarLogger('', 'click_sidebar_minimize'));
      localStorage.setItem(GLOBAL_NAVIGATION_KEY_NAME, 'folded');
    }
  };
  render() {
    const { path, logging, folded, dailyReportUnreadCount, changeSideNavFold } = this.props;
    const { handleFold } = this;
    return (
      <React.Fragment>
        <NavList id="sidebar_wrapper" isCostPredictionSetting={path === 'cost_prediction_setting'}>
          <Nav folded={folded}>
            <SideNavItem
              title={'成績'}
              id="sidebar_store"
              folded={folded}
              img={Assistant}
              linkTo={'/store/indices'}
              selected={path === 'store'}
              logging={(meta: Logger) => logging(meta)}
              onClick={handleFold}
            />
            <SideNavItem
              title={'全店舗一覧'}
              id="sidebar_all_index"
              folded={folded}
              img={DataTable}
              linkTo={'/all_index'}
              selected={path === 'all_index'}
              logging={(meta: Logger) => logging(meta)}
              onClick={handleFold}
            />
            <SideNavItem
              title={'月次レポート'}
              id="sidebar_lookback"
              folded={folded}
              img={Report}
              linkTo={'/monthly_lookback/'}
              selected={path === 'monthly_lookback'}
              logging={(meta: Logger) => logging(meta)}
              onClick={() => {
                if (window.innerWidth <= ipadVerticalSizeY && !folded) {
                  changeSideNavFold(true);
                  logging(genSideBarLogger('', 'click_sidebar_minimize_auto', 'load'));
                  localStorage.setItem(GLOBAL_NAVIGATION_KEY_NAME, 'folded');
                } else {
                  handleFold();
                }
              }}
            />
            <Border isFolded={folded}></Border>
            <SideNavItem
              title={'リアルタイム'}
              id="sidebar_realtime"
              folded={folded}
              img={Realtime}
              linkTo={'/realtime/'}
              selected={path === 'realtime'}
              logging={(meta: Logger) => logging(meta)}
              onClick={handleFold}
            />
            <SideNavItem
              title={'日報'}
              id="sidebar_daily_report_list"
              folded={folded}
              img={Dailyreport}
              linkTo={'/daily_report_list/'}
              selected={path === 'daily_report_list'}
              logging={(meta: Logger) => logging(meta)}
              unreadCount={dailyReportUnreadCount}
              onClick={handleFold}
            />
            <SideNavItem
              title={'目標設定'}
              id="sidebar_set_target"
              folded={folded}
              img={Target}
              linkTo={'/set_target/'}
              selected={path === 'set_target'}
              logging={(meta: Logger) => logging(meta)}
              onClick={() => {
                this.props.isChangeOpenBaloon(true);
              }}
            />
            <SideNavToggleItem
              title={'分析'}
              id="sidebar_analysis"
              folded={folded}
              img={Analysis}
              path={path}
              logging={(meta: Logger) => logging(meta)}
              onClick={() => {
                changeSideNavFold(false);
              }}
              selected={
                path === 'menu_analysis' ||
                path === 'labor_cost_analysis' ||
                path === 'voucher_analysis' ||
                path === 'visitor_num_analysis' ||
                path === 'dayofweek_hourly'
              }
              children={[
                <SideNavItem
                  title={'商品分析'}
                  id="sidebar_menu_analysis"
                  folded={folded}
                  linkTo={'/menu_analysis/'}
                  selected={path === 'menu_analysis'}
                  logging={(meta: Logger) => logging(meta)}
                  onClick={handleFold}
                />,
                <SideNavItem
                  title={'曜日・時間帯別分析'}
                  id=""
                  folded={folded}
                  linkTo={'/dayofweek_hourly/'}
                  selected={path === 'dayofweek_hourly'}
                  logging={(meta: Logger) => logging(meta)}
                  onClick={handleFold}
                />,
                <SideNavItem
                  title={'人件費分析'}
                  id="sidebar_labor_cost_analysis"
                  folded={folded}
                  linkTo={'/labor_cost_analysis/'}
                  selected={path === 'labor_cost_analysis'}
                  logging={(meta: Logger) => logging(meta)}
                  onClick={handleFold}
                />,
                <SideNavItem
                  title={'伝票分析'}
                  id="sidebar_voucher_analysis"
                  folded={folded}
                  linkTo={'/voucher_analysis/'}
                  selected={path === 'voucher_analysis'}
                  logging={(meta: Logger) => logging(meta)}
                  onClick={handleFold}
                />,
              ]}
            />
            <SideNavToggleItem
              title={'コスト管理'}
              id="sidebar_cost_management"
              folded={folded}
              img={Budget}
              path={path}
              logging={(meta: Logger) => logging(meta)}
              onClick={() => {
                changeSideNavFold(false);
              }}
              selected={path === 'daily_cost_list' || path === 'cost_prediction_setting'}
              children={[
                <SideNavItem
                  title={'日次入力'}
                  id="sidebar_daily_cost_list"
                  folded={folded}
                  linkTo={'/daily_cost_list/'}
                  selected={path === 'daily_cost_list'}
                  logging={(meta: Logger) => logging(meta)}
                  onClick={handleFold}
                />,
                <SideNavItem
                  title={'月次入力'}
                  id="sidebar_cost_prediction_setting"
                  folded={folded}
                  linkTo={'/cost_prediction_setting/'}
                  selected={path === 'cost_prediction_setting'}
                  logging={(meta: Logger) => logging(meta)}
                  onClick={handleFold}
                />,
                <SideNavItem
                  title={'Airカード連携'}
                  id="sidebar_card_cost_list"
                  folded={folded}
                  linkTo={'/card_cost_list/'}
                  selected={path === 'card_cost_list'}
                  logging={(meta: Logger) => logging(meta)}
                  onClick={handleFold}
                />,
                <SideNavItem
                  title={'Airインボイス連携'}
                  id="sidebar_invoice_cost_list"
                  folded={folded}
                  linkTo={'/invoice_cost_list/'}
                  selected={path === 'invoice_cost_list'}
                  logging={(meta: Logger) => logging(meta)}
                  onClick={handleFold}
                />,
              ]}
            />
            <SideNavToggleItem
              title={'キャッシュフロー'}
              id=""
              folded={folded}
              img={CashFlow}
              path={path}
              logging={(meta: Logger) => logging(meta)}
              onClick={() => {
                changeSideNavFold(false);
              }}
              selected={path === 'deposit_information'}
              children={[
                <SideNavItem
                  title={'入金情報'}
                  id=""
                  folded={folded}
                  linkTo={'/transfer/'}
                  selected={path === 'transfer'}
                  logging={(meta: Logger) => logging(meta)}
                  onClick={handleFold}
                />,
              ]}
              fontSize={sideNavFontSmallSize}
            />
            <Border isFolded={folded}></Border>

            <SideNavToggleItem
              title={'設定'}
              id="sidebar_setting"
              folded={folded}
              img={Setting}
              path={path}
              logging={(meta: Logger) => logging(meta)}
              onClick={() => {
                changeSideNavFold(false);
              }}
              selected={
                path === 'basic_setting' || path === 'cost_item_setting' || path === 'customize_daily_report'
              }
              children={[
                <SideNavItem
                  title={'基本設定'}
                  id="sidebar_basic_setting"
                  folded={folded}
                  linkTo={'/basic_setting/'}
                  selected={path === 'basic_setting'}
                  logging={(meta: Logger) => logging(meta)}
                  onClick={handleFold}
                />,
                <SideNavItem
                  title={'売上取込設定'}
                  id="sidebar_data_import_setting"
                  folded={folded}
                  linkTo={'/data_import_setting/'}
                  selected={path === 'data_import_setting'}
                  logging={(meta: Logger) => logging(meta)}
                  onClick={handleFold}
                />,
                <SideNavItem
                  title={'コスト項目設定'}
                  id="sidebar_cost_item_setting"
                  folded={folded}
                  linkTo={'/cost_item_setting/'}
                  selected={path === 'cost_item_setting'}
                  logging={(meta: Logger) => logging(meta)}
                  onClick={handleFold}
                />,
                <SideNavItem
                  title={'Airカード連携設定'}
                  id="sidebar_card_setting"
                  folded={folded}
                  linkTo={'/card_setting/'}
                  selected={path === 'card_setting'}
                  logging={(meta: Logger) => logging(meta)}
                  onClick={handleFold}
                />,
                <SideNavItem
                  title={'日報設定'}
                  id="sidebar_customize_daily_report"
                  folded={folded}
                  linkTo={'/customize_daily_report/'}
                  selected={path === 'customize_daily_report'}
                  logging={(meta: Logger) => logging(meta)}
                  onClick={handleFold}
                />,
              ]}
            />
            <SideNavToggleItem
              title={'データ入出力'}
              id="sidebar_data_input_output"
              folded={folded}
              img={Data}
              path={path}
              logging={(meta: Logger) => logging(meta)}
              onClick={() => {
                changeSideNavFold(false);
                setTimeout(() => {
                  document
                    .getElementById('sidebar_data_input_output')
                    ?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                }, 300);
              }}
              selected={path === 'data_output' || path === 'past_data_import'}
              children={[
                <SideNavItem
                  title={'データ出力'}
                  id="sidebar_data_output"
                  folded={folded}
                  linkTo={'/data_output/'}
                  selected={path === 'data_output'}
                  logging={(meta: Logger) => logging(meta)}
                  onClick={handleFold}
                />,
                <SideNavItem
                  title={'売上一括取込'}
                  id="sidebar_past_data_import"
                  folded={folded}
                  linkTo={'/past_data_import/'}
                  selected={path === 'past_data_import'}
                  logging={(meta: Logger) => logging(meta)}
                  onClick={handleFold}
                />,
              ]}
            />
            <Waypoint
              onEnter={() => {
                logging(genSideBarLogger('', 'load_sp_modal_button', 'load'));
              }}
            >
              <div>
                <SideNavItem
                  title={'アプリ版のご案内'}
                  id="sidebar_app_infomation"
                  folded={folded}
                  img={Phone}
                  logging={(meta: Logger) => logging(meta)}
                  onClick={() => {
                    this.setState({ isShow: true });
                    logging(genSideBarLogger('', 'open_sp_modal'));
                  }}
                />
              </div>
            </Waypoint>
            <SideNavItem
              title={'お役立ちアイデア'}
              id=""
              folded={folded}
              linkTo="/product_idea_list/"
              img={ProductIdeaList}
              logging={(meta: Logger) => logging(meta)}
              isLastNavItem={true}
              selected={path === 'product_idea_list'}
            />
            {!folded && (
              <NaviFooterWrapper>
                <RuleWrapper>
                  <RuleLink>
                    <Link
                      onClick={() => logging(genSideBarLogger('Airサービス共通利用約款'))}
                      href={commonTermForService}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Airサービス共通利用約款 <StyledExternalLink />
                    </Link>
                  </RuleLink>
                  <RuleLink>
                    <Link
                      onClick={() => logging(genSideBarLogger('プライバシーポリシー'))}
                      href={privacyPolicy}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      プライバシーポリシー <StyledExternalLink />
                    </Link>
                  </RuleLink>
                </RuleWrapper>
              </NaviFooterWrapper>
            )}
            <OpenCloseBar
              onClick={() => {
                changeSideNavFold(!folded);
                this.handleBar(folded);
              }}
              folded={folded}
            >
              {!folded && <CloseText>メニューを隠す</CloseText>}
              <ArrowDouble
                style={{
                  margin: '0 14px',
                  verticalAlign: 'middle',
                  transform: folded ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
            </OpenCloseBar>
          </Nav>
        </NavList>
        {this.state.isShow && (
          <SpLeadModal
            onClick={() => {
              this.setState({ isShow: false });
              logging(genSideBarLogger('', 'close_sp_modal'));
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

const NavList = styled.div<{ isCostPredictionSetting: boolean }>`
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 100%;
  background-color: #47545a;
  z-index: 5;
  transform: translate3d(0, 0, 0);
  //月次コスト管理のテーブルのデザイン崩れを防ぐ為
  ${props => props.isCostPredictionSetting && 'z-index: 3;'}
`;

const Nav = styled.nav<{ folded: boolean }>`
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100%;
  width: ${props => (props.folded ? sideNavWidth.fold : sideNavWidth.global)}px;
  max-width: 100%;
  transition: all 0.2s ease 0s;
`;

const OpenCloseBar = styled.div<{ folded: boolean }>`
  width: 100%;
  height: 48px;
  line-height: 48px;
  background-color: #29363b;
  text-align: right;
  cursor: pointer;
  :hover {
    background-color: #3e494e;
  }
  position: sticky;
  ${props => props.folded && 'margin-top: auto'};
  bottom: 0;
`;

const RuleWrapper = styled.div`
  padding: 5px 14px;
  font-size: 11px;
  background-color: #47545a;
  bottom: 48px;
`;

const RuleLink = styled.div`
  margin: 10px 0;
`;

const Link = styled.a`
  color: white;
`;

const StyledExternalLink = styled(ExternalLink)`
  margin: auto 0 auto 4px;
  width: 15px;
`;

const NaviFooterWrapper = styled.div`
  margin-top: auto;
  padding-top: 32px;
`;

const CloseText = styled.span`
  color: ${white};
  font-size: 12px;
`;

const Border = styled.div<{ isFolded: boolean }>`
  border: solid 1px ${disabledTextColor};
  width: ${props => (props.isFolded ? '82%' : '92%')};
  margin: 8px auto;
`;

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  isChangeOpenBaloon: (bool: boolean) => dispatch(Ui.actions.isChangeOpenBaloon(bool)),
});

export default connect(undefined, mapDispatchToProps)(SideNav);
