/**
 * 外部リンクの可変パラメーターを定義する
 */
export const ACD_COST_SETTING_TOP_BTN_LID_PARAMETER = 'mate_acdCostSetting_TopBtn';
export const ACD_COST_MANAGEMENT_TOP_BTN_LID_PARAMETER = 'mate_acdCostManagement_TopBtn';
export const PRODUCTLIST_CARD_MODAL_BTN_LID_PARAMETER = 'mate_productidea_acd';
export const PRODUCTLIST_SHIFT_MODAL_BTN_LID_PARAMETER = 'mate_productidea_sft';
export const PRODUCTLIST_OES_MODAL_BTN_LID_PARAMETER = 'mate_productidea_ahd';
export const PRODUCTLIST_RB_MODAL_BTN_LID_PARAMETER = 'mate_productidea_rb';
export const PRODUCTLIST_REGI_MODAL_BTN_LID_PARAMETER = 'mate_productidea_arg';
export const PRODUCTLIST_CASH_MODAL_BTN_LID_PARAMETER = 'mate_productidea_acs';
export const PRODUCTLIST_INVOICE_MODAL_BTN_LID_PARAMETER = 'mate_productidea_inv';
export const PRODUCTLIST_APY_MODAL_BTN_LID_PARAMETER = 'mate_productidea_apy';
export const PRODUCTLIST_MPA_MODAL_BTN_LID_PARAMETER = 'mate_productidea_mpa';
export const MONTHLY_LOOKBACK_LABOR_COST_ANALYSIS_SHIFT_BTN_LID_PARAMETER =
  'mate_monthly_lookback_preview_sftTopBtn';
export const MONTHLYLOOKBACK_EMPTYSTATE_REGI_PARAMETER = 'mate_monthly_lookback_arg_argappeal_modal_submit';
export const DAYOFWEEKHOURLY_MODAL_BTN_LID_PARAMETER = 'mate_dayofweek_hourly_argTopBtn';
export const STORE_CALENDAR_INDICES_CARD_MODAL_BTN_LID_PARAMETER = 'mate_topCalCatalog_acdTopBtn';
export const STORE_CALENDAR_INDICES_CARD_SUMMARY_MODAL_BTN_LID_PARAMETER =
  'mate_store_indices_acd_calendar_summary_link';
export const STORE_CALENDAR_INDICES_SHIFT_MODAL_BTN_LID_PARAMETER = 'mate_topCalCatalog_sftTopBtn';
export const STORE_CALENDAR_INDICES_SHIFT_SUMMARY_MODAL_BTN_LID_PARAMETER =
  'mate_store_indices_sft_calendar_summary_link';
export const STORE_CALENDAR_INDICES_OES_MODAL_BTN_LID_PARAMETER = 'mate_topCalCatalog_ahdTopBtn';
export const STORE_CALENDAR_INDICES_RBMODAL_BTN_LID_PARAMETER = 'mate_topCalCatalog_rbTopBtn';
export const STORE_CALENDAR_INDICES_REGI_MODAL_BTN_LID_PARAMETER = 'mate_topPopup_argTopBtn';
export const STORE_DAILY_INDICES_SHIFT_MODAL_BTN_LID_PARAMETER = 'mate_topCatalog_sftTopBtn';
export const STORE_CALENDAR_INDICES_CASH_MODAL_BTN_LID_PARAMETER = 'mate_topCalCatalog_acsTopBtn';
export const STORE_CALENDAR_INDICES_SPO_BTN_LID_PARAMATER = 'mate_topCalCatalog_spoCampaignBtn';
export const STORE_INDICES_ARG_LINKINGTOAIRPAY_MODAL_SUBMIT_PARAMETER =
  'mate_store_indices_arg_linkingtoairpay_modal_submit';
export const MENU_ANALYSIS_REGI_MODAL_BTN_LID_PARAMETER = 'mate_menu_analysis_argTopBtn';
export const TRANSFER_TOP_BANNER_ACT_BTN_LID_PARAMETER = 'mate_transfer_topBanner_acsTopBtn';
export const TRANSFER_BOTTOM_APY_TEXTLINK_ACT_BTN_LID_PARAMETER = 'mate_transfer_bottom_apyTextLink';
export const TRANSFER_MPA_APY_USE_ACT_BTN_LID_PARAMETER = 'mate_transfer_mpaApyUse';
export const TRANSFER_APY_QR_USE_ACT_BTN_LID_PARAMETER = 'mate_transfer_apyQrUse';
export const TRANSFER_APY_EMPTY_USE_ACT_BTN_LID_PARAMETER = 'mate_transfer_apyEmptyUse';
export const TRANSFER_APY_MODAL_TEXTLINK_ACT_BTN_LID_PARAMETER = 'mate_transfer_modal_apyTextLink';
export const LABORCOSTANALYSIS_SHIFT_MODAL_BTN_LID_PARAMETER = 'mate_labor_cost_analysis_sftTopBtn';
export const LABORCOSTANALYSIS_OES_MODAL_BTN_LID_PARAMETER = 'mate_labor_cost_analysis_ordTopBtn';
export const DAILYREPORT_SHIFT_MODAL_BTN_LID_PARAMETER = 'mate_diary_report_sftTopBtn';
export const DAILYREPORT_EMPTYSTATE_REGI_PARAMETER = 'mate_daily_report_list_argappeal_modal_submit';
export const REALTIME_REGI_MODAL_BTN_LID_PARAMETER = 'mate_realtime_argTopBtn_preview';
export const REALTIME_SHIFT_MODAL_BTN_LID_PARAMETER = 'mate_realtime_sftTopBtn_preview';
export const REALTIME_OES_MODAL_BTN_LID_PARAMETER = 'mate_realtime_ahdPromoRequestInfoBtn_preview';
export const REALTIME_RB_MODAL_BTN_LID_PARAMETER = 'mate_realtime_rbTopBtn_preview';
export const REALTIME_SALESESTIMATED_RB_MODAL_BTN_LID_PARAMETER = 'mate_realtime_rb_suggest_text';
export const REALTIME_SALESESTIMATED_OES_MODAL_BTN_LID_PARAMETER = 'mate_realtime_ahd_suggest_text';
export const REALTIME_SALESESTIMATED_REGI_MODAL_BTN_LID_PARAMETER = 'mate_realtime_arg_suggest_text';
export const STORE_MONTHLY_INDICES_SHIFT_MODAL_BTN_LID_PARAMETER =
  'mate_storeIndicesAutoFill_sftRequestInfoBtn';
export const STORE_MONTHLY_INDICES_OES_MODAL_BTN_LID_PARAMETER =
  'mate_storeIndicesAutoFill_handyPromoRequestInfoBtn';
export const DAILY_DETAIL_PAST_OES_MODAL_BTN_LID_PARAMETER =
  'mate_storeIndicePastDetails_handyPromoRequestInfoBtn';
export const DAILY_DETAIL_PAST_RB_MODAL_BTN_LID_PARAMETER = 'mate_storeIndicePastDetails_rbTopBtn';
export const DAILY_DETAIL_PAST_REGI_MODAL_BTN_LID_PARAMETER = 'mate_storeIndicePastDetails_argTopBtn';
export const DAILY_DETAIL_PAST_SHIFT_MODAL_BTN_LID_PARAMETER = 'mate_storeIndicePastDetails_sftTopBtn';
export const DAILY_DETAIL_FUTURE_SHIFT_MODAL_BTN_LID_PARAMETER = 'mate_storeIndiceFutureDetails_sftTopBtn';
export const DAILY_DETAIL_FUTURE_RB_MODAL_BTN_LID_PARAMETER = 'mate_storeIndiceFutureDetails_rbTopBtn';
export const ALL_INDEX_ARG_LINKINGTOAIRPAY_MODAL_SUBMIT_PARAMETER =
  'mate_all_index_arg_linkingtoairpay_modal_submit';

// キャンペーンコード
export const ACD_COST_MANAGEMENT_TOP_BTN_CAMPAIGN_PARAMETER = 'mtea0003';
export const ACD_COST_SETTING_TOP_BTN_CAMPAIGN_PARAMETER = 'mtea0004';
export const PRODUCTLIST_CARD_MODAL_BTN_CAMPAIGN_PARAMETER = 'mtea0006';
export const STORE_CALENDAR_INDICES_CARD_MODAL_BTN_CAMPAIGN_PARAMETER = 'mtea0007';
export const STORE_CALENDAR_INDICES_CARD_SUMMARY_MODAL_BTN_CAMPAIGN_PARAMETER = 'mtea0010';

// 各ストアのリンクパラメータ
export const APPSTORE_AMT_INDICES_CATALOG2_QR_QUERYPARAMETER = '?pt=21979&ct=amt_indices_catalog2_qr&mt=8';
export const APPSTORE_AMT_SIDEBAR_MODAL_LINK_QUERYPARAMETER = '?pt=21979&ct=amt_sidebar_modal_link&mt=8';
export const GOOGLEPLAYSTORE_QUERYPARAMETER = '?id=jp.airmate&hl=ja&gl=US';

// シフトモーダルのパラメータ
export const AIRSHIFT_APPEALMODAL_QUERYPARAMETER = '?lid=mate_diary_report_cost_input_sftTopBtn';

// カードモーダルのパラメータ
export const AIRCARD_APPMODAL_QUERYPARAMETER = '?campaignCd=mtea0005&lid=mate_diary_report_acdTopBtn';

// appリンクのパラメータ
export const STORE_CALENDAR_INDICES_REGI_MODAL_APP_ADJUST_PARAMETER = '13d6irvv';
export const STORE_CALENDAR_INDICES_LINKAGE_PAYQR_REGI_MODAL_APP_ADJUST_PARAMETER = '1e601rcl';

// Airインボイスのパラメータ
export const AIRINVOICE_COST_MANAGEMENT_TOP_BTN_LID_PARAMETER = 'mate_diary_report_acdTopBtn';

// 入金情報ダウンロードモーダルのパラメータ
export const APY_TRANSFER_DOWNLOAD_MODAL_LID_PARAMETER = '?lid=mate_transfer_csv_modal_apyTextLink';
export const APY_QR_TRANSFER_DOWNLOAD_MODAL_LID_PARAMETER = '?lid=mate_transfer_csv_modal_mpaTextLink';
