import {
  FormatTenThousand,
  LocaleInteger,
  baseFontSize,
  postfixUnit,
  prefixUnit,
} from '../../../../components/common/atoms/Number';

export const FormatSmall人 = baseFontSize(32)(postfixUnit('人')(LocaleInteger));
export const Format人 = baseFontSize(44)(postfixUnit('人')(LocaleInteger));
export const FormatSmall円 = baseFontSize(30)(prefixUnit('¥')(LocaleInteger));
export const Format円 = baseFontSize(36)(prefixUnit('¥')(LocaleInteger));
export const FormatSmall万 = baseFontSize(30)(prefixUnit('¥')(postfixUnit('万')(FormatTenThousand)));
export const Format万 = baseFontSize(36)(prefixUnit('¥')(postfixUnit('万')(FormatTenThousand)));
