import { PostResponse, ApiState, apiState, ErrorType } from '../typedef/api/Utility';
import { Logger, Tracker } from '../typedef/logger';
import { AllIndexSummaryItem, ThisMonthPostConfigRequest, StoreList } from '../typedef/api/AllIndex';
import { CustomRangeResponse } from '../typedef/api/AllIndex';
import { StoresData } from '../modules/user';
import { LocalDateObj } from '../helpers/mclDate';

type _WithTags = {
  readonly tags: ReadonlyArray<string>;
};

export type MydConfig = ReadonlyArray<AllIndexSummaryItem>;

export type AllIndexStore = Readonly<{} & StoresData & StoreList & _WithTags>;

export type State = {
  readonly selectedStoreTag: string;
  readonly isOpenModal: boolean;
  readonly mydConfig: ApiState<MydConfig>;
  readonly isPosting: boolean;
  readonly isPosted: boolean;
  readonly postErr: ErrorType | undefined | null;
  readonly isEditing: boolean;
  readonly isOpenDialog: boolean;
  readonly storeListState: ApiState<CustomRangeResponse>;
  readonly selectedDate: { dateFrom: LocalDateObj; dateTo: LocalDateObj } | null;
  readonly initialized: boolean;
};

export const USER_SELECT_STORE_TAG = 'user/allIndex/SELECT_STORE_TAG';
export const TOGGLE_MODAL = 'user/allIndex/TOGGLE_MODAL';
export const START_FETCH_CUSTOMIZE_ITEM = 'user/allIndex/START_FETCH_CUSTOMIZE_ITEM';
export const START_FETCH_CUSTOMIZE_ITEM_INITIALIZE = 'user/allIndex/START_FETCH_CUSTOMIZE_ITEM_INITIALIZE';
export const SUCCESS_FETCH_MYD_CUSTOMIZE_ITEM = 'user/allIndex/SUCCESS_FETCH_MYD_CUSTOMIZE_ITEM';
export const SUCCESS_FETCH_MYD_CUSTOMIZE_ITEM_INITIALIZE =
  'user/allIndex/SUCCESS_FETCH_MYD_CUSTOMIZE_ITEM_INITIALIZE';
export const FAIL_FETCH_CUSTOMIZE_ITEM = 'user/allIndex/FAIL_FETCH_CUSTOMIZE_ITEM';
export const FAIL_FETCH_CUSTOMIZE_ITEM_INITIALIZE = 'user/allIndex/FAIL_FETCH_CUSTOMIZE_ITEM_INITIALIZE';
export const START_POST_CONFIG = 'user/allIndex/START_POST_CONFIG';
export const SUCCESS_POST_CONFIG = 'user/allIndex/SUCCESS_POST_CONFIG';
export const SUCCESS_POST_CONFIG_INITIALIZE = 'user/allIndex/SUCCESS_POST_CONFIG_INITIALIZE';
export const FAIL_POST_CONFIG = 'user/allIndex/FAIL_POST_CONFIG';
export const FAIL_POST_CONFIG_INITIALIZE = 'user/allIndex/FAIL_POST_CONFIG_INITIALIZE';
export const EDIT = 'user/allIndex/EDIT';
export const TOGGLE_DIALOG = 'user/allIndex/TOGGLE_DIALOG';
export const INITIALIZED = 'user/allIndex/INITIALIZED';

const START_FETCH_CUSTOM_RANGE_STORE_DATA = 'allIndex/START_FETCH_CUSTOM_RANGE_STORE_DATA';
const SUCCESS_FETCH_CUSTOM_RANGE_STORE_DATA = 'allIndex/SUCCESS_FETCH_CUSTOM_RANGE_STORE_DATA';
const FAILURE_FETCH_CUSTOM_RANGE_STORE_DATA = 'allIndex/FAILURE_FETCH_CUSTOM_RANGE_STORE_DATA';
const CHANGE_SELECT_DATE = 'allIndex/CHANGE_SELECT_DATE';

export const types = {
  USER_SELECT_STORE_TAG,
  TOGGLE_MODAL,
  SUCCESS_FETCH_MYD_CUSTOMIZE_ITEM,
  SUCCESS_FETCH_MYD_CUSTOMIZE_ITEM_INITIALIZE,
  START_FETCH_CUSTOMIZE_ITEM,
  START_FETCH_CUSTOMIZE_ITEM_INITIALIZE,
  FAIL_FETCH_CUSTOMIZE_ITEM,
  FAIL_FETCH_CUSTOMIZE_ITEM_INITIALIZE,
  START_POST_CONFIG,
  SUCCESS_POST_CONFIG,
  SUCCESS_POST_CONFIG_INITIALIZE,
  FAIL_POST_CONFIG,
  FAIL_POST_CONFIG_INITIALIZE,
  EDIT,
  TOGGLE_DIALOG,
  START_FETCH_CUSTOM_RANGE_STORE_DATA,
  SUCCESS_FETCH_CUSTOM_RANGE_STORE_DATA,
  FAILURE_FETCH_CUSTOM_RANGE_STORE_DATA,
  CHANGE_SELECT_DATE,
  INITIALIZED,
};

export type UserSelectStoreTagAction = {
  readonly type: 'user/allIndex/SELECT_STORE_TAG';
  readonly payload: string;
  readonly meta?: Tracker;
};

export type ToggleModalAction = {
  readonly type: 'user/allIndex/TOGGLE_MODAL';
  readonly meta?: Tracker;
};

export type StartFetchCustomizeItemAction = {
  readonly type: 'user/allIndex/START_FETCH_CUSTOMIZE_ITEM';
  readonly meta?: Tracker;
};

export type StartFetchCustomizeItemInitializeAction = {
  readonly type: 'user/allIndex/START_FETCH_CUSTOMIZE_ITEM_INITIALIZE';
  readonly meta?: Tracker;
};

export type SuccessFetchMYDCustomizeItemAction = {
  readonly type: 'user/allIndex/SUCCESS_FETCH_MYD_CUSTOMIZE_ITEM';
  readonly payload: MydConfig;
};

export type SuccessFetchMYDCustomizeItemInitializeAction = {
  readonly type: 'user/allIndex/SUCCESS_FETCH_MYD_CUSTOMIZE_ITEM_INITIALIZE';
  readonly payload: MydConfig;
};

export type FailFetchCustomizeItemAction = {
  readonly type: 'user/allIndex/FAIL_FETCH_CUSTOMIZE_ITEM';
  readonly payload: ErrorType;
};

export type FailFetchCustomizeItemInitializeAction = {
  readonly type: 'user/allIndex/FAIL_FETCH_CUSTOMIZE_ITEM_INITIALIZE';
  readonly payload: ErrorType;
};

export type StartPostConfigAction = {
  readonly type: 'user/allIndex/START_POST_CONFIG';
  readonly payload: ThisMonthPostConfigRequest;
  readonly meta?: Tracker;
};

export type SuccessPostConfigAction = {
  readonly type: 'user/allIndex/SUCCESS_POST_CONFIG';
  readonly payload: PostResponse;
};

export type SuccessPostConfigInitializeAction = {
  readonly type: 'user/allIndex/SUCCESS_POST_CONFIG_INITIALIZE';
  readonly payload: PostResponse;
};

export type FailPostConfigAction = {
  readonly type: 'user/allIndex/FAIL_POST_CONFIG';
  readonly payload: ErrorType;
};

export type FailPostConfigInitializeAction = {
  readonly type: 'user/allIndex/FAIL_POST_CONFIG_INITIALIZE';
  readonly payload: ErrorType;
};

export type EditAction = {
  readonly type: 'user/allIndex/EDIT';
};

export type ToggleDialogAction = {
  readonly type: 'user/allIndex/TOGGLE_DIALOG';
};

export type InitializedAction = {
  readonly type: 'user/allIndex/INITIALIZED';
};

type StartFetchCustomRangeStoreDataAction = {
  readonly type: typeof START_FETCH_CUSTOM_RANGE_STORE_DATA;
};

type SuccessFetchCustomRangeStoreDataAction = {
  readonly type: typeof SUCCESS_FETCH_CUSTOM_RANGE_STORE_DATA;
  readonly payload: CustomRangeResponse;
};

type FailureFetchCustomRangeStoreDataAction = {
  readonly type: typeof FAILURE_FETCH_CUSTOM_RANGE_STORE_DATA;
  readonly payload: ErrorType;
};

type ChangeSelectDateAction = {
  readonly type: typeof CHANGE_SELECT_DATE;
  readonly payload: { dateFrom: LocalDateObj; dateTo: LocalDateObj };
};

export type Action =
  | UserSelectStoreTagAction
  | ToggleModalAction
  | StartFetchCustomizeItemAction
  | StartFetchCustomizeItemInitializeAction
  | SuccessFetchMYDCustomizeItemAction
  | SuccessFetchMYDCustomizeItemInitializeAction
  | FailFetchCustomizeItemAction
  | FailFetchCustomizeItemInitializeAction
  | StartPostConfigAction
  | SuccessPostConfigAction
  | SuccessPostConfigInitializeAction
  | FailPostConfigAction
  | FailPostConfigInitializeAction
  | EditAction
  | ToggleDialogAction
  | StartFetchCustomRangeStoreDataAction
  | SuccessFetchCustomRangeStoreDataAction
  | FailureFetchCustomRangeStoreDataAction
  | ChangeSelectDateAction
  | InitializedAction;

export const userSelectStoreTag = (storeTag: string, log?: Logger): UserSelectStoreTagAction => {
  return {
    type: USER_SELECT_STORE_TAG,
    payload: storeTag,
    meta: log && log.tracker,
  };
};

export const toggleModal = (log?: Logger): ToggleModalAction => {
  return {
    type: TOGGLE_MODAL,
    meta: log && log.tracker,
  };
};

// 欲しいmasterの種類をmodeで指定. mtd or realtime
export const startFetchCustomizeItem = (log?: Logger): StartFetchCustomizeItemAction => {
  return {
    type: START_FETCH_CUSTOMIZE_ITEM,
    meta: log && log.tracker,
  };
};

export const startFetchCustomizeItemInitialize = (log?: Logger): StartFetchCustomizeItemInitializeAction => {
  return {
    type: START_FETCH_CUSTOMIZE_ITEM_INITIALIZE,
    meta: log && log.tracker,
  };
};

export const successFetchMYDCustomizeItem = (data: MydConfig): SuccessFetchMYDCustomizeItemAction => {
  return {
    type: SUCCESS_FETCH_MYD_CUSTOMIZE_ITEM,
    payload: data,
  };
};

export const successFetchMYDCustomizeItemInitialize = (
  data: MydConfig
): SuccessFetchMYDCustomizeItemInitializeAction => {
  return {
    type: SUCCESS_FETCH_MYD_CUSTOMIZE_ITEM_INITIALIZE,
    payload: data,
  };
};

export const failFetchCustomizeItem = (err: ErrorType): FailFetchCustomizeItemAction => {
  return {
    type: FAIL_FETCH_CUSTOMIZE_ITEM,
    payload: err,
  };
};

export const failFetchCustomizeItemInitialize = (err: ErrorType): FailFetchCustomizeItemInitializeAction => {
  return {
    type: FAIL_FETCH_CUSTOMIZE_ITEM_INITIALIZE,
    payload: err,
  };
};

export const startPostConfig = (value: ThisMonthPostConfigRequest, log?: Logger): StartPostConfigAction => {
  return {
    type: START_POST_CONFIG,
    payload: value,
    meta: log && log.tracker,
  };
};

export const successPostConfig = (data: PostResponse): SuccessPostConfigAction => {
  return {
    type: SUCCESS_POST_CONFIG,
    payload: data,
  };
};

export const successPostConfigInitialize = (data: PostResponse): SuccessPostConfigInitializeAction => {
  return {
    type: SUCCESS_POST_CONFIG_INITIALIZE,
    payload: data,
  };
};

export const failPostConfig = (err: ErrorType): FailPostConfigAction => {
  return {
    type: FAIL_POST_CONFIG,
    payload: err,
  };
};

export const failPostConfigInitialize = (err: ErrorType): FailPostConfigInitializeAction => {
  return {
    type: FAIL_POST_CONFIG_INITIALIZE,
    payload: err,
  };
};

export const edit = (): EditAction => {
  return {
    type: EDIT,
  };
};

export const toggleDialog = (): ToggleDialogAction => {
  return {
    type: TOGGLE_DIALOG,
  };
};

export const initialize = (): InitializedAction => {
  return {
    type: INITIALIZED,
  };
};

const startFetchCustomRangeStoreData = (): StartFetchCustomRangeStoreDataAction => ({
  type: START_FETCH_CUSTOM_RANGE_STORE_DATA,
});

const successFetchCustomRangeStoreData = (
  res: CustomRangeResponse
): SuccessFetchCustomRangeStoreDataAction => ({ type: SUCCESS_FETCH_CUSTOM_RANGE_STORE_DATA, payload: res });

const failureFetchCustomRangeStoreData = (error: ErrorType): FailureFetchCustomRangeStoreDataAction => ({
  type: FAILURE_FETCH_CUSTOM_RANGE_STORE_DATA,
  payload: error,
});

const changeSelectDate = (selectDate: { dateFrom: LocalDateObj; dateTo: LocalDateObj }) => ({
  type: CHANGE_SELECT_DATE,
  payload: selectDate,
});

export const actions = {
  userSelectStoreTag,
  toggleModal,
  startFetchCustomizeItem,
  startFetchCustomizeItemInitialize,
  successFetchMYDCustomizeItem,
  successFetchMYDCustomizeItemInitialize,
  failFetchCustomizeItem,
  failFetchCustomizeItemInitialize,
  startPostConfig,
  successPostConfig,
  successPostConfigInitialize,
  failPostConfig,
  failPostConfigInitialize,
  edit,
  toggleDialog,
  startFetchCustomRangeStoreData,
  successFetchCustomRangeStoreData,
  failureFetchCustomRangeStoreData,
  changeSelectDate,
  initialize,
};

export const initialState: State = {
  selectedStoreTag: '全店舗',
  isOpenModal: false,
  mydConfig: apiState.initial(),
  isPosting: false,
  isPosted: false,
  postErr: null,
  isEditing: false,
  isOpenDialog: false,
  storeListState: apiState.initial(),
  selectedDate: null,
  initialized: false,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case USER_SELECT_STORE_TAG:
      return { ...state, selectedStoreTag: action.payload };

    case TOGGLE_MODAL:
      return { ...state, isOpenModal: !state.isOpenModal, isEditing: false, isOpenDialog: false };

    case START_FETCH_CUSTOMIZE_ITEM:
      return { ...state, mydConfig: apiState.started() };

    case SUCCESS_FETCH_MYD_CUSTOMIZE_ITEM:
      return { ...state, mydConfig: apiState.completed(action.payload) };

    case SUCCESS_FETCH_MYD_CUSTOMIZE_ITEM_INITIALIZE:
      return { ...state, mydConfig: apiState.completed(action.payload), initialized: true };

    case FAIL_FETCH_CUSTOMIZE_ITEM:
      return { ...state, mydConfig: apiState.failed(action.payload) };

    case FAIL_FETCH_CUSTOMIZE_ITEM_INITIALIZE:
      return { ...state, mydConfig: apiState.failed(action.payload), initialized: true };

    case START_POST_CONFIG:
      return { ...state, isPosting: true, isPosted: false, isEditing: false };

    case SUCCESS_POST_CONFIG:
      return { ...state, isPosting: false, isPosted: true, isOpenModal: false };

    case SUCCESS_POST_CONFIG_INITIALIZE:
      return { ...state, isPosting: false, isPosted: true, isOpenModal: false };

    case FAIL_POST_CONFIG:
      return { ...state, isPosting: false, isPosted: true, postErr: action.payload };

    case FAIL_POST_CONFIG_INITIALIZE:
      return { ...state, isPosting: false, isPosted: true, postErr: action.payload, initialized: true };

    case EDIT:
      return { ...state, isEditing: true };

    case TOGGLE_DIALOG:
      return { ...state, isOpenDialog: !state.isOpenDialog };

    case START_FETCH_CUSTOM_RANGE_STORE_DATA:
      return { ...state, storeListState: apiState.started() };

    case SUCCESS_FETCH_CUSTOM_RANGE_STORE_DATA:
      return { ...state, storeListState: apiState.completed(action.payload) };

    case FAILURE_FETCH_CUSTOM_RANGE_STORE_DATA:
      return { ...state, storeListState: apiState.failed(action.payload) };

    case CHANGE_SELECT_DATE:
      return { ...state, selectedDate: action.payload };

    case INITIALIZED:
      return { ...state, initialized: true };

    default:
      return state;
  }
};

export default reducer;
