import { fork, call, put, takeLatest } from 'redux-saga/effects';
import { ApiCallEffectResult } from '../../typedef/api/Utility';
import { types, actions, StartFetchYearlyReportAction } from '../../modules/yearlyReport/api';
import YearlyReportAPI from '../../services/yearlyReportAPI';
import { YearlyLookbackResponse } from '../../typedef/api/YearlyReport/Yearlyreport';

function* fetchYearlyReportSaga() {
  yield takeLatest(types.START_FETCH_YEARLY_REPORT, function* (action: StartFetchYearlyReportAction) {
    const year = action.payload;
    const { payload, error }: ApiCallEffectResult<YearlyLookbackResponse> = yield call(
      YearlyReportAPI.fetchYearlyReport,
      year
    );

    if (error != null) {
      yield put(actions.failFetchYearlyReport(error));
    } else if (payload) {
      yield put(actions.successFetchYearlyReport(payload));
    }
  });
}

export default function* yearlyReportSaga() {
  yield fork(fetchYearlyReportSaga);
}
