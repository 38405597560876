import { PRODUCT_TYPE } from '../../../constants/appealModal';
import { CROSSSELL_PRODUCT_TYPE } from '../../../constants/crossSellProduct';

import {
  airCardPromoUrl,
  airCardUrl,
  airPaySummary,
  airRegiLogin,
  airRegiOrderItem,
  airRegiOrderRequestInfo,
  airRegiTop,
  airShiftUrlWeeklyShift,
  rbIndex,
  rbTop,
  airCashLogin,
  airInvoicePromoDocumentRequestUrl,
  airPayUrl,
  airPayqrEntryUrl,
  airInvoiceUrl,
} from '../../../constants/externalLink';
import {
  PRODUCTLIST_CARD_MODAL_BTN_CAMPAIGN_PARAMETER,
  PRODUCTLIST_CARD_MODAL_BTN_LID_PARAMETER,
  PRODUCTLIST_OES_MODAL_BTN_LID_PARAMETER,
  PRODUCTLIST_RB_MODAL_BTN_LID_PARAMETER,
  PRODUCTLIST_REGI_MODAL_BTN_LID_PARAMETER,
  PRODUCTLIST_SHIFT_MODAL_BTN_LID_PARAMETER,
  PRODUCTLIST_CASH_MODAL_BTN_LID_PARAMETER,
  PRODUCTLIST_INVOICE_MODAL_BTN_LID_PARAMETER,
  PRODUCTLIST_APY_MODAL_BTN_LID_PARAMETER,
  PRODUCTLIST_MPA_MODAL_BTN_LID_PARAMETER,
} from '../../../constants/externalLinkParameter';

export const PRODUCT_ITEM = {
  card: {
    checkText: ['原価をラクに把握したい', '経費の入力がめんどう'],
    leadText: ['経費管理がラクになりポイントもたまる', 'おトクなビジネスカード'],
    url: airCardUrl,
    buttonText: '詳しく見る',
  },
  shift: {
    checkText: ['出退勤をきちんと管理したい', '人件費を見直したい'],
    leadText: ['やりとりも作成もラクになる', 'シフト管理サービス'],
    url: `${airShiftUrlWeeklyShift}?lid=${PRODUCTLIST_SHIFT_MODAL_BTN_LID_PARAMETER}`,
    buttonText: '詳しく見る',
  },
  oes: {
    checkText: ['提供遅れを減らしたい', 'スタッフの負担を減らしたい'],
    leadText: ['飲食店の業務をカンタンにする', 'オーダーシステム'],
    url: airRegiOrderItem,
    buttonText: '詳しく見る',
  },
  rb: {
    checkText: ['ミスなく予約管理したい', '顧客情報を残したい'],
    leadText: ['集客と接客に効く', '予約台帳アプリ'],
    url: rbIndex,
    buttonText: '詳しく見る',
  },
  regi: {
    checkText: ['レジ締め作業に時間がかかる', 'カンタンに売上を分析したい'],
    leadText: ['０円でカンタンに使える', 'POSレジアプリ'],
    url: `${airRegiLogin}`,
    buttonText: '詳しく見る',
  },
  pay: {
    checkText: ['キャッシュレス決済を導入したい', '導入コストを抑えたい'],
    leadText: ['カード・電マネ・QR・ポイントも使える', 'お店の決済サービス'],
    url: airPaySummary,
    buttonText: '詳しく見る',
  },
  cash: {
    checkText: ['すぐに資金調達がしたい', 'ローン・借入に抵抗がある'],
    leadText: ['将来の売上を今のお金にかえる', '資金調達サービス'],
    url: ``,
    buttonText: '詳しく見る',
  },
  invoice: {
    checkText: ['請求書の振込手数料を抑えたい', '請求書の支払いをまとめてしたい'],
    leadText: ['振込も保管もラクになる', '請求書支払アプリ'],
    url: airInvoiceUrl,
    buttonText: '詳しく見る',
  },
  mpa: {
    checkText: ['QR決済を導入したい', '導入コストを抑えたい'],
    leadText: ['　', 'QR決済の導入なら'],
    url: ``,
    buttonText: '詳しく見る',
  },
};

export const PRODUCTLIST_MODAL_TYPE: {
  card: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
  };
  shift: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
  };
  oes: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
  };
  rb: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
  };
  regi: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
  };
  cash: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
  };
  invoice: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
  };
  pay: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
  };
  mpa: {
    type: keyof typeof PRODUCT_TYPE;
    buttonText: string;
    url: string;
  };
} = {
  card: {
    type: 'PRODUCTLIST_CARD',
    buttonText: 'Airカードの申込みはこちら',
    url: `${airCardPromoUrl}?campaignCd=${PRODUCTLIST_CARD_MODAL_BTN_CAMPAIGN_PARAMETER}&lid=${PRODUCTLIST_CARD_MODAL_BTN_LID_PARAMETER}`,
  },
  shift: {
    type: 'PRODUCTLIST_SHIFT',
    buttonText: 'いますぐAirシフトの利用をはじめる',
    url: `${airShiftUrlWeeklyShift}?lid=${PRODUCTLIST_SHIFT_MODAL_BTN_LID_PARAMETER}`,
  },
  oes: {
    type: 'PRODUCTLIST_OES',
    buttonText: '資料請求（無料）・お問い合わせはこちら',
    url: `${airRegiOrderRequestInfo}?lid=${PRODUCTLIST_OES_MODAL_BTN_LID_PARAMETER}`,
  },
  rb: {
    type: 'PRODUCTLIST_RB',
    buttonText: 'いますぐレストランボードの利用をはじめる',
    url: `${rbTop}?lid=${PRODUCTLIST_RB_MODAL_BTN_LID_PARAMETER}`,
  },
  regi: {
    type: 'PRODUCTLIST_REGI',
    buttonText: 'いますぐAirレジの利用をはじめる',
    url: `${airRegiTop}?lid=${PRODUCTLIST_REGI_MODAL_BTN_LID_PARAMETER}`,
  },
  cash: {
    type: 'PRODUCTLIST_CASH',
    buttonText: 'ご利用可能金額を確認する',
    url: `${airCashLogin}?lid=${PRODUCTLIST_CASH_MODAL_BTN_LID_PARAMETER}`,
  },
  invoice: {
    type: 'PRODUCTLIST_INVOICE',
    buttonText: '資料ダウンロード（無料）はこちら',
    url: `${airInvoicePromoDocumentRequestUrl}?lid=${PRODUCTLIST_INVOICE_MODAL_BTN_LID_PARAMETER}`,
  },
  pay: {
    type: 'PRODUCTLIST_PAY',
    buttonText: 'Airペイの申込みはこちら',
    url: `${airPayUrl}?lid=${PRODUCTLIST_APY_MODAL_BTN_LID_PARAMETER}`,
  },
  mpa: {
    type: 'PRODUCTLIST_MPA',
    buttonText: 'Airペイ QRの申込みはこちら',
    url: `${airPayqrEntryUrl}?lid=${PRODUCTLIST_MPA_MODAL_BTN_LID_PARAMETER}`,
  },
};

// ログのパラメーター用に変換
export const getLogParam = (productName: keyof typeof PRODUCT_ITEM) => {
  switch (productName) {
    case 'card':
      return CROSSSELL_PRODUCT_TYPE.acd;
    case 'shift':
      return CROSSSELL_PRODUCT_TYPE.sft;
    case 'oes':
      return CROSSSELL_PRODUCT_TYPE.ord;
    case 'rb':
      return CROSSSELL_PRODUCT_TYPE.rb;
    case 'regi':
      return CROSSSELL_PRODUCT_TYPE.arg;
    case 'pay':
      return CROSSSELL_PRODUCT_TYPE.apy;
    case 'cash':
      return CROSSSELL_PRODUCT_TYPE.acs;
    case 'invoice':
      return CROSSSELL_PRODUCT_TYPE.inv;
    case 'mpa':
      return CROSSSELL_PRODUCT_TYPE.mpa;
  }
};
