import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { StyleSheet, css } from 'aphrodite';
import styled from 'styled-components';
import ClickOutside from 'react-click-outside';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { Button } from '@air-kit/air-kit';
import { airblue, borderColor, dangerColor } from '../../constants/colors';
import {
  DEV_PLUGINKEY,
  PRODUCTION_PLUGINKEY,
  NETWORK_TIMEOUT,
  ACCESS_DENIED_RANGE_START,
  ACCESS_DENIED_RANGE_END,
  OFFSET_FOR_LOG,
  HASH_NO_MASK_CHARACTER_FOR_LOG,
} from '../../constants/channelIO';
import { mateFaq, mateRequest } from '../../constants/externalLink';
import HeaderLogo from '../../icons/logo.svg';
import TutorialIcon from '../../icons/TutorialIcon.svg';
import { userLogout, UserData } from '../../modules/user';
import { clickLogoTracking } from '../../modules/tracking';
import { actions as NoticeAndTodoActions } from '../../modules/noticeAndTodo';
import { actions as ChannelTalkUserInfoActions } from '../../modules/channelIO/channelIOUserInfo';
import { NoticeBalloon as Balloon, HelpBalloon } from './';
import AirCmnHeader from 'react-air-common-header';
import { track } from '../../modules/logging';
import { genGaLog } from '../../gaLogger';
import { State as ReduxState } from '../../modules';
import { ApiState, API_STATE_INITIAL, API_STATE_COMPLETED } from '../../typedef/api/Utility';
import { apiState } from '../../typedef/api/Utility';
import { BatchProcessedDate } from '../../typedef/BatchProcessedDate';
import { Notice, Todo, YearlyReportNotice } from '../../typedef/api/NoticeAndTodo';
import { channelService } from '../../vendor/channelIO/ChannelService';
import { actions as ChannelIOActions } from '../../modules/channelIO/channelIO';
import { maskHashIfNeeded } from '../../helpers/stringHelper';
import * as Onboarding from '../../helpers/onboardingHelper';
import { ROLE_TYPE } from '../../typedef/api/UserInfo';
import BadgeModal from './BadgeModal';
import { BadgeAcquiredInfo } from '../../typedef/api/Badge';
import { actions as badgeListactions } from '../../modules/badge';
import { BADGE_TYPE } from '../../constants/onboarding';
import { BADGE_CHECKED_INFO_KEY_NAME, ENV } from '../../constants/LocalStorage';
import BadgeIcon from '../../icons/BadgeIcon.png';
import { actions as commonUiActions } from '../../modules/uiConfig';
import { BasicSetting as BasicSettingType } from '../../typedef/api/BasicSetting';
import { fetchBasicSettingStart } from '../../modules/basicSetting';
import { getVocBrowser } from '../../helpers/util';
import { noticeSelector } from '../../selectors/noticeAndTodoSelectors';

type DispatchProps = {
  readonly userLogout: typeof userLogout;
  readonly startFetchNoticeListUnreadNoticeCount: typeof NoticeAndTodoActions.startFetchNoticeListUnreadNoticeCount;
  readonly startPostNoticeRead: typeof NoticeAndTodoActions.startPostNoticeRead;
  readonly startRegisterChannelTalkUserInfo: typeof ChannelTalkUserInfoActions.startFetchUserInfoData;
  readonly clickLogoTracking: typeof clickLogoTracking;
  readonly setUnreadTutorialList: typeof NoticeAndTodoActions.setUnreadTutorialList;
  readonly updateOnboardingIdList: typeof NoticeAndTodoActions.updateOnboardingIdList;
  readonly track: typeof track;
  readonly setUnreadCount: typeof ChannelIOActions.setUnreadCount;
  readonly setBootStatus: typeof ChannelIOActions.setBootStatus;
  readonly notificationBootError: typeof ChannelIOActions.notificationBootError;
  readonly postBadgeList: typeof badgeListactions.postBadgeList;
  readonly checkedBadge: typeof commonUiActions.checkedBadge;
  readonly unCheckedBadge: typeof commonUiActions.unCheckedBadge;
  readonly changeLaborCostViewScopeTypeAll: typeof commonUiActions.changeLaborCostViewScopeTypeAll;
  readonly changeLaborCostViewScopeTypeManager: typeof commonUiActions.changeLaborCostViewScopeTypeManager;
  readonly fetchBasicSetting: typeof fetchBasicSettingStart;
};
type StateProps = {
  readonly loggedIn: boolean;
  readonly representName: string;
  readonly roleType: ROLE_TYPE | null;
  readonly latestDate: BatchProcessedDate;
  readonly noticeList?: ReadonlyArray<Notice | YearlyReportNotice>;
  readonly apiNoticeList: ApiState<ReadonlyArray<Notice>>;
  readonly apiTodoList: ApiState<ReadonlyArray<Todo>>;
  readonly unreadNoticeCount: number;
  readonly unreadTutorialList: ReadonlyArray<string>;
  readonly tutorialLoadingFlag: boolean;
  readonly clientUserId?: string;
  readonly clientUserIdHash?: string;
  readonly unreadCount: number;
  readonly isBooted: boolean;
  readonly userData: UserData | null;
  readonly badgeAcquiredInfo: BadgeAcquiredInfo | null;
  readonly badgeCheckedInfo: boolean;
  readonly basicSettingState: ApiState<BasicSettingType>;
  readonly laborCostViewScopeType: 'all' | 'manager';
};
type PassedProps = {
  readonly viewName: string;
  readonly isLoginPage?: boolean;
};
type Props = RouteComponentProps<{}> & DispatchProps & StateProps & PassedProps;
type State = {
  noticeFlg: boolean;
  debugFlag: boolean;
  helpFlg: boolean;
  openBadgeModalFlg: boolean;
  isBadgeAcquiredFlag: boolean;
};

const Logo = () => {
  return <HeaderLogo key="logo-img" className={css(styles.logo)} />;
};

const env = process.env.REACT_APP_ENV;

class Header extends React.PureComponent<Props, State> {
  お問い合わせ欄 = React.createRef<HTMLTextAreaElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      noticeFlg: false,
      debugFlag: false,
      helpFlg: false,
      openBadgeModalFlg: false,
      isBadgeAcquiredFlag: false,
    };
  }

  componentDidMount() {
    this.props.startFetchNoticeListUnreadNoticeCount();
    // チャネルトーク用処理
    const pluginKey = env === 'production' ? PRODUCTION_PLUGINKEY : DEV_PLUGINKEY;
    if (this.props.clientUserId != null && this.props.clientUserIdHash != null) {
      channelService.bootInitializer(
        pluginKey,
        this.props.clientUserId,
        this.props.clientUserIdHash,
        this.props.setUnreadCount,
        this.props.setBootStatus,
        this.channelIOBootLog
      );
    } else {
      this.props.setBootStatus(false);
      this.channelIOBootLog(pluginKey, undefined, undefined, undefined);
    }
    channelService.onBadgeChanged(this.props.setUnreadCount);

    // 初回ログイン分のデータ追加
    const badgeList =
      this.props.badgeAcquiredInfo?.badgeAcquiredInfo.badgeList != null
        ? this.props.badgeAcquiredInfo?.badgeAcquiredInfo.badgeList.concat()
        : [];
    badgeList?.unshift({
      badgeType: '00',
      badgeName: '初回ログイン',
      isBadgeAcquired: true,
    });

    // LocalStorageが無い or バッジ獲得 かつ バッジ一覧モーダル未読のLocalStorageがある場合、未読表示の赤丸を付与
    const BadgeCheckdLocalStorage = localStorage.getItem(BADGE_CHECKED_INFO_KEY_NAME);
    const isBadgeCheckedLocalStorageEmpty = BadgeCheckdLocalStorage === null;
    const isBadgeUnChecked =
      BadgeCheckdLocalStorage &&
      JSON.parse(BadgeCheckdLocalStorage).some(item => item.isBadgeAcquired && !item.isBadgeChecked);
    if (isBadgeCheckedLocalStorageEmpty || isBadgeUnChecked) {
      this.props.unCheckedBadge();
    } else {
      this.props.checkedBadge();
    }

    if (this.props.basicSettingState.type === API_STATE_INITIAL) {
      this.props.fetchBasicSetting();
    }
  }
  // 基本設定のAPIから管理者権限を取得し、合わせてStateを更新する
  componentDidUpdate(prevProps: Pick<StateProps, 'basicSettingState'>): void {
    const { basicSettingState, changeLaborCostViewScopeTypeAll, changeLaborCostViewScopeTypeManager } =
      this.props;
    if (
      basicSettingState.type !== prevProps.basicSettingState.type &&
      basicSettingState.type === API_STATE_COMPLETED
    ) {
      switch (basicSettingState.payload.basicSetting.laborCostViewScopeType) {
        case 'all':
          changeLaborCostViewScopeTypeAll();
          break;
        case 'manager':
          changeLaborCostViewScopeTypeManager();
          break;
      }
    }
  }

  _handleStorageLoaded = () => {
    // 未読フラグ(バッジ獲得済み かつ バッジ獲得一覧画面を見ていない)をローカルストレージに格納する
    const storage = localStorage.getItem(BADGE_CHECKED_INFO_KEY_NAME);
    if (storage != null) {
      const storageList = JSON.parse(storage);
      const unCheckedBadgeList = storageList.map(item => {
        if (item.badgeType === BADGE_TYPE.spUse.key) {
          return {
            badgeType: item.badgeType,
            isBadgeAcquired: true,
            isBadgeChecked: false,
          };
        } else {
          return item;
        }
      });
      localStorage.setItem(BADGE_CHECKED_INFO_KEY_NAME, JSON.stringify(unCheckedBadgeList));
    }
  };

  _handleSPUseLoaded(
    badgeAcquiredInfo: BadgeAcquiredInfo | null,
    postBadgeList: typeof badgeListactions.postBadgeList,
    unCheckedBadge: () => void,
    isSPUse?: boolean
  ) {
    const spUseBadgeInfo = badgeAcquiredInfo?.badgeAcquiredInfo.badgeList.find(
      item => item.badgeType === BADGE_TYPE.spUse.key
    );
    const { isBadgeAcquiredFlag } = this.state;
    // アプリ利用 かつ バッジ未獲得の場合 かつ バッジ獲得フラグが立っていない場合バッジ獲得処理
    if (isSPUse && !spUseBadgeInfo?.isBadgeAcquired && !isBadgeAcquiredFlag) {
      this.setState({ isBadgeAcquiredFlag: true });
      postBadgeList(BADGE_TYPE.spUse.key);
      unCheckedBadge();
      this._handleStorageLoaded();
    }
  }

  _handleClickBadgeButton = () => {
    const logData = genGaLog('header', 'badge_list', 'view_badge_list', {}, {}, 'click');
    this.props.track(logData);
  };

  _handleClickLogout = () => {
    const viewName = this.props.viewName;

    const log = _genLogoutLog(viewName, 'logout');

    this.props.userLogout(log);
  };

  _handleClickNoticeList = () => {
    if (!this.state.noticeFlg) {
      const logData = genGaLog('header', 'notification', 'view_notification_list', {}, {}, 'click');
      this.props.track(logData);
    }

    this.setState({
      noticeFlg: !this.state.noticeFlg,
      helpFlg: false,
    });
  };
  _handleClickNoticeDetail = (
    id: string | undefined | null,
    category: string | undefined | null,
    url?: string,
    linkTo?: string
  ) => {
    if (id == null) {
      return;
    }

    const logData = genGaLog(
      'header',
      'notification',
      'view_notification_page',
      {},
      { notification_id: id, category },
      'click'
    );
    this.props.track(logData);
    this.props.startPostNoticeRead(id);
    // announceの場合
    url != null && window.open(url);
    // yearlyReportの場合
    linkTo != null && this.props.history.push(linkTo);
  };
  _handleClickOutSide = () => {
    this.setState({
      noticeFlg: false,
      helpFlg: false,
    });
  };

  _handleClickDebug = () => {
    this.setState({ debugFlag: !this.state.debugFlag });
  };
  _handleChangeEnv = (env: string) => {
    localStorage.setItem(ENV, env);
    window.location.reload();
  };
  _handleClickTutorial = (e: React.SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
    // ローディング中は何もしない
    if (this.props.tutorialLoadingFlag) {
      return;
    }
    this.props.track(_genClickTutorialLog(this.props.viewName));
    Onboarding.showIntro();
    // やることリストのAPIが完了していない場合はidのリストを更新する
    if (!apiState.isCompleted(this.props.apiTodoList)) {
      this.props.updateOnboardingIdList();
    }
  };

  _handleClickHelp = () => {
    // チャネルトークの未読件数が存在する場合はチャットを開く
    if (this.props.unreadCount > 0) {
      this._handleClickChat(false);
    } else {
      this.setState({
        helpFlg: !this.state.helpFlg,
        noticeFlg: false,
      });
    }
    this.props.track(_genClickHelpLog());
  };

  _handleClickHelpFaq = () => {
    window.open(mateFaq);
    this.props.track(_genClickHelpFaqLog());
  };

  _handleClickRequest = () => {
    const { browser, version } = getVocBrowser();
    window.open(
      `${mateRequest}?group_id=${this.props.userData?.groupId}&inflow_source=webHeader&device=${browser}${
        version != null ? `&version=${version}` : ``
      }`
    );
    this.props.track(_genClickRequestLog());
  };

  _handleClickChat = (isOutputLog: boolean) => {
    // 初期起動時にbootが失敗していた場合に再度bootを行う
    if (!this.props.isBooted && this.props.clientUserId != null && this.props.clientUserIdHash != null) {
      const pluginKey = env === 'production' ? PRODUCTION_PLUGINKEY : DEV_PLUGINKEY;
      channelService.boot(
        pluginKey,
        this.props.clientUserId,
        this.props.clientUserIdHash,
        this.props.setUnreadCount,
        this.props.setBootStatus,
        this.props.notificationBootError,
        true,
        this.channelIOBootLog
      );
    }

    if (this.props.isBooted) {
      this.props.startRegisterChannelTalkUserInfo();
      channelService.updateUser();
      channelService.show();
    }

    this.setState({
      helpFlg: false,
      noticeFlg: false,
    });
    if (isOutputLog) {
      this.props.track(_genClickChatLog());
    }
  };

  channelIOBootLog = (
    pluginKey: string,
    errorStatus?: number,
    clientUserId?: string,
    clientUserIdHash?: string
  ) => {
    let maskedHash;
    if (clientUserIdHash != null) {
      maskedHash = maskHashIfNeeded(clientUserIdHash, HASH_NO_MASK_CHARACTER_FOR_LOG).slice(OFFSET_FOR_LOG);
    }

    const slicePluginKey = pluginKey.slice(OFFSET_FOR_LOG);
    let func;
    let parameters;

    if (errorStatus != null) {
      parameters = {
        masked_hash: maskedHash,
        client_user_id: clientUserId,
        plugin_key: slicePluginKey,
        status: errorStatus,
      };
      if (NETWORK_TIMEOUT === errorStatus) {
        func = 'networkTimeout';
      } else if (ACCESS_DENIED_RANGE_START <= errorStatus && errorStatus <= ACCESS_DENIED_RANGE_END) {
        func = 'access_denied';
      } else {
        func = 'others';
      }
    } else {
      // bootが成功した場合
      if (clientUserId != null && clientUserIdHash != null) {
        func = 'success';
        parameters = { masked_hash: maskedHash, client_user_id: clientUserId, plugin_key: slicePluginKey };
      } else {
        // userInfoからclientUserId、clientUserIdHashが取得出来なかった場合のログ出力
        func = 'undefined_hash';
        parameters = { masked_hash: '', client_user_id: '', plugin_key: slicePluginKey };
      }
    }

    this.props.track(_genChannelIOBootLog(func, parameters));
  };

  render() {
    const {
      representName,
      loggedIn,
      clickLogoTracking,
      isLoginPage,
      noticeList,
      apiNoticeList,
      unreadNoticeCount,
      unreadTutorialList,
      unreadCount,
      tutorialLoadingFlag,
      userData,
      badgeAcquiredInfo,
      postBadgeList,
      badgeCheckedInfo,
      checkedBadge,
      unCheckedBadge,
    } = this.props;
    const clientId = env === 'production' ? 'AMT' : env === 'staging' ? 'AMT_S' : 'AMT_D';
    const uri =
      env === 'production'
        ? 'https://connect.airregi.jp'
        : env === 'staging'
        ? 'https://dev9-shr-plf.arg.x.recruit.co.jp'
        : 'https://dev6-shr-plf.arg.x.recruit.co.jp';
    const redirectUri =
      env === 'production'
        ? 'https://airmate.jp'
        : env === 'staging'
        ? 'https://dev9-shr-plf.arg.x.recruit.co.jp'
        : 'https://dev6-shr-plf.arg.x.recruit.co.jp';

    // 表示されているゴールのidと未達成ゴールのidを比較して表示されている未達成のゴール数を出す
    const introDisplayList: string[] =
      // ローカルだとSTANDSMotionがundefinedでエラーになるためチェック
      typeof STANDSMotion !== 'undefined'
        ? STANDSUnit.steps.goals.filter(item => item.introDisplay).map(item => item.id)
        : [];
    const unreadTutorialCount: number = introDisplayList.filter(item =>
      unreadTutorialList.includes(item)
    ).length;
    this._handleSPUseLoaded(badgeAcquiredInfo, postBadgeList, unCheckedBadge, userData?.isSpUse);

    return (
      <Wrapper>
        <AirCmnHeader
          productName="AirMATE"
          storeName="店舗グループ管理"
          accountName={representName}
          breakPoint={420}
          onLogoutClick={() => this._handleClickLogout()}
          roleSetting={{
            onClick: () => {
              const log = genGaLog(
                'header',
                'assigned_store_setting',
                'go_assigned_store_setting',
                {},
                {},
                'click'
              );
              this.props.track(log);
              this.props.history.push('/assigned_store_setting/');
            },
          }}
          myAccount={{
            link: `${uri}/view/account/profile?client_id=${clientId}&isPoPuP=1&redirect_uri=${redirectUri}`,
          }}
          accountMenuMaps={[
            {
              code: '1000',
              link: `${uri}/view/store/storeList?client_id=${clientId}&isPoPuP=1&redirect_uri=${redirectUri}`,
            },
            {
              code: '1001',
              link: `${uri}/view/group/accounts?client_id=${clientId}&isPoPuP=1&redirect_uri=${redirectUri}`,
            },
          ]}
        />
        <Container>
          {loggedIn ? (
            <Link to={'/'} onClick={clickLogoTracking}>
              <Logo />
            </Link>
          ) : (
            <Logo />
          )}
          {!isLoginPage ? (
            <HeaderMenu>
              {env === 'development' || env === 'local-dev' ? (
                <React.Fragment>
                  <HelpButton onClick={this._handleClickDebug}>デバッグ</HelpButton>
                  <Debug isShow={this.state.debugFlag}>
                    {['dev', 'dev1', 'dev2', 'dev3', 'dev4', 'dev5', 'qa1', 'qa2', 'qa3'].map(e => (
                      <Button
                        key={e}
                        primary={localStorage.getItem(ENV) === e}
                        onClick={() => this._handleChangeEnv(e)}
                      >
                        {e}
                      </Button>
                    ))}
                  </Debug>
                </React.Fragment>
              ) : null}
              <BadgeButton
                id="header_badge"
                onClick={() => {
                  this.setState({ openBadgeModalFlg: true });
                  checkedBadge();
                  this._handleClickBadgeButton();
                }}
              >
                <UnreadBadge isShow={!badgeCheckedInfo}></UnreadBadge>
                バッジ
              </BadgeButton>
              <HelpButton
                id="header_tutorial"
                onClick={this._handleClickTutorial}
                tutorialLoadingFlag={tutorialLoadingFlag}
              >
                <StyledTutorialIcon />
                <UnreadBadge isShow={unreadTutorialCount > 0}>{unreadTutorialCount}</UnreadBadge>
                {tutorialLoadingFlag ? '読み込み中･･･' : '利用ガイド'}
              </HelpButton>
              <CustomClickOutside onClickOutside={() => this._handleClickOutSide()}>
                <HelpButton onClick={this._handleClickHelp} id="header_help">
                  <HelpBalloon
                    showFlag={this.state.helpFlg}
                    unreadCount={unreadCount}
                    onClickChat={this._handleClickChat}
                    onClickHelpFaq={this._handleClickHelpFaq}
                    onClickRequest={this._handleClickRequest}
                  ></HelpBalloon>
                </HelpButton>
                <HelpButton onClick={this._handleClickNoticeList} id="header_notice">
                  <Balloon
                    showFlag={this.state.noticeFlg}
                    unreadCount={unreadNoticeCount}
                    noticeList={noticeList}
                    noticeClick={this._handleClickNoticeDetail}
                    isLoading={!(apiState.isCompleted(apiNoticeList) || apiState.isFailed(apiNoticeList))}
                  />
                </HelpButton>
              </CustomClickOutside>
              {this.state.openBadgeModalFlg && (
                <BadgeModal
                  onClick={() => {
                    this.setState({ openBadgeModalFlg: false });
                  }}
                  tutorialEventHandler={this._handleClickTutorial}
                  tutorialLoadingFlag={tutorialLoadingFlag}
                />
              )}
            </HeaderMenu>
          ) : null}
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;
`;
const Container = styled.header`
  background-color: ${airblue};
  min-height: 45px;
  line-height: 45px;
  color: white;
`;
const HeaderMenu = styled.div`
  float: right;
  background-color: ${airblue};
`;
const CustomClickOutside = styled(ClickOutside)`
  display: inline-block;
`;
const HelpButton = styled.a<{ tutorialLoadingFlag?: boolean }>`
  position: relative;
  padding: 0 16px;
  border-left: solid 1px ${borderColor};
  box-shadow: 1px 0 0 rgba(255, 255, 255, 0.5) inset;
  color: white;
  display: inline-block;
  height: 45px;
  ${props => !props.tutorialLoadingFlag && 'cursor: pointer;'}
  &:hover {
    background-color: #4ebfea;
  }
`;

const Debug = styled.div<{ isShow: boolean }>`
  position: absolute;
  top: 81px;
  right: 0;
  z-index: 1000;
  ${props => (props.isShow ? '' : 'display: none;')}
`;

const styles = StyleSheet.create({
  headerMenuButton: {
    margin: '16px',
  },
  headerMenuIcon: {
    height: '15px',
    width: '15px',
    verticalAlign: 'middle',
  },
  logo: {
    verticalAlign: 'middle',
    marginLeft: '16px',
  },
});

const StyledTutorialIcon = styled(TutorialIcon)`
  vertical-align: middle;
  margin-right: 8px;
  margin-bottom: 2px;
`;

const UnreadBadge = styled.div<{ isShow: boolean }>`
  ${props => (props.isShow ? '' : 'display:none;')}
  position: absolute;
  top: 6px;
  left: 26px;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  background-color: ${dangerColor};
`;

const BadgeButton = styled.a`
  position: relative;
  padding: 0 16px;
  border-left: solid 1px ${borderColor};
  box-shadow: 1px 0 0 rgba(255, 255, 255, 0.5) inset;
  color: white;
  display: inline-block;
  background-image: url(${BadgeIcon});
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 10px 10px;
  padding-left: 44px;
  &:hover {
    background-color: #4ebfea;
    cursor: pointer;
  }
  > div:first-child {
    width: 10px;
    height: 10px;
  }
`;

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return bindActionCreators(
    {
      userLogout,
      clickLogoTracking,
      track,
      startFetchNoticeListUnreadNoticeCount: NoticeAndTodoActions.startFetchNoticeListUnreadNoticeCount,
      startPostNoticeRead: NoticeAndTodoActions.startPostNoticeRead,
      startRegisterChannelTalkUserInfo: ChannelTalkUserInfoActions.startFetchUserInfoData,
      setUnreadTutorialList: NoticeAndTodoActions.setUnreadTutorialList,
      updateOnboardingIdList: NoticeAndTodoActions.updateOnboardingIdList,
      setUnreadCount: ChannelIOActions.setUnreadCount,
      setBootStatus: ChannelIOActions.setBootStatus,
      notificationBootError: ChannelIOActions.notificationBootError,
      postBadgeList: badgeListactions.postBadgeList,
      checkedBadge: commonUiActions.checkedBadge,
      unCheckedBadge: commonUiActions.unCheckedBadge,
      fetchBasicSetting: fetchBasicSettingStart,
      changeLaborCostViewScopeTypeAll: commonUiActions.changeLaborCostViewScopeTypeAll,
      changeLaborCostViewScopeTypeManager: commonUiActions.changeLaborCostViewScopeTypeManager,
    },
    dispatch
  );
};

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    loggedIn: state.user.loggedIn,
    representName: state.user.loaded && state.user.data != null ? state.user.data.representName : '',
    roleType:
      state.user.loaded && state.user.data != null && state.user.data.roleType
        ? state.user.data.roleType
        : null,
    latestDate: state.uiConfig.batchProcessedDate,
    noticeList: noticeSelector(state),
    apiNoticeList: state.noticeAndTodo.apiNoticeList,
    apiTodoList: state.noticeAndTodo.apiTodoList,
    unreadNoticeCount: state.noticeAndTodo.unreadNoticeCount,
    unreadTutorialList: state.noticeAndTodo.unreadTutorialList,
    tutorialLoadingFlag: state.noticeAndTodo.tutorialLoadingFlag,
    clientUserId: state.user.data != null ? state.user.data.clientUserId : undefined,
    clientUserIdHash: state.user.data != null ? state.user.data.clientUserIdHash : undefined,
    unreadCount: state.channelIO.unreadCount,
    isBooted: state.channelIO.isBooted,
    userData: state.user.data,
    badgeAcquiredInfo: state.badge.data,
    badgeCheckedInfo: state.uiConfig.isCheckedBadge,
    basicSettingState: state.basicSetting.data,
    laborCostViewScopeType: state.uiConfig.laborCostViewScopeType,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));

const _genLogoutLog = (viewName, func) => {
  const description = 'ログアウト';
  return genGaLog(viewName, 'header', func, { description }, {}, 'click');
};

const _genClickHelpLog = () => {
  return genGaLog(window.location.pathname, 'header', 'open_help', {}, {}, 'click');
};

const _genClickHelpFaqLog = () => {
  return genGaLog(window.location.pathname, 'header', 'open_faq', {}, {}, 'click');
};

const _genClickRequestLog = () => {
  return genGaLog(window.location.pathname, 'header', 'open_request', {}, {}, 'click');
};

const _genClickChatLog = () => {
  return genGaLog(window.location.pathname, 'header', 'open_chat', {}, {}, 'click');
};

const _genChannelIOBootLog = (func, parameters) => {
  return genGaLog('channel_io', 'boot', func, {}, parameters, 'load');
};

const _genClickTutorialLog = (viewName: string) => {
  return genGaLog(viewName, 'header', 'click_tutorial', {}, {}, 'click');
};
