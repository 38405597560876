import React from 'react';
import styled from 'styled-components';
import BgPng from '../../../../icons/NewYearReport/newYearBgPc.png';
import Ornament from '../../../../icons/NewYearReport/EmptyOrnament.svg';
import CameCustomer from '../../../../icons/NewYearReport/CameCustomer.svg';
import Ranking from '../../../../icons/NewYearReport/Ranking.svg';
import ThrivingDate from '../../../../icons/NewYearReport/ThrivingDate.svg';
import PopularWeekdayTimezone from '../../../../icons/NewYearReport/PopularWeekdayTimezone.svg';
import MaxCustomerPayment from '../../../../icons/NewYearReport/MaxCustomerPayment.svg';
import OpenLinkIcon from '../../../../icons/openLinkBlue.svg';
import {
  airblue,
  airBoxShadow,
  black,
  buttonActiveColor,
  buttonHoverColor,
  darkBlack,
  disabledTextColor,
  newYearBoxBorderColor,
  newYearUnderLineColor,
  textLinkColor,
  white,
  yearReportBoxShadowColor,
} from '../../../../constants/colors';
import { Format万, Format人, Format円 } from './Styles';
import Zindex from '../../../../constants/z-index';
import { newYearReportFaq } from '../../../../constants/faqUrls';
import { track } from '../../../../modules/logging';
import { Waypoint } from 'react-waypoint';
import { genGaLog } from '../../../../gaLogger';
import { formatter, mclDayjs } from '../../../../helpers/mclDate';
import MateBowing from '../../../../icons/NewYearReport/MateBowing.svg';
import MateIntro from '../../../../icons/NewYearReport/MateIntro.svg';
import AirMateLogo from '../../../../icons/NewYearReport/FooterAirMateLogo.svg';
import AppQRCode from '../../../../icons/NewYearReport/NewYearReportAppQRCode.svg';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import YearlyReportEmptyData from './YearlyReportEmptyData';
import { YearlyLookbackResponse } from '../../../../typedef/api/YearlyReport/Yearlyreport';
import { getDayOfWeek } from '../../../../helpers/util';
import {
  OVER_ONE_MILLION_NUMBER_OF_DIGITS,
  STORE_NAME_LENGTH_LIMIT,
  STORE_NAME_LENGTH_LIMIT_NUM,
  WIDTH_LIMIT,
} from '../YearlyReportConstants';

type ThroughProps = {
  yearlyReport: YearlyLookbackResponse;
  readonly track: typeof track;
  readonly year: number;
};

type Props = ThroughProps & RouteComponentProps<{}> & ThroughProps;

/**
 * 年始サマリPCバージョン
 */
const YearlyReport = (props: Props) => {
  const { track, history } = props;
  const { topSalesStores, totalVisitorNum, storeDetails, targetYear } = props.yearlyReport.yearlyLookback;
  /* 一般権限の場合は全店舗まとめはトルツメ 売上が1円以上の店舗が、2店舗以上存在しない場合もトルツメ*/
  const isAllStoreSection = topSalesStores.length !== 0 && storeDetails.length >= 2;
  return (
    <React.Fragment>
      <BackGround />
      <Wrapper>
        <Top>
          <OrnamentTopText>あなたのお店の</OrnamentTopText>
          <OrnamentText>{targetYear}年まとめレポート</OrnamentText>
          <OrnamentBottomText>今年はどんな1年だった？</OrnamentBottomText>
          <StyledOrnament />
        </Top>
        <Lead>あなたのお店の{targetYear}年を振り返りましょう。</Lead>
        {/* レジ利用なし・売上0円の場合はエラー文言表示 */}
        {topSalesStores.length === 0 && storeDetails.length === 0 ? (
          <YearlyReportEmptyData />
        ) : (
          <React.Fragment>
            {isAllStoreSection && (
              <React.Fragment>
                <Waypoint
                  onEnter={() => {
                    track(_genImpressionLog('all_store'));
                  }}
                >
                  <SectionTitle marginTop={24} id="all_stores">
                    全店舗のまとめ
                  </SectionTitle>
                </Waypoint>
                <SectionLead>
                  {/* TODO: 年毎に集計期間の終わりを定数定義したい */}
                  集計期間：{targetYear}年1月1日〜{targetYear}年12月25日
                </SectionLead>
                <ContentWrapper>
                  <ContentBox size={48} isMargin={false}>
                    <CameCustomer />
                    <ContentText>来てくれたお客様</ContentText>
                    <VisitorNum>
                      <Format人 value={totalVisitorNum} />
                      <UnderLine />
                    </VisitorNum>
                  </ContentBox>
                  <ContentBox size={52} isMargin={true}>
                    <Ranking />
                    <ContentText>1番売上が多かった店舗</ContentText>
                    {topSalesStores.map(store => {
                      const { storeName } = store;
                      // underlineを引くために文字数が多い場合、区切って1行ごとに要素を作成する。
                      if (storeName.length > STORE_NAME_LENGTH_LIMIT) {
                        let storeNameArr: Array<string> = [];
                        STORE_NAME_LENGTH_LIMIT_NUM.forEach(limitNum => {
                          storeNameArr.push(storeName.substring(limitNum.start, limitNum.end));
                        });
                        return storeNameArr.map(name => {
                          return (
                            <StoreName>
                              {name}
                              <UnderLine />
                            </StoreName>
                          );
                        });
                      } else {
                        return (
                          <StoreName>
                            {storeName}
                            <UnderLine />
                          </StoreName>
                        );
                      }
                    })}
                  </ContentBox>
                </ContentWrapper>
                <AnnotationWrapper>
                  <AnnotationText
                    href={newYearReportFaq}
                    onClick={() => {
                      track(_genClickLog('lookback_detail'));
                    }}
                    target="_blank"
                    rel="noopenner noreferrer"
                  >
                    まとめの集計方法
                    <StyledOpenLinkIcon />
                  </AnnotationText>
                </AnnotationWrapper>
              </React.Fragment>
            )}
            {storeDetails.map((store, index) => {
              const date = mclDayjs(store.topSalesDate);
              const dayOfTheWeek = date.format(formatter.week);
              const isOverOneMillion =
                store.topVisit.customerPayment.toString().length >= OVER_ONE_MILLION_NUMBER_OF_DIGITS;
              const isMenuRanking = store.menuRanking.length !== 0;
              return (
                <StoreContent id={`store_${index}`}>
                  {index === 0 && (
                    <Waypoint
                      onEnter={() => {
                        track(_genImpressionLog('store'));
                      }}
                    />
                  )}
                  {/* 全店舗まとめ表示なし & 1番目の店舗にmargin付与 */}
                  <SectionTitle marginTop={!isAllStoreSection && index === 0 ? 24 : 0}>
                    {store.storeName}
                  </SectionTitle>
                  <SectionLead>
                    {/* TODO: 年毎に集計期間の終わりを定数定義したい */}
                    集計期間：{targetYear}年1月1日〜{targetYear}年12月25日
                  </SectionLead>
                  <ContentWrapper>
                    <ContentBox size={45} isMargin={false}>
                      <CameCustomer />
                      <ContentText marginBottom={isMenuRanking ? 26 : 18}>来てくれたお客様</ContentText>
                      <VisitorNum>
                        <Format人 value={store.visitorNum} />
                      </VisitorNum>
                    </ContentBox>
                    <ContentBox size={55} isMargin={true}>
                      <Ranking />
                      <ContentText marginBottom={16}>1番売れた商品</ContentText>
                      {isMenuRanking ? (
                        <RankingOl>
                          {store.menuRanking.map(menu => {
                            return (
                              <RankingLi isTopRank={menu.rank === 1}>
                                {menu.rank}.{menu.menuName}
                              </RankingLi>
                            );
                          })}
                        </RankingOl>
                      ) : (
                        <EmptyText>表示できる商品がありません</EmptyText>
                      )}
                    </ContentBox>
                  </ContentWrapper>
                  <ContentWrapper isMargin={true}>
                    <ContentBox size={33} isMargin={false}>
                      <ThrivingDate />
                      <ContentText marginBottom={33}>1番繁盛した日</ContentText>
                      <Date>
                        {date.pureMonth()}
                        <span>月</span>
                        {date.date()}
                        <span>日({dayOfTheWeek})</span>
                      </Date>
                    </ContentBox>
                    <ContentBox size={33} isMargin={true}>
                      <PopularWeekdayTimezone />
                      <ContentText marginBottom={16}>人気だった曜日・時間帯</ContentText>
                      <Date>
                        {getDayOfWeek(store.popular.dayOfWeek)}
                        <span>曜日</span>
                      </Date>
                      <Date>
                        {store.popular.aggregateHour}
                        <span>時〜</span>
                        {store.popular.aggregateHour + 1}
                        <span>時</span>
                      </Date>
                    </ContentBox>
                    <ContentBox size={33} isMargin={true}>
                      <MaxCustomerPayment />
                      <ContentText marginBottom={22}>最高客単価</ContentText>
                      <VisitorNum>
                        {isOverOneMillion ? (
                          <Format万 value={store.topVisit.customerPayment} />
                        ) : (
                          <Format円 value={store.topVisit.customerPayment} />
                        )}
                      </VisitorNum>
                      <Day>
                        {mclDayjs(store.topVisit.customerPaymentDate, formatter.mapiDate).format(
                          formatter.monthDayWeekJpn
                        )}
                        {store.topVisit.isMultipleDate && 'など'}
                      </Day>
                    </ContentBox>
                  </ContentWrapper>
                  <AnnotationWrapper>
                    <AnnotationText
                      href={newYearReportFaq}
                      onClick={() => {
                        track(_genClickLog('lookback_detail'));
                      }}
                      target="_blank"
                      rel="noopenner noreferrer"
                    >
                      まとめの集計方法
                      <StyledOpenLinkIcon />
                    </AnnotationText>
                  </AnnotationWrapper>
                </StoreContent>
              );
            })}
            <FooterWrapper>
              <FlexWrapper>
                <Text>
                  来年もお客様に愛されるように、
                  <br />
                  あなたのお店づくりをサポートします。
                </Text>
                <Waypoint
                  onEnter={() => {
                    track(_genImpressionLog('last_mate_info'));
                  }}
                >
                  <StyledMateBowing>
                    <MateBowing />
                  </StyledMateBowing>
                </Waypoint>
                {/* 来年なのでtargetYear + 1 */}
                <BlueText>{targetYear + 1}年もAirメイトをよろしくお願いいたします。</BlueText>
                <CloseButtonWrapper>
                  <StyledButton
                    onClick={() => {
                      history.push('/store/indices');
                      track(_genClickLog('mate_link'));
                    }}
                  >
                    レポートを閉じてAirメイトに戻る
                  </StyledButton>
                  <FooterAnnotationText>
                    このレポートをまた見たい場合は、Airメイト内のお知らせをご確認ください。
                  </FooterAnnotationText>
                </CloseButtonWrapper>
                <AboutAreaWrapper>
                  <StyledMateIntro />
                  <AboutWrapper>
                    <AboutTitleWrapper>
                      <AirMateLogo />
                    </AboutTitleWrapper>
                    <DescriptionWrapper>
                      <DescriptionText>
                        Airメイトは、AirレジをはじめとするAir
                        ビジネスツールズと連携して、無料でカンタンにお店の状況を把握できる経営サポートサービスです。
                        <br />
                        Airレジで会計を行なっていると、すぐに分析を開始できます。ぜひ引き続きご利用ください。
                      </DescriptionText>
                    </DescriptionWrapper>
                  </AboutWrapper>
                  <AppQrWrapper>
                    <AppTopText>
                      Airメイトアプリ
                      <br />
                      ダウンロード
                    </AppTopText>
                    <AppQRCode />
                    <AppBottomText>iOS / Android対応</AppBottomText>
                  </AppQrWrapper>
                </AboutAreaWrapper>
              </FlexWrapper>
            </FooterWrapper>
          </React.Fragment>
        )}
      </Wrapper>
    </React.Fragment>
  );
};

export default withRouter(YearlyReport);

const _genImpressionLog = (feature: 'all_store' | 'store' | 'last_mate_info') => {
  return genGaLog('year_lookback', feature, 'impression', {}, {}, 'impression');
};

const _genClickLog = (feature: 'mate_link' | 'lookback_detail') => {
  return genGaLog('year_lookback', feature, 'button', {}, {}, 'click');
};

const Wrapper = styled.div`
  max-width: ${WIDTH_LIMIT}px;
  margin: 0 auto;
  height: 100%;
`;

const BackGround = styled.div`
  background-image: url(${BgPng});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  position: fixed;
  top: 32px;
  width: 100%;
  height: 240px;
  z-index: ${Zindex.yearMonthReportBg};
`;

const OrnamentTopText = styled.div`
  font-size: 20px;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 1px;
  text-align: center;
  color: ${airblue};
  margin: 32px 0 -16px;
`;

const OrnamentText = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 2px;
  text-align: center;
  position: absolute;
  top: 80px;
  color: ${airblue};
  letter-spacing: 1px;
`;

const OrnamentBottomText = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 1px;
  text-align: center;
  position: absolute;
  bottom: -28px;
  color: ${airblue};
`;

const StyledOrnament = styled(Ornament)`
  position: relative;
  top: 28px;
  z-index: -1;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Lead = styled.div`
  font-size: 14px;
  color: ${darkBlack};
  margin-top: 50px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;

const SectionTitle = styled.div<{ marginTop?: number }>`
  color: ${airblue};
  text-align: center;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  margin-top: ${props => props.marginTop != null && `${props.marginTop}px`};
  padding-top: 40px;
`;

const SectionLead = styled.div`
  color: ${black};
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 8px 0 24px;
  text-align: center;
`;

const ContentWrapper = styled.div<{ isMargin?: boolean }>`
  padding: 0 60px;
  display: flex;
  width: 100%;
  ${props => props.isMargin && 'margin-top:24px;'}
`;
const ContentBox = styled.div<{ size: number; isMargin: boolean }>`
  border-radius: 16px;
  border: 2px solid ${newYearBoxBorderColor};
  background: ${white};
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${props => `${props.size}%`};
  ${props => props.isMargin && 'margin-left:24px;'};
  z-index: ${Zindex.newYearReportBox};
`;

const ContentText = styled.div<{ marginBottom?: number }>`
  color: ${black};
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin: 20px 0 ${props => (props.marginBottom != null ? `${props.marginBottom}px` : '26px')};
`;

const VisitorNum = styled.div<{ isUnderLine?: boolean }>`
  font-weight: 600;
  color: ${airblue};
  position: relative;
`;

const UnderLine = styled.div`
  position: absolute;
  bottom: 7px;
  width: 100%;
  height: 14px;
  background: ${newYearUnderLineColor};
  z-index: ${Zindex.newYearReportBox};
`;

const StoreName = styled.div`
  position: relative;
  font-size: 24px;
  color: ${airblue};
  font-weight: 700;
  line-height: 40px;
  max-width: 384px;
`;

const AnnotationWrapper = styled.div`
  margin: 16px 0 0 auto;
  padding: 0 60px;
  display: flex;
  justify-content: flex-end;
  text-align: center;
`;

const AnnotationText = styled.a`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  color: ${textLinkColor};
  margin-left: 4px;
  cursor: pointer;
`;

const StoreContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledOpenLinkIcon = styled(OpenLinkIcon)`
  margin-left: 4px;
`;

const RankingOl = styled.ol`
  list-style: none;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
`;

const RankingLi = styled.li<{ isTopRank: boolean }>`
  text-align: center;
  ${props =>
    props.isTopRank &&
    `
  color: var(--air-air-blue, ${airblue});
  font-size: 24px;
  font-weight: 700;  
  `}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Date = styled.div`
  color: ${airblue};
  font-size: 36px;
  font-weight: 600;
  span {
    font-size: 24px;
  }
`;

const Day = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
`;

const EmptyText = styled.div`
  color: ${disabledTextColor};
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-top: 16px;
`;

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  margin-top: 56px;
  padding: 0 60px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
`;

const FooterAnnotationText = styled.div`
  margin-top: 16px;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: center;
`;

const BlueText = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
  color: ${airblue};
`;

const StyledMateIntro = styled(MateIntro)`
  width: 129px;
  height: 100px;
  margin: 16px 0;
`;

const CloseButtonWrapper = styled.div`
  margin-top: 40px;
`;

const StyledButton = styled.button`
  border: 1px solid ${airBoxShadow};
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  height: 48px;
  width: 320px;
  padding: 12px 16px 12px 16px;
  white-space: nowrap;
  background: ${airblue};
  box-shadow: 0px 1px 0px 0px ${yearReportBoxShadowColor};
  color: ${white};
  &:hover {
    background: ${buttonHoverColor};
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
  &:active {
    background: ${buttonActiveColor};
  }
`;

const AboutAreaWrapper = styled.div`
  margin-top: 56px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: ${white};
  padding: 32px 32px 32px 43px;
  margin-bottom: 80px;
`;

const AboutWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledMateBowing = styled.div`
  width: 54px;
  height: 73px;
  margin: 16px 0;
`;

const AboutTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  padding: 0 32px;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  padding: 0 32px;
  margin-top: 16px;
  width: 100%;
`;

const DescriptionText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: left;
`;

const AppQrWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 160px;
`;

const AppTopText = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: center;
  margin-bottom: 12px;
`;

const AppBottomText = styled.small`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 12px;
`;
