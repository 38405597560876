/**
 * index summaryを表示する画面.
 */
import React from 'react';
import styled from 'styled-components';
import Big from 'big.js';
import {
  FormatTenThousand,
  Decimal,
  prefixUnit,
  postfixUnit,
  baseFontSize,
  sign,
  colorOnCondition,
} from '../../../../../components/common/atoms/Number';
import { red, green, black, dangerColor } from '../../../../../constants/colors';
import { greaterThanOrEqualTo } from '../../../../../helpers/numberHelper';

const big = Big();
big.RM = 0; // Round modeを切り捨てに設定
type Props = {
  readonly totalSales?: number | null;
  readonly targetAchievementDiff?: number | null;
  readonly personnelExpenseRate?: string | null;
  readonly costRate?: string | null;
  readonly goalLaborCostRate?: string | null;
  readonly goalFoodCostRate?: string | null;
  readonly page: 'daily' | 'monthly';
  readonly isDisplayedStoreList: boolean;
  readonly isFirstDayOfMonth?: boolean;
};

const FormatTotalSales = styled(baseFontSize(16)(prefixUnit('¥')(postfixUnit('万')(FormatTenThousand))))`
  line-height: 16px;
`;
const FormatTargetDiff = styled(
  baseFontSize(16)(
    colorOnCondition(
      props => big(props.value).lt(props.threshold as string | number | Big),
      red,
      green
    )(postfixUnit('万')(sign(FormatTenThousand)))
  )
)`
  line-height: 16px;
`;
const FormatPercentage = baseFontSize(16)(postfixUnit('%')(Decimal));

const TopPane = (props: Props) => {
  const {
    totalSales,
    targetAchievementDiff,
    personnelExpenseRate,
    costRate,
    goalLaborCostRate,
    goalFoodCostRate,
    page,
    isDisplayedStoreList,
    isFirstDayOfMonth,
  } = props;
  const hasSales = totalSales != null && totalSales > 0;
  return (
    <React.Fragment>
      <IndexSummaryWrapper isDisplayedStoreList={isDisplayedStoreList}>
        <SummaryTitle>{page === 'daily' ? '月間' : page === 'monthly' && '年度'}売上</SummaryTitle>
        {isFirstDayOfMonth ? <EmptyTarget>-</EmptyTarget> : <FormatTotalSales value={totalSales} />}
        <SummaryTitle>目標差分</SummaryTitle>
        {targetAchievementDiff != null ? (
          <FormatTargetDiff value={targetAchievementDiff} threshold={100} />
        ) : (
          <FormatTotalSales value={0} />
        )}
        <SummaryTitle>人件費率</SummaryTitle>
        <StyledValue
          color={greaterThanOrEqualTo(goalLaborCostRate, personnelExpenseRate) ? dangerColor : black}
        >
          {hasSales ? (
            <FormatPercentage value={personnelExpenseRate} />
          ) : (
            <NoPercent>
              <FormatPercentage value={'-'} />
            </NoPercent>
          )}
        </StyledValue>
        <SummaryTitle>原価率</SummaryTitle>
        <StyledValue color={greaterThanOrEqualTo(goalFoodCostRate, costRate) ? dangerColor : black}>
          {hasSales ? (
            <FormatPercentage value={costRate} />
          ) : (
            <NoPercent>
              <FormatPercentage value={'-'} />
            </NoPercent>
          )}
        </StyledValue>
      </IndexSummaryWrapper>
    </React.Fragment>
  );
};

const IndexSummaryWrapper = styled.div<{ isDisplayedStoreList: boolean }>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 34px;
  width: 80%;
  margin-top: ${props => (props.isDisplayedStoreList ? '20px;' : '5px;')};
  max-width: 304px;
`;

const SummaryTitle = styled.div`
  font-size: 10px;
  line-height: 10px;
  margin-bottom: 6px;
`;

const EmptyTarget = styled.span`
  line-height: 16px;
`;

const StyledValue = styled.span<{ color: string }>`
  color: ${props => props.color};
  line-height: 16px;
`;

const NoPercent = styled.div`
  display: inline;
  margin-left: 12px;
`;

export default TopPane;
