const Zindex = {
  selectBox: 1000,
  //selectBoxのmultipleタイプ時にlistItemがboxpointerに被る時に使用
  listItem: 1001,
  tableRowNameCell: 1,
  dialogPortal: 1000,
  // modalの上から表示させることができる
  modalPortal: 999,
  tooltip: 1000,
  menuTable: 10,
  confirmModal: 6,
  toastModal: 1000,
  menuSearchModal: 6,
  underModal: 998,
  // 月次レポート商品分析の商品名
  menuName: 0,
  // 年始サマリの背景画像
  yearMonthReportBg: -2,
  yearMonthStoreList: 1,
  newYearReportBox: -1,
  // 売上一括取り込みのカレンダー表示用（AIRLAB-16748）
  datePickerCalendar: 100,
};

export default Zindex;
