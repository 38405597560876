import axios from 'axios';
import { getAPIURL } from '../helpers/stringHelper';
import { AxiosResponse } from 'axios';
import {
  AllIndexSummaryItemResponse,
  ThisMonthPostConfigRequest,
  CustomRangeResponse,
  CustomRangeRequest,
} from '../typedef/api/AllIndex';
import { MapiResponse, ApiPromise, PostResponse, ErrorType } from '../typedef/api/Utility';
const AllIndexAPI = {
  fetchMonthlyItem(): ApiPromise<AllIndexSummaryItemResponse> {
    const url = getAPIURL('core/v2/stores/summaries_item/get_monthly/');
    return axios
      .get(url)
      .then((res: AxiosResponse<MapiResponse<AllIndexSummaryItemResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  postMTDConfig(value: ThisMonthPostConfigRequest): ApiPromise<PostResponse> {
    const url = getAPIURL('core/v2/stores/summaries_item/monthly/');
    return axios
      .post(url, value)
      .then((res: AxiosResponse<MapiResponse<PostResponse>>) => {
        return {
          payload: res.data.result,
        };
      })
      .catch((error: ErrorType) => {
        return {
          error,
        };
      });
  },

  fetchCustomRangeStoreData(req: CustomRangeRequest): ApiPromise<CustomRangeResponse> {
    const url = getAPIURL('core/v4/store_summaries/custom_range');
    return axios
      .post(url, req)
      .then((res: AxiosResponse<MapiResponse<{ customRangeResult: CustomRangeResponse }>>) => {
        return {
          payload: res.data.result.customRangeResult,
        };
      })
      .catch((error: ErrorType) => {
        return { error };
      });
  },
};
export default AllIndexAPI;
