import * as React from 'react';
import styled from 'styled-components';
import { bgGray, lightgray, white } from '../../../../../constants/colors';
import { INDICES_TYPE } from '../../storesConstants';
import DataTable from '../../../../../icons/Datatable.png';
import Calendar from '../../../../../icons/Calendar.png';

type Props = {
  readonly selectedIndicesType: keyof typeof INDICES_TYPE;
  readonly onClickItem: (indicesType: keyof typeof INDICES_TYPE) => void;
  readonly className?: string;
};
type State = {
  selectedItem: string;
  textArray: ReadonlyArray<{ text: string; id: string }>;
};

class Toggle extends React.Component<Props, State> {
  render() {
    const { className, selectedIndicesType, onClickItem } = this.props;
    return (
      <ToggleStateless
        selectedIndicesType={selectedIndicesType}
        className={className || ''}
        onClickItem={onClickItem}
      />
    );
  }
}

class ToggleStateless extends React.Component<Props> {
  render() {
    const { className, selectedIndicesType, onClickItem } = this.props;
    return (
      <Row className={className} id={'store_indices_type_toggle'}>
        <Item
          onClick={() => {
            if (selectedIndicesType !== 'calendar') {
              onClickItem('calendar');
            }
          }}
          isSelected={selectedIndicesType === 'calendar'}
        >
          <Img src={Calendar} alt="カレンダー" />
          <Text>カレンダー</Text>
        </Item>
        <Item
          onClick={() => {
            if (selectedIndicesType === 'calendar') {
              onClickItem('daily');
            }
          }}
          isSelected={selectedIndicesType !== 'calendar'}
          id={'store_indices_type_toggle_table'}
        >
          <Img src={DataTable} alt="表" />
          <Text>表</Text>
        </Item>
      </Row>
    );
  }
}

const Row = styled.div`
  height: 44px;
  width: 144px;
  padding: 4px;
  background: ${bgGray};
  border-radius: 4px;
  display: inline-block;
  align-items: center;
`;
const Item = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-flow: column;
  display: inline-block;
  padding: 4px;
  height: 36px;
  width: 68px;
  cursor: pointer;
  background-color: ${props => (props.isSelected ? white : bgGray)};
  border: ${props => (props.isSelected ? `solid 1px ${lightgray}` : white)};
  border-radius: 6px;
  text-align: center;
`;

const Img = styled.img`
  display: block;
  margin: auto;
  height: 14px;
  width: 14px;
`;

const Text = styled.div`
  font-weight: 300;
  font-size: 10px;
  margin-top: 2px;
`;

export default Toggle;
