import React from 'react';
import styled from 'styled-components';
import {
  BANNER_TYPE,
  BANNER_TYPE_SHIFT,
  BANNER_TYPE_RESTAURANT_BOARD,
  BANNER_TYPE_ORDER,
  BANNER_TYPE_CARD,
  BANNER_TYPE_IDEALIST,
  BANNER_TYPE_CASH,
  BANNER_TYPE_SPO,
  BANNER_TYPE_REGI_LINKAGE_PAYQR,
} from '../../../storesConstants';
import { black } from '../../../../../../constants/colors';
import ShiftLogoImage from '../../../../../../icons/shift_logo_image.svg';
import RestaurantBoardLogoImage from '../../../../../../icons/RestaurantBoard.svg';
import CardLogoImage from '../../../../../../icons/LogoAirCard.svg';
import OrderLogoImage from '../../../../../../icons/LogoAirOrder.svg';
import CashLogoImage from '../../../../../../icons/AirCashLogo.svg';

type Props = {
  readonly bannerType: BANNER_TYPE;
  readonly from: 'calendar' | 'daily';
};

const TitleText = ({ bannerType }: { bannerType: BANNER_TYPE }) => {
  switch (bannerType) {
    case BANNER_TYPE_SHIFT:
      return (
        <Title>
          やりとりも作成もラクになる
          <br />
          シフト管理サービス
        </Title>
      );
    case BANNER_TYPE_CARD:
      return (
        <Title>
          経費管理がラクになりポイントもたまる
          <br />
          おトクなビジネスカード
        </Title>
      );
    case BANNER_TYPE_ORDER:
      return (
        <Title>
          飲食店の業務をカンタンにする
          <br />
          オーダーシステム
        </Title>
      );
    case BANNER_TYPE_RESTAURANT_BOARD:
      return <Title>集客と接客に効く予約台帳アプリ</Title>;

    case BANNER_TYPE_CASH:
      return (
        <Title>
          将来の売上を今のお金にかえる
          <br />
          資金調達サービス
        </Title>
      );

    default:
      return <React.Fragment />;
  }
};

const Logo = ({ bannerType }: { bannerType: BANNER_TYPE }) => {
  switch (bannerType) {
    case BANNER_TYPE_SHIFT:
      return <ShiftLogoStyle />;
    case BANNER_TYPE_CARD:
      return <CardLogoStyle />;
    case BANNER_TYPE_ORDER:
      return <OrderLogoStyle />;
    case BANNER_TYPE_RESTAURANT_BOARD:
      return <RestaurantBoardLogoStyle />;
    case BANNER_TYPE_CASH:
      return <CashLogoStyle />;

    default:
      return <React.Fragment></React.Fragment>;
  }
};

const TitleLogoWrapper = (props: Props) => {
  const { bannerType, from } = props;

  return (
    <TitleWrapper from={from} bannerType={bannerType}>
      <TitleText bannerType={bannerType} />
      <LogoWrapper from={from}>
        <Logo bannerType={bannerType} />
      </LogoWrapper>
    </TitleWrapper>
  );
};

const TitleWrapper = styled.div<{ from?: string; bannerType?: BANNER_TYPE }>`
  line-height: 14px;
  text-align: center;
  margin-top: ${props =>
    props.from === 'daily'
      ? props.bannerType === BANNER_TYPE_IDEALIST || props.bannerType === BANNER_TYPE_SPO
        ? '14px'
        : props.bannerType === BANNER_TYPE_REGI_LINKAGE_PAYQR
        ? '20px'
        : '38px'
      : '14px'};
`;

const Title = styled.div<{ fontSize?: number }>`
  color: ${black};
  font-size: ${props => (props.fontSize != null ? props.fontSize : '10')}px;
  font-weight: 600;
`;

const LogoWrapper = styled.div<{ from?: string }>`
  margin-top: 5px;
  ${props => props.from === 'daily' && 'transform: scale(76%);'}
`;

const ShiftLogoStyle = styled(ShiftLogoImage)`
  width: 130px;
  margin-top: 6px;
`;

const RestaurantBoardLogoStyle = styled(RestaurantBoardLogoImage)`
  width: 101px;
  height: 32px;
  margin-top: 6px;
`;

const CardLogoStyle = styled(CardLogoImage)`
  width: 105px;
  height: 20px;
  margin-top: 6px;
`;

const OrderLogoStyle = styled(OrderLogoImage)`
  width: 153px;
  height: 20px;
  margin-top: 6px;
`;

const CashLogoStyle = styled(CashLogoImage)`
  width: 112px;
  margin-top: 6px;
`;

export default TitleLogoWrapper;
