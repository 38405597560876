import React from 'react';
import styled from 'styled-components';
import {
  airblue,
  buttonHoverColor,
  buttonActiveColor,
  airblueBorder,
  white,
} from '../../../../constants/colors';
import MateBowing from '../../../../icons/NewYearReport/MateBowing.svg';
import MateIntro from '../../../../icons/NewYearReport/MateIntro.svg';
import AirMateLogo from '../../../../icons/NewYearReport/AirMateLogo.svg';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import { yearMonthAppLink } from '../../../../constants/externalLink';
import { Waypoint } from 'react-waypoint';
import { genGaLog } from '../../../../gaLogger';
import { track } from '../../../../modules/logging';

type Props = RouteComponentProps<{}> & ThroughProps;

type ThroughProps = {
  isSp: boolean;
  readonly track: typeof track;
};

/**
 * 年始サマリのフッター
 * 2024年始サマリがみれるようになったら、このファイルは削除する。
 */
const NewYearReportFooter = (props: Props) => {
  const { isSp, history, track } = props;

  return (
    <React.Fragment>
      <Wrapper isSp={isSp}>
        <FlexWrapper>
          <Text isSp={isSp}>
            昨年以上にお客さまに愛されるように、
            <br />
            今年もあなたのお店づくりをサポートします。
          </Text>
          <Waypoint
            onEnter={() => {
              track(_genImpressionLog());
            }}
          >
            <StyledMateBowing>
              <MateBowing />
            </StyledMateBowing>
          </Waypoint>
          <BlueText>2024年もAirメイトを{isSp && <br />}よろしくお願いいたします。</BlueText>
          {!isSp && (
            <CloseButtonWrapper>
              <StyledButton
                onClick={() => {
                  history.push('/store/indices');
                  track(_genClickLog('pc'));
                }}
              >
                レポートを閉じてAirメイトに戻る
              </StyledButton>
              <AnnotationText>
                このレポートをまた見たい場合は、Airメイト内のお知らせをご確認ください。
              </AnnotationText>
            </CloseButtonWrapper>
          )}
          <AboutAreaWrapper isSp={isSp}>
            {!isSp && <StyledMateIntro />}
            <AboutWrapper>
              <AboutTitleWrapper>
                <AirMateLogo />
                <AboutText>について</AboutText>
              </AboutTitleWrapper>
              <DescriptionWrapper isSp={isSp}>
                <DescriptionText isSp={isSp}>
                  Airメイトは、AirレジをはじめとするAir
                  ビジネスツールズと連携して、無料でカンタンにお店の状況を把握できるサービスです。
                  <br />
                  Airレジで会計を行なっていると、すぐに分析を開始できます。ぜひ引き続きご利用ください。
                </DescriptionText>
              </DescriptionWrapper>
              {isSp && (
                <AppWrapper>
                  <AppText>Airメイトのご利用はこちらから</AppText>
                  <StyledLink
                    href={yearMonthAppLink}
                    target="_blank"
                    onClick={() => {
                      track(_genClickLog('sp'));
                    }}
                  >
                    アプリを開く
                  </StyledLink>
                </AppWrapper>
              )}
            </AboutWrapper>
          </AboutAreaWrapper>
        </FlexWrapper>
      </Wrapper>
    </React.Fragment>
  );
};

export default withRouter(NewYearReportFooter);

const _genImpressionLog = () => {
  return genGaLog('year_lookback2023', 'last_mate_info', 'impression', {}, {}, 'impression');
};

const _genClickLog = (param: 'sp' | 'pc') => {
  return genGaLog('year_lookback2023', 'mate_link', 'button', {}, { view: param }, 'click');
};

const Wrapper = styled.div<{ isSp: boolean }>`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  margin-top: 56px;
  ${props => !props.isSp && 'padding: 0 60px'};
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Text = styled.div<{ isSp: boolean }>`
  font-size: ${props => (props.isSp ? '14px' : '16px')};
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
`;

const AnnotationText = styled.div`
  margin-top: 16px;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: center;
`;

const BlueText = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
  color: ${airblue};
`;

const StyledMateIntro = styled(MateIntro)`
  width: 87px;
  height: 100px;
  margin: 16px 0;
`;

const CloseButtonWrapper = styled.div`
  margin-top: 40px;
`;

const StyledButton = styled.button`
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  height: 48px;
  width: 320px;
  padding: 12px 16px 12px 16px;
  white-space: nowrap;
  background: ${airblue};
  box-shadow: inset 0 1px 0 0 ${airblueBorder} inset -1px 0 0 0 ${airblueBorder},
    inset 1px 0 0 0 ${airblueBorder}, inset 0 -2px 0 0 ${airblueBorder};
  color: ${white};
  &:hover {
    background: ${buttonHoverColor};
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
  &:active {
    background: ${buttonActiveColor};
  }
`;

const StyledLink = styled.a`
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  height: 48px;
  width: 100%;
  padding: 12px 16px 12px 16px;
  white-space: nowrap;
  background: ${airblue};
  box-shadow: inset 0 1px 0 0 ${airblueBorder} inset -1px 0 0 0 ${airblueBorder},
    inset 1px 0 0 0 ${airblueBorder}, inset 0 -2px 0 0 ${airblueBorder};
  color: ${white};
  &:hover {
    background: ${buttonHoverColor};
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
  &:active {
    background: ${buttonActiveColor};
  }
`;

const AboutAreaWrapper = styled.div<{ isSp: boolean }>`
  margin-top: 56px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: ${props => (props.isSp ? '406px' : '176px')};
  background-color: ${white};
  ${props => !props.isSp && 'padding: 32px 32px 32px 55px;'}
  ${props => !props.isSp && 'margin-bottom:80px;'}
  ${props => props.isSp && 'padding: 40px 0;'}
`;

const AboutWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledMateBowing = styled.div`
  width: 54px;
  height: 73px;
  margin: 16px 0;
`;

const AboutTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  padding: 0 32px;
`;

const AboutText = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  margin-left: 6px;
`;

const DescriptionWrapper = styled.div<{ isSp: boolean }>`
  display: flex;
  padding: 0 32px;
  margin-top: 16px;
`;

const DescriptionText = styled.div<{ isSp: boolean }>`
  font-size: 14px;
  font-weight: 400;
  line-height: ${props => (props.isSp ? '24px' : '23px')};
  letter-spacing: 0px;
  text-align: left;
`;

const AppWrapper = styled.div`
  padding: 0 32px;
`;

const AppText = styled.div`
  margin-top: 48px;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 15px;
`;
