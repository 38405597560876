import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  airblue,
  black,
  hoverAndSelectedColor,
  uploadBorderColor,
  white,
} from '../../../../constants/colors';
import Logo from '../../../../icons/logo.svg';
import ArrowDown from '../../../../icons/ArrowDownWhite.svg';
import Close from '../../../../icons/closeWhite.svg';
import Zindex from '../../../../constants/z-index';
import ClickOutside from 'react-click-outside';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import { track } from '../../../../modules/logging';
import { genGaLog } from '../../../../gaLogger';
import { getCookie } from '../../../../helpers/cookieHelper';
import { YearlyLookbackResponse } from '../../../../typedef/api/YearlyReport/Yearlyreport';

type Props = RouteComponentProps<{}> & ThroughProps;

type ThroughProps = {
  children: React.ReactNode;
  readonly track: typeof track;
  yearlyReport: YearlyLookbackResponse;
};

/**
 * 年始サマリのヘッダーレイアウト
 */
const YearlyReportLayout = (props: Props) => {
  const { yearlyReport, history, track } = props;
  const { storeDetails, topSalesStores } = yearlyReport.yearlyLookback;
  const [isOpenStoreList, setIsOpenStoreList] = useState<boolean>(false);

  const _handleToggleSelectBox = (e: React.SyntheticEvent<HTMLElement>) => {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため
    setIsOpenStoreList(false);
    e.preventDefault();
  };

  useEffect(() => {
    track(_genOnLoadLog());
  }, [track]);

  return (
    <React.Fragment>
      <Wrapper>
        <StyledLogo
          onClick={() => {
            track(_genClickLog('logo_mate_link', 'button'));
            history.push('/store/indices');
          }}
        />
        <FlexWrapper>
          {/* 売上1円以上の店舗が2店舗以上の場合、店舗を選ぶボタン表示 */}
          {storeDetails.length > 1 && (
            <SelectStoreListWrapper>
              <ButtonWrapper
                onClick={() => {
                  setIsOpenStoreList(true);
                  track(_genClickLog('store_select', 'header_button'));
                }}
              >
                <StyledArrowDown />
                <Text>店舗を選ぶ</Text>
              </ButtonWrapper>
              {isOpenStoreList && (
                <ClickOutside
                  onClickOutside={e => {
                    _handleToggleSelectBox(e);
                  }}
                >
                  <BoxPointer />
                  <List>
                    {/* 一般権限の場合 or 売上が1円以上の店舗が2店舗以上存在しない場合、全店舗まとめはトルツメ*/}
                    {topSalesStores.length !== 0 && storeDetails.length >= 2 && (
                      <ListItem
                        onClick={() => {
                          setIsOpenStoreList(false);
                          document
                            .getElementById('all_stores')
                            ?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                          track(_genClickLog('store_select', 'store_button'));
                        }}
                      >
                        全店舗のまとめ
                      </ListItem>
                    )}
                    {storeDetails.map((store, i) => {
                      return (
                        <ListItem
                          onClick={() => {
                            setIsOpenStoreList(false);
                            document
                              .getElementById(`store_${i}`)
                              ?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                            track(_genClickLog('store_select', 'store_button'));
                          }}
                        >
                          {store.storeName}
                        </ListItem>
                      );
                    })}
                  </List>
                </ClickOutside>
              )}
            </SelectStoreListWrapper>
          )}
          {storeDetails.length !== 0 && (
            <ButtonWrapper
              onClick={() => {
                history.push('/store/indices');
                track(_genClickLog('lookback_close', 'close_button'));
              }}
              isMargin={true}
            >
              <StyledCloseIcon />
              <Text>ウィンドウを閉じる</Text>
            </ButtonWrapper>
          )}
        </FlexWrapper>
      </Wrapper>
      {props.children}
    </React.Fragment>
  );
};

export default withRouter(YearlyReportLayout);

const _genOnLoadLog = () => {
  const cookieData = getCookie('influxData');
  let lid: string | undefined;
  if (cookieData != null) {
    const cookieDataJson = JSON.parse(cookieData);
    lid = cookieDataJson.lid;
  }
  return genGaLog(
    'year_lookback',
    'year_lookback',
    'on_load',
    {},
    {},
    'load',
    undefined,
    undefined,
    lid,
    undefined
  );
};

const _genClickLog = (feature: string, funcName: string) => {
  return genGaLog('year_lookback', feature, funcName, {}, {}, 'click');
};

const Wrapper = styled.div`
  background-color: ${airblue};
  color: ${white};
  display: flex;
  align-items: center;
  padding: 10px 24px 10px 16px;
  justify-content: space-between;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLogo = styled(Logo)`
  width: 128px;
  cursor: pointer;
`;

const StyledArrowDown = styled(ArrowDown)`
  margin-right: 8px;
`;

const StyledCloseIcon = styled(Close)`
  width: 12px;
  margin-right: 8px;
`;

const Text = styled.div`
  color: ${white};
  font-size: 14px;
`;

const BoxPointer = styled.div`
  top: 16px;
  left: 5px;
  width: 0;
  height: 0;
  position: absolute;
  border: 10px solid transparent;
  border-bottom: 10px solid ${white};
  z-index: ${Zindex.selectBox};
`;

const ButtonWrapper = styled.button<{ isMargin?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  ${props => props.isMargin && 'margin-left: 32px'};
`;

const List = styled.div`
  width: 240px;
  background-color: ${white};
  position: absolute;
  top: 35px;
  left: -2px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  max-height: 400px;
  overflow-y: scroll;
  z-index: ${Zindex.yearMonthStoreList};
`;

const ListItem = styled.div`
  color: ${black};
  min-height: 40px;
  padding: 12px;
  :not(:first-child) {
    border-top: solid 1px;
    border-color: ${uploadBorderColor};
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  word-break: break-all;
  cursor: pointer;
  &:hover {
    background-color: ${hoverAndSelectedColor};
  }
`;

const SelectStoreListWrapper = styled.div`
  position: relative;
`;
