import * as React from 'react';
import styled from 'styled-components';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DayPickerRangeController, FocusedInputShape } from 'react-dates';
import './calStyle.css';
import { MclDayjs, formatter, mclDayjs, parser } from '../../../../helpers/mclDate';
import { Button } from '@air-kit/air-kit';
import ClickOutside from 'react-click-outside';
import { uploadBorderColor } from '../../../../constants/colors';
import Text from '../../../common/atoms/Text';
import ArrowLeft from '../../../../icons/ArrowLeft.svg';
import ArrowRight from '../../../../icons/ArrowRight.svg';
import Calendar from '../../../../icons/calendarIcon.svg';
import ZIndex from '../../../../constants/z-index';
import { BatchProcessedDate } from '../../../../typedef/BatchProcessedDate';
// eslint-disable-next-line import/no-restricted-paths
import moment from 'moment';

type Props = {
  startDate: MclDayjs;
  endDate: MclDayjs;
  batchDate: BatchProcessedDate;
  selectDate: (b: MclDayjs, a: MclDayjs) => void;
  comment?: string;
  openBalloon?: () => void;
  changeRangeButton?: (range: 'this_month' | 'last_month' | 'yesterday') => void;
  clickMonthNav?: (nav: 'later' | 'ago') => void;
  changeFocusedRange?: (date: string) => void;
  id?: string;
  balloonPosition?: 'right' | 'left' | 'center';
};

type State = {
  focused: FocusedInputShape;
  tempStartDate: MclDayjs;
  tempEndDate: MclDayjs | undefined | null;
  isOpen: boolean;
  calendarChangeFlag: boolean;
  fadeFlag: boolean;
};

export default class RangeCalendar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      focused: 'startDate',
      tempStartDate: mclDayjs(),
      tempEndDate: mclDayjs(),
      isOpen: false,
      calendarChangeFlag: false,
      fadeFlag: false,
    };
  }

  componentDidMount() {
    this.setState({
      tempStartDate: this.props.startDate,
      tempEndDate: this.props.endDate,
    });
  }

  componentWillUnmount() {
    this.setState({
      isOpen: false,
    });
  }

  _handleOpenCloseBalloonModal = (e: React.SyntheticEvent<HTMLElement>, changeFlag?: boolean) => {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため

    if (!this.state.isOpen && this.props.openBalloon != null) {
      this.props.openBalloon();
    }
    if (changeFlag) {
      this.setState({
        isOpen: !this.state.isOpen,
        tempStartDate: this.props.startDate,
        tempEndDate: this.props.endDate,
      });
    } else {
      this.setState({
        isOpen: !this.state.isOpen,
      });
    }

    e.preventDefault();
  };

  _handleClickMonthPrev = () => {
    if (this.props.clickMonthNav != null) {
      this.props.clickMonthNav('ago');
    }
  };

  _handleClickMonthNext = () => {
    if (this.props.clickMonthNav != null) {
      this.props.clickMonthNav('later');
    }
  };

  _renderBalloonContent = () => {
    const { batchDate, comment, balloonPosition } = this.props;
    const { tempStartDate, tempEndDate, calendarChangeFlag, fadeFlag } = this.state;
    return (
      <ClickOutside onClickOutside={e => this._handleOpenCloseBalloonModal(e, true)}>
        <BalloonWrapper>
          <Balloon position={balloonPosition != null ? balloonPosition : 'left'}>
            <Container>
              <CalendarWrapper>
                <div className={`mateCalendar${fadeFlag ? ' fade' : ''}`}>
                  <DayPickerRangeController
                    key={calendarChangeFlag.toString()}
                    startDate={moment(tempStartDate.toISOString())} // 選択開始日
                    endDate={tempEndDate != null ? moment(tempEndDate?.toISOString()) : null} // 選択終了日
                    transitionDuration={0}
                    onDatesChange={({ startDate, endDate }) => {
                      if (this.state.focused === 'startDate') {
                        if (this.props.changeFocusedRange != null && startDate != null) {
                          this.props.changeFocusedRange(
                            mclDayjs(startDate.toISOString()).format(formatter.mapiDate)
                          );
                        }
                        if (startDate != null) {
                          this.setState({
                            tempStartDate: mclDayjs(startDate.toISOString()),
                            tempEndDate: null,
                          });
                        }
                      } else {
                        if (startDate != null && endDate != null) {
                          this.setState({
                            tempStartDate: mclDayjs(startDate.toISOString()),
                            tempEndDate: mclDayjs(endDate.toISOString()),
                          });
                        }
                      }
                    }}
                    focusedInput={this.state.focused}
                    onFocusChange={focusedInput => {
                      this.setState({
                        focused: !focusedInput ? 'startDate' : focusedInput,
                      });
                    }}
                    initialVisibleMonth={() => moment(tempStartDate.toISOString())}
                    isOutsideRange={day =>
                      mclDayjs(day.toISOString()).isAfter(parser.fromDateObject(batchDate), 'day') ||
                      mclDayjs(day.toISOString()).isBefore(
                        parser.fromDateObject({ year: 2013, month: 1, day: 1 })
                      )
                    } // disabledとする
                    enableOutsideDays // 該当月以外の日付も表示
                    hideKeyboardShortcutsPanel // 右下に表示される？ボタン削除
                    noBorder // カレンダー周囲の線削除
                    numberOfMonths={1} // カレンダーの数
                    monthFormat="YYYY[年]M[月]" // カレンダーの日時フォーマット
                    firstDayOfWeek={1} // 曜日を月（１）始まりにする
                    minimumNights={0} // 選択できる最低限の日数
                    verticalBorderSpacing={5}
                    navPrev={
                      <StyledArrow direction="left">
                        <ArrowLeft />
                      </StyledArrow>
                    }
                    navNext={
                      <StyledArrow direction="right">
                        <ArrowRight />
                      </StyledArrow>
                    }
                    onPrevMonthClick={this._handleClickMonthPrev}
                    onNextMonthClick={this._handleClickMonthNext}
                  />
                  <CalText>開始日と終了日を選択してください。</CalText>
                </div>
                <RightWrapper>
                  <SelectPeriodWrapper>
                    <PeriodButton
                      onClick={() =>
                        this._handleChangeCalendar(
                          mclDayjs().startOf('month'),
                          mclDayjs().subtract(1, 'd'),
                          'this_month'
                        )
                      }
                      disabled={moment().date() === 1}
                    >
                      今月
                    </PeriodButton>
                    <PeriodButton
                      onClick={() =>
                        this._handleChangeCalendar(
                          mclDayjs().subtract(1, 'M').startOf('month'),
                          mclDayjs().subtract(1, 'M').endOf('month'),
                          'last_month'
                        )
                      }
                    >
                      先月
                    </PeriodButton>
                    <PeriodButton
                      onClick={() =>
                        this._handleChangeCalendar(
                          parser.fromDateObject(batchDate),
                          parser.fromDateObject(batchDate),
                          'yesterday'
                        )
                      }
                    >
                      昨日
                    </PeriodButton>
                  </SelectPeriodWrapper>
                  {comment != null && <Comment>{comment}</Comment>}
                </RightWrapper>
              </CalendarWrapper>
              <SubmitWrapper>
                <SubmitButton
                  primary
                  disabled={this.state.tempEndDate == null}
                  // @ts-ignore airkit
                  onClick={e => {
                    if (this.state.tempEndDate != null) {
                      this.props.selectDate(this.state.tempStartDate, this.state.tempEndDate);

                      this._handleOpenCloseBalloonModal(e);
                    }
                  }}
                >
                  確定
                </SubmitButton>
              </SubmitWrapper>
            </Container>
          </Balloon>
          <BoxPointer position={balloonPosition != null ? balloonPosition : 'left'} />
        </BalloonWrapper>
      </ClickOutside>
    );
  };

  _handleChangeCalendar = (
    tempStartDate: MclDayjs,
    tempEndDate: MclDayjs,
    logInfo: 'this_month' | 'last_month' | 'yesterday'
  ) => {
    this.setState({
      fadeFlag: true,
    });
    setTimeout(() => {
      this.setState({
        tempStartDate,
        tempEndDate,
        calendarChangeFlag: !this.state.calendarChangeFlag,
        fadeFlag: false,
      });
      if (this.props.changeRangeButton != null) {
        this.props.changeRangeButton(logInfo);
      }
    }, 200);
  };

  render() {
    const { startDate, endDate, id } = this.props;
    return (
      <Wrapper id={id}>
        <ModalContainer onClick={e => this._handleOpenCloseBalloonModal(e)}>
          <Text.Default>
            {startDate.format(formatter.mapiDefaultDate)} 〜 {endDate.format(formatter.mapiDefaultDate)}
          </Text.Default>
          <Calendar />
        </ModalContainer>
        {this.state.isOpen && this._renderBalloonContent()}
      </Wrapper>
    );
  }
}

const Container = styled.div`
  padding: 24px;
`;

const CalendarWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 137px;
`;

const StyledArrow = styled.div<{ direction: string }>`
  ${props => props.direction && `${props.direction}: 22px`};
  position: absolute;
  top: 18px;
  line-height: 0.78;
  padding: 6px 9px;
`;

const CalText = styled.p`
  font-size: 12px;
  text-align: center;
`;

const RightWrapper = styled.div`
  border-left: 1px solid ${uploadBorderColor};
`;

const SelectPeriodWrapper = styled.div`
  text-align: center;
`;

const PeriodButton = styled(Button)`
  margin: 12px 0;
  width: 78px;
  height: 40px;
  padding: 0;
`;

const SubmitWrapper = styled.div`
  border-top: 1px solid ${uploadBorderColor};
  margin-top: 16px;
  padding-top: 18px;
  text-align: right;
`;

const SubmitButton = styled(Button)`
  height: 40px;
  width: 94px;
  font-size: 16px;
`;

const Wrapper = styled.div`
  position: relative;
  min-width: 250px;
`;

const ModalContainer = styled.div`
  overflow: hidden;
  height: 44px;
  border-radius: 4px;
  border: solid 1px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: ${uploadBorderColor};
  cursor: pointer;
`;

const BalloonWrapper = styled.div`
  z-index: ${ZIndex.selectBox};
`;

const Balloon = styled.div<{ position: 'right' | 'left' | 'center' }>`
  background-color: white;
  position: absolute;
  ${props =>
    props.position === 'right' ? 'left: 0;' : props.position === 'left' ? 'right: 0;' : 'right: -130px;'}
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  margin-top: 5px;
  z-index: ${ZIndex.selectBox};
`;

const BoxPointer = styled.div<{ position: 'right' | 'left' | 'center' }>`
  background-color: white;
  ${props => (props.position === 'right' ? 'left: 10px;' : 'right: 10px;')}
  width: 10px;
  height: 10px;
  position: absolute;
  transform: rotate(45deg);
  z-index: ${ZIndex.selectBox};
  box-shadow: -1px -1px 1px 0px rgba(0, 0, 0, 0.05);
`;

const Comment = styled.div`
  font-size: 12px;
  width: 78px;
  margin: auto;
`;
