import React, { useState } from 'react';
import styled from 'styled-components';
import AirRegiLogo from '../../../../../icons/airRegiLogo.svg';
import AirRegiAppeal from '../../../../../icons/airRegiAppealImage.svg';
import AirMateLogo from '../../../../../icons/airMateLogo.svg';
import ArrowUnder from '../../../../../icons/underArrowBlue.svg';
import ArrowRight from '../../../../../icons/arrow_right.svg';
import OpenLink from '../../../../../icons/openLinkBlue.svg';
import { airblue, airblueBorder, black, textLinkColor, white } from '../../../../../constants/colors';
import CloseIcon from '../../../../../icons/close.svg';
import { faqTop } from '../../../../../constants/faqUrls';
import { STORE_CALENDAR_INDICES_POPUP_CLOSE_INFO_KEY_NAME } from '../../../../../constants/LocalStorage';
import { AppealModal } from '../../../../../components/common/appealModal/AppealModal';
import { track } from '../../../../../modules/logging';
import { genGaLog } from '../../../../../gaLogger';

type Props = {
  onClickClose: () => void;
  tracker: typeof track;
  isRegiUse: boolean;
  akrCode?: string;
};

/**
 * 成績カレンダーに表示されるレジ訴求ポップアップ
 */
const AirRegiAppealPopup = (props: Props) => {
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const { onClickClose, tracker, isRegiUse, akrCode } = props;
  return (
    <React.Fragment>
      <Wrapper>
        <CloseButton
          onClick={() => {
            localStorage.setItem(STORE_CALENDAR_INDICES_POPUP_CLOSE_INFO_KEY_NAME, 'close');
            onClickClose();
          }}
        >
          <CloseIcon />
        </CloseButton>
        <ImageWrapper>
          <StyledAirRegiLogo />
          <StyledImg />
          <ArrowUnder />
          <StyledMateLogo />
        </ImageWrapper>
        <TextWrapper>
          <Title>売上を自動連携</Title>
          <Lead>
            Airレジの売上データを
            <br />
            自動で連携できます。
          </Lead>
          <DetailButton
            onClick={() => {
              setIsShowModal(true);
              tracker(_genOpenModalLog(isRegiUse, akrCode));
            }}
          >
            詳しく見る
            <StyledArrow />
          </DetailButton>
          <OthersButton href={faqTop} target="_blank">
            その他の入力方法
            <StyledOpenLink />
          </OthersButton>
        </TextWrapper>
      </Wrapper>
      {isShowModal && (
        <AppealModal
          productType={'STORE_CALENDAR_INDICES_REGI'}
          onClick={() => {
            setIsShowModal(false);
            tracker(_genCloseModalLog(isRegiUse, akrCode));
          }}
          isNoFooter={true}
          tracker={tracker}
          isRegiUse={isRegiUse}
          akrCode={akrCode}
        />
      )}
    </React.Fragment>
  );
};

export default AirRegiAppealPopup;

const Wrapper = styled.div`
  position: relative;
  width: 300px;
  height: 184px;
  border-radius: 6px;
  padding: 20px 0px 12px;
  display: flex;
  justify-content: center;
  background: ${white};
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
`;

const StyledAirRegiLogo = styled(AirRegiLogo)`
  width: 69px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${black};
`;

const Lead = styled.div`
  font-size: 12px;
  font-style: normal;
  color: ${black};
  text-align: center;
  margin: 8px 0;
`;

const DetailButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 126px;
  height: 32px;
  padding: 10px 0;
  margin-bottom: 18px;
  border: 1px solid ${airblueBorder};
  border-radius: 4px;
  background: ${airblue};
  box-shadow: 0px 1px 0px 0px ${airblueBorder};
  color: ${white};
  cursor: pointer;
  font-size: 14px;
`;

const StyledArrow = styled(ArrowRight)`
  margin-left: 9px;
`;

const OthersButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${textLinkColor};
  font-size: 11px;
  font-weight: 300;
  background-color: ${white};
  cursor: pointer;
`;

const StyledOpenLink = styled(OpenLink)`
  margin-left: 6px;
`;

const StyledImg = styled(AirRegiAppeal)`
  margin: 9px 7px;
`;

const StyledMateLogo = styled(AirMateLogo)`
  margin-top: 9px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 18px;
  border: none;
  background: none;
  cursor: pointer;
`;

const _genOpenModalLog = (isRegiUse: boolean, akrCode?: string) => {
  return genGaLog(
    'store_indices',
    'store_indices_crossuse_popup',
    'open',
    {},
    {
      type: ['arg'],
      selected_indices_type: 'calendar',
      cu_status: isRegiUse ? 'use' : 'not_use',
    },
    'click',
    akrCode
  );
};

const _genCloseModalLog = (isRegiUse: boolean, akrCode?: string) => {
  return genGaLog(
    'store_indices',
    'store_indices_argpopup_modal',
    'close',
    {},
    {
      type: ['arg'],
      cu_status: isRegiUse ? 'use' : 'not_use',
    },
    'click',
    akrCode
  );
};
